import React from 'react';
import { objectsForEach } from '../../common/objectToArray';
import { uploadLeadDocument } from '../../services/manage-leads';
import splitUnderscoreToCaptialise from '../../common/splitUnderscoreToCaptialise';
import BranchCode from './BranchCode';
import AdditionalInfoCTA from '../../Views/Portfolio/ViewPortfolio/AdditionalInfo/AdditionalInfoCTA';
const Input = ({
	rule,
	label,
	id,
	errors,
	type,
	parentIndex,
	parentId,
	setBody,
	body,
	edit,
	leadId,
	params={},
}) => {

	const [addInfoModalState, setAddInfoModalState] = React.useState(false);

	const handleAdditionalInfoModal = () => {
		setAddInfoModalState(!addInfoModalState)
	}

	const getSplittedLabel = (label) => {
		let newLabel = label;
		if (label?.includes('Add/')) {
			newLabel = label.split('Add/')[1]
		}
		return newLabel;
	}

	const getStyles = id => {
		switch (id) {
			case 'pan_no':
			case 'comp_pan':
			case 'gst_no':
			case 'org_pan':
			case 'pan_number':
				return { textTransform: 'uppercase' };
		}
	};
	const onChange = async e => {
		let value;
		let inputValue = e.target.value;
		if (rule.input_type === 'file') {
			const response = await uploadLeadDocument(e.target.files[0]);
			if (response?.status?.code) {
				inputValue = response.data.resource.file_id;
			}
		}
		if (type === 'multiple') {
			value = body[parentId];
			value[parentIndex] = {
				...value[parentIndex],
				[id]: inputValue
			};
		} else {
			value = {
				...body[parentId],
				[id]: inputValue
			};
		}
		setBody(values => {
			return {
				...values,
				...body,
				[parentId]: value
			};
		});
	};

	if (rule.input_type === 'branch_code') {
		return <BranchCode />;
	}
	if (rule.input_type === 'dropdown') {
		return (
			<>
				<select
					disabled={
						rule.disabled || (!edit && leadId) || (edit && id === 'assigned_to')
					}
					value={
						type === 'multiple'
							? body?.[parentId]?.[parentIndex]?.[id]
							: body?.[parentId]?.[id]
					}
					onChange={onChange}
					className='form-control'
				>
					<option value={''}>{'Please Select'}</option>

					{objectsForEach(rule.options, 'STRING_VAL').map(({ value, id }) => (
						<option value={value}>{splitUnderscoreToCaptialise(id)}</option>
					))}
				</select>

				{errors && (
					<p className='mt-2 mb-0 text-danger'>
						{type === 'multiple'
							? errors?.[parentId]?.[parentIndex]?.[id]
							: errors?.[parentId]?.[id]}
					</p>
				)}
			</>
		);
	}

	if (rule.input_type === 'cta') {
		return (
			<>
				<AdditionalInfoCTA
					modalState={addInfoModalState}
					toggleModal={handleAdditionalInfoModal}
					onChange={onChange}
					params={params}
					keyName={id}
					leadId={leadId}
					body={body}
					edit={edit}
				/>
				<span
					style={{'width': 'max-content',padding:'5px'}}
					className='btn btn-custom'
					onClick={handleAdditionalInfoModal}
					value={
						rule.input_type !== 'file' && type === 'multiple'
							? (body?.[parentId]?.[parentIndex]?.[id] || '')
							: (body?.[parentId]?.[id] || '')
					}
				>
					{getSplittedLabel(label)}
				</span>
				{errors && (
					<p className='mt-2 mb-0 text-danger'>
						{type === 'multiple'
							? errors?.[parentId]?.[parentIndex]?.[id]
							: errors?.[parentId]?.[id]}
					</p>
				)}
			</>
		);
	}

	return (
		<>
			<input
				disabled={rule.disabled || (!edit && leadId)}
				onChange={onChange}
				onKeyDown={event => {
					var invalidChars = ['-', '+', 'e'];
					if (id === 'cibil_score' && event.key == '-') {
						return;
					} else if (
						rule.input_type === 'number' &&
						invalidChars.includes(event.key)
					) {
						event.preventDefault();
						return;
					}
				}}
				type={rule.input_type}
				className='form-control'
				value={
					rule.input_type !== 'file' && type === 'multiple'
						? body?.[parentId]?.[parentIndex]?.[id]
						: body?.[parentId]?.[id]
				}
				style={getStyles(id)}
			/>
			{rule.input_type === 'file' &&
				(type === 'multiple' ? (
					<a
						className='link'
						href={body?.[parentId]?.[parentIndex]?.[id + '_url']}
						target='_blank'
					>
						{body?.[parentId]?.[parentIndex]?.[id + '_filename']}
					</a>
				) : (
					<a
						className='link'
						href={body?.[parentId]?.[id + '_url']}
						target='_blank'
					>
						{' '}
						{body?.[parentId]?.[id + '_filename']}
					</a>
				))}
			{errors && (
				<p className='mt-2 mb-0 text-danger'>
					{type === 'multiple'
						? errors?.[parentId]?.[parentIndex]?.[id]
						: errors?.[parentId]?.[id]}
				</p>
			)}
		</>
	);
};

export default Input;

import ReactApexChart from 'react-apexcharts';

function GraphContainer({
	title,
	type,
	filters,
	loading,
	error,
	series,
	options,
	children,
	height,
	width,
}) {
	return (
		<div className='lender-portfolio-pie'>
			<div className='d-flex align-items-center justify-content-between'>
				<div className='chart-heading text-orange'>{title}</div>
			</div>

			<div className='mt-2'>{filters}</div>
			{children}
			<div className='chart-container text-center mt-4'>
				{loading && <div className='loader' />}
				{(error || series?.length === 0) && !loading && (
					<div className='text-center'>
						{error ? 'Failed to load chart data' : 'No data found'}
					</div>
				)}
				{!loading && series?.length > 0 && (
					<ReactApexChart
						options={options}
						series={series}
						height={height}
						width={width}
						type={type}
					/>
				)}
			</div>
		</div>
	);
}

export default GraphContainer;

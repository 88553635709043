import React from 'react';
import { Link } from 'react-router-dom';
const AffiliateActions = ({
	showDropDown,
	setDropDownStatus,
	changeAffiliateStatus,
	checkHasPermission,
	affiliateTypeModal,
	part_id,
	part_type,
	block_status_id,
	is_active
}) => {
	return (
		<div className={`dropdown ${showDropDown && 'show'}`}>
			<button
				// onBlur={() => setDropDownStatus(false)}
				onClick={() => setDropDownStatus(!showDropDown)}
				className='btn dropdown-toggle ActionBtn'
				type='button'
				data-toggle='dropdown'
				aria-haspopup='true'
				aria-expanded={showDropDown}
			>
				<span className='text-gray'>Action</span>
			</button>
			<div
				className={`dropdown-menu min-width-auto ${showDropDown && 'show'}`}
				aria-labelledby='dropdownMenuButton'
			>
				{checkHasPermission('update-affiliate-status') && (
					<a
						className='dropdown-item'
						onClick={e => changeAffiliateStatus(e, block_status_id ? 0 : 1)}
						href='#'
					>
						{block_status_id ? 'Unblock' : 'Block'}
						{/* {is_active  ? 'Active' : 'Inactive'} */}
					</a>
				)}
				{/* <a
                className='dropdown-item'
                onClick={e => changeAffiliateStatus(e, is_active ? 0 : 1)}
                href='#'
            >
                {block_status_id  ? 'Block' : 'Inactive'}
            </a> */}
				{checkHasPermission('get-partner-commission-config') && (
					<Link
						className='dropdown-item'
						to={`/manage-affiliate/cofigure-comission/${part_id}`}
					>
						Comission Config
					</Link>
				)}
				{checkHasPermission('change-partner-type') && (
					<Link
						to={`#`}
						onClick={e => {
							e.preventDefault();
							affiliateTypeModal(part_id, part_type);
							setDropDownStatus(!showDropDown);
						}}
						className='dropdown-item'
					>
						Change Affiliate Type
					</Link>
				)}{' '}
				{checkHasPermission('list-lead-offer-files') && (
					<Link to={`/offers/${part_id}`} className='dropdown-item'>
						Offer File
					</Link>
				)}
				{checkHasPermission('get-lead-api-request') && is_active ? (
					<Link
						className='dropdown-item'
						to={`/manage-affiliate/api-details/${part_id}`}
					>
						Api Details
					</Link>
				) : null}
				{checkHasPermission('update-affiliate') && (
					<Link
						className='dropdown-item'
						to={`/admin/affiliate-kyc/details/partner/${part_id}`}
					>
						View / Edit
					</Link>
				)}
				{/* {checkHasPermission('get-affiliate') && (
            <Link
                to={`/admin/affiliate-kyc/view/${part_id}`}
                className='dropdown-item'
            >
                View
            </Link>
        )} */}
			</div>
		</div>
	);
};

export default AffiliateActions;

import LafId from '../TableItems/LafId';
import LeadStatus from '../TableItems/LeadStatus';
import BatchId from './BatchId';

export default [
	{ title: 'LAF ID', field: 'laf_id', component: LafId },
	{ title: 'Lender Name', field: 'lender_name' },
	{ title: 'Activity', field: 'activity_desc' },
	{ title: 'Customer Name', field: 'cust_name' },
	{ title: 'Program Name', field: 'prod_name' },
];

import React from 'react';
import Modal from '../Modal';
import { toast } from 'react-toastify';
import message from '../../constant/message';
import {
	changeStatus,
	getLeadStatus,
	getRejectLeadReason
} from '../../services/manage-leads';
import { objectsForEach } from '../../common/objectToArray';
const LeadStatus = ({ state, toggleModal, reloadTable, leadId, type }) => {
	const style = { width: '300px' };

	const [status, setStatus] = React.useState('');
	const [errors, setErrors] = React.useState(null);
	const [loading, setLoading] = React.useState(false);
	const [comment, setComment] = React.useState('');
	const [status_date, setStatusDate] = React.useState('');
	const [statusList, setStatusList] = React.useState([]);
	const [rejectionReasonList, setRejectionReasonList] = React.useState([]);
	const [selectedRejection, setSelectedRejection] = React.useState('');
	const [showDateTime, setShowDateTime] = React.useState(false);
	const [selectedDropDownName, setSelectedDropDownName] = React.useState('');
	/**
	 *
	 * Change status of lead
	 * @param {SyntheticEvent} e
	 */
	const submit = async e => {
		setErrors(null);
		e.preventDefault();
		setLoading(true);
		const body = {
			status,
			child_id: selectedRejection,
			status_date:
				status === 4 || selectedRejection === 4
					? +new Date(status_date)
					: undefined,
			comment
		};
		const response = await changeStatus({ body, leadId, type });
		if (response?.status?.code === 1) {
			let success = response?.messages?.success;
			toast.success(
				success?.form?.[0] || success?.others?.[0] || message.UPDATE_SUCCESS
			);
			toggleModal(false);
			reloadTable();
		} else {
			let error = response?.messages?.error;
			setErrors(error);
			toast.error(error?.form?.[0] || error?.others?.[0] || message.ERROR);
		}
		setLoading(false);
	};

	const getStatusList = async () => {
		const response = await getLeadStatus({ type });
		if (response?.status?.code === 1) {
			setStatusList(
				objectsForEach(response?.data?.lead_status, 'STRING_VAL') || []
			);
		}
	};
	React.useEffect(() => {
		getStatusList();
	}, []);

	const onStatusChange = async statusId => {
		if (statusId === 7 || statusId === 3 || statusId === 6) {
			const response = await getRejectLeadReason({ type, statusId });
			if (response?.status?.code === 1) {
				setRejectionReasonList(
					objectsForEach(response?.data?.lead_status, 'STRING_VAL') || []
				);
				setSelectedDropDownName(
					statusList.find(status => status.id === statusId).value
				);
			}

			setShowDateTime(false);
		} else {
			setRejectionReasonList([]);
			setShowDateTime(false);
		}
		if (statusId === 4) {
			setRejectionReasonList([]);
			setShowDateTime(true);
		}
		setErrors(null);
		setStatus(statusId);
	};
	return (
		<Modal state={state} style={style} closeModal={() => toggleModal(false)}>
			<div className='modal-content gray-bg'>
				{/* Modal body */}
				<div className='modal-body'>
					<h5 className=' text-orange font-r'>Change Status</h5>
					<form>
						<div className='form-group'>
							<label>
								Status <tt>*</tt>
							</label>
							<select
								value={status}
								onChange={e => onStatusChange(e.target.value)}
								className='form-control'
							>
								<option value={''}>Select Status</option>
								{statusList.map(({ id, value }) => (
									<option key={id} value={id}>
										{value}
									</option>
								))}
							</select>
							{errors && (
								<p className='mt-2 mb-0 text-danger'>
									{errors?.fields?.status?.[0]}
								</p>
							)}
						</div>

						{Boolean(rejectionReasonList.length) && (
							<div className='form-group'>
								<label>
									Reason of {selectedDropDownName} <tt>*</tt>
								</label>
								<select
									value={selectedRejection}
									onChange={e => {
										if (e.target.value === 4) {
											setShowDateTime(true);
										}
										setSelectedRejection(e.target.value);
									}}
									className='form-control'
								>
									<option value={null}>Select reason</option>
									{rejectionReasonList.map(({ id, value }) => (
										<option key={id} value={id}>
											{value}
										</option>
									))}
								</select>
								{errors && (
									<p className='mt-2 mb-0 text-danger'>
										{errors?.fields?.child_id?.[0]}
									</p>
								)}
							</div>
						)}
						{showDateTime && (
							<div className='form-group'>
								<label>
									Follow up Date <tt>*</tt>
								</label>
								<input
									value={status_date}
									onChange={e => setStatusDate(e.target.value)}
									type={'datetime-local'}
									className='form-control'
									placeholder='Enter anything here...'
								/>
								{errors && (
									<p className='mt-2 mb-0 text-danger'>
										{errors?.fields?.status_date?.[0]}
									</p>
								)}
							</div>
						)}
						<div className='form-group'>
							<label>Comment</label>
							<textarea
								maxlength='200'
								value={comment}
								onChange={e => setComment(e.target.value)}
								type='number'
								className='form-control'
								placeholder='Enter anything here...'
							/>
							{comment.length > 199 && (
								<>
									<span className='text-danger font-size-12'>
										You have reached your maximum limit of characters allowed
									</span>
									<span
										data-tip
										data-for='comment-exceed'
										className='float-right'
									>
										<i
											className='fa fa-info-circle text-danger'
											aria-hidden='true'
										></i>
									</span>
								</>
							)}
							{errors && (
								<p className='mt-2 mb-0 text-danger'>
									{errors?.fields?.comment?.[0]}
								</p>
							)}
						</div>

						<button
							disabled={loading}
							onClick={submit}
							type='submit'
							className='btn btn-custom mr-2'
						>
							{loading ? <div className='loader' /> : 'Submit'}
						</button>
						<button
							onClick={() => toggleModal(false)}
							type='button'
							className='btn btn-close'
							data-dismiss='modal'
						>
							Cancel
						</button>
					</form>
				</div>
			</div>
		</Modal>
	);
};

export default LeadStatus;

import React, { useContext } from 'react';
function subtractMonths(date, months) {
	date.setMonth(date.getMonth() - months);
	return date;
}
const LeadType = ({ row, _field }) => {
	if (row?.lead_type === 1 && row?.application_status === 'Pre-Sanctioned') {
		return <span>{`Option ${row?.lead_type}`} </span>
	} else {
		return <span>{`Option ${row?.lead_type}`} </span>
	}
 
};

export default LeadType;

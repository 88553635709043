import Login from '../pages/login';
import Register from '../pages/register';
import ForgotPassword from '../pages/forgot-password';
import Dashboard from '../pages/dashboard';
import ManageLeads from '../pages/mange-leads';
import LeadDetails from '../pages/lead-details';
import AddLead from '../pages/add-lead';
import ResetPassword from '../pages/reset-password';
import AffiliateKyc from '../pages/affiliate-kyc';
import RegisterThankingNote from '../pages/register-thanking-note';
import ComissionReport from '../pages/comission-report';
import ManageUsers from '../pages/manage-users';
import NotFound from '../components/NotFound';
import ManageApplications from '../pages/manage-applications';
import ViewApplication from '../pages/view-application';
import ContainerRouter from '../components/ContainerRouter';
import ApiDetails from '../pages/api-details';

import OnboardingForm from '../pages/onboarding-form';
import ApplicationDetails from '../pages/application-details';
import SuccessPage from '../pages/SucessPage';
import ChangePassword from '../pages/change-password';

export default [
	{
		component: Login,
		path: '/affiliate/login',
		title: 'login',
		exact: true,
	},
	{
		component: Login,
		path: '/dsa/login',
		title: 'login',
		exact: true,
		type: 'dsa',
	},
	{
		component: Login,
		path: '/lender/login',
		title: 'login',
		exact: true,
		type: 'lender',
	},
	{
		component: Register,
		path: '/affiliate/register',
		title: 'register',
		exact: true,
	},
	{
		component: RegisterThankingNote,
		path: '/affiliate/register/thank-you',
		title: 'register',
		exact: true,
	},
	{
		component: ForgotPassword,
		path: '/affiliate/password/forget',
		title: 'forgotPassword',
		exact: true,
	},
	{
		component: ResetPassword,
		path: '/affiliate/password/reset',
		title: 'resetPassword',
		exact: true,
	},
	{
		component: ContainerRouter,
		path: '/*',
		title: 'container',
		type: 'affiliate',
	},
	{
		component: Dashboard,
		path: '/',
		title: 'dashboard',
		exact: true,
		private: true,
		permission: 'view-dashboard',
	},
	{
		component: ManageUsers,
		path: '/affiliate/manage-users',
		title: 'manageUser',
		exact: true,
		private: true,
		permission: 'affiliates-user',
	},
	{
		component: ManageLeads,
		path: '/manage-leads',
		title: 'manageLeads',
		exact: true,
		private: true,
		permission: 'lead-listing',
	},
	{
		component: AffiliateKyc,
		path: '/affiliate/kyc',
		title: 'affiliateKyc',
		exact: true,
		private: true,
		permission: 'edit-kyc',
	},
	{
		component: AddLead,
		path: '/lead/add',
		title: 'addNewLead',
		exact: true,
		private: true,
		permission: 'add-lead',
	},
	{
		component: ComissionReport,
		path: '/comission-report',
		title: 'comissionReports',
		exact: true,
		private: true,
		permission: 'generate-partner-commission',
	},
	{
		component: ComissionReport,
		path: '/dsa/comission-report',
		title: 'comissionReports',
		exact: true,
		private: true,
		type: 'dsa',
	},
	{
		component: ManageApplications,
		path: '/manage-applications',
		title: 'comissionReports',
		exact: true,
		private: true,
	},
	{
		component: ViewApplication,
		path: '/manage-applications/view/:id',
		title: 'comissionReports',
		exact: true,
		private: true,
	},
	{
		component: AddLead,
		path: '/lead/edit/:id',
		title: 'editLead',
		exact: true,
		private: true,
		permission: 'edit-lead',
	},
	{
		component: AddLead,
		path: '/lead/:id',
		title: 'leadDetails',
		exact: true,
		private: true,
	},
	{
		component: ApiDetails,
		path: '/affiliate/api-details',
		title: 'apiDetails',
		exact: true,
		private: true,
		type: 'affiliate',
	},
	{
		component: ApplicationDetails,
		path: '/onboarding/*',
		private: true,
	},
	{
		component: SuccessPage,
		path: '/onboarding/thanks',
		title: 'onboardingThanks',
		private: true,
		exact: true,
		type: 'onboarding-success',
	},
	{ component: NotFound, path: '*' },
	{
		component: ChangePassword,
		path: '/password/change',
		title: 'changePassword',
		exact: true,
		private: true,
	},
];

import React from 'react';
import { convertToSlug } from '../../../common/slugify';
const KycDocument = ({ name, uploadFile, uploadedFile, errors }) => {
	const fileRef = React.createRef();
	const [file, setFile] = React.useState(null);
	const [loading, setLoading] = React.useState(false);
	const submitUpload = async () => {
		setLoading(true);
		await uploadFile(file, convertToSlug(name));
		setLoading(false);
	};
	return (
		<>
			<div className='row mt-3'>
				<div className='col-md-5 pr-0'>
					<div className='card'>
						<h5 className='mb-0 '>
							<button
								onClick={() => fileRef.current.click()}
								className='btn btn-link text-light-gray font-weight-bold mb-0'
							>
								{name} <i className='fa fa-chevron-down'></i>
							</button>
						</h5>
					</div>
					<input
						ref={fileRef}
						onChange={e => setFile(e.target.files[0])}
						hidden
						className='form-control'
						type='file'
					/>
					<span>{uploadedFile ? uploadedFile : file?.name}</span>
				</div>

				<div className='col-md-2'>
					<button
						onClick={submitUpload}
						disabled={loading}
						type='button'
						className='btn btn-custom'
					>
						{loading ? <div className='loader' /> : 'Upload'}
					</button>
				</div>
			</div>
			{errors && (
				<p className='mt-2 mb-0 text-danger'>
					{errors?.fields?.['part_docs']?.[0]}
				</p>
			)}
		</>
	);
};

export default KycDocument;

import React from 'react';
import { affiliatesListing } from '../../services/manage-affiliate';
import { getCurrentMonth, getCurrentYear } from '../../common/date';
import { useRouter } from '../../hooks/useRouter';
import DatePicker from 'react-datepicker';
const Header = ({ generateReport, userType, error }) => {
	const [partner, setPartner] = React.useState('');
	const [date, setDate] = React.useState(new Date());
	const [loading, setLoading] = React.useState(false);
	const [currentPartner, setCurrentPartner] = React.useState(null);
	const [partnerList, setPartnerListing] = React.useState([]);
	const getAffiliatesListing = async () => {
		const response = await affiliatesListing();
		if (response?.status?.code == 1) {
			setPartnerListing(response?.data?.resource || []);
		}
	};
	React.useEffect(() => {
		if (router.params.id) {
			setPartner(router.params.id);
		}
		// if (userType !== 'dsa')
		getAffiliatesListing();
	}, []);
	React.useEffect(() => {
		const affiliate = partnerList.find(part => part.part_id == partner);
		setCurrentPartner(affiliate);
	}, [partner]);
	const getReport = async () => {
		setLoading(true);
		await generateReport({
			partner,
			date: `${getCurrentYear(date)}-${(getCurrentMonth(date) + 1)
				.toString()
				.padStart(2, '0')}`
		});
		setLoading(false);
	};
	const router = useRouter();
	return (
		<div className='row  align-items-center '>
			<div className=''>
				<div className='row no-gutters align-items-center'>
					{userType === 'admin' && (
						<div className='form-group'>
							<label>Select Affiliate</label>
							<select
								className='form-control custom-textfield-selectAffiliate  mr-2  text-black'
								value={partner}
								onChange={e => setPartner(e.target.value)}
							>
								<option value={null}>Select Affiliate</option>
								{partnerList.map(({ part_id, name }) => (
									<option value={part_id}>{name}</option>
								))}
							</select>
							{error && (
								<p className=' mb-0 text-danger'>
									{error?.fields?.['part_id']?.[0]}
								</p>
							)}
						</div>
					)}
					<div className='form-group'>
						<label>Select Month</label>
						<DatePicker
							disabled={
								userType !== 'dsa' && userType !== 'affiliate' && !partner
							}
							minDate={currentPartner && new Date(currentPartner.created_at)}
							maxDate={new Date()}
							selected={date}
							dateFormat='yyyy/MM'
							className='form-control custom-textfield-selectAffiliate'
							showMonthYearPicker
							onChange={selectedDate => setDate(selectedDate)} //only when value has changed
						/>
						{/* <input
							value={date}
							disabled={
								userType !== 'dsa' && userType !== 'affiliate' && !partner
							}
							onChange={e => setDate(e.target.value)}
							type='month'
							min={
								currentPartner &&
								`${new Date(
									currentPartner.created_at
								).getFullYear()}-${new Date(currentPartner.created_at)
									.getMonth()
									.toString()
									.padStart(2, '0')}`
							}
							max={`${getCurrentYear()}-${getCurrentMonth()
								.toString()
								.padStart(2, '0')}`}
							className='form-control custom-textfield-selectAffiliate'
						/> */}
						{error && (
							<p className=' mb-0 text-danger'>
								{error?.fields?.['comm_for']?.[0]}
							</p>
						)}
					</div>
					<div className='ml-4 mt-1'>
						<button
							disabled={loading}
							onClick={getReport}
							type='submit'
							className='btn btn-custom'
						>
							{loading ? <div className='loader' /> : 'Generate'}
						</button>
					</div>
				</div>
			</div>
			<div className='col-4'></div>
		</div>
	);
};

export default Header;

/**
 * check wether a variable passed is array of strings
 * @param {*} variable
 */
 export default function isStringArray(variable) {
    const status = Object.prototype.toString.call(variable) === "[object Array]";
    if (status) {
      return typeof variable[0] === "string";
    }
    return false;
  }

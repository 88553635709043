import React from 'react';
import { assignes } from '../../services/dashboard';
import dynamicSum from '../../common/dynamicSum';
import objectToQueryParams from '../../common/objectToQueryParams';
const Assignes = ({
	type,
	businessType,
	userTypes,
	loanType,
	productType,
	campaignCode,
	leadType,
	sourceId,
	stateId,
	cityId
}) => {
	const [assign, setAssigns] = React.useState([]);
	const getAssignes = async () => {
		const queryParams = objectToQueryParams({
			business_type: (businessType || []).map(
				business_type => business_type.value
			),
			user_ids: (userTypes || []).map(user_id => user_id.value),
			loan_type: (loanType || []).map(loan_type => loan_type.value),
			product_type: (productType || []).map(product_type => product_type.value),
			campaign_code: (campaignCode || []).map(camp_code => camp_code.value),
			lead_type: leadType?.value || '',
			source_id: sourceId?.value || '',
			state_id: stateId?.value || '',
			city_id: cityId?.value || ''
		});
		const response = await assignes({ type, queryParams });
		if (response?.status?.code === 1) {
			setAssigns(response?.data?.assignees || []);
		}
	};
	React.useEffect(() => {
		getAssignes();
	}, [
		type,
		businessType,
		userTypes,
		loanType,
		productType,
		campaignCode,
		leadType,
		sourceId,
		stateId,
		cityId
	]);
	return (
		<div className='panel mb-4'>
			<div className='head_top'>
				<h5>Assignee</h5>
			</div>
			<div className='table-reponsive'>
				<table className='table mb-0'>
					<thead>
						<tr>
							<th>Assignee Name</th>
							<th className='text-center'>
								{type === 'dsa' ? 'Applications' : 'Leads'}
							</th>
						</tr>
					</thead>
					<tbody>
						{assign.map(({ full_name, mobile_no, email, assigned_leads }) => (
							<tr key={email}>
								<td>
									<span className='name'>{full_name || 'No assignee'}</span>
									<br />
									<small>{mobile_no}</small>
								</td>
								<td align='center'>
									<span className='text-danger font-bold'>
										{type === 'dsa'
											? assigned_leads
											: assigned_leads?.length || 0}{' '}
										{type === 'dsa' ? '' : 'Leads'}
									</span>
								</td>
								{email && (
									<td align='right'>
										<small>
											{email}
											<br />
											{/* <a className='text-success'>Send mail</a> */}
										</small>
									</td>
								)}
							</tr>
						))}
						{/* <tr>
							<td>
								<span className='name'>Anjum Rawat</span>
								<br />
								<small>+91-9910099999</small>
							</td>
							<td align='center'>
								<span className='text-danger font-bold'>8 Leads</span>
							</td>
							<td align='right'>
								<small>
									anjum@gmail.com
									<br />
									<a className='text-success'>Send mail</a>
								</small>
							</td>
						</tr>
						<tr>
							<td>
								<span className='name'>Rajesh D.</span>
								<br />
								<small>+91-9910099999</small>
							</td>
							<td align='center'>
								<span className='text-danger font-bold'>12 Leads</span>
							</td>
							<td align='right'>
								<small>
									rajesh@gmail.com
									<br />
									<a className='text-success'>Send mail</a>
								</small>
							</td>
						</tr> */}
					</tbody>
				</table>
			</div>
		</div>
	);
};

export default Assignes;

import { format, parseISO } from 'date-fns';

export const monthNames = [
	'January',
	'February',
	'March',
	'April',
	'May',
	'June',
	'July',
	'August',
	'September',
	'October',
	'November',
	'December'
];
export function getFullDate(dateObj) {
	let month = monthNames[dateObj.getMonth()];
	let day = String(dateObj.getDate()).padStart(2, '0');
	let year = dateObj.getFullYear();
	let output = month + ' ' + day + ',' + year;
	return output;
}

export function getFormattedDate(dateObj, dateFormat = 'dd/MM/yyyy') {
	return format(dateObj, dateFormat);
}

export function getFormattedDateFromString(dateStr, dateFormat = 'dd/MM/yyyy') {
	return format(parseISO(dateStr, 'yyyy-MM-dd'), dateFormat);
}

export function getCurrentYear(date) {
	return date ? new Date(date).getFullYear() : new Date().getFullYear();
}

export function getCurrentMonth(date) {
	return date ? new Date(date).getMonth() : new Date().getMonth();
}

export function generateArrayOfYears(last) {
	var max = new Date().getFullYear();
	var min = max - last;
	var years = [];

	for (var i = max; i >= min; i--) {
		years.push(i);
	}
	return years;
}

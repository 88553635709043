import React from 'react';
import Modal from '../../../components/Modal';
import ReactTooltip from 'react-tooltip';
import { downloadFile } from '../../../common/download';
import { Context } from '../../../config/store';
import LenderUgroService from '../../../services/lender';
import message from '../../../constant/message';
import { toast } from 'react-toastify';
import RenderFile from '../../../pages/OnboardingForm/RenderFile';

const ViewFile = ({ row: { file_id, doc_type } }) => {
	const { state } = React.useContext(Context);
	const [modalState, setModalState] = React.useState(false);
	const [fileUrl, setFileUrl] = React.useState('');
	const [loading, setState] = React.useState(false);
	const toggleModal = () => {
		setModalState(!modalState);
		if (!matchMedia) setFileUrl('');
	};
	const viewFile = async () => {
		setState(true);
		try {
			const response = await LenderUgroService.downloadDocFile(
				state.userType,
				state.userType === 'admin' ? 'co-lender' : '',
				file_id
			);

			let url = window.URL.createObjectURL(response.data);
			setFileUrl(url);
			toggleModal();
		} catch (err) {
			toast.error(message.ERROR);
		}
		setState(false);
	};
	return (
		<>
			<Modal
				state={modalState}
				closeModal={toggleModal}
				removeBorderSpace={true}
				style={fileUrl && { width: '70%', height: '80%' }}
			>
				{!fileUrl ? (
					'No file to view'
				) : (
					<RenderFile
						extension={(String(doc_type) || '').split('/')?.[1] || doc_type}
						fileUrl={fileUrl}
						closeModal={toggleModal}
					/>
				)}
			</Modal>
			<ReactTooltip id='view-file'>View Document</ReactTooltip>
			<div
				type='button'
				className='ml-2'
				onClick={viewFile}
				disabled={loading}
				data-tip
				data-for='view-file'
			>
				{loading ? <div className='loader' /> : <i className='fa fa-eye fa-lg' style={{color: '#4A4E88',cursor:'pointer'}}></i>}
			</div>
		</>
	);
};
const DocumentDownload = ({
	row,
	reloadTable,
	selectedLender,
	selectedLenderName,
}) => {
	const [showBankPasswordModal, setShowBankPasswordModal] =
		React.useState(false);
	const [bankPasswordModalType, setBankPasswordModalType] =
		React.useState(null);
	const [bankPasswordModalInputValue, setBankPasswordModalInputValue] =
		React.useState('');
	const [updatingPassword, setUpdatingPassword] = React.useState(false);
	const [loading, setLoading] = React.useState(false);
	const { state } = React.useContext(Context);
	const showEditPasswordModal = () => {
		setBankPasswordModalType('Edit');
		setShowBankPasswordModal(true);
	};

	const viewableSelectedLender = () => {
		return (
			typeof row?.for_lenders === 'string'
				? JSON.parse(row.for_lenders)
				: row?.for_lenders || []
		).some((lender) => lender === selectedLender);
	};
	const [isChecked, setCheckStatus] = React.useState(
		viewableSelectedLender() || false
	);
	const showViewPasswordModal = () => {
		setBankPasswordModalType('View');
		setShowBankPasswordModal(true);
	};

	React.useEffect(() => {
		setBankPasswordModalInputValue(row.password);
	}, []);
	React.useEffect(() => {
		setCheckStatus(viewableSelectedLender());
	}, [selectedLender]);
	const downloadDoc = async () => {
		setLoading(true);
		try {
			const response = await LenderUgroService.downloadDocFile(
				state.userType,
				state.userType === 'admin' ? 'co-lender' : '',
				row.lead_doc_file_id
			);
			downloadFile(response.data, `${row.file_name}`);
		} catch (err) {
			toast.error(message.ERROR);
		}
		setLoading(false);
	};

	const updateBankPasswordModalValue = async () => {
		setUpdatingPassword(true);
		try {
			const response = await LenderUgroService.updateDocPassword(
				state.userType,
				{
					lead_doc_file_id: row.lead_doc_file_id,
					doc_password: bankPasswordModalInputValue,
					bank_id: row.bank_id,
				},
				state.userType === 'admin' ? 'co-lender' : null
			);
			const success = response?.data?.messages?.success;
			toast.success(
				success?.form?.[0] ||
					success?.others?.[0] ||
					'Password' + message.UPDATE_SUCCESS
			);
			setUpdatingPassword(false);
			reloadTable();
			setShowBankPasswordModal(false);
		} catch (err) {
			reloadTable();
			setUpdatingPassword(false);
			let error = err?.response?.data?.messages?.error;
			toast.error(
				error?.fields?.doc_password?.[0] ||
					error?.form?.[0] ||
					error?.others?.[0] ||
					message.ERROR_FORM
			);
			setShowBankPasswordModal(false);
		}
	};

	const toggleDocumentVisibilityForLender = async () => {
		setCheckStatus(!isChecked);
		try {
			const response = await LenderUgroService.toggleDocumentForLender(
				state.userType,
				state.userType === 'admin' ? 'co-lender' : null,
				{
					doc_lender_updates: [{ [row.lead_doc_file_id]: !isChecked ? 1 : 0 }],
					lender_id: selectedLender,
				}
			);
			const success = response?.data?.messages?.success;
			toast.success(
				success?.form?.[0] || success?.others?.[0] || message.UPDATE_SUCCESS
			);
			setUpdatingPassword(false);
			reloadTable();
			setShowBankPasswordModal(false);
		} catch (err) {
			reloadTable();
			setUpdatingPassword(false);
			let error = err?.response?.data?.messages?.error;
			toast.error(
				error?.fields?.doc_password?.[0] ||
					error?.form?.[0] ||
					error?.others?.[0] ||
					message.ERROR_FORM
			);
			setShowBankPasswordModal(false);
		}
	};
	return (
		<div className='d-flex align-items-center justify-content-center'>
			<ReactTooltip id='download-document'>Download Document</ReactTooltip>
			<div
				className='mr-2'
				onClick={downloadDoc}
				disabled={loading}
				data-tip
				data-for='download-document'
			>
				{loading ? <div className='loader' /> : <span style={{ cursor: 'pointer' }}><i class="fa fa-cloud-download fa-lg" style={{color: '#4A4E88'}} aria-hidden="true"></i></span>}
			</div>
			<div>
				{' '}
				<ReactTooltip id='viewable-current-lender'>
					{isChecked ? 'Visible' : 'Not visible'} to {selectedLenderName}
				</ReactTooltip>
			</div>
			<ViewFile
				row={{ doc_type: row.mime_type, file_id: row.lead_doc_file_id }}
			/>
			<div className='d-flex'>
				<ReactTooltip id='view-password'>View Password</ReactTooltip>
				<ReactTooltip id='edit-password'>Edit Password</ReactTooltip>
				{row.bank_id && (
					<>
						{/* <button
							className='btn btn-custom font-18 mr-2'
							data-tip
							data-for='view-password'
							onClick={showViewPasswordModal}
						>
							<i className='fa fa-info-circle'></i>
						</button> */}
						{/* <button
							className='btn btn-custom font-18'
							data-tip
							data-for='edit-password'
							onClick={showEditPasswordModal}
						>
							<i className='fa fa-pencil'></i>
						</button> */}
					</>
				)}
			</div>

			<Modal
				state={showBankPasswordModal}
				closeModal={() => setShowBankPasswordModal(false)}
			>
				<div className='p-2'>
					{bankPasswordModalType === 'View' ? (
						<div className='text-center'>
							<p>
								{row.password?.trim() ? (
									<span>
										Password for document is <b>{row.password}</b>
									</span>
								) : (
									'No password set for this document'
								)}
							</p>
							<button
								className='btn btn-custom'
								onClick={() => setShowBankPasswordModal(false)}
							>
								OK
							</button>
						</div>
					) : (
						<div className='text-center'>
							<input
								type='text'
								className='form-control'
								onChange={(e) => setBankPasswordModalInputValue(e.target.value)}
								value={bankPasswordModalInputValue}
								placeholder='Enter password for document'
							></input>
							<div className='d-flex align-items-center justify-content-center mt-3'>
								<button
									className='btn btn-custom mr-2'
									onClick={updateBankPasswordModalValue}
								>
									{updatingPassword ? <div className='loader' /> : 'Update'}
								</button>
								<button
									className='btn btn-outline-custom'
									disabled={updatingPassword}
									onClick={() => setShowBankPasswordModal(false)}
								>
									Close
								</button>
							</div>
						</div>
					)}
				</div>
			</Modal>
		</div>
	);
};

export default DocumentDownload;

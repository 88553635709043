// import {} from 'react';
import { useContext, useState } from 'react';
import splitCamelCase from '../../common/splitCamelCase';
import splitUnderscoreToCaptialise from '../../common/splitUnderscoreToCaptialise';
import { Context } from '../../config/store';
import { getIFSCData } from '../../services/onboarding-form-service';
import Error from '../Error';
function IFSCCode({
	label,
	value,
	body,
	required,
	onChange,
	type,
	disabled,
	id,
	index,
	multiple,
	errors,
	bindKey,
	errorKey,
}) {
	const [errorMessage, setErrorMessage] = useState(null);
	const [loading, setLoading] = useState(false);
	const { state } = useContext(Context);
	console.log(body);
	const validateIFSC = async () => {
		if (value) {
			setErrorMessage(null);
			setLoading(true);
			try {
				const response = await getIFSCData({
					type: state.userType,
					IFSC: value,
				});
				if (response?.data?.status?.code == 1) {
					const bankInfo = response.data.data;
					onChange(bankInfo.BANK, 'bank_name', index, multiple);
					onChange(bankInfo.BRANCH, 'branch_name', index, multiple);
					onChange(bankInfo.ADDRESS, 'branch_addr', index, multiple);
				}
			} catch (err) {
				let error = err?.response?.data?.messages?.error;
				console.log(error);
				setErrorMessage(error);
			}
			setLoading(false);
		}
	};
	return (
		<div className='col-md-4' key={id}>
			<div className='form-group'>
				<label>
					{label ? label : splitCamelCase(splitUnderscoreToCaptialise(id))}
					{required && <tt>*</tt>}
				</label>
				<div className='d-flex align-items-center'>
					<input
						onBlur={validateIFSC}
						value={value}
						onChange={(e) => {
							if (errorMessage) setErrorMessage(null);
							onChange(e.target.value, id, index, multiple);
						}}
						type={type ? type : 'text'}
						className='form-control'
						disabled={disabled || loading}
					/>
					{loading && <div className='ml-2 loader' />}
				</div>
				{errors && (
					<Error
						errorMessage={errorMessage?.form?.[0]}
						errorKey={errorKey}
						bindKey={bindKey}
						index={index}
						errors={errors}
						multiple={multiple}
						id={id}
					/>
				)}
			</div>
		</div>
	);
}

export default IFSCCode;

import React from 'react';
import {
	register,
	sendOtp,
	verifyOtp,
	getAffiliateFromToken
} from '../services/auth';
import { useRouter } from '../hooks/useRouter';
import { toast } from 'react-toastify';
import message from '../constant/message';
import CountDown from '../components/CountDown';

import ReactTooltip from 'react-tooltip';
import PasswordPolicy from '../components/PasswordPolicy';
const Register = () => {
	const [name, setName] = React.useState('');
	const [email, setEmail] = React.useState('');
	const [phone, setPhone] = React.useState('');
	const [password, setPassword] = React.useState('');
	const [confirmPassword, setConfirmPassword] = React.useState('');
	const [otpLoading, setOtpLoading] = React.useState(false);
	const [loading, setLoading] = React.useState(false);
	const [affiliate_type, setAffiliateType] = React.useState('');
	const [otp, setOtp] = React.useState('');
	const [panNO, setPanNO] = React.useState('');
	const [showEnterOtp, setEnterOtpStatus] = React.useState(false);
	const [errors, setErrors] = React.useState(null);
	const [verified, setVerified] = React.useState(false);
	const [userFromToken, setUserFromToken] = React.useState(false);
	const [showPassword, setShowPassword] = React.useState(false);
	const [showPassword2, setShowPassword2] = React.useState(false);
	const [triggerResetCountDown, setTriggerResetCountDown] = React.useState(
		false
	);
	let [countDown, setCountDown] = React.useState(60);
	const verifyPhone = async e => {
		e.preventDefault();
		if (showEnterOtp) {
			await verifyUserOtp();
		} else {
			await askForOtp();
		}
	};
	const askForOtp = async () => {
		setOtpLoading(true);
		const response = await sendOtp({ mobile_no: phone });
		if (response?.status?.code === 1) {
			setErrors(null);
			setEnterOtpStatus(true);
			let success = response?.messages?.success;
			toast.success(
				success.form?.[0] || success?.others?.[0] || message.OTP_SENT
			);
		} else {
			let error = response.messages.error;
			setErrors(error);
			toast.error(error?.form?.[0] || error?.others?.[0] || message.ERROR);
		}
		setOtpLoading(false);
	};
	const verifyUserOtp = async () => {
		const response = await verifyOtp({ mobile_no: phone, otp });
		if (response?.status?.code === 1) {
			setVerified(true);
			let success = response?.messages?.success;
			toast.success(success || message.OTP_VERIFIED);
		} else {
			let error = response.messages.error;
			setErrors(error);
			toast.error(error.form?.[0] || error?.others?.[0] || message.ERROR);
		}
	};

	const resendOtp = e => {
		e.preventDefault();
		setTriggerResetCountDown(!triggerResetCountDown);
		askForOtp();
	};
	const router = useRouter();
	const userRegister = async e => {
		setLoading(true);
		e.preventDefault();
		// if (!verified) {
		// 	return toast.error(message.NOT_VERIFIED);
		// }
		const payload = {
			name,
			email,
			mobile_no: phone,
			pan_no: panNO,
			password,
			confirm_password: confirmPassword,
			part_type: 1,
			affiliate_type
		};
		const response = await (await register(
			payload,
			userFromToken && 'register-with-token'
		)).json();
		if (response?.status?.code === 1) {
			toast.success(message.REGISTER_SUCCESS);
			router.navigate('/affiliate/register/thank-you');
		} else {
			let error = response.messages.error;
			setErrors(error);
			toast.error(error.form?.[0] || error?.others?.[0] || message.ERROR);
		}
		setLoading(false);
	};

	const getUserFromToken = async token => {
		const response = await getAffiliateFromToken(token);
		if (response?.status?.code === 1) {
			const userData = response.data.resource;
			setEmail(userData.part_email);
			setName(userData.part_name);
			setPhone(userData.part_phone);
			setAffiliateType(userData.affiliate_type);
			setUserFromToken(true);
		}
	};
	React.useEffect(() => {
		if (router.query.token) {
			getUserFromToken(router.query.token);
		}
	}, []);
	return (
		<section className='user-section '>
			<div className='container'>
				<div className='row'>
					<div className='col-md-6 ml-auto mr-auto'>
						<div className='user-bg col-10 mx-auto'>
							<div className='logo text-center mb-5'>
								<img src='../images/logo.png' width={130} />
							</div>
							<form>
								{/* <div className='form-group'>
									<label>User Type</label>
									<input
										value={name}
										disabled={userFromToken}
										onChange={e => setName(e.target.value)}
										type='text'
										className='form-control'
									/>
									{errors && (
										<p className='mt-2 mb-0 text-danger'>
											{errors?.fields?.part_type?.[0]}
										</p>
									)}
								</div> */}
								<div className='form-group'>
									<label>
										{affiliate_type === 2 ? 'Company Name' : 'Name'}
									</label>
									<input
										value={name}
										disabled={userFromToken}
										onChange={e => setName(e.target.value)}
										type='text'
										className='form-control'
										placeholder={affiliate_type === 2 ? 'Company Name' : 'Name'}
									/>
									{errors && (
										<p className='mt-2 mb-0 text-danger'>
											{errors?.fields?.name?.[0]}
										</p>
									)}
								</div>
								<div className='form-group'>
									<label>Email</label>
									<input
										value={email}
										disabled={userFromToken}
										onChange={e => setEmail(e.target.value)}
										type='email'
										className='form-control'
										placeholder='Email'
									/>
									{errors && (
										<p className='mt-2 mb-0 text-danger'>
											{errors?.fields?.email?.[0]}
										</p>
									)}
								</div>
								<div className='form-group'>
									<label>{affiliate_type === 2 ? 'Company PAN' : 'PAN'}</label>
									<input
										value={panNO}
										onChange={e => setPanNO(e.target.value)}
										type='text'
										className='form-control'
										placeholder=''
										style={{ textTransform: 'uppercase' }}
									/>
									{errors && (
										<p className='mt-2 mb-0 text-danger'>
											{errors?.fields?.pan_no?.[0]}
										</p>
									)}
								</div>
								<div className='form-group'>
									<div className='row'>
										<div className='col-12'>
											<label>Mobile No.</label>
										</div>
										<div className='col-7 pr-0'>
											<input
												value={phone}
												disabled={phone && userFromToken}
												onChange={e => setPhone(e.target.value)}
												type='number'
												name='mobile'
												className='form-control'
												placeholder='+91- 9876542435'
											/>
											{errors && (
												<p className='mt-2 mb-0 text-danger'>
													{errors?.fields?.mobile_no?.[0]}
												</p>
											)}
										</div>

										<div className='col-4 pr-0'>
											<button
												className='btn btn-orange w-100 d-flex align-items-center justify-content-center'
												disabled={otpLoading || verified}
												onClick={verifyPhone}
											>
												{otpLoading ? (
													<div className='loader' />
												) : verified ? (
													'Verified'
												) : showEnterOtp ? (
													'Verify'
												) : (
													'Get Otp'
												)}
											</button>
										</div>
									</div>
								</div>
								{!verified && showEnterOtp && (
									<div className='form-group'>
										<label>One Time Password</label>
										<input
											value={otp}
											onChange={e => setOtp(e.target.value)}
											type='password'
											className='form-control'
											placeholder='XXXXXX'
										/>
										{errors && (
											<p className='mt-2 mb-0 text-danger'>
												{errors?.fields?.otp?.[0]}
											</p>
										)}
										<p>
											<span className='float-right'>
												<small>
													<CountDown
														countDown={countDown}
														setCountDown={setCountDown}
														reset={triggerResetCountDown}
													/>
													{countDown < 1 && (
														<a
															href='#'
															onClick={resendOtp}
															className='ForgotPaswd ml-3'
														>
															<u>Resend OTP</u>
														</a>
													)}
												</small>
											</span>
										</p>
										<div className='clearfix' />
									</div>
								)}
								<div className='form-group'>
									<label>Password</label>
									<div className='input-group'>
										<input
											value={password}
											onChange={e => setPassword(e.target.value)}
											type={showPassword2 ? 'text' : 'password'}
											className='form-control'
											placeholder='Password'
										/>

										<PasswordPolicy password={password} />
										<div
											className='input-group-addon'
											onClick={() => setShowPassword2(!showPassword2)}
										>
											{!showPassword2 ? (
												<i className='fa fa-eye' aria-hidden='true'></i>
											) : (
												<i className='fa fa-eye-slash' aria-hidden='true'></i>
											)}
										</div>
									</div>
									{errors && (
										<p className='mt-2 mb-0 text-danger'>
											{errors?.fields?.password?.[0]}
										</p>
									)}
								</div>
								<div className='form-group'>
									<label>Confirm Password</label>
									<div className='input-group'>
										<input
											value={confirmPassword}
											onChange={e => setConfirmPassword(e.target.value)}
											type={showPassword ? 'text' : 'password'}
											className='form-control'
											placeholder='Confirm Password'
										/>

										<div
											className='input-group-addon'
											onClick={() => setShowPassword(!showPassword)}
										>
											{!showPassword ? (
												<i className='fa fa-eye' aria-hidden='true'></i>
											) : (
												<i className='fa fa-eye-slash' aria-hidden='true'></i>
											)}
										</div>
									</div>
									{errors && (
										<p className='mt-2 mb-0 text-danger'>
											{errors?.fields?.confirm_password?.[0]}
										</p>
									)}
								</div>
								<div className='form-group'>
									<button
										disabled={loading}
										onClick={userRegister}
										className={
											'btn btn-orange w-100  d-flex justify-content-center'
										}
									>
										{loading ? <div className='loader' /> : 'Submit'}
									</button>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default Register;

import { getCookie } from './cookie';

// consist of all the configuration related to services
const config = {
	// base urls of different roles
	baseUrl: {
		affiliate: process.env.REACT_APP_AFFILIATE_BASE_URL,
		admin: process.env.REACT_APP_ADMIN_BASE_URL,
		dsa: process.env.REACT_APP_DSA_BASE_URL,
		lender: process.env.REACT_APP_LENDER_BASE_URL,
		coLender: process.env.REACT_APP_COLENDER_BASE_URL
	},
	host: process.env.REACT_APP_HOST,
	client: {
		client_id: '5',
		client_secret: 'drUiF72Iya00HwlQJfxq6mOff3UbIxxwZwBLGFMd'
	},
	defaultHeaders: {
		'Content-Type': 'application/json',
		Accept: 'application/json'
	}
};
export default {
	...config,
	getConfigData: async userType => {
		const token = getCookie(btoa('token'));
		return await (await fetch(
			`${config.baseUrl[userType]}/get-config-dependency`,
			{
				headers: { Authorization: `Bearer ${token}`, ...config.defaultHeaders }
			}
		)).json();
	},
	getCityByState: async state_id => {
		const token = getCookie(btoa('token'));
		return await (await fetch(
			`${config.baseUrl.affiliate}/cities?state_id=${state_id}`,
			{
				headers: { Authorization: `Bearer ${token}`, ...config.defaultHeaders }
			}
		)).json();
	},
	getProducts: async () => {
		const token = getCookie(btoa('token'));
		return await (await fetch(`${config.baseUrl.admin}/get-products`, {
			headers: { Authorization: `Bearer ${token}`, ...config.defaultHeaders }
		})).json();
	},
	getStateCityByZip: async zipcode => {
		const token = getCookie(btoa('token'));
		return await (await fetch(
			`${config.baseUrl.affiliate}/master/branch-state/zipcode/${zipcode}`,
			{
				headers: { Authorization: `Bearer ${token}`, ...config.defaultHeaders }
			}
		)).json();
	}
};

import React from 'react';
import { useRouter } from '../../hooks/useRouter';
import { saveRole } from '../../services/manage-roles';
import { toast } from 'react-toastify';
import message from '../../constant/message';
const Actions = ({
	type,
	role_id,
	is_active,
	role_name,
	parentPermissions,
	childrenPermissions,
	setErrors,
	pageType,
}) => {
	const router = useRouter();
	const [loading, setLoading] = React.useState(false);
	const createRole = async () => {
		// if (!role_name) return toast.error(message.ADD_ROLE.INVALID_ROLE_NAME);
		setLoading(true);

		const response = await saveRole(
			{
				role_id,
				is_active,
				role_name,
				parent: [...new Set(parentPermissions)],
				child: [...new Set(childrenPermissions)],
			},
			pageType
		);
		if (response?.status?.code === 1) {
			let success = response.messages.success;

			toast.success(
				success?.form?.[0] || success?.others?.[0] || message.UPDATE_SUCCESS
			);
			router.navigate(`/${pageType ? pageType : 'admin'}/roles`);
		} else {
			let error = response.messages.error;
			setErrors(error);
			toast.error(error?.form?.[0] || error?.others?.[0] || message.ERROR_FORM);
		}
		setLoading(false);
	};
	return (
		<div className='content d-flex'>
			{type === 'ADD_ROLE' && (
				<button
					type='button'
					disabled={loading}
					onClick={createRole}
					className='btn btn-custom'
				>
					{loading ? <div className='loader' /> : 'Create'}
				</button>
			)}
			{type === 'UPDATE_ROLE' && (
				<button
					type='button'
					disabled={loading}
					onClick={createRole}
					className='btn btn-custom'
				>
					{loading ? <div className='loader' /> : 'Update'}
				</button>
			)}
			{(type === 'UPDATE_ROLE' || type === 'ADD_ROLE') && (
				<button
					onClick={() =>
						router.navigate(`/${pageType ? pageType : 'admin'}/roles`)
					}
					type='button'
					className='btn btn-close ml-2'
				>
					Cancel
				</button>
			)}
		</div>
	);
};

export default Actions;

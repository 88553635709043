import { getCookie } from './cookie';
import config from './config';

export const getComissionReport = async ({ body, userType }) => {
	const token = getCookie(btoa('token'));
	return await (await fetch(
		`${config.baseUrl[userType]}/generate-${
			userType === 'dsa' ? 'dsa' : 'partner'
		}-commission`,
		{
			method: 'POST',
			body: JSON.stringify(body),
			headers: {
				Authorization: `Bearer ${token}`,
				...config.defaultHeaders
			}
		}
	)).json();
};

export const getComissionConfig = async ({ part_id }) => {
	const token = getCookie(btoa('token'));
	return await (await fetch(
		`${config.baseUrl.admin}/get-partner-commission-config/${part_id}`,
		{
			headers: {
				Authorization: `Bearer ${token}`,
				...config.defaultHeaders
			}
		}
	)).json();
};

export const saveComissionConfig = async (body, part_id) => {
	// const comissionBody = {
	// 	vli: body.vli.length ? body.vli : undefined,
	// 	per_lead: Object.keys(body.per_lead).length ? body.per_lead : undefined,
	// 	logged_lead: Object.keys(body.logged_lead).length ?body.logged_lead:und
	// };
	const token = getCookie(btoa('token'));
	return await (await fetch(
		`${config.baseUrl.admin}/save-partner-commission-config/${part_id}`,
		{
			method: 'POST',
			body: JSON.stringify(body),
			headers: {
				Authorization: `Bearer ${token}`,
				...config.defaultHeaders
			}
		}
	)).json();
};

import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import Modal from '../../../../components/Modal';
import message from '../../../../constant/message';
import LenderUgroService from '../../../../services/lender';

const FundOutModal = ({ userType, state, toggleModal, leadId, reloadTable, rowData }) => {
    const [loading, setLoading] = useState(false);
    const [utr, setUTR] = useState('');
    const [fundamount, setfundamount] = useState('');
    const [fundoutDate, SetfundoutDate] = useState('');
    // const [file, setFile] = useState(null);
    const [response, setResponse] = useState('');
    const [errors, setErrors] = useState();

    useEffect(() => {
        setUTR(rowData?.utr_no)
        setfundamount(rowData?.disbursed_amt)
        SetfundoutDate(rowData?.disbursed_date)
    },[])
    const formSubmitHandler = async (e) => {
        e.preventDefault();
        setLoading(true);
        const formData = new FormData();

        utr && formData.append('utr_no', utr);
        fundoutDate && formData.append('disbursed_date', fundoutDate);
        fundamount && formData.append('disbursed_amt', fundamount);
        leadId && formData.append('lead_id', leadId);
        // file && formData.append('file', file);

        try {
            let apiResponse;
            apiResponse = await LenderUgroService.addUTRInfo({
                type: userType,
                formData,
                subType: userType === 'admin' ? 'co-lender' : '',
            });
            if (apiResponse?.data?.status?.code === 1) {
                toast.success(
                    apiResponse?.data?.data?.messages?.[0]?.[0] || message.SAVED_SUCCESS
                );
                toggleModal();
                reloadTable();
            }
        } catch (err) {
            let error = err?.response?.data?.messages?.error;

            toast.error(error?.form?.[0] || error?.others?.[0] || message.ERROR_FORM);
            setErrors(error);
        }
    };

    return (
        <Modal state={state} closeModal={toggleModal}>
            <form onSubmit={formSubmitHandler}>
                <div class='form-group'>
                    <label>UTR No.</label>
                    <div class='row'>
                        <div class='col-12'>
                            <input
                                class='form-control custom-form-control'
                                value={  utr}
                                onChange={(e) => setUTR(e.target.value)}
                            />
                        </div>
                    </div>
                </div>
                <div class='form-group'>
                    <label>Fundout Amount</label>
                    <div class='row'>
                        <div class='col-12'>
                            <input
                                class='form-control custom-form-control'
                                value={  fundamount}
                                onChange={(e) => setfundamount(e.target.value)}
                            />
                        </div>
                    </div>
                </div>

                <div class='form-group'>
                    <label> Foundout  Date</label>
                    <div class='row'>
                        <div class='col-12'>
                            <input
                                type='Date'
                                className='form-control'
                                name='date'
                                value={ fundoutDate}
                                onChange={(e) => SetfundoutDate(e.target.value)}
                            />
                        </div>
                    </div>
                </div>
                {/* {userType !== 'lender' && (
					<div class='form-group'>
						<label>Document Upload</label>
						<div class='row'>
							<div class='col-12'>
								<input
									type='file'
									className='form-control'
									name='date'
									onChange={(e) => setFile(e.target.files[0])}
								/>
							</div>
							{errors && (
								<p className='mt-2 mb-0 text-danger'>{errors.fields.file[0]}</p>
							)}
						</div>
					</div>
				)} */}
                <div class='d-flex align-items-center'>
                    <button type='submit' class='btn btn-custom' disabled={loading}>
                        {loading ? <div className='loader' /> : 'OK'}
                    </button>
                    <button
                        type='button'
                        class='btn btn-outline-custom ml-2'
                        data-dismiss='modal'
                        onClick={toggleModal}
                    >
                        Cancel
                    </button>
                </div>
            </form>
        </Modal>
    );
};

export default FundOutModal;

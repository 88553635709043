import React from 'react';
import { Link } from 'react-router-dom';
import AssignLenderLeadModal from '../../../components/Portfolio/AssignLenderLeadModal';
import { Context } from '../../../config/store';
import { useLocation } from 'react-router';
import OpsModal from '../ViewPortfolio/OpsModal';
import AssignLenderUserModal from '../../../components/Portfolio/AssignlenderUserModal';

const Actions = ({ row, field, reloadTable }) => {
	const [modalState, toggleModal] = React.useState(false);
	const { state } = React.useContext(Context);
	const location = useLocation();
	const checkHasPermission = (checkPermission) => {
		if (state.is_super_admin === 1) return true;
		const permission = state.permission.find(
			(permission) => permission === checkPermission
		);

		if (permission) return true;
		else return false;
	};
	const toggleModalState = () => toggleModal(!modalState);
	const isDisbursalRoute = () =>
		location.pathname === '/portfolio/disbursal-pending' ||
		location.pathname === '/portfolio/disbursal-approved' ||
		location.pathname === '/portfolio/disbursal-rejected';
	if (
		state.userType === 'lender' &&
		(location.pathname === '/portfolio/pre-sanction' ||
		location.pathname === '/portfolio/pre-sanction-approve' ||
		location.pathname === '/portfolio/assigned' ||
		location.pathname === '/portfolio/approved' ||
		location.pathname === '/portfolio/disbursal-pending' ||
		location.pathname === '/portfolio/in-disbursal-approve')	
	) {
		return (
			<>
				<Link
					to={`#`}
					onClick={(e) => {
						e.preventDefault();
						toggleModal(true);
					}}
				>
					Assign
				</Link>
				{modalState && (
					<AssignLenderUserModal
						modelState={modalState}
						toggleModal={toggleModalState}
						colenders={[]}
						selectedIds={[row.cl_lead_id]}
						reloadTable={reloadTable}
					/>
				)}
			</>
		);
	}
	if (
		(state.userType === 'lender' && !isDisbursalRoute()) ||
		(state.userType === 'admin' && isDisbursalRoute())
	) {
		return '---';
	}

	if (row.lead_type === 10) {
		// will be visible if status[] is empty or any object with status.id === 5
		if (
			(row.status.length === 0 || row.status.find((s) => s.id === 5)) &&
			location.pathname !== '/portfolio/assigned'
		) {
			return (
				<>
					<Link
						to={`#`}
						onClick={(e) => {
							e.preventDefault();
							toggleModal(true);
						}}
					>
						Assign
					</Link>

					{modalState && (
						<AssignLenderLeadModal
							modelState={modalState}
							toggleModal={() => toggleModal(!modalState)}
							colenders={[]}
							selectedIds={[row.cl_lead_id]}
							reloadTable={reloadTable}
							leadType={row.lead_type}
							type='details'
						/>
					)}
				</>
			);
		} else if (row.status_id === 2) {
			return (
				<Link to={`/portfolio/view/${row.cl_lead_id}/additional`}>
					Additional Info
				</Link>
			);
		} else if (row.status_id === 2) {
			return (
				<Link to={`/portfolio/view/${row.cl_lead_id}/documents`}>
					Upload Document
				</Link>
			);
		} else {
			return '---';
		}
	} else if (
		location.pathname !== '/portfolio/assigned' &&
		location.pathname !== '/portfolio/disbursal-approved' &&
		location.pathname !== '/portfolio/disbursal-rejected' &&
		checkHasPermission('assign-colender-lead')
	) {
		return (
			<>
				<Link
					to={`#`}
					onClick={(e) => {
						e.preventDefault();
						toggleModal(true);
					}}
				>
					Assign
				</Link>
				{modalState  && (
					<AssignLenderLeadModal
						modelState={modalState}
						toggleModal={toggleModalState}
						colenders={[]}
						selectedIds={[row.cl_lead_id]}
						reloadTable={reloadTable}
						leadType={row.lead_type}
						type='details'
					/>
				)}
			</>
		);
	} else if (
		state.userType === 'lender' &&
		location.pathname === '/portfolio/disbursal-pending'
	) {
		return (
			<>
				<Link
					to={`#`}
					onClick={(e) => {
						e.preventDefault();
						toggleModalState();
					}}
				>
					Disburse
				</Link>

				{modalState && (
					<OpsModal
						reloadTable={reloadTable}
						state={modalState}
						toggleModal={toggleModalState}
						leadId={row.cl_lead_id}
						userType={state.userType}
					/>
				)}
			</>
		);
	} else {
		return '---';
	}
};

export default Actions;
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
const LafId = ({ row, field }) => {
	return (
		<Link
			to={ `/portfolio/view/${row.cl_lead_id}/details?status=${row.status_id}`}>
			{row[field]}
		</Link>
	);
};

export default LafId;

import React from 'react';
import { Context } from '../../../config/store';
import Select from '.';
const BusinessConstitions = ({ onChange, value, disabled }) => {
	const { state } = React.useContext(Context);
	const [constitutions, setConstitutions] = React.useState([]);
	React.useEffect(() => {
		const constitutions = state.config.constitutions.map(constitution => ({
			value: constitution.id,
			label: constitution.name
		}));
		setConstitutions(constitutions);
	}, [state.config.constitutions]);
	return (
		<Select
			onChange={onChange}
			disabled={disabled}
			value={value}
			options={constitutions}
		/>
	);
};

export default BusinessConstitions;

import React from "react";
import objectToQueryParams from "../../common/objectToQueryParams";

let isOutside = false;

const Filters = ({ setQueryParams }) => {
  
	let searchData = JSON.parse(sessionStorage.getItem('searchData'));
	const getParamsFromSessionStorage = (key, type = null) => {
		let searchDataResult = searchData ? searchData[key] : '';
		if (type === 'date' && searchDataResult) {
			let convertedDate = searchDataResult?.split('/')
			return new Date(`${convertedDate[1]}/${convertedDate[0]}/${convertedDate[2]}`)
		}
		return searchDataResult;
	}

  const [role_name, setRoleName] = React.useState(getParamsFromSessionStorage('role_name'));
  const [status, setStatus] = React.useState(getParamsFromSessionStorage('status'));
  const searchFilterRef = React.useRef()
  const submitQueryParams = () => {
    const searchObject = { role_name, status };
    sessionStorage.setItem('searchData', JSON.stringify({ ...searchObject, org_page: 1 }))
    const queryString = objectToQueryParams(searchObject);
    setQueryParams(queryString);
  };
  const resetQueryParams = () => {
    sessionStorage.removeItem('searchData')
    setQueryParams("");
    setRoleName("");
    setStatus("");
  };
  
  React.useEffect(() => {
		const listener = event => {
			if (event.code === "Enter" || event.code === "NumpadEnter") {
				if (isOutside) {
					document.getElementById("submitButton").click();
				}
				event.preventDefault();
			}
		};
		document.addEventListener("keydown", listener);
		return () => {
			document.removeEventListener("keydown", listener);
		};
	}, []);

	React.useEffect(
		() => {
			const listener1 = (event) => {
				if (!searchFilterRef.current || searchFilterRef.current.contains(event.target)) {
					isOutside = true
					return;
				} else {
					isOutside = false
				}
			};
			document.addEventListener("mousedown", listener1);
			document.addEventListener("touchstart", listener1);
			return () => {
				document.removeEventListener("mousedown", listener1);
				document.removeEventListener("touchstart", listener1);
			};
		},
		[searchFilterRef, isOutside]
	);

  return (
    <div className="tab panel" >
      <input type="checkbox" id="accordion"></input>
      <label for="accordion" className="w-100 head_top tab-label">
        <h4>Search Filter</h4>
      </label>
      <form className="tab-content" ref={searchFilterRef}>
        <div className="">
          <div className="row">
            {/* <div className='col-md-4'>
						<div className='form-group'>
							<label>Role ID</label>
							<input
								type='text'
								className='form-control'
								placeholder='Role ID'
							/>
						</div>
					</div> */}
            <div className="col-md-4">
              <div className="form-group">
                <label>Role Name</label>
                <input
                  value={role_name}
                  onChange={(e) => setRoleName(e.target.value)}
                  type="text"
                  className="form-control"
                  placeholder="Role Name"
                />
              </div>
            </div>
            {/* <div className="col-md-4">
              <div className="form-group">
                <label>Status</label>
                <select
                  value={status}
                  onChange={(e) => setStatus(e.target.value)}
                  className="form-control"
                >
                  <option value={null}>Status</option>
                  <option value={0}>In active</option>
                  <option value={1}>Active</option>
                </select>
              </div>
            </div> */}
               <div className="col-md-4">
              <div className="form-group">
                <label>Status</label>
                <select
                  value={status}
                  onChange={(e) => setStatus(e.target.value)}
                  className="form-control"
                >
                  <option value={null}>Status</option>
                  <option value={0}>In active</option>
                  <option value={1}>Active</option>
                </select>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 d-flex">
              <button
                onClick={submitQueryParams}
                type="button"
                className="btn btn-custom btn-custom-height"
                id='submitButton'
              >
                Search
              </button>
              <button
                onClick={resetQueryParams}
                type="button"
                className="btn btn-custom ml-2 btn-custom-height"
              >
                Reset All
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default Filters;

import React from 'react';
import { savePartnerKyc } from '../../../services/manage-affiliate';
import { toast } from 'react-toastify';
import message from '../../../constant/message';
import { Link } from 'react-router-dom';
const Actions = ({ setErrors, affiliateKyc, partId, userType }) => {
	const [loading, setLoading] = React.useState(false);
	const saveDetails = async () => {
		if (!affiliateKyc.address_two || affiliateKyc.address_three) {
			affiliateKyc.address_two = '';
			affiliateKyc.address_three = '';
		}
		setLoading(true);
		const response = await savePartnerKyc(affiliateKyc, partId, userType);
		if (response?.status?.code === 1) {
			toast.success(message.SAVED_SUCCESS);
		} else {
			let error = response.messages.error;
			toast.error(error?.form?.[0] || error?.others?.[0] || message.ERROR_FORM);
			setErrors(error);
		}
		setLoading(false);
	};
	return (
		<div className='content'>
			<div className='d-flex mb-3'>
				<div className='form-check'>
					<input
						className='form-check-input'
						type='checkbox'
						defaultValue
						id='flexCheckChecked'
						defaultChecked
					/>
					<label className='form-check-label' htmlFor='flexCheckChecked'>
						I agree with you grow capital{' '}
						<a href='#' className='ml-1'>
							terms &amp; Conditions
						</a>
					</label>
				</div>
			</div>
			<button
				onClick={saveDetails}
				disabled={loading}
				type='submit'
				className='btn btn-custom'
			>
				{loading ? <div className='loader' /> : 'Save details'}
			</button>
			<Link
				to={userType === 'admin' ? '/manage-affiliate' : '/'}
				type='submit'
				className='btn btn-close ml-2'
			>
				Cancel
			</Link>
			{/* <button type='submit' className='btn btn-close ml-2'>
				Next
			</button> */}
		</div>
	);
};

export default Actions;

import React from "react";
import { Link } from "react-router-dom";
import { login } from "../services/auth";
import { toast } from "react-toastify";
import message from "../constant/message";
import { useRouter } from "../hooks/useRouter";
import { setCookie } from "../services/cookie";
import { Context } from "../config/store";
import userTypeCodeMap from "../config/userTypeCode";

export default function Login({ type }) {
  const [email, setEmail] = React.useState("");
  const { state, dispatch } = React.useContext(Context);
  const [password, setPassword] = React.useState("");
  const [errors, setErrors] = React.useState({});
  const [showPassword, setShowPassword] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const router = useRouter();
  const [showOtpFields, setShowOtpFields] = React.useState(false);
  const [otp, setOtp] = React.useState("");
  const [showOtp, setShowOtp] = React.useState(false);

  const loginUser = async (e) => {
    e.preventDefault();
    setLoading(true);

    const response = await (
      await login({
        email,
        password,
        type: type || state.userType,
      })
    ).json();
    if (response?.status?.code === 1) {
      if (response?.data?.is_otp) {
        setTimeout(() => {
          setShowOtpFields(false);
        }, 10 * 60 * 1000);
        setShowOtpFields(!showOtpFields);
        let success = response?.messages?.success;
        toast.success(success?.form?.[0] || success?.others?.[0]);
      } else {
        setCookie(btoa("token"), response?.data?.auth?.token);
        toast.success(message.LOGIN_SUCCESS);
        dispatch({
          type: "userTypeCode",
          userTypeCode: response.data.resource.user_type,
        });
        dispatch({
          type: "userType",
          userType: userTypeCodeMap[response.data.resource.user_type - 1],
        });
        router.navigate("/portfolio/home");
      }
    } else {
      let error = response?.messages?.error;
      setErrors(error);
      toast.error(error?.form?.[0] || error?.others?.[0] || message.ERROR);
    }
    setLoading(false);
  };

  const handleVerifyOtp = async (e) => {
    e.preventDefault();
    setLoading(true);
    const response = await (
      await login({
        email,
        password,
        type: state.userType,
        otp: otp,
        loginType: "otp",
      })
    ).json();

    if (response?.status?.code === 1) {
      setCookie(btoa("token"), response?.data?.auth?.token);
      toast.success(message.LOGIN_SUCCESS);
      dispatch({
        type: "userTypeCode",
        userTypeCode: response.data.resource.user_type,
      });
      dispatch({
        type: "userType",
        userType: userTypeCodeMap[response.data.resource.user_type - 1],
      });
      router.navigate("/portfolio/home");
    } else {
      let error = response?.messages?.error;
      toast.error(
        error?.fields?.otp?.[0] ||
        error?.form?.[0] ||
        error?.others?.[0] ||
        message.ERROR
      );
    }
    setLoading(false);
  };

  const handleResendOtp = async (e) => {
    e.preventDefault();
    const response = await (
      await login({
        email,
        password,
        type: state.userType,
        loginType: "resend",
      })
    ).json();

    if (response?.status?.code === 1 && response?.data?.is_otp) {
      let success = response?.messages?.success;
      toast.success(success?.form?.[0] || success?.others?.[0]);
    } else {
      let error = response?.messages?.error;
      toast.error(error?.form?.[0] || error?.others?.[0] || message.ERROR);
    }
  };

  return (
    <section className="user-section">
      <div className="loginContainer">
        <div className="row align-items-center justify-content-start h-100">
          <div className="col-md-6">
            <div className="p-5 m-auto">
              <div className="text-center">
                <img src="../images/logo.png" width={200} />
              </div>
              <h6 className="text-center mb-5" style={{ fontSize: "20px" }}>
                Welcome to Gro-Xstream Portal
              </h6>
              {!showOtpFields ? (
                <form className="d-flex flex-column align-items-center">
                  <div className="form-group">
                    <label style={{ fontSize: "18px" }}>
                      {type === "dsa" ? "User Id" : "Email ID"}
                    </label>
                    <div className="inputWithIcon">
                      <input
                        value={email}
                        type="email"
                        onChange={(e) => setEmail(e.target.value)}
                        className="form-control"
                        placeholder={type === "dsa" ? "User Id" : "Email"}
                        style={{ height: "40px", width: "400px" }}
                      />
                      <i className="fa fa-envelope"></i>
                    </div>
                    {errors && (
                      <p className="mt-2 mb-0 text-danger">
                        {errors?.fields?.email?.[0]}
                      </p>
                    )}
                  </div>
                  <div className="form-group">
                    <label style={{ fontSize: "18px" }}>Password</label>
                    <div className="inputWithIcon">
                      <input
                        value={password}
                        type={showPassword ? "text" : "password"}
                        onChange={(e) => setPassword(e.target.value)}
                        className="form-control"
                        placeholder="Password"
                        style={{ height: "40px", width: "400px" }}
                      />
                      <i
                        onClick={() => setShowPassword(!showPassword)}
                        className={`fa ${!showPassword ? "fa-eye-slash" : "fa-eye"
                          }`}
                      ></i>
                    </div>
                    {errors && (
                      <p className="mt-2 mb-0 text-danger">
                        {errors?.fields?.password?.[0]}
                      </p>
                    )}
                    <Link
                      to={`/${state.userType}/password/forget`}
                      className="float-right ForgotPaswd"
                      style={{ fontSize: "16px", color: "#EE6B33" }}
                    >
                      Forgot Password?
                    </Link>
                    <div className="clearfix" />
                  </div>
                  <div className="d-flex justify-content-center align-items-center mt-1">
                    <div className="form-group">
                      <button
                        disabled={loading}
                        onClick={loginUser}
                        className={`p-2 btn btn-blueButton hoverStyle d-flex justify-content-center align-items-center ${(!email || !password) && "disabled"
                          }`}
                        style={{ width: "80px", height: "30px" }}
                      >
                        {loading ? <div className="loader" /> : "LOGIN"}
                      </button>
                    </div>
                  </div>
                  {state.userType !== "admin" && type !== "lender" && (
                    <p className="text-center font-18 mb-0">
                      {" "}
                      <small>
                        Don't have account{" "}
                        <Link className="text-orange" to="/affiliate/register">
                          Register here
                        </Link>
                      </small>
                    </p>
                  )}
                </form>
              ) : (
                <form>
                  <p
                    className="text-base text-center mt-6 mb-4"
                    style={{ fontSize: "16px" }}
                  >
                    Please Enter Your One Time Password (OTP)
                  </p>
                  <div className="d-flex justify-content-center align-items-center">
                    <div className="form-group">
                      <label style={{ fontSize: "18px" }}>OTP</label>
                      <div className="inputWithIcon">
                        <input
                          value={otp}
                          type={showOtp ? "text" : "password"}
                          onChange={(e) => setOtp(e.target.value)}
                          className="form-control otpInput"
                          style={{ height: "40px", width: "400px" }}
                          maxLength={6}
                          placeholder="OTP"
                        />
                        <i
                          onClick={() => setShowOtp(!showOtp)}
                          className={`fa ${showOtp ? "fa-unlock" : "fa-lock"}`}
                        ></i>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex justify-content-center align-items-center mt-3">
                    <div className="form-group">
                      <button
                        disabled={loading}
                        onClick={handleVerifyOtp}
                        className={`p-2 btn btn-blueButton hoverStyle d-flex justify-content-center align-items-center}`}
                        style={{ width: "80px", height: "30px" }}
                      >
                        {loading ? <div className="loader" /> : "Verify"}
                      </button>
                    </div>
                  </div>
                  <p
                    className="text-base text-center mt-6 mb-4"
                    style={{ fontSize: "12px" }}
                  >
                    OTP not received ?{" "}
                    <span
                      style={{ cursor: "pointer", color: "#ea652b" }}
                      className="text-underline"
                      onClick={handleResendOtp}
                    >
                      Resend
                    </span>
                  </p>
                </form>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

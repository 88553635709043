import OnboardingForm from '../pages/onboarding-form';
import QueryList from '../Views/Portfolio/ViewPortfolio/QueryList';
import Documentation from '../pages/documentation';
import FIList from '../pages/FIList';

export const pages = [
	{
		component: OnboardingForm,
		path: '*',
		pathName: '/onboarding/details',
		title: 'Onboarding Form',
		private: true,
		showPage: true,
	},
	{
		component: QueryList,
		path: '/queries/:id',
		pathName: '/onboarding/queries',
		title: 'Queries',
		private: true,
		showPage: true,
		type: 'onboarding-form',
	},
	{
		component: FIList,
		path: '/fi-fcu/:id',
		pathName: '/onboarding/fi-fcu',
		title: 'FI/FCU',
		private: true,
		showPage: true,
	},
	{
		component: Documentation,
		path: '/documentation/*',
		private: true,
	},
];

export const getSteps = (userType) => {
	let stepPages = [
		{
			component: OnboardingForm,
			path: '*',
			pathName:
				userType === 'admin'
					? '/onboarding/details/partner'
					: '/onboarding/details',
			title: 'Onboarding Form',
			private: true,
			showPage: true,
		},
		{
			component: QueryList,
			path: '/queries/:id',
			pathName: '/onboarding/queries',
			title: 'Queries',
			private: true,
			showPage: true,
			type: 'onboarding-form',
		},
		{
			component: FIList,
			path: '/fi-fcu/:id',
			pathName: '/onboarding/fi-fcu',
			title: 'FI/FCU',
			private: true,
			showPage: userType === 'admin' ? true : false,
		},
		{
			component: Documentation,
			path: '/documentation/*',
			private: true,
		},
	];
	return stepPages
		.filter((page) => page.title && page.showPage)
		.map((page) => ({ ...page, path: page.path.substring(1) }));
};

import React from 'react';
import Modal from '../Modal';
import { changeLeadStatus } from '../../services/manage-leads';
import { toast } from 'react-toastify';
import message from '../../constant/message';
const LeadActionStatus = ({
	state,
	toggleModal,
	selectedLead,
	reloadTable,
	type
}) => {
	const style = { width: '300px' };
	const [loading, setLoading] = React.useState(false);
	const [leadState, setLeadState] = React.useState(selectedLead.lead_type || 1);
	const [errors, setErrors] = React.useState();
	const submit = async e => {
		setErrors(null);
		e.preventDefault();
		setLoading(true);
		const body = {
			lead_id: selectedLead.lead_id,
			lead_type: leadState
		};
		const response = await changeLeadStatus({ body, type });
		if (response?.status?.code === 1) {
			let success = response?.messages?.success;
			toast.success(
				success?.form?.[0] || success?.others?.[0] || message.UPDATE_SUCCESS
			);
			toggleModal(false);
			reloadTable();
		} else {
			let error = response?.messages?.error;
			setErrors(error);
			toast.error(error?.form?.[0] || error?.others?.[0] || message.ERROR);
		}
		setLoading(false);
	};
	return (
		<Modal state={state} style={style} closeModal={() => toggleModal(false)}>
			<div className='modal-content gray-bg'>
				{/* Modal body */}
				<div className='modal-body'>
					<h5 className=' text-orange font-r'>Lead Status</h5>
					<form>
						{' '}
						<div className='pb-4 pt-2'>
							{' '}
							<div
								class='custom-control custom-radio'
								onClick={e => setLeadState(1)}
							>
								<input
									type='radio'
									class='custom-control-input'
									name='leadStatus'
									value={1}
									id={1}
									checked={leadState === 1}
								/>
								<label class='custom-control-label mt-1' for='defaultUnchecked'>
									Cold
								</label>
							</div>
							<div
								class='custom-control custom-radio'
								onClick={e => setLeadState(2)}
							>
								<input
									type='radio'
									class='custom-control-input'
									name='leadStatus'
									value={2}
									id={2}
									checked={leadState === 2}
								/>
								<label class='custom-control-label mt-1' for='defaultUnchecked'>
									Warm
								</label>
							</div>
							<div
								class='custom-control custom-radio'
								onClick={e => setLeadState(3)}
							>
								<input
									type='radio'
									class='custom-control-input'
									name='leadStatus'
									value={3}
									id={3}
									checked={leadState === 3}
								/>
								<label class='custom-control-label mt-1' for='defaultUnchecked'>
									Hot
								</label>
							</div>
						</div>
						<button
							disabled={loading}
							onClick={submit}
							type='button'
							className='btn btn-custom mr-2'
						>
							{loading ? <div className='loader' /> : 'Submit'}
						</button>
						<button
							onClick={() => toggleModal(false)}
							type='button'
							className='btn btn-close'
							data-dismiss='modal'
						>
							Cancel
						</button>
					</form>
				</div>
			</div>
		</Modal>
	);
};

export default LeadActionStatus;

import { getCookie } from './cookie';
import config from './config';
import objectToQueryParams from '../common/objectToQueryParams';
export const leadListing = async ({ type, page, queryParams, exportLead }) => {
	const token = getCookie(btoa('token'));
	let url = `${config.baseUrl[type]}/lead`;
	if (type === 'admin') {
		url += '/lead-list';
	}
	url += `?page=${page}&${queryParams}`;

	if (exportLead) {
		url += `&export=1`;
	}
	const response = await fetch(url, {
		headers: {
			Authorization: `Bearer ${token}`,
			...config.defaultHeaders
		}
	});
	if (exportLead) {
		return await response.blob();
	} else {
		return await response.json();
	}
};

export const addLead = async (body, type, partId) => {
	const token = getCookie(btoa('token'));
	let url = `${config.baseUrl[type]}/lead/add`;
	if (type === 'admin') {
		url += `/${partId}`;
	}
	return await (await fetch(url, {
		method: 'POST',
		body: JSON.stringify(body),
		headers: {
			Authorization: `Bearer ${token}`,
			...config.defaultHeaders
		}
	})).json();
};

/**
 *
 * @param {*} file
 * @param {String} part_id
 */
export const addLeadOffer = async (file, part_id) => {
	const token = getCookie(btoa('token'));
	const formData = new FormData();
	formData.append('file', file);
	formData.append('part_id', part_id);

	return await (await fetch(
		`${config.baseUrl.admin}/lead/bulk-lead-offer-upload`,
		{
			method: 'POST',
			headers: {
				Authorization: `Bearer ${token}`,
				Accept: 'application/json'
			},
			body: formData
		}
	)).json();
};
/**
 *
 * @param {String} leadId
 * @param {{}} body
 */
export const updateLead = async (leadId, body, type) => {
	const token = getCookie(btoa('token'));
	return await (await fetch(`${config.baseUrl[type]}/lead/update/${leadId}`, {
		method: 'POST',
		body: JSON.stringify(body),
		headers: {
			Authorization: `Bearer ${token}`,
			...config.defaultHeaders
		}
	})).json();
};
/**
 *
 * @param {String} type
 * @param {String} leadId
 */
export const getLead = async ({ type, leadId }) => {
	const token = getCookie(btoa('token'));
	return await (await fetch(`${config.baseUrl[type]}/lead/${leadId}`, {
		headers: {
			Authorization: `Bearer ${token}`,
			...config.defaultHeaders
		}
	})).json();
};

export const getManageLeadParams = async type => {
	const token = getCookie(btoa('token'));
	return await (await fetch(
		`${config.baseUrl[type]}/lead/get-assigned-user-with-lead-status`,
		{
			headers: {
				Authorization: `Bearer ${token}`,
				...config.defaultHeaders
			}
		}
	)).json();
};

export const bulkUploadTemplate = async (type, partId) => {
	const token = getCookie(btoa('token'));
	let url = `${config.baseUrl[type]}/lead/bulk/download-template`;
	if (type === 'admin') {
		url += `/${partId}`;
	}
	return await (await fetch(url, {
		headers: {
			Authorization: `Bearer ${token}`,
			...config.defaultHeaders
		}
	})).blob();
};

/**
 *
 * @param {File} file
 */
export const bulkUpload = async (file, type, partId) => {
	const token = getCookie(btoa('token'));
	const formData = new FormData();
	formData.append('file', file);
	let url = `${config.baseUrl[type]}/lead/bulk/upload`;
	if (type === 'admin') {
		url += `/${partId}`;
	}
	return await (await fetch(url, {
		method: 'POST',
		headers: {
			Authorization: `Bearer ${token}`,
			Accept: 'application/json'
		},
		body: formData
	})).json();
};

/**
 *
 * @param {File} file
 * @param {String} type
 */
export const uploadLeadDocument = async (file, type = 'pan') => {
	const token = getCookie(btoa('token'));
	const formData = new FormData();
	formData.append('file', file);
	formData.append('type', type);
	return await (await fetch(`${config.baseUrl.affiliate}/doc/upload`, {
		method: 'POST',
		headers: {
			Authorization: `Bearer ${token}`,
			Accept: 'application/json'
		},
		body: formData
	})).json();
};

export const getLeadApiDetails = async (part_id, type = 'admin') => {
	const token = getCookie(btoa('token'));
	const body = { part_id };
	return await (await fetch(`${config.baseUrl[type]}/get-lead-api-request`, {
		method: 'POST',
		body: JSON.stringify(body),
		headers: {
			Authorization: `Bearer ${token}`,
			...config.defaultHeaders
		}
	})).json();
};
export const assignLead = async body => {
	const token = getCookie(btoa('token'));
	return await (await fetch(`${config.baseUrl.admin}/lead/assign-lead`, {
		method: 'POST',
		body: JSON.stringify(body),
		headers: {
			Authorization: `Bearer ${token}`,
			...config.defaultHeaders
		}
	})).json();
};

export const getLeadStatus = async ({ type }) => {
	const token = getCookie(btoa('token'));
	return await (await fetch(`${config.baseUrl[type]}/lead/status`, {
		headers: {
			Authorization: `Bearer ${token}`,
			...config.defaultHeaders
		}
	})).json();
};

export const getRejectLeadReason = async ({ type, statusId }) => {
	const token = getCookie(btoa('token'));
	return await (await fetch(
		`${config.baseUrl[type]}/status/child/${statusId}`,
		{
			headers: {
				Authorization: `Bearer ${token}`,
				...config.defaultHeaders
			}
		}
	)).json();
};
/**
 *
 * @param {String} type userType
 * @param {String} leadId
 */
export const getOfferData = async ({ type, leadId }) => {
	const token = getCookie(btoa('token'));
	return await (await fetch(
		`${config.baseUrl[type]}/lead/get-lead-offer/${leadId}`,
		{
			headers: {
				Authorization: `Bearer ${token}`,
				...config.defaultHeaders
			}
		}
	)).json();
};

export const getLeadHistory = async ({ type, leadId }) => {
	const token = getCookie(btoa('token'));
	return await (await fetch(
		`${config.baseUrl[type]}/lead/status-history/${leadId}`,
		{
			headers: {
				Authorization: `Bearer ${token}`,
				...config.defaultHeaders
			}
		}
	)).json();
};

export const changeStatus = async ({ type, leadId, body }) => {
	const token = getCookie(btoa('token'));
	return await (await fetch(
		`${config.baseUrl[type]}/lead/update-status/${leadId}`,
		{
			method: 'POST',
			body: JSON.stringify(body),
			headers: {
				Authorization: `Bearer ${token}`,
				...config.defaultHeaders
			}
		}
	)).json();
};

export const leadAssignUser = async () => {
	const token = getCookie(btoa('token'));
	return await (await fetch(
		`${config.baseUrl.admin}/lead/get-lead-assign-user`,
		{
			headers: {
				Authorization: `Bearer ${token}`,
				...config.defaultHeaders
			}
		}
	)).json();
};

/**
 *
 * @param {String} type
 * @param {String} params
 */
export const getDdupeDetails = async ({ type, params }) => {
	const token = getCookie(btoa('token'));
	return await (await fetch(
		`${config.baseUrl[type]}/lead/get-dedupe-leads?${objectToQueryParams(
			params
		)}`,
		{
			headers: {
				Authorization: `Bearer ${token}`,
				...config.defaultHeaders
			}
		}
	)).json();
};

export const getLeadTools = async (type, partId) => {
	const token = getCookie(btoa('token'));
	let url = `${config.baseUrl[type]}/get-lead-tools`;
	if (type === 'admin') {
		url += `/${partId}`;
	}
	return await (await fetch(url, {
		headers: {
			Authorization: `Bearer ${token}`,
			...config.defaultHeaders
		}
	})).json();
};

export const changeLeadStatus = async ({ type, body }) => {
	const token = getCookie(btoa('token'));
	return await (await fetch(`${config.baseUrl[type]}/lead/update-lead-type`, {
		method: 'POST',
		body: JSON.stringify(body),
		headers: {
			Authorization: `Bearer ${token}`,
			...config.defaultHeaders
		}
	})).json();
};

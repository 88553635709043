import React from 'react';
import { Link } from 'react-router-dom';
const LafId = ({ row, field }) => {
	return (
		<Link
			to={`/portfolio/views/post-disbursal/${row.cl_lead_id}/repayment-details`}
		>
			{row[field]}
		</Link>
	);
};

export default LafId;

import React from 'react';
import ReactTooltip from 'react-tooltip';

const DdupeTable = ({ ddupeData, userType }) => {
	return (
		<>
			<ReactTooltip id='ddupe'>
				<div className='table-responsive '>
					<table className='table table-striped'>
						<thead>
							<tr>
								<th>Affiliate</th>
								<th>Date Added</th>
								{userType === 'admin' && <th>Status</th>}
							</tr>
						</thead>
						<tbody className='text-black'>
							{ddupeData.map(({ part_name, lead, created_at }) => (
								<tr>
									<td>{part_name}</td>
									{userType !== 'admin' && <td>{created_at}</td>}
									{userType === 'admin' && <td>{lead?.created_at}</td>}
									{userType === 'admin' && <td>{lead?.status}</td>}
								</tr>
							))}
						</tbody>
					</table>
				</div>
			</ReactTooltip>
			<span>
				<i
					data-tip
					data-for='ddupe'
					className='fa fa-info-circle'
					aria-hidden='true'
				></i>
			</span>
		</>
	);
};

export default DdupeTable;

import React, { useState } from "react";
import FundOutModal from "./FoundoutModal";
const Action = (props) => {
    return (
        <div className={`row no-gutters ${props.className}`}>
            <EditFile {...props} />
        </div>
    );
};


const EditFile = ({ row: { file_id, doc_type, ...rest } ,row}) => {
    const [loading, setloading] = useState(null)
    const [modalState, setModalState] = useState(false);
 

    const toggleModal = () => {
      setModalState(!modalState);
    };
    return (
        <>
            <button
                className="btn  ml-2 "
                onClick={toggleModal}
            >
                {loading ? <div className="loader" /> : <><i class="fa fa-edit text-orange"></i></>}
            </button>
            {modalState && (
                <FundOutModal
                    state={modalState}
                    toggleModal={toggleModal}
                    rowData = {row}
                />
            )}
        </>
    );
};

export default Action;

import React from 'react';
import Steps from '../components/AffiliateMapping/Steps';
import Routing from '../router/Routing';
import { Context } from '../config/store';
import { getRoutes, getSteps } from '../helpers/onboarding-form-routes';
import { Link, useParams } from 'react-router-dom';
const OnboardingForm = () => {
	const { state } = React.useContext(Context);

	const [pages, setPages] = React.useState([]);
	const [steps, setSteps] = React.useState([]);

	const params = useParams();
	React.useLayoutEffect(() => {
		setPages(getRoutes(state.userType));
		setSteps(getSteps());
	}, []);
	const page = {
		partner: 0,
		bank: 1,
		documents: 2,
		user: 3
	};
	return (
		<div>
			{' '}
			{/* <ul className='nav nav-tabs mt-3 custom-tabs pb-4'>
				{mainPages.map(({ title, path }) => (
					<li className='nav-item'>
						<Link href={path} className={`nav-link active`}>
							{title}
						</Link>
					</li>
				))}
			</ul> */}
			<>
				<div className='mt-4'>
					<Steps
						showPastGreen={true}
						active={page[((String(params['*']) || '').split('/')?.[1])]}
						partId={(String(params['*']) || '').split('/')?.[2]}
						pages={steps}
					/>
				</div>
				<hr className='m-0' />
			</>
			<div className='content p-3'>
				<Routing routes={pages} />
			</div>
		</div>
	);
};

export default OnboardingForm;

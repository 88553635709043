import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { objectsForEach } from '../../../../common/objectToArray';
import Modal from '../../../../components/Modal';
import message from '../../../../constant/message';
import LenderUgroService from '../../../../services/lender';
import MasterDataService from '../../../../services/master-data';
import Select from '../../../../components/Common/Input/Select';
const AddUTRModal = ({ userType, state, toggleModal, leadId, reloadTable }) => {
	const [loading, setLoading] = useState(false);
	const [reasonslist, setReasonsList] = useState([])
	const [reason, setReason] = React.useState()
	const [comment, setComment] = useState('');
	const [file, setFile] = useState(null);
	const [errors, setErrors] = useState();
	const [reasons_name, setselectedReasons_name] = React.useState()
	const formSubmitHandler = async (e) => {
		e.preventDefault();
		setLoading(true);
		const formData = new FormData();
		comment && formData.append('comment', comment);
		reasons_name && formData.append('reasons_id', reasons_name);
		leadId && formData.append('lead_id', leadId);
		file && formData.append('file', file);
		try {
			let apiResponse;
			apiResponse = await LenderUgroService.ConditionalApprove({
				type: userType,

				formData,
				subType: userType === 'admin' ? 'co-lender' : '',

			});
			if (apiResponse?.data?.status?.code === 1) {
				toast.success(
					apiResponse?.data?.data?.messages?.[0]?.[0] || message.SAVED_SUCCESS
				);
				toggleModal();
				reloadTable();
			}
		} catch (err) {
			let error = err?.response?.data?.messages?.error;
			toast.error(error?.form?.[0] || error?.others?.[0] || message.ERROR_FORM);
			setErrors(error);
			setLoading(false);
		}
	};
	const getreasonslist = async () => {
		try {
			const response = await MasterDataService.getReasonsList(
				state.userType,
				'co-lender'
			);
	
			let responseList = objectsForEach(
				response.data.data.reasons_list,
				'STRING_VAL'
			);
			let ReasonsList_name = responseList.map((response) => ({
				label: response.value,
				value: response.id,
			}));
	
			setReasonsList(ReasonsList_name);
		} catch (error) {
			console.log(error)
		}
	};
	React.useEffect(() => {
		getreasonslist();
	}, []);

	const handleSanctionIds = (data) => {
		let Data_data = '';
		let max = data?.length;
		data?.map((ele, index) => {
			if (index === max - 1) {
				Data_data += ele.value
			} else {
				Data_data += `${ele.value},`
			}
		})
		setselectedReasons_name(Data_data)
		 
	}
 
	return (
		<Modal state={state} closeModal={toggleModal} style={{ width: "500px"}} removeBorderSpace={true}>
			<div className="modal-header-container">
				<div className="modal-title">Add Sanction Conditions</div>
				<div className="closeModalView" onClick={toggleModal}>
					<span className="closeModalIcon">x</span>
				</div>
			</div>
			<div className="p-4 modalData">
				<form onSubmit={formSubmitHandler}>
					<div class='form-group'>
						<div class='row'>
							<div class='col-12'>
								<Select
									label='Sanction condition'
									className={'dropdown'}
									options={reasonslist}
									onChange={(data) => handleSanctionIds(data)}
								// defaultValue={defaultProgram}
								/>
							</div>
						</div>
					</div>
					<div class='form-group'>
						<label>Comment <tt>*</tt></label>
						<textarea
							class="form-control custom-form-control"
							cols="2"
							rows="2"
							value={comment}
							onChange={(e) => setComment(e.target.value.replace(/[<>]+/g, ''))}
						></textarea>
					{errors && <tt>{errors?.fields?.comment[0]}</tt>}
					</div>
					{userType == 'lender' && (
						<div class='form-group'>
							<label>Document Upload</label>
							<div class='row'>
								<div class='col-12'>
									<input
										type='file'
										className='form-control'
										name='date'
										onChange={(e) => setFile(e.target.files[0])}
									/>
								</div>

							</div>
						</div>
					)}
					<div class='d-flex align-items-center justify-content-center'>
						<button type='submit' class='btn btn-custom' disabled={loading}>
							{loading ? <div className='loader' /> : 'OK'}
						</button>
						<button
							type='button'
							class='btn btn-outline-custom ml-2'
							data-dismiss='modal'
							onClick={toggleModal}
						>
							Cancel
						</button>
					</div>
				</form>
			</div>
		</Modal>
	);
};

export default AddUTRModal;

/**
 * @author Dhruv Sachdeva <dhruvsach18@gmail.com>
 */

// FileViewHelper.js is a file resolver for native supported file extensions on web

// getExtension is to common name resolver for different file extensions
import { getExtension } from './extensions';

/**
 * FileViewHelper takes two @params:
 *
 * @param {string} fileType
 * @param {string} src
 *
 * resolve the support format with and file render
 * non-support format with a can't preview
 * check file @method getExtension before adding a check
 * you might need to add a extension in @file extension.js map
 */

export default function FileViewHelper(fileType, src) {
	let file;
	switch (getExtension(fileType)) {
		// check for text
		//check for html
		case 'text':
		case 'html':
			file = (
				<iframe src={src} frameborder='0' height='100%' width='100%'></iframe>
			);
			break;

		//check for audio
		case 'audio':
			file = (
				<audio
					src={src}
					controls
					frameborder='0'
					height='100%'
					width='100%'
				></audio>
			);
			break;

		//check for video
		case 'video':
			file = (
				<video
					src={src}
					controls
					frameborder='0'
					height='100%'
					width='100%'
				></video>
			);
			break;

		// check for image
		case 'img':
			file = <img width='100%' height='500' src={src} />;
			break;

		// default case
		default:
			file = (
				<div class='text-center justify-content-center'>
					<p></p>
					<i>Can't preview this file, please download</i>
				</div>
			);
			break;
	}

	return file;
}

import React from 'react';
import { Context } from '../../../config/sectionContext';
import Select from '.';
import config from '../../../services/config';
import { objectsForEach } from '../../../common/objectToArray';
const City = ({ onChange, value, disabled }) => {
	const { state: sectionState } = React.useContext(Context);
	const [initalLoad, setInitialLoad] = React.useState(true);
	const [options, setOptions] = React.useState([]);
	React.useEffect(() => {
		if (sectionState.stateId) {
			getCityData();
			if (!initalLoad) onChange({ target: { value: '' } });
			else setInitialLoad(false);
		}
	}, [sectionState.stateId]);

	const getCityData = async () => {
		const response = await config.getCityByState(sectionState.stateId);
		if (response?.status?.code === 1) {
			const cities = objectsForEach(response.data.cities, 'STRING_VAL').map(
				({ value, id }) => ({ label: id, value })
			);
			setOptions(cities);
		}
	};
	return (
		<Select
			disabled={disabled}
			onChange={onChange}
			value={value}
			options={options}
		/>
	);
};

export default City;

import React from 'react';
import ReactTooltip from 'react-tooltip';
const LenderName = ({ row, field }) => {
	return (
		<>
			{row.status_id === 1 || row.status_id === 5 ? 
				<>
					<ReactTooltip id={'lender-name' + row.cl_lead_id}>
						<span>{row.eligible_lenders}</span>
					</ReactTooltip>
					<span
						className='tooltip-column-text'
						data-tip
						data-for={'lender-name' + row.cl_lead_id}
					>Eligible Lender</span>
				</> : <span>{row.lender_name}</span>}
		</>
	);
};

export default LenderName;

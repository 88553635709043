import React from 'react';
import Modal from '../../../components/Modal';
import LenderUgroService from '../../../services/lender';
import MasterDataService from '../../../services/master-data';
import { Context } from '../../../config/store';
import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router';
import message from '../../../constant/message';
import { objectsForEach } from '../../../common/objectToArray';

const PullBackModal = ({
	modelState,
	toggleModal,
	getLeadDetail,
	toggleRejectModal,
	leadType,
}) => {
	const navigate = useNavigate();
	const { state } = React.useContext(Context);
	const [comment, setComment] = React.useState('');
	const [errors, setErrors] = React.useState({});
	const [lenders, setLenders] = React.useState([]);
	const [lenderListLoading, setLenderListLoading] = React.useState(false);
	const [lenderListError, setLenderListError] = React.useState(false);
	const [lenderListErrorMessage, setLenderListErrorMessage] =
		React.useState(false);
	const [submitLoading, setSubmitLoading] = React.useState(false);
	const [lenderId, setLenderId] = React.useState([]);
	const leadId = useParams()['id'];
	React.useEffect(() => {
		getlenders();
	}, []);
	const getlenders = async () => {
		setLenderListLoading(true);
		setLenderListError(false);
		setLenderListErrorMessage('');
		try {
			const response = await MasterDataService.getLenderListByLeadId(
				state.userType,
				'co-lender',
				leadId,
				'pullback'
			);

			let responseList = objectsForEach(
				response.data.data.lender_list,
				'STRING_VAL'
			);
			let lenderItems = responseList.map((response) => ({
				label: response.value,
				value: response.id,
			}));
			setLenderListLoading(false);
			if (lenderItems.length === 0) {
				setLenderListError(true);
				setLenderListErrorMessage('No Lender Found');
			} else {
				setLenders(lenderItems);
				setLenderId([lenderItems[0].value]);
			}
		} catch (err) {
			setLenderListLoading(false);
			setLenderListError(true);
			setLenderListErrorMessage('Server side error occured fetching lenders');
		}
	};
	const pullBackLead = async () => {
		setSubmitLoading(true);
		try {
			const response = await LenderUgroService.pullBackLead(
				state.userType,
				'co-lender',
				leadId,
				lenderId,
				comment
			);
			getLeadDetail();
			const success = response?.data?.messages?.success;
			setSubmitLoading(false);
			navigate('/portfolio/unassigned');
			toast.success(
				success?.form?.[0] || success?.others?.[0] || message.SAVED_SUCCESS
			);
		} catch (err) {
			setSubmitLoading(false);
			let error = err?.response?.data?.messages?.error;
			toast.error(error?.form?.[0] || error?.others?.[0] || message.ERROR_FORM);
		}
	};

	const formSubmitHandler = (e) => {
		e.preventDefault();
		if (lenderId && !submitLoading) {
			pullBackLead();
		}
	};
	const onLenderSelected = (e) => {
		if (leadType == 1 && e.target.checked) {
			setLenderId([...lenderId, e.target.value]);
		} else if (leadType == 1 && !e.target.checked) {
			let filteredValues = lenderId.filter((id) => id != e.target.value);
			setLenderId(filteredValues);
		} else {
			setLenderId([e.target.value]);
		}
	};

	return (
		<Modal state={modelState} closeModal={toggleRejectModal}>
			<form onSubmit={formSubmitHandler}>
				{lenderListLoading || lenderListError || lenders.length === 0 ? (
					<div>
						{lenderListLoading && <div className='loader'></div>}
						{lenderListError && (
							<div className='lender-error'>{lenderListErrorMessage}</div>
						)}
						{lenders.length === 0 && !lenderListLoading && (
							<div className='d-flex'>
								<button
									type='button'
									className='btn btn-outline-custom ml-2'
									data-dismiss='modal'
									onClick={toggleModal}
								>
									Cancel
								</button>
							</div>
						)}
					</div>
				) : (
					<>
						<div className='form-group mb-0'>
							<div className='custom_control d-flex'>
								{(lenders || []).map(({ value, label }) => (
									<div className='custom_radio_control mr-4' key={value}>
										<input
											onChange={onLenderSelected}
											type={leadType == 1 ? 'checkbox' : 'radio'}
											name='entity-type'
											value={value}
											checked={
												leadType == 1
													? lenderId.includes(value)
													: value == lenderId
											}
										/>
										<label className='ml-1'>{label}</label>
									</div>
								))}
							</div>
							{errors && (
								<p className='mt-2 mb-0 text-danger'>
									{errors?.fields?.lender_id?.[0]}
								</p>
							)}
						</div>
						<div className='form-group'>
							<label>Reason for Pullback</label>
							<textarea
								className='form-control custom-form-control'
								cols={4}
								rows={4}
								value={comment}
								onChange={(e) => setComment(e.target.value)}
								defaultValue={''}
							/>
							{errors && (
								<p className='mt-2 mb-0 text-danger'>
									{errors?.fields?.comment?.[0]}
								</p>
							)}
						</div>
						<div className='d-flex'>
							<button
								type='submit'
								className='btn btn-custom'
								disabled={!lenderId}
							>
								{submitLoading ? <div className='loader'></div> : 'Submit'}
							</button>
							<button
								type='button'
								className='btn btn-outline-custom ml-2'
								data-dismiss='modal'
								onClick={toggleModal}
							>
								Cancel
							</button>
						</div>
					</>
				)}
			</form>
		</Modal>
	);
};

export default PullBackModal;

import APIService from './api-service';
import config from './config';

export default class MasterDataService extends APIService {

	static getportfoliodetail(type, subType) {
		let url = config.baseUrl[type];
		if (subType) {
			url += `/${subType}`;
		}
		return this.get(`${url}/dashboard/portfolio `);
	}
	static getTableData(type, subType, routes, selectedMonths, selectedlenderData, selectedprograam, selectedyear,leadType,assignDateFrom,assignDateTo) {

		let url = config.baseUrl[type];
		if (subType) {
			url += `/${subType}`;
		}
		return this.get(`${url}/dashboard/portfolio/${routes}?months=${selectedMonths}&year=${selectedyear}&lender_ids=${selectedlenderData}&prod_cat=${selectedprograam}&lead_type=${leadType}&from_date=${assignDateFrom}&to_date=${assignDateTo}`,);
	}
	static getExcelTableData(type, subType, routes, selectedMonths, selectedlenderData, selectedprograam, selectedyear, leadType, assignDateFrom, assignDateTo, exportExcel) {

		let url = config.baseUrl[type];
		if (subType) {
			url += `/${subType}`;
		}
		if (exportExcel === 1) {
			let responseType = 'blob'
			return this.get(`${url}/dashboard/portfolio/${routes}?months=${selectedMonths}&year=${selectedyear}&lender_ids=${selectedlenderData}&prod_cat=${selectedprograam}&lead_type=${leadType}&from_date=${assignDateFrom}&to_date=${assignDateTo}&export=${exportExcel}`, null, responseType);
		}
	}
	/**
	 *
	 * @param {String} type
	 * @param {String} subType
	 */
	static getLenderList_Not_valid(type, subType) {
		type = "lender"

		let url = config.baseUrl[type];
		// if (subType) {
		// 	url += `/${subType}`;
		// }
		return this.get(`${url}/master/lender-list`);
	}
	/**
	 *
	 * @param {String} type
	 * @param {String} subType
	 */
	static getProductList(type, subType) {
		let url = config.baseUrl[type];
		if (subType) {
			url += `/${subType}`;
		}
		return this.get(`${url}/master/product-list`);
	}
	/**
	 *
	 * @param {String} type
	 * @param {String} subType
	 */
	static getProductTypeList(type, subType) {
		let url = config.baseUrl[type];
		if (subType) {
			url += `/${subType}`;
		}
		return this.get(`${url}/master/product-type-list`);
	}
	/**
	 *
	 * @param {String} type
	 * @param {String} subType
	 */
	static getStatusList(type, subType) {
		let url = config.baseUrl[type];
		if (subType) {
			url += `/${subType}`;
		}
		return this.get(`${url}/master/status-list`);
	}

	/**
	 *
	 * @param {String} type
	 * @param {String} subType
	 */
	static getReasonsList(type, subType, reasomnsType='approve') {
		if(reasomnsType === 'reject')
			reasomnsType = 2;
		else
			reasomnsType = 1;
		let url = config.baseUrl["lender"];
		// if (subType) {
		// 	url += `/${subType}`;
		// }
		return this.get(`${url}/master/reasons-list?type=${reasomnsType}`);
	}

	/**
	 *
	 * @param {String} type
	 * @param {String} subType
	 */
	static getDocumentList(type, subType, leadId, lenderId) {
		let url = config.baseUrl[type];
		if (subType) {
			url += `/${subType}`;
		}
		return this.get(`${url}/master/document-list/${leadId}/${lenderId}`);
	}
	static uploadDocument(type, subType, leadId, formData, lenderId) {
		let url = config.baseUrl[type];
		if (subType) {
			url += `/${subType}`;
		}
		return this.post(
			`${url}/lead/documents/${leadId}/${lenderId}`,
			formData,
			null,
			null,
			{
				'Content-Type': 'multipart/form-data',
			}
		);
	}
	static getLenderListByLeadId(type, subType, leadId, listType) {
		type = "lender"

		let url = config.baseUrl[type];
		// if (subType) {
		// 	url += `/${subType}`;
		// }
		return this.get(
			`${url}/master/lender-list/${leadId}${listType ? `?type=${listType}` : ''
			}`
		);
	}

	static getPreapprovedLenders(type, subType, leadId) {
		let url = config.baseUrl[type];
		if (subType) {
			url += `/${subType}`;
		}
		return this.get(`${url}/master/preapproved-lender-list/${leadId}`);
	}
	static savePreapprovedLenderLead(type, subType, leadId, lenderId, comment) {
		let url = config.baseUrl[type];
		if (subType) {
			url += `/${subType}`;
		}
		return this.post(`${url}/master/save-preapproved-lender-lead`, {
			cl_lead_id: leadId,
			lender_id: lenderId,
			comment,
		});
	}
	static getLafId(type, leadId) {
		let url = config.baseUrl[type];
		return this.get(`${url}/lead/get-lead-info/${leadId}`);
	}
}

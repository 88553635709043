import React from 'react';
import { offerList } from '../services/manage-offer';
import Pagination from '../components/Pagination';
import OffersTable from '../components/ManageOffer/OffersTable';
import { useRouter } from '../hooks/useRouter';
import AddOffer from '../components/ManageOffer/AddOffer';
import { Context } from '../config/store';
const ManageOffers = () => {
	const [offers, setOffers] = React.useState([]);
	const [partId, setPartId] = React.useState('');
	const [pagination, setPaginationData] = React.useState({});
	const [offerModalState, setOfferModalState] = React.useState(false);
	const { state } = React.useContext(Context);
	const [page, setPage] = React.useState(1);
	const router = useRouter();
	const getOffers = async partId => {
		const response = await offerList(partId, { page });
		if (response?.status?.code === 1) {
			setOffers(response?.data.temp_offer_files?.data || []);

			setPaginationData(response.data.temp_offer_files?.pagination || {});
		}
	};
	React.useEffect(() => {
		const partId = router.params.partId;
		setPartId(partId);
		getOffers(partId);
	}, [page]);
	const checkHasPermission = checkPermission => {
		if (state.is_super_admin === 1) return true;
		const permission = state.permission.find(
			permission => permission === checkPermission
		);

		if (permission) return true;
		else return false;
	};
	return (
		<>
			{offerModalState && (
				<AddOffer
					partId={partId}
					state={offerModalState}
					toggleModal={setOfferModalState}
					reloadTable={getOffers}
				/>
			)}
			<>
				{checkHasPermission('bulk-lead-offer-upload') && (
					<a
						href='#'
						className='btn btn-custom btn-custom-height float-right mt-3'
						onClick={e => {
							e.preventDefault();
							setOfferModalState(true);
						}}
					>
						Add Offer
					</a>
				)}
				<div className='clearfix' />
				<OffersTable page={page} offers={offers} partId={partId} />
				<Pagination
					setPage={setPage}
					total_pages={pagination.total_pages}
					total={pagination.total}
				/>
			</>
		</>
	);
};

export default ManageOffers;

import React from 'react';
import ComissionTableItem from './ComissionTableItem';
import ComisssionDsaTableItem from './ComissionDsaTableItem';

const ComissionTable = ({ reports = [], userType }) => {
	return (
		<div className='panel '>
			<div className='table-responsive '>
				<table className='table table-striped'>
					<thead>
						<tr>
							<th>Lead ID</th>
							<th>Loan Amount (INR)</th>

							<th>Converted to Application On</th>
							<th>Product</th>
							{userType !== 'dsa' && <th>Comission Type</th>}
							{/* <th>Base Payout %</th> */}
							<th>Base Payout</th>
							{/* 	<th>Tenor Linked incentive %</th>
							<th>Tenor Linked Incentive</th> */}
							<th>Total</th>
						</tr>
					</thead>
					<tbody>
						{reports.map(({ lead_id, ...rest }) =>
							userType === 'dsa' ? (
								<ComisssionDsaTableItem
									key={lead_id}
									lead_id={lead_id}
									{...rest}
								/>
							) : (
								<ComissionTableItem key={lead_id} lead_id={lead_id} {...rest} />
							)
						)}
					</tbody>
				</table>
			</div>
			{/* <Pagination setPage={setPage} total_pages={pagination.total_pages} /> ); */}
		</div>
	);
};

export default ComissionTable;

import React from 'react';
import { getFullDate } from '../../common/date';
import { updateAffiliate } from '../../services/manage-affiliate';
import { toast } from 'react-toastify';
import message from '../../constant/message';
import { Link } from 'react-router-dom';
import { Context } from '../../config/store';
import AffiliateActions from './TableItems/AffiliateActions';
import PendingAffiliateActions from './TableItems/PendingAffiliateActions';
import { useRouter } from '../../hooks/useRouter';
const AffiliateListingItem = ({
	user_id,
	part_id,
	partner_code,
	nsm_status,
	f_name,
	l_name,
	part_type,
	email,
	mobile_no,
	created_at,
	is_active,
	pan_no,
	reloadTable,
	toggleOfferModal,
	userIds,
	block_status_id,
	affiliateTypeModal,
	listType,
	setCheckedStatus,
	part_type_view
}) => {
	const [showDropDown, setDropDownStatus] = React.useState(false);
	const { state } = React.useContext(Context);
	const router = useRouter();
	/**
	 * Change status to approve
	 */
	const changeAffiliateStatus = async (e, status) => {
		e.preventDefault();
		const response = await updateAffiliate({ user_id, status, part_id });
		if (response?.status?.code === 1) {
			let success = response?.messages?.success;
			setDropDownStatus(false);
			toast.success(
				success?.form?.[0] || success?.others?.[0] || message.UPDATE_SUCCESS
			);
			reloadTable();
		} else {
			let error = response?.messages?.error;
			toast.error(error?.form?.[0] || error?.others?.[0] || message.ERROR_FORM);
		}
	};
	const checkHasPermission = checkPermission => {
		if (state.is_super_admin === 1) return true;
		const permission = state.permission.find(
			permission => permission === checkPermission
		);

		if (permission) return true;
		else return false;
	};
	return (
		<tr>
			{Boolean(
				listType === 'pending-affiliate-dsa-list' &&
					router.pathname.includes('approval')
			) && (
				<td>
					<input
						type='checkbox'
						checked={(userIds || []).includes(user_id)}
						id='header-checkbox'
						onChange={e => setCheckedStatus(e.target.checked, part_id, user_id)}
					></input>
				</td>
			)}
			<td>AF{user_id}</td>
			<td>
				{f_name} {l_name}
			</td>
			<td>{email}</td>
			<td>{mobile_no}</td>

			<td>{pan_no}</td>

			<td>
				{part_type_view === 1 && 'Retail DSA'}
				{part_type_view === 2 && 'Corporate DSA'}
				{part_type_view === 3 && 'Connector'}
				{part_type_view === 4 && 'Vendor'}
			</td>
			<td>{partner_code}</td>
			<td>{state.config?.onboardingStatus?.[nsm_status] || ''}</td>
			<td>
				<span className={is_active ? 'text-success' : 'text-warning'}>
					{is_active ? 'Active' : 'In active'}
				</span>
			</td>
			<td>{getFullDate(new Date(created_at))}</td>
			<td>
				{listType === 'affiliate' ? (
					<AffiliateActions
						showDropDown={showDropDown}
						setDropDownStatus={setDropDownStatus}
						changeAffiliateStatus={changeAffiliateStatus}
						checkHasPermission={checkHasPermission}
						affiliateTypeModal={affiliateTypeModal}
						part_id={part_id}
						part_type={part_type}
						block_status_id={block_status_id}
						is_active={is_active}
					/>
				) : (
					<PendingAffiliateActions
						showDropDown={showDropDown}
						setDropDownStatus={setDropDownStatus}
						part_id={part_id}
					/>
				)}
			</td>
		</tr>
	);
};

export default AffiliateListingItem;

import React from 'react';
import PermissionEach from './PermissionEach';
const PermissionList = ({
	permissions = [],
	childrenPermissions,
	setChildrenPermissions,
	setParentPermissions,
	parentPermissions,
	userPermissions,
	edit
}) => {
	return (
		<div className='form-check icon'>
			{permissions.map(
				({ display_name, childrens, parent_permission_id, id }, index) => (
					<PermissionEach
						userPermissions={userPermissions}
						edit={edit}
						id={id}
						parentId={id}
						childrenPermissions={childrenPermissions}
						setParentPermissions={setParentPermissions}
						setChildrenPermissions={setChildrenPermissions}
						parentPermissions={parentPermissions}
						parent_permission_id={parent_permission_id}
						permissions={permissions}
						key={index}
						display_name={display_name}
						childrens={childrens}
					/>
				)
			)}
		</div>
	);
};

export default PermissionList;

import React from 'react'
import './Dashboard.css'

const Tables = ({tableData }) => {
    return (
        <div>
            {<table className='dashboard_table'>
                <tr className='table_row'>
                    <th className='Sl_no'>S.No.</th>
                    <th className='leder_name'>Program</th>
                    <th>Case Assigned </th>
                    <th>Pending</th>
                    <th>Rejected</th>
                    <th>Sanctioned</th>
                    <th>Disbursal Pending</th>
                    <th>Disbursal Rejected</th>
                    <th>Disbursed</th>
                </tr>
                {tableData?.map((ele, ind) => {
                    return (
                        <>
                            <tr className='table_data'>
                                <td className='Sl_no'>{ind+1}</td>
                                <td className={'co_landing_data'}>{ele?.id}</td>
                                <td className={'co_landing_data' }>{ele?.lead_assigned}</td>
                                <td className={'co_landing_data' }>{ele?.lender_pending}</td>
                                <td className={'co_landing_data' }>{ele?.sanction_rejected}</td>
                                <td className={'co_landing_data' }>{isNaN(ele?.sanction_approved + ele?.pre_sanction_approved)? 0 :ele?.sanction_approved + ele?.pre_sanction_approved}</td>
                                <td className={'co_landing_data' }>{ele?.disbursal_pending}</td>
                                <td className={'co_landing_data' }>{ele?.disbursal_rejected}</td>
                                <td className={'co_landing_data' }>{ele?.disbursal_portfolio + ele?.disbursal_lender_decision}</td>

                            </tr>
                        </>
                    )
                })}
            </table>}
        </div >
    )
}

export default Tables
import React from 'react';
import { Route } from 'react-router-dom';
const PrivateRoute = ({
	path,
	component: Component,
	children,
	type,
	...rest
}) => {
	return (
		<Route {...rest} key={path} path={`${path}`}>
			<Component type={type} children={children} />
		</Route>
	);
};

export default PrivateRoute;

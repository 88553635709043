import React, { useState } from 'react';
import './index.css';
import SearchFilter from '../../../components/Portfolio/SearchFilter';
import Table from '../../../components/Common/Table';
import Pagination from '../../../components/Pagination';
import Button from '../../../components/Common/Button';
import { productType } from '../../../constant/selectOptions';
import { eligibleForLender } from '../../../constant/selectOptions';
import { productName } from '../../../constant/selectOptions';
import PortfolioContainer from '../../../components/Portfolio/PortfolioContainer';
import LenderUgroService from '../../../services/lender';
import { Context } from '../../../config/store';
import AssignLenderLeadModal from '../../../components/Portfolio/AssignLenderLeadModal';
import TableNoRecords from '../../../components/Common/Table/TableNoRecords';
import portfolio from '../../../constant/tableHeaders/portfolio';
import { useNavigate } from 'react-router-dom';
import { downloadFile } from '../../../common/download';

function UnassignedPortfolio() {
	const [queryParams, setQueryParams] = React.useState('');
	const navigate = useNavigate();
	const [tableData, setTableData] = useState({
		columns: portfolio,
		rows: [],
	});
	const [page, setCurrentPage] = useState(1);
	const [pagination, setPagination] = useState({});
	const [tableHeaderChecked, setTableHeaderChecked] = useState(false);
	const [selectedIds, setSelectedIds] = React.useState([]);
	const [modalState, setModalState] = React.useState(false);
	const [assignCoLenders, setAssignCoLender] = React.useState([]);
	const { state, dispatch } = React.useContext(Context);
	const [loading, setLoading] = React.useState(true);
	const [sortParams, setSortParams] = React.useState({
		sortBy: '',
		sortOrder: '',
	});
	const tableHeaderCheckboxUpdateCallback = (checked, data) => {
		setTableData({
			...tableData,
			rows: data,
		});
		setTableHeaderChecked(checked);
		getSelectedIds();
	};

	const tableRowCheckboxUpdateCallback = (
		data,
		selectedLength,
		state,
		currentIndex
	) => {
		setTableData({
			...tableData,
			rows: data,
		});
		getSelectedIds(true, state, currentIndex);
	};
	const selectRows = () => {
		for (var i = 0; i < tableData.rows.length; i++) {
			const itemIndex = selectedIds.findIndex(
				({ laf_id }) => tableData.rows[i].laf_id === laf_id
			);
			tableData.rows[i].checked = itemIndex !== -1;
		}
	};
	const getSelectedIds = (singleRowCheck, state, currentIndex) => {
		let selectedItems = tableData.rows
			.filter((data) => data.checked)
			.map((d) => ({
				cl_lead_id: d.cl_lead_id,
				laf_id: d.laf_id,
			}));
		if (singleRowCheck) {
			const itemIndex = selectedIds.findIndex(
				({ laf_id }) => tableData.rows[currentIndex].laf_id === laf_id
			);
			if (!state && itemIndex !== -1) {
				selectedIds.splice(itemIndex, 1);
			} else {
				selectedIds.push({
					cl_lead_id: tableData.rows[currentIndex].cl_lead_id,
					laf_id: tableData.rows[currentIndex].laf_id,
				});
			}

			return setSelectedIds([...selectedIds]);
		}
		setSelectedIds(selectedItems);
	};

	const getPortfolio = async (paramsChanged, sortOrder, sortBy) => {
		setLoading(true);
		const response = await LenderUgroService.getAdminFilteredPortfolio({
			type: state.userType,
			page: paramsChanged ? 1 : page,
			leadType: 'unassigned',
			dataExport: 0,
			sortBy: sortBy ? sortBy : sortParams.sortBy,
			sortOrder: sortOrder ? sortOrder : sortParams.sortOrder,
			queryParams,
		});
		const data = response.data.data?.cl_leads;

		setTableData({
			...tableData,
			rows: data?.data || [],
		});
		setLoading(false);
		setCurrentPage(page);
		setPagination(response?.data?.data.cl_leads.pagination);
	};

	const toggleModal = () => {
		getAssignLenders();
		setModalState(!modalState);
	};
	const onSortChange = ({ sortOrder, sortBy }) => {
		setSortParams({ sortBy, sortOrder });
		getPortfolio(false, sortOrder, sortBy);
	};
	const navigateToHistory = () => {
		navigate('/portfolio/audit/history');
	};
	const reloadTable = () => {
		setSelectedIds([]);
		getPortfolio();
	};
	const exportLead = async () => {
		const response = await LenderUgroService.getAdminFilteredPortfolio({
			type: state.userType,
			page,
			leadType: 'unassigned',
			dataExport: 1,
			queryParams,
			sortBy: sortParams.sortBy,
			sortOrder: sortParams.sortOrder,
			responseType: 'blob',
		});
		downloadFile(response.data, 'unassigned_leads' + +new Date());
	};

	const getAssignLenders = async () => {
		const response = await LenderUgroService.getCoLenders({
			type: state.userType,
			leadId: selectedIds[0].cl_lead_id,
		});
		return response;
	};

	const redirectToAdditional = (leadId) => {
		navigate('/portfolio/view/' + leadId + '/additional');
	};

	React.useEffect(() => {
		getPortfolio();
	}, [page]);
	React.useEffect(() => {
		selectRows();
	}, [tableData]);
	React.useEffect(() => {
		if (!loading) getPortfolio(true);
	}, [queryParams]);
	React.useEffect(() => {
		dispatch({
			type: 'selectedColender',
			selectedColender: '/portfolio/unassigned',
		});
		localStorage.setItem('selectedColender', '/portfolio/unassigned');
	}, []);
	const showCheckbox = (row) => {
		return row.lead_type != 1 && row.is_da === 1;
	};
	return (
		<PortfolioContainer>
			<SearchFilter
				setQueryParams={setQueryParams}
				productTypes={productType}
				productNames={productName}
				lenders={eligibleForLender}
				statusOptions={[
					{ label: 'Unassigned', value: 1 },
					{ label: 'Additional Info Pending', value: 2 },
					{
						label: 'Doc Pending',
						value: 3,
					},

					{ label: 'Pulled Back', value: 8 },
				]}
				loading={loading}
			></SearchFilter>
			<div className='d-flex justify-content-end'>
				{selectedIds.length > 0 && (
					<Button
						type='btn-custom'
						classNames='mt-3 mb-3 mr-2'
						clickHandler={toggleModal}
					>
						Bulk Submit to Lender ({selectedIds.length})
					</Button>
				)}
				<Button
					type='btn-export'
					classNames='float-right  mt-3 mb-3 mr-2'
					clickHandler={navigateToHistory}
				>
					History
				</Button>
				<Button
					type='btn-export'
					classNames='float-right  mt-2 mb-2'
					clickHandler={exportLead}
				>
					<i class="fa fa-file-excel-o mr-1" aria-hidden="true"></i>
					Export Excel
				</Button>
			</div>
			<Table
				columns={tableData.columns}
				checkbox={true}
				customCheck={showCheckbox}
				rows={tableData.rows}
				headerChecked={tableHeaderChecked}
				headerCheckboxUpdateCallback={tableHeaderCheckboxUpdateCallback}
				rowCheckboxUpdateCallback={tableRowCheckboxUpdateCallback}
				loading={loading}
				reloadTable={redirectToAdditional}
				onSortChange={onSortChange}
				type='unassigned'
			>
				{Boolean(!tableData.rows.length && !loading) && <TableNoRecords />}
			</Table>

			{pagination?.total_pages ? (
				<Pagination
					disabled={loading}
					setPage={setCurrentPage}
					total_pages={pagination.total_pages}
					total={pagination.total}
					showTotal={true}
				/>
			) : (
				''
			)}

			{modalState && selectedIds.length && (
				<AssignLenderLeadModal
					bulkAssign={true}
					modelState={modalState}
					toggleModal={toggleModal}
					colenders={assignCoLenders}
					selectedIds={selectedIds}
					reloadTable={reloadTable}
				/>
			)}
		</PortfolioContainer>
	);
}

export default UnassignedPortfolio;


import DateFormatter from '../../../../components/Common/DateFormatter';
import Actions from '../../../../pages/OnboardingForm/DocumentsUpload/Actions';

export default [
	{ title: 'LAN No.', field: 'lan_no' },
	{ title: 'Customer Name', field: 'cust_name' },
	{ title: 'Disbursed Amount', field: 'disbursed_amt' },
	{ title: 'Disbursed Date', field: 'disbursed_date' },
	{ title: 'UTR No.', field: 'utr_no' },
	{ title: 'UTR Date', field: 'utr_date' },
	{ title: 'Created By', field: 'created_by_name' },
	{ title: 'Created At', field: 'created_at', component: DateFormatter },
	{ title: 'Action', field: '', component: Actions },
];

import React from 'react'

const Reasons = (props) => {
  let response = props?.row?.reasons_name?.split(', ')
  if (response) {
    return (
      <>
        <ol>
          {
            response?.map((ele) => {
              return <>
                <li style={{ listStyle: 'auto', marginLeft: '10px' }}>{ele}.<br /></li>
              </>
            })
          }
        </ol>
      </>
    )
  } else {
    return (
      <>
        <span>{'---'}</span>
      </>
    )
  }
}

export default Reasons
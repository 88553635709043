import React from 'react';
import SectionContent from './SectionContent';
import splitCamelCase from '../../../common/splitCamelCase';
import splitUnderscoreToCaptialise from '../../../common/splitUnderscoreToCaptialise';
const Section = ({
	onChange,
	errors,
	body,
	apiResponse,
	fields,
	id,
	multiple,
	addNewSection,
	errorKey,
	removeSection,
	bindKey,
	edit = true,
}) => {
	if (multiple && !bindKey) {
		return (
			<div>
				<h5>{splitCamelCase(splitUnderscoreToCaptialise(id))} </h5>{' '}
				{(body || []).map(({}, index) => (
					<div className='row align-items-center' key={id + index}>
						<div className='col-md-11'>
							<div className='row'>
								<SectionContent
									key={body?.[index]}
									onChange={onChange}
									index={index}
									body={body?.[index]}
									apiResponse={apiResponse}
									errors={errors}
									fields={fields}
									errorKey={errorKey}
									multiple={multiple}
									edit={edit}
								/>
							</div>
							<hr />
						</div>
						<div className='col-md-1 text-center'>
							{multiple &&
								(body.length - 1 === index ? (
									<>
										{index === 1 && (
											<i
												onClick={() => removeSection(index)}
												className={`fa fa-minus-circle text-orange font-18`}
											></i>
										)}
										<i
											onClick={addNewSection}
											className={`fa fa-plus-circle text-orange font-18 ml-2`}
										></i>
									</>
								) : (
									index >= 1 && (
										<i
											onClick={() => removeSection(index)}
											className={`fa fa-minus-circle text-orange font-18`}
										></i>
									)
								))}
						</div>
					</div>
				))}
			</div>
		);
	} else if (multiple)
		return (
			<div>
				<h5>{splitCamelCase(splitUnderscoreToCaptialise(id))} </h5>{' '}
				{(body[bindKey] || []).map(({}, index) => (
					<div className='row align-items-center' key={id + index}>
						<div className='col-md-11'>
							<div className='row'>
								<SectionContent
									key={body?.[index]}
									onChange={onChange}
									index={index}
									body={body?.[bindKey]?.[index]}
									apiResponse={apiResponse}
									errors={errors}
									bindKey={bindKey}
									fields={fields}
									errorKey={errorKey}
									multiple={multiple}
									edit={edit}
								/>
							</div>
							<hr />
						</div>
						<div className='col-md-1 text-center'>
							{multiple &&
								edit &&
								(body?.[bindKey].length - 1 == index ? (
									<>
										{index === 1 && (
											<i
												onClick={() => removeSection(index, bindKey)}
												className={`fa fa-minus-circle text-orange font-18`}
											></i>
										)}
										<i
											onClick={() => addNewSection(index, bindKey)}
											className={`fa fa-plus-circle text-orange font-18 ml-2`}
										></i>
									</>
								) : (
									index >= 1 && (
										<i
											onClick={() => removeSection(index, bindKey)}
											className={`fa fa-minus-circle text-orange font-18`}
										></i>
									)
								))}
						</div>
					</div>
				))}
			</div>
		);
	return (
		<div className=''>
			<h5>{splitCamelCase(splitUnderscoreToCaptialise(id))} </h5>{' '}
			<div className='row'>
				<SectionContent
					onChange={onChange}
					body={body}
					apiResponse={apiResponse}
					errors={errors}
					fields={fields}
					errorKey={errorKey}
					multiple={multiple}
					edit={edit}
				/>
			</div>
		</div>
	);
};

export default Section;

import React from 'react';
import Steps from '../components/AffiliateMapping/Steps';
import BusinessDetails from '../components/AffiliateMapping/AffiliateKyc/BusinessDetails';
import BusinessAddressDetails from '../components/AffiliateMapping/AffiliateKyc/BusinessAddressDetails';
import BankAccountDetails from '../components/AffiliateMapping/AffiliateKyc/BankAccountDetails';
import Actions from '../components/AffiliateMapping/AffiliateKyc/Actions';
import { useRouter } from '../hooks/useRouter';
import PageHeader from '../components/AffiliateMapping/AffiliateKyc/PageHeader';
import { getKycDetails, uploadPartDoc } from '../services/manage-affiliate';
import { Context } from '../config/store';
import KycDocument from '../components/AffiliateMapping/AffiliateKyc/KycDocument';
import { toast } from 'react-toastify';
import message from '../constant/message';
import OnboardingForm from './onboarding-form';
const AffiliateKyc = () => {
	const router = useRouter();

	const { state } = React.useContext(Context);

	let routeArray = (String(router.params['*']) || '').split('/');
	let partId = routeArray?.[routeArray.length - 1];
	return (
		<>
			<div className='p-3'>
				{partId && <PageHeader partId={partId} />}
				{state.userType === 'admin' && (
					<>
						<div>
							<Steps pathName={router.pathname} partId={partId} active={0} />
						</div>
						<hr className='m-0' />
					</>
				)}

				<OnboardingForm />
			</div>
		</>
	);
};

export default AffiliateKyc;

{
	/* <div id='accordion' className='custom-accodian custom-accodian1'>
<div className='row '>
	<div className='col-md-5 pr-0'>
		<div className='card'>
			<div className='card-header' id='headingnine'>
				<h5 className='mb-0'>
					<button
						className='btn btn-link collapsed'
						data-toggle='collapse'
						data-target='#collapseNine'
						aria-expanded='false'
						aria-controls='collapseNine'
					>
						GST Number
					</button>
				</h5>
			</div>
			<div
				id='collapseNine'
				className='collapse'
				aria-labelledby='headingnine'
				data-parent='#accordion'
			>
				<div className='card-body p-0'>
					<table className='table'>
						<thead>
							<tr>
								<th>File Name</th>
								<th>Uploaded on</th>
								<th>Download</th>
								<th>Action</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>XYZ</td>
								<td>25 july 2021</td>
								<td align='center'>
									<a href='#' className='text-success'>
										<i
											className='fa fa-download'
											aria-hidden='true'
										/>
									</a>
								</td>
								<td align='center'>
									<a href='#' className='text-danger'>
										<i
											className='fa fa-trash'
											aria-hidden='true'
										/>
									</a>
									<a href='#' className='text-primary'>
										<i
											className='fa fa-pencil-square-o'
											aria-hidden='true'
										/>
									</a>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>
	</div>
	<div className='col-md-2 d-flex pt-1'>
		<button
			type='button'
			className='btn btn-custom btn-custom-height'
			data-toggle='modal'
			data-target='#myModal'
		>
			Upload
		</button>
		<a href className='ml-2'>
			<i
				className='fa fa-plus-circle fa-2x text-gray'
				aria-hidden='true'
			/>
		</a>
	</div>
</div>
<div className='row '>
	<div className='col-md-5 pr-0'>
		<div className='card'>
			<div className='card-header' id='headingnine1'>
				<h5 className='mb-0'>
					<button
						className='btn btn-link collapsed'
						data-toggle='collapse'
						data-target='#collapseNine1'
						aria-expanded='false'
						aria-controls='collapseNine1'
					>
						GST Number
					</button>
				</h5>
			</div>
			<div
				id='collapseNine1'
				className='collapse'
				aria-labelledby='headingnine1'
				data-parent='#accordion'
			>
				<div className='card-body p-0'>
					<table className='table'>
						<thead>
							<tr>
								<th>File Name</th>
								<th>Uploaded on</th>
								<th>Download</th>
								<th>Action</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>XYZ</td>
								<td>25 july 2021</td>
								<td align='center'>
									<a href='#' className='text-success'>
										<i
											className='fa fa-download'
											aria-hidden='true'
										/>
									</a>
								</td>
								<td align='center'>
									<a href='#' className='text-danger'>
										<i
											className='fa fa-trash'
											aria-hidden='true'
										/>
									</a>
									<a href='#' className='text-primary ml-2'>
										<i
											className='fa fa-pencil-square-o'
											aria-hidden='true'
										/>
									</a>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>
	</div>
	<div className='col-md-2 d-flex pt-1'>
		<button
			type='button'
			className='btn btn-custom btn-custom-height'
			data-toggle='modal'
			data-target='#myModal'
		>
			Upload
		</button>
		<a href className='ml-2'>
			<i
				className='fa fa-plus-circle fa-2x text-gray'
				aria-hidden='true'
			/>
		</a>
	</div>
</div>
</div> */
}

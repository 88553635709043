import React from 'react';
import Modal from '../Modal';
import { toast } from 'react-toastify';
import message from '../../constant/message';
import {
	saveAffiliateUser,
	getAffiliateUser
} from '../../services/manage-user';
const AddAffiliateUser = ({ user_id, state, toggleModal, reloadTable }) => {
	const style = { width: '300px' };
	const [status, setStatus] = React.useState(1);
	const [fName, setFName] = React.useState('');
	const [LName, setLName] = React.useState('');
	const [email, setEmail] = React.useState('');
	const [phone, setPhone] = React.useState('');
	const [errors, setErrors] = React.useState(null);
	const [loading, setLoading] = React.useState(false);
	const submit = async e => {
		setLoading(true);
		e.preventDefault();
		const user = {
			user_id,
			f_name: fName,
			l_name: LName,
			email: email,
			mobile_no: phone,

			is_active: status
		};
		const response = await saveAffiliateUser(user);
		if (response?.status?.code === 1) {
			toast.success(message.ADD_SUCCESS);
			toggleModal(false);
			reloadTable();
		} else {
			let error = response.messages.error;
			setErrors(error);
			toast.error(error?.form?.[0] || error?.others?.[0] || message.ERROR);
		}
		setLoading(false);
	};

	const updateUserParams = async () => {
		const response = await getAffiliateUser(user_id);
		if (response?.status?.code === 1) {
			const userData = response?.data?.userData || {};
			setFName(userData.f_name);
			setLName(userData.l_name);
			setEmail(userData.email);
			setPhone(userData.mobile_no);
			setStatus(userData.is_active);
		}
	};

	React.useEffect(() => {
		if (user_id) updateUserParams();
	}, []);

	return (
		<Modal state={state} style={style} closeModal={() => toggleModal(false)}>
			<div className='modal-content gray-bg'>
				{/* Modal body */}
				<div className='modal-body'>
					<h5 className=' text-orange font-r'>Add User</h5>
					<form>
						<div className='form-group'>
							<label>First Name</label>
							<input
								value={fName}
								onChange={e => setFName(e.target.value)}
								type='text'
								className='form-control'
								placeholder={'First Name'}
							/>
							{errors && (
								<p className='mt-2 mb-0 text-danger'>
									{errors?.fields?.f_name?.[0]}
								</p>
							)}
						</div>
						<div className='form-group'>
							<label>Last Name</label>
							<input
								value={LName}
								onChange={e => setLName(e.target.value)}
								type='text'
								className='form-control'
								placeholder={'Last Name'}
							/>
							{errors && (
								<p className='mt-2 mb-0 text-danger'>
									{errors?.fields?.l_name?.[0]}
								</p>
							)}
						</div>
						<div className='form-group'>
							<label>Email</label>
							<input
								value={email}
								disabled={user_id}
								onChange={e => setEmail(e.target.value)}
								type='email'
								className='form-control'
								placeholder='Name@company.com'
							/>
							{errors && (
								<p className='mt-2 mb-0 text-danger'>
									{errors?.fields?.email?.[0]}
								</p>
							)}
						</div>
						<div className='form-group'>
							<label>Phone Number</label>
							<input
								value={phone}
								disabled={user_id}
								onChange={e => setPhone(e.target.value)}
								type='number'
								className='form-control'
								placeholder='Phone Number'
							/>
							{errors && (
								<p className='mt-2 mb-0 text-danger'>
									{errors?.fields?.mobile_no?.[0]}
								</p>
							)}
						</div>
						<div className='form-group'>
							<label>Status</label>
							<select
								value={status}
								onChange={e => setStatus(e.target.value)}
								className='form-control'
							>
								<option value={null}>Select Status</option>
								<option value={0}>In Active</option>
								<option value={1}>Active</option>
							</select>
						</div>
						<button
							disabled={loading}
							onClick={submit}
							type='submit'
							className='btn btn-custom mr-2'
						>
							{loading ? <div className='loader' /> : 'Submit'}
						</button>
						<button
							onClick={() => toggleModal(false)}
							type='button'
							className='btn btn-close'
							data-dismiss='modal'
						>
							Cancel
						</button>
					</form>
				</div>
			</div>
		</Modal>
	);
};

export default AddAffiliateUser;

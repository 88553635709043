import React from 'react';
import { objectsForEach, isObjectArray } from '../../common/objectToArray';
import SectionItem from './SectionItem';
import splitUnderscoreToCaptialise from '../../common/splitUnderscoreToCaptialise';
const SectionContent = ({ params }) => {
	if (isObjectArray(params?.value)) {
		return (
			<>
				{(params?.value || []).map(({ id, ...rest }) => (
					<SectionContent key={id} title={'Abc'} params={rest} />
				))}
			</>
		);
	}

	return (
		<div className='row mt-3'>
			{objectsForEach(params || [], 'STRING_VAL').map(({ id, ...rest }) => (
				<SectionItem
					key={id}
					label={splitUnderscoreToCaptialise(id)}
					{...rest}
				/>
			))}
		</div>
	);
};

export default SectionContent;

import VideoKYC from '../pages/Documentation/VideoKYC';
import VideoPD from '../pages/Documentation/VideoPD';
import Bureau from '../pages/Documentation/BureauReport';
import FI from '../pages/Documentation/FI';

export const pages = [
	// {
	// 	component: VideoKYC,
	// 	path: '/videokyc/:id',
	// 	pathName: '/onboarding/documentation/videokyc',
	// 	title: 'Video KYC',
	// 	private: true,
	// 	showPage: true
	// },
	// {
	// 	component: VideoPD,
	// 	path: '/videopd/:id',
	// 	pathName: '/onboarding/documentation/videopd',
	// 	title: 'Video PD',
	// 	private: true,
	// 	showPage: true
	// },
	{
		component: Bureau,
		path: '/bureau/:id',
		pathName: '/onboarding/documentation/bureau',
		title: 'Bureau',
		private: true,
		showPage: true
	},
	{
		component: FI,
		path: '/fi/:id',
		pathName: '/onboarding/documentation/fi',
		title: 'FI',
		private: true,
		showPage: true
	}
];

export const getSteps = () => {
	return pages.map(page => ({ ...page, path: page.path.substring(1) }));
};

import React from 'react';
import config from '../../services/config';
import VolumeLinkedItem from './VolumeLinkedItem';
const VolumeLinked = ({ body, setBody, error }) => {
	const [volLinkedSwitch, toggleVolLinkedSwitch] = React.useState(false);
	const [products, setProducts] = React.useState([]);
	const getProductList = async () => {
		const response = await config.getProducts();
		if (response?.status?.code === 1) {
			setProducts(response?.data?.resource || []);
		}
	};

	React.useEffect(() => {
		if (body?.vli?.length) {
			toggleVolLinkedSwitch(true);
		}
		getProductList();
	}, []);

	const onSwitchToggle = e => {
		if (e.target.checked) {
			body.vli = [];
			setBody(body);
		} else {
			body.vli = undefined;
		}
		toggleVolLinkedSwitch(e.target.checked);
	};
	return (
		<div className='mx-3 mt-3'>
			<div className='row'>
				<div className='col-md-2'>
					<h6 className='mt-2'>Volume Linked</h6>
				</div>
				<div className='col-md-3'>
					<label className='switch'>
						<input
							checked={volLinkedSwitch}
							value={volLinkedSwitch}
							onChange={onSwitchToggle}
							className='switch-input'
							type='checkbox'
						/>
						<span className='switch-label' data-on='On' data-off='Off' />{' '}
						<span className='switch-handle' />{' '}
					</label>
				</div>
			</div>
			{volLinkedSwitch && (
				<>
					{' '}
					{(body?.vli || []).map((value, index) => (
						<VolumeLinkedItem
							error={error}
							key={index + Boolean(index > 1) && Math.random()}
							{...value}
							index={index}
							setBody={setBody}
							products={products}
							body={body}
							showAddNew={body?.vli?.length - 1 === index}
						/>
					))}
					{Boolean(!body?.vli?.length) && (
						<VolumeLinkedItem
							showAddNew
							index={body?.vli?.length || 0}
							setBody={setBody}
							products={products}
							body={body}
						/>
					)}
				</>
			)}
		</div>
	);
};

export default VolumeLinked;

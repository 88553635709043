import React from 'react';
import Modal from '../Modal';
import message from '../../constant/message';
import { toast } from 'react-toastify';
import { addLeadOffer } from '../../services/manage-leads';
import config from '../../services/config';
const AddOffer = ({ state, toggleModal, partId, reloadTable }) => {
	const [file, setFile] = React.useState(null);
	const [loading, setLoading] = React.useState(false);
	const style = { width: '360px' };
	const uploadFile = async e => {
		e.preventDefault();
		if (!file) {
			return toast.error(message.SELECT_FILE);
		}
		setLoading(true);
		const response = await addLeadOffer(file, partId);
		if (response?.status?.code === 1) {
			let success = response?.messages?.success;
			toast.success(
				success?.form?.[0] || success?.others?.[0] || message.UPLOAD_SUCCESS
			);
			reloadTable(partId);
			toggleModal(false);
			setFile(null);
		} else {
			let error = response?.messages?.error;
			toast.error(error?.form?.[0] || error?.others?.[0] || message.ERROR_FORM);
		}
		setLoading(false);
	};
	return (
		<Modal state={state} style={style} closeModal={() => toggleModal(false)}>
			<div className='modal-content gray-bg'>
				{/* Modal body */}
				<div className='modal-body'>
					<h5 className=' text-orange font-r'>Add Offer</h5>
					<form>
						<div className='panel mb-3 '>
							<div className='head_top border-0 d-flex align-items-center mt-3'>
								<h4 className='font-r'>Select File </h4>
								<a
									href={`${config.host}/files/lead_offer.xlsx`}
									className='ml-4'
									download
								>
									{' '}
									(Download Template)
								</a>
							</div>
							<div className='p-3'>
								<div className='row'>
									<div className='col-md-8 pr-0'>
										<input
											type='text'
											className='form-control value-text'
											placeholder='Search File'
										/>
										<div> {file?.name}</div>
										<small className='text-gray'>
											[max file size 10 mb/ only .xls file allowed]
										</small>
									</div>
									<div className='col-md-4 d-flex '>
										<div className='custom-file-uploader mt-1'>
											<input
												file={file}
												onChange={e => setFile(e.target.files[0])}
												type='file'
												accept='application/vnd.ms-excel,.csv,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
												className='custom-file-input'
											/>

											<button type='button' className='btn btn-custom '>
												Browse
											</button>
										</div>
									</div>
								</div>
							</div>
							<br />
							<br />
							<br />
						</div>
						<button
							disabled={loading}
							onClick={uploadFile}
							type='button'
							className='btn btn-custom'
						>
							{loading ? <div className='loader' /> : 'Submit'}
						</button>
						<button
							onClick={e => {
								e.preventDefault();
								toggleModal(false);
							}}
							type='button'
							className='btn btn-close ml-2'
						>
							Cancel
						</button>
					</form>
				</div>
			</div>
		</Modal>
	);
};

export default AddOffer;

import React from 'react';
import Container from './Container';
import { Routes, Route, Navigate } from 'react-router-dom';
import PrivateRoute from '../router/private-route';
import getRoutes from '../router/routes';
import { Context } from '../config/store';
import Spinner from './Spinner';
import { refreshToken, getLoggedInUser } from '../services/auth';
import { setCookie } from '../services/cookie';
import { getLeadTools } from '../services/manage-leads';
import config from '../services/config';
import Routing from '../router/Routing';
import userTypeCodeMap from '../config/userTypeCode';
import { useRouter } from '../hooks/useRouter';
const ContainerRouter = ({ type }) => {
	const [validate, checkUser] = React.useState();
	const [loading, setStatus] = React.useState(true);
	const router = useRouter();
	const loadGlobalConfig = async () => {
		const response = await config.getConfigData(state.userType);
		if (response?.status?.code === 1) {
			dispatch({
				type: 'config',
				config: response?.data || {},
			});
		}
	};

	const { dispatch, state } = React.useContext(Context);
	React.useEffect(() => {
		// if (state.userType === 'dsa') return;
		init();
	}, []);
	const getUser = async (userTypeFromRefresh) => {
		const response = await getLoggedInUser(
			userTypeFromRefresh ? userTypeCodeMap[userTypeFromRefresh - 1] : type
		);
		let userType;
		if (response.data.resource) {
			userType = userTypeCodeMap[response.data.resource.user_type - 1];
		}
		let permission = userType === 'lender' ? ['lender-portal'] : [];
		if (response?.status?.code === 1) {
			dispatch({
				type: 'user',
				user: response?.data?.resource || {},
			});
			dispatch({
				type: 'permission',
				permission:
					[...permission, ...(response?.data && response?.data?.permission)] ||
					[],
			});
			dispatch({
				type: 'is_super_admin',
				is_super_admin: response?.data?.is_super_admin || '',
			});
			dispatch({
				type: 'userTypeCode',
				userTypeCode: response.data.resource.user_type,
			});
			dispatch({
				type: 'userType',
				userType: userTypeCodeMap[response.data.resource.user_type - 1],
			});
			const isPwdChanged =
				state.userType === 'admin' && !response?.data?.resource?.is_pwd_changed;
			router.navigate(
				!isPwdChanged
					? state.userType === 'admin'
						? (response.data.permission.indexOf('view-dashboard') !== -1 ||
								response.data.permission.indexOf('view-colender-dashboard') !==
									-1) &&
						  router.pathname === '/'
							? '/portfolio/dashboard'
							: router.pathname
						: (response?.data?.resource?.part_type === 1 ||
								response?.data?.resource?.part_type === 3) &&
						  router.pathname === '/'
						? '/manage-leads'
						: router.pathname
					: router.pathname === '/'
					? '/password/change'
					: router.pathname
			);
		}
	};
	const leadTools = async () => {
		const response = await getLeadTools(state.userType);
		dispatch({
			type: 'leadTools',
			leadTools: response?.data?.resource,
		});
	};
	const init = async () => {
		const userTypeFromRefresh = await validateToken();
		await getUser(userTypeFromRefresh);

		// await loadGlobalConfig();
		// await leadTools();

		setStatus(false);
	};
	const checkHasPermission = (route) => {
		if (!route.permission) return true;
		if (state.is_super_admin === 1) return route;
		const permission = state.permission.find(
			(permission) => (route.permission || '').split(',').includes(permission)
		);
		if (permission) return route;
		else return false;
	};
	const validateToken = async () => {
		const response = await (
			await refreshToken({
				type: type,
			})
		).json();

		if (response?.status?.code === 1 && response.data?.auth?.token) {
			setCookie(btoa('token'), response.data?.auth?.token);
			checkUser(true);
			return response.data.resource.user_type;
		} else {
			checkUser(false);
		}
	};
	if (!loading && !validate)
		return (
			<Navigate
				replace
				to={`/${state.dsaUser ? 'dsa' : state.userType}/login?redirectUrl=${
					router.pathname.indexOf('login') === -1 && router.pathname
				}`}
			/>
		);
	return (
		<Container>
			{loading && (
				<div className='d-flex h-100 align-items-center justify-content-center flex-1'>
					<Spinner />
				</div>
			)}

			{!loading && validate && (
				<Routing
					routes={getRoutes(state.userType).filter(
						(route) => route.private && checkHasPermission(route)
					)}
				/>
			)}
		</Container>
	);
};

export default ContainerRouter;

import React from 'react';
import { Link, useParams } from 'react-router-dom';
const Steps = ({ active, partId, showPastGreen, pathName, pages }) => {
	return (
		<div className='d-flex flex-row justify-content-between mb-4 px-4'>
			{(pages ? pages : steps).map(({ title, path, disabled }, index) => (
				<Link
					to={disabled ? '#' : `${path}${partId ? `/${partId}` : ''}`}
					key={index}
					className={`btn btn-close ${
						active !== index
							? showPastGreen && index < active
								? 'border-light-green'
								: 'border-light-gray'
							: 'border-light-orange'
					}`}
				>
					<h6
						className={`${
							active !== index
								? showPastGreen && index < active
									? 'text-light-green'
									: 'text-light-gray'
								: 'text-light-orange'
						} font-weight-bold mb-0`}
					>
						{title}
					</h6>
				</Link>
			))}
		</div>
	);
};

export default Steps;

const steps = [
	{
		title: 'Affiliate KYC Details',
		path: '/admin/affiliate-kyc/details/partner',
		permission: 'affiliate-detail-kyc'
	},
	{
		title: 'Configuration Settings',
		path: '/admin/configure-affiliate'
	},
	{
		title: 'Commission Settings',
		path: '/manage-affiliate/cofigure-comission'
	}
];

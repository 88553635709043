import React from 'react';

const VolumeLinkedIncentiveItem = ({
	product_name,
	tot_vol,
	tot_amt,
	vli_percent,
	loan_type_name,
	base_payout,
	amt_type
}) => {
	return (
		<tr>
			<td>
				{product_name} {loan_type_name}
			</td>
			<td>{parseFloat(tot_vol).toLocaleString('en-IN')}</td>
			<td>
				{base_payout} {amt_type == 'fixed' ? 'INR' : '%'}
			</td>
			{/* <td>{vli_percent}</td> */}
			<td width='25%'>{parseFloat(tot_amt).toLocaleString('en-IN')} INR</td>
		</tr>
	);
};

export default VolumeLinkedIncentiveItem;

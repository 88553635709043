import React from 'react';
import { useParams } from 'react-router';
import { Context } from '../../../../config/store';
import DatePicker from 'react-datepicker';
import Button from '../../../../components/Common/Button';
import LenderUgroService from '../../../../services/lender';
import { getFormattedDate } from '../../../../common/date';
import TableNoRecords from '../../../../components/Common/Table/TableNoRecords';
import Table from '../../../../components/Common/Table';
import PostDemand from './PostDemand';

export default function LenderDemand() {
	const { state } = React.useContext(Context);
	const leadId = useParams().id;

	const columns = [
		{ title: 'Demand Schedule Id', field: 'demand_schedule_id' },
		{ title: 'Amount', field: 'amount' },
		{ title: 'Demand Date', field: 'demand_date' }
	];

	const [dueDate, setDueDate] = React.useState(new Date());
	const [demandData, setDemandData] = React.useState([]);
	const [loading, setLoading] = React.useState(false);
	const [error, setError] = React.useState(false);
	const [errorMessage, setErrorMessage] = React.useState('');

	const getDemandData = async () => {
		setLoading(true);
		setError(false);
		setErrorMessage('');
		const body = {
			due_date: getFormattedDate(dueDate, 'dd/MM/yyyy')
		};
		try {
			const response = await LenderUgroService.getLeadDemand(
				state.userType,
				'co-lender',
				leadId,
				body
			);
			if (response?.data?.status?.code === 1) {
				const data = response.data.data;
				setDemandData(data);
			} else {
				setError(true);
				setErrorMessage(response?.data?.messages?.error?.form[0] || response?.data?.messages?.error?.others[0] || 'Failed to get demand data');
			}
			setLoading(false);
		} catch (err) {
			setError(true);
			setErrorMessage(
				err.response?.data?.messages?.error?.form[0] ||
					err.response?.data?.messages?.error?.others[0] ||
					'Failed to get demand data'
			);
		}
	};

	return (
		<>
			<div className='row mt-4'>
				<div className='col-12'>
					<form>
						<div className='row align-items-center'>
							<div className='col-md-4'>
								<div className='form-group'>
									<label>Due Date</label>
									<DatePicker
										dateFormat='dd/MM/yyyy'
										selected={dueDate}
										portalId="datepickerportal"
										onChange={selectedDate => setDueDate(selectedDate)}
										className='form-control'
									/>
								</div>
							</div>
							<div className='col-md-4'>
								<Button type='btn-custom' clickHandler={getDemandData}>
									Get Demand
								</Button>
							</div>
						</div>
					</form>
				</div>
			</div>
			{(demandData || loading || error) && (
				<Table
					checkbox={false}
					columns={columns}
					rows={demandData}
					loading={loading}
					type=''
					tableExtraClass={''}
					reloadTable={getDemandData}
				>
					{Boolean(!demandData.length && !loading) && <TableNoRecords />}
				</Table>
			)}
			<PostDemand getDemandData={getDemandData} dueDate={dueDate}/>
		</>
	);
}

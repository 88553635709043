import React from 'react'
import './Dashboard.css'
const TotalCount = ({ total }) => {
    return (
        // <>
        //     <div className='total_card'>
        //         <div className='total_card_child'>
        //             <span className='card_total_value'> {total?.lead_assigned}</span>
        //             <span>Case Assigned</span>
        //         </div>
        //         <div className='total_card_child'>
        //             <span className='card_total_value'>{total?.amount_assigned}Cr</span>
        //             <span>Amount Assigned</span>
        //         </div>
        //         <div className='total_card_child'>
        //             <span className='card_total_value'>{total?.lender_pending}</span>
        //             <span>Senction Pending</span>
        //         </div>
        //         <div className='total_card_child'>
        //             <span className='card_total_value'>{total?.sanction_rejected}</span>
        //             <span>Sanction Rejected</span>
        //         </div>
        //         <div className='total_card_child'>
        //             <span className='card_total_value'>{total?.disbursal_pending}</span>
        //             <span>Disbursal Pending</span>
        //         </div>
        //         <div className='total_card_child'>
        //             <span className='card_total_value'>{total?.disbursal_rejected}</span>
        //             <span>Disbursal Rejected</span>
        //         </div>
        //         <div className='total_card_child'>
        //             <span className='card_total_value'>{total?.disbursal_portfolio}</span>
        //             <span>Disbursed Portfolio</span>
        //         </div>
        //     </div>

        // </>
        <>
        { <div className='total_card'>
            <div className='total_card_child'>
                <span className='card_total_value'> {total?.lead_assigned}</span>
                <span>Case Assigned</span>
            </div>
            <div className='total_card_child'>
                <span className='card_total_value'>{total?.lender_pending}</span>
                <span>Pending</span>
            </div>
            <div className='total_card_child'>
                <span className='card_total_value'>{total?.sanction_rejected}</span>
                <span>Rejected</span>
            </div>
            <div className='total_card_child'>
                <span className='card_total_value'>{isNaN(total?.pre_sanction_approved + total?.sanction_approved)? 0 :total?.pre_sanction_approved + total?.sanction_approved}</span>
                <span>Sanctioned</span>
            </div>
            <div className='total_card_child'>
                <span className='card_total_value'>{total?.disbursal_pending}</span>
                <span>Disbursal Pending</span>
            </div>
            <div className='total_card_child'>
                <span className='card_total_value'>{total?.disbursal_rejected}</span>
                <span>Disbursal Rejected</span>
            </div>
            <div className='total_card_child'>
                <span className='card_total_value'>{isNaN(total?.disbursal_portfolio + total?.disbursal_lender_decision)? 0 : total?.disbursal_portfolio + total?.disbursal_lender_decision}</span>
                <span>Disbursed</span>
            </div>
        </div>}
    </>
    )
}

export default TotalCount
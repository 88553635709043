import { getCookie } from './cookie';
import config from './config';

export const roleListing = async ({ type = 'admin', page, queryParams }) => {
	const token = getCookie(btoa('token'));
	return await (
		await fetch(
			`${config.baseUrl[type]}/get-role-list?page=${page}&${queryParams}`,
			{
				headers: {
					Authorization: `Bearer ${token}`,
					...config.defaultHeaders,
				},
			}
		)
	).json();
};

/**
 *
 * @param {String} role_id
 */
export const getRolePermission = async ({ type = 'admin', role_id }) => {
	const token = getCookie(btoa('token'));
	return await (
		await fetch(
			`${config.baseUrl[type]}/get-role-permissions?role_id=${role_id}`,
			{
				headers: {
					Authorization: `Bearer ${token}`,
					...config.defaultHeaders,
				},
			}
		)
	).json();
};

/**
 *
 * @param {*} body
 */
export const saveRole = async (body, type = 'admin') => {
	const token = getCookie(btoa('token'));
	return await (
		await fetch(`${config.baseUrl[type]}/save-role-permission`, {
			method: 'POST',
			body: JSON.stringify(body),
			headers: {
				Authorization: `Bearer ${token}`,
				...config.defaultHeaders,
			},
		})
	).json();
};

import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { getSteps, pages } from '../router/admin-onboarding-routes';
import Routing from '../router/Routing';
import { useRouter } from '../hooks/useRouter';
import { Context } from '../config/store';
import { getOnboardingStatus } from '../services/onboarding-form-service';
const ApplicationDetails = () => {
	const { state } = React.useContext(Context);
	const [applicationStatus, setApplicationStatus] = React.useState('');
	const [loading, setLoading] = React.useState(true);
	const params = useParams();
	const route = useRouter();
	const currentRouteIndex = (currentIndex) => {
		switch (currentIndex) {
			case 0:
				return route.pathname.indexOf('details') !== -1;
			case 1:
				return route.pathname.indexOf('queries') !== -1;
			case 2:
				return route.pathname.indexOf('fi-fcu') !== -1;
		}
	};
	const userOnboardingStatus = async () => {
		try {
			const response = await getOnboardingStatus({ type: state.userType });
			if (response?.data?.status?.code === 1) {
				setApplicationStatus(response?.data?.data?.onboarding_status);
			}
			setLoading(false);
		} catch {}
	};
	React.useEffect(() => {
		userOnboardingStatus();
	}, []);
	return (
		<div>
			<div className='row pr-4 mr-1 justify-content-between align-items-center'>
				<ul className='nav nav-tabs mt-3 custom-tabs pb-4'>
					{route.pathname.indexOf('documentation') == -1 &&
						getSteps(state.userType).map(({ title, pathName }, index) => {
							let routeArray = (String(params['*']) || '').split('/');
							let partId = routeArray?.[routeArray.length - 1];
							return (
								<li className='nav-item' key={title}>
									<Link
										to={`${pathName}${partId && `/${partId}`}`}
										className={`nav-link ${
											currentRouteIndex(index) && 'active'
										}`}
									>
										{title}
									</Link>
								</li>
							);
						})}
				</ul>

				{state.userType === 'affiliate' && (
					<button class='btn btn-success ml-2'>
						{loading ? (
							<div className='loader' />
						) : (
							'Status -' + applicationStatus
						)}
					</button>
				)}
			</div>
			<Routing routes={pages} />
		</div>
	);
};

export default ApplicationDetails;

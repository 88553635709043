import Button from '../../../components/Common/Button';
import GenerateCode from './GenerateCode';

function RequiredUserActions(props) {
	const editUser = () => {
		props.otherProps.toggleModal(props.index);
	};
	return (
		<div className='row no-gutters'>
			{props.otherProps.showAddEdit() && !props?.row?.[props.field] && (
				<Button type='btn-custom mr-2' clickHandler={editUser}>
					Edit
				</Button>
			)}
			{!props?.row?.[props.field] && <GenerateCode {...props} />}
		</div>
	);
}

export default RequiredUserActions;

import LafId from '../../Views/Portfolio/TableItems/LafId';
import Actions from '../../Views/Portfolio/TableItems/Actions';
import LenderName from '../../Views/Portfolio/TableItems/LenderName';
import LeadType from '../../Views/Portfolio/TableItems/LeadType';
import LeadStatus from '../../Views/Portfolio/TableItems/LeadStatus';
import DisbursedDate from '../../Views/Portfolio/TableItems/DisbursedDate';

export default [
	{ title: 'LAF ID', field: 'laf_id', component: LafId },
	{ title: 'LAN ID', field: 'lan_id', component: LafId },
	{
		title: 'CLM Type',
		field: 'lead_assign_as_type',
		component: LeadType,
	},
	{ title: 'Customer Name', field: 'cust_name' },
	{ title: 'Program Name', field: 'prod_name' },
	{ title: 'Loan Amount', field: 'loan_amount', sort: true },
	{
		title: 'Disbursed Date',
		field: 'disbursed_date',
		component: DisbursedDate,
		sort: true,
	},

	{ title: 'Lender Name', field: 'lender_name', component: LenderName },
	// { title: 'No. of EMI Paid', field: 'no_of_EMI_paid' },

	{ title: 'Status', field: 'status.admin_status', component: LeadStatus },
	{ title: 'Action', field: 'action', component: Actions },
];

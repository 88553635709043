import React from 'react';
import { sharedLeads } from '../../services/dashboard';
import { generateArrayOfYears } from '../../common/date';
import { objectsForEach } from '../../common/objectToArray';
import objectToQueryParams from '../../common/objectToQueryParams';
import { sharedLeadPerformance } from '../../constant/options';
import ReactApexChart from 'react-apexcharts';
const LeadSharedPerformance = ({
	type,
	businessType,
	userTypes,
	loanType,
	productType,
	campaignCode,
	leadType,
	sourceId,
	stateId,
	cityId
}) => {
	const [year, setYear] = React.useState(new Date().getFullYear());
	const [leadSharedData, setLeadShared] = React.useState(null);
	const [options, setOptions] = React.useState(sharedLeadPerformance);
	const [data, setData] = React.useState([]);
	React.useEffect(() => {
		loadLeadSharedPerformance();
	}, [
		year,
		type,
		businessType,
		userTypes,
		loanType,
		productType,
		campaignCode,
		leadType,
		sourceId,
		cityId,
		stateId
	]);
	const loadLeadSharedPerformance = async () => {
		const response = await sharedLeads({
			type,
			queryParams: objectToQueryParams({
				year,
				business_type: (businessType || []).map(
					business_type => business_type.value
				),
				user_ids: (userTypes || []).map(user_id => user_id.value),
				loan_type: (loanType || []).map(loan_type => loan_type.value),
				product_type: (productType || []).map(
					product_type => product_type.value
				),
				campaign_code: (campaignCode || []).map(camp_code => camp_code.value),
				lead_type: leadType?.value || '',
				source_id: sourceId?.value || '',
				state_id: stateId?.value || '',
				city_id: cityId?.value || ''
			})
		});
		if (response?.status?.code === 1) {
			let dataTemp = response?.data?.shared_leads;
			setLeadShared(dataTemp);
			let dataTrail = (dataTemp?.monthly_leads || []).map(
				(month, index) => month[index + 1]
			);
			let dataItem = [
				{
					name: type === 'dsa' ? 'Applications' : 'Leads',
					data: dataTrail
				}
			];

			setData(dataItem);
		}
	};
	return (
		<div className='panel'>
			<div className='head_top'>
				<h5>Shared {type === 'dsa' ? 'Application' : 'Lead'} Performance</h5>
			</div>
			<div className='p-3'>
				<div className='row'>
					<div className='col-md-12'>
						<select
							value={year}
							onChange={e => setYear(e.target.value)}
							className='form-control custom-textfield height float-right mb-2'
						>
							{generateArrayOfYears(5).map((year, i) => (
								<option value={year} key={i}>
									{year}
								</option>
							))}
						</select>
					</div>
					<div className='col-md-12'>
						<ReactApexChart
							options={options}
							series={data}
							type='area'
							height={120}
						/>
					</div>
				</div>
				<div className='row'>
					<div className='col-md-6'>
						<p>
							<b>{type === 'dsa' ? 'Applications' : 'Leads'} Shared</b>
							<br />
							{parseFloat(leadSharedData?.lead_shared || 0).toLocaleString(
								'en-IN'
							)}{' '}
							{type === 'dsa' ? 'Applications' : 'Leads'}
						</p>
						<p className='mb-0'>
							<b> {type === 'dsa' ? 'Applications' : 'Leads'} Approved</b>
							<br />
							{parseFloat(leadSharedData?.lead_approved || 0).toLocaleString(
								'en-IN'
							)}{' '}
							{type === 'dsa' ? 'Applications' : 'Leads'}
						</p>
					</div>
					<div className='col-md-6 d-flex align-items-center justify-content-end'>
						<div className='progress blue'>
							{' '}
							<span className='progress-left'>
								{' '}
								<span className='progress-bar' />{' '}
							</span>{' '}
							<span className='progress-right'>
								{' '}
								<span className='progress-bar' />{' '}
							</span>
							<div className='progress-value'>
								<b>{leadSharedData?.lead_approved_percent}%</b>
								<br />
								<span>Approved</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default LeadSharedPerformance;

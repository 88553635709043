import React from 'react';
import { useLocation, Link } from 'react-router-dom';
import { Context } from '../../config/store';
const SidebarItem = ({ path, title, id, sidebarIcon, subChilds, showIconOnly }) => {
	const location = useLocation();
	const { dispatch, state } = React.useContext(Context);
	const checkHasPermission = (sidebarMenu) => {
		if (state.is_super_admin === 1) return sidebarMenu;
		const permission = state.permission.find(
			(permission) => permission === sidebarMenu.permission
		);

		if (permission) return sidebarMenu;
		else return false;
	};
	const [showChilds, setShowChilds] = React.useState(false);
	const [parentId, setParentId] = React.useState('');

	let localdata = localStorage.getItem('parent');
	React.useEffect(() => {
		if (localdata) {
			setParentId(localdata)
		}
	}, [localdata]);
	return (
		<li className='nav-item' key={path} onClick={()=>sessionStorage.removeItem('searchData')}>
			<Link
				className={`nav-link collapsed ${!subChilds?.length &&
					(path === location.pathname ||
						(state.selectedColender && state.selectedColender === path)) && !showIconOnly &&
					'active'
					}`}
				to={!subChilds?.length && path}
				onClick={() =>{
					if (path.indexOf('portfolio') < 0) {
						localStorage.setItem('selectedColender', null);
						dispatch({
							type: 'selectedColender',
							selectedColender: '',
						});
					}
					subChilds?.length && setShowChilds(!showChilds);
				}}
			// aria-expanded='false'
			>
				{' '}
				{!showIconOnly ?
					<>
						{sidebarIcon} <span className='item-name'>{title}</span>
					</>
					:
					<>
						<span>{sidebarIcon}</span>
					</>
				}
				{subChilds?.length && !showIconOnly && (
					<i
						className={`fa fa-angle-${showChilds ? 'up' : 'down'} float-right`}
						aria-hidden='true'
					/>
				)}
			</Link>


			{subChilds && (
				<ul
					id={id}
					className={`submenu collapse ${showChilds && !showIconOnly && 'show'}`}
					data-bs-parent='#nav_accordion'
				>
					{subChilds
						.filter((sidebarMenu) =>
							sidebarMenu.permission
								? checkHasPermission(sidebarMenu)
								: sidebarMenu
						)
						.map(({ title, path, icon, parent }) => (
							<li key={path}>
								<Link
									className={
										(path === location.pathname && parent === parentId) ||
											(state.selectedColender && state.selectedColender === path && parent === parentId)
											? 'active'
											: ''
									}
									tabIndex={-1}
									to={path}
									onClick={() => {
										localStorage.setItem('parent', parent)
										if (path.indexOf('portfolio') < 0) {
											localStorage.setItem('selectedColender', null);
											dispatch({
												type: 'selectedColender',
												selectedColender: '',
											});
										}
										// if (path === location.pathname) {
										// 	window.location.reload();
										// }
									}}
								>
									{' '}
									{icon} {title}
								</Link>
							</li>
						))}
				</ul>
			)}
		</li>

	);
};

export default SidebarItem;

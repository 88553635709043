import lender from './lender';
// import lenderUgro from './lender-ugro.js';
import admin from './admin';
import affiliate from './affiliates';
const routes = {
	lender,
	// lenderUgro,
	admin,
	affiliate,
	dsa: affiliate
};
export default function getRoutes(type) {
	return routes[type];
}

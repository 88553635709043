import { useState, useEffect } from 'react';
import Table from '../../../components/Common/Table';
import TableNoRecords from '../../../components/Common/Table/TableNoRecords';
import Pagination from '../../../components/Pagination';
import PortfolioContainer from '../../../components/Portfolio/PortfolioContainer';
import { useRouter } from '../../../hooks/useRouter';
import LenderUgroService from '../../../services/lender';
import batchHistoryColumns from './batchHistoryColumns';
function BatchHistory() {
	const [tableData, setTableData] = useState({
		columns: batchHistoryColumns,
		rows: [],
	});
	const [loading, setLoading] = useState(false);
	const [pagination, setPagination] = useState({});
	const [page, setCurrentPage] = useState(1);
	const router = useRouter();
	const { batchId } = router.params;
	const getAuitTrailHistory = async () => {
		const response = await LenderUgroService.getLeadAuditTrailByBatch({
			page: page,
			batchId,
		});
		const data = response.data.data;
		setTableData({
			...tableData,
			rows: data?.data || [],
		});
		setLoading(false);
		setCurrentPage(page);
		setPagination(response?.data?.data?.pagination);
	};
	useEffect(() => {
		getAuitTrailHistory();
	}, [page]);
	return (
		<PortfolioContainer>
			<Table
				columns={tableData.columns}
				rows={tableData.rows}
				loading={loading}
			>
				{Boolean(!tableData.rows.length && !loading) && <TableNoRecords />}
			</Table>

			{pagination?.total_pages ? (
				<Pagination
					setPage={setCurrentPage}
					total_pages={pagination.total_pages}
					total={pagination.total}
					showTotal={true}
				/>
			) : (
				''
			)}
		</PortfolioContainer>
	);
}

export default BatchHistory;

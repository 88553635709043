import React from 'react';
import { useParams } from 'react-router';
import LenderUgroService from '../../../../services/lender';
import { Context } from '../../../../config/store';
import Table from '../../../../components/Common/Table';
import { getFormattedDate } from '../../../../common/date';
import TableNoRecords from '../../../../components/Common/Table/TableNoRecords';
import AddRepaymentDocument from './AddRepaymentDocument';

export default function PaymentList() {
	const { state } = React.useContext(Context);
	const leadId = useParams().id;

	const columns = [
		{ title: 'Installment No.', field: 'InstallmentNumber' },
		{ title: 'Payment Date', field: 'PaymentDate' },
		{ title: 'Amount', field: 'Amount' },
		{ title: 'Principle', field: 'Principle' },
		{ title: 'Interest', field: 'Interest' },
		{ title: 'Outstanding Balance', field: 'OSBalance' },
	];
	const [tableData, setTableData] = React.useState([]);
	const [loading, setLoading] = React.useState(true);
	const [error, setError] = React.useState(false);
	const [errorMessage, setErrorMessage] = React.useState('');
	const [modalState, setModalState] = React.useState(false);
	const getPaymentList = async () => {
		setLoading(true);
		setError(false);
		setErrorMessage('');
		try {
			const response = await LenderUgroService.getPaymentList(leadId);
			const data =
				response.data.data?.RepaySchedules?.Consolidate?.RepaymentData;
			data.forEach((d) => {
				d['PaymentDate'] = getFormattedDate(new Date(d['PaymentDate']));
			});
			setTableData(data);
			setLoading(false);
		} catch (err) {
			setError(true);
			setErrorMessage(
				err.response?.data?.messages?.error?.form[0] ||
					err.response?.data?.messages?.error?.others[0] ||
					'Failed to load payment'
			);
		}
	};
	const toggleModal = () => {
		setModalState(!modalState);
	};
	React.useLayoutEffect(() => {
		getPaymentList();
	}, []);

	return (
		<>
			<div className='row no-gutters justify-content-between align-items-center mt-4'>
				<div></div>
				<div className='mb-4'>
					<button className='btn btn-custom' onClick={toggleModal}>
						Add Repayment Document
					</button>
					{modalState && (
						<AddRepaymentDocument
							userType={state.userType}
							state={modalState}
							toggleModal={toggleModal}
							leadId={leadId}
						/>
					)}
				</div>
			</div>
			{!error ? (
				<Table
					checkbox={false}
					columns={columns}
					rows={tableData}
					loading={loading}
					type='paymentData'
					tableExtraClass={''}
					reloadTable={getPaymentList}
				>
					{Boolean(!tableData.length && !loading) && <TableNoRecords />}
				</Table>
			) : (
				<div className='text-center table-error-message mt-4'>
					<p>{errorMessage}</p>
					{/* <Button
					type='btn-custom'
					classNames='small'
					clickHandler={getPaymentList}
				>
					Retry
				</Button> */}
				</div>
			)}

			{/* {totalElements > pageSize && (
				<Pagination
					size={pageSize}
					currentPage={page}
					totalElements={totalElements}
					updatePage={updateTablePage}
				></Pagination>
			)} */}
			{/* queryInfo.cl_lender_id  &&*/}
		</>
	);
}



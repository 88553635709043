import React from 'react';
import { Context } from '../config/store';
import config from '../services/config';
import { useNavigate } from 'react-router';
import './home.css'

const Home = () => {
	const { state, dispatch } = React.useContext(Context);
	const [configData, setConfigData] = React.useState({});
	const [type, setType] = React.useState(
		state?.user?.part_type === 1 ? 'affiliate' : 'dsa'
	);

	React.useEffect(() => {
		dispatch({
			type: 'selectedColender',
			selectedColender: '/portfolio/home',
		});
		localStorage.setItem('selectedColender', '/portfolio/home');
	}, []);

	return (
		<>
			<div className='homePageContainer chart-card'>
				<img src="/home1.png" style={{ height: "81.5vh", width: "100%" }} />
			</div>
		</>
	);
};

export default Home;

import { getCookie } from './cookie';
import config from './config';
import axios from 'axios';

export const affiliateListing = async ({
	page,
	queryParams,
	listType,
	listingType,
	exportAffiliateFlag
}) => {
	const token = getCookie(btoa('token'));
	let url = `${config.baseUrl.admin}/${listType}?page=${page}${
		listingType ? `&part_type=${listingType}` : ''
	}&${queryParams}`;
	if (exportAffiliateFlag) {
		url += `&export=1`;
	}
	const response = await fetch(url, {
		headers: {
			Authorization: `Bearer ${token}`,
			...config.defaultHeaders
		}
	});
	if (exportAffiliateFlag) {
		return await response.blob();
	} else {
		return await response.json();
	}
};

export const affiliatesListing = async () => {
	const token = getCookie(btoa('token'));
	return await (await fetch(`${config.baseUrl.admin}/affiliates`, {
		headers: {
			Authorization: `Bearer ${token}`,
			...config.defaultHeaders
		}
	})).json();
};
export const registerAffiliate = async body => {
	const token = getCookie(btoa('token'));
	return await (await fetch(`${config.baseUrl.admin}/reg_affiliate`, {
		method: 'POST',
		body: JSON.stringify(body),
		headers: {
			Authorization: `Bearer ${token}`,
			...config.defaultHeaders
		}
	})).json();
};

export const changeAffiliateType = async body => {
	const token = getCookie(btoa('token'));
	return await (await fetch(`${config.baseUrl.admin}/change-partner-type`, {
		method: 'POST',
		body: JSON.stringify(body),
		headers: {
			Authorization: `Bearer ${token}`,
			...config.defaultHeaders
		}
	})).json();
};

/**
 *
 * Update Affiliate
 *
 * @param {{ "user_id": String, "part_id": String,"status": String}} body
 */

export const updateAffiliate = async body => {
	const token = getCookie(btoa('token'));
	return await (await fetch(`${config.baseUrl.admin}/update-affiliate-status`, {
		method: 'POST',
		body: JSON.stringify(body),
		headers: {
			Authorization: `Bearer ${token}`,
			...config.defaultHeaders
		}
	})).json();
};

/**
 *
 * @param {{part_id :"String"}} body
 */

export const getAffiliateConfig = async body => {
	const token = getCookie(btoa('token'));
	return await (await fetch(
		`${config.baseUrl.admin}/get-affiliate-lead-config`,
		{
			method: 'POST',
			body: JSON.stringify(body),
			headers: {
				Authorization: `Bearer ${token}`,
				...config.defaultHeaders
			}
		}
	)).json();
};

/**
 * @param { "part_id": "1", "aff_lead_config":{"per_info":{"lead_name":"1","lead_email":"1","lead_mobile":"1","lead_addr":"0"},"loan_info":{"loan_amt":"1","loan_type":"1","ownership_status":"1"},"collat_detail":{"prop_type":"1","prop_status":"1","prop_area":"0","tent_mkt_val":"1"},"biz_info":{"biz_const":"1","comp_pan":"0","gst_no":"0","entity_name":"1","cin":"1","biz_type":"1","industry":"1","sub_industry":"0","biz_owns_type":"1","msme_type":"1","msme_no":"1","do_incorp":"1","latest_biz_turnover":"1","gst_addr":"1","com_addr":"1","wareh_addr":"1","fcty_addr":"0"},"mgmt_info":{"pmt_name":"0","share":"0","dob":"0","gender":"0","pan":"0","designation":"0","other_owns":"1","addr":"0","networth":"0","mobile_no":"1"},"lead_ref":{"name":"1","mobile_no":"1","designation":"1"}}} body
 */

export const setAffiliateConfig = async body => {
	const token = getCookie(btoa('token'));
	return await (await fetch(
		`${config.baseUrl.admin}/save-affiliate-lead-config`,
		{
			method: 'POST',
			body: JSON.stringify(body),
			headers: {
				Authorization: `Bearer ${token}`,
				...config.defaultHeaders
			}
		}
	)).json();
};

export const partnerConfig = async ({ type, partId }) => {
	const token = getCookie(btoa('token'));
	let url = `${config.baseUrl[type]}/lead/config`;
	if (type === 'admin') {
		url += `/${partId}`;
	}
	return await (await fetch(url, {
		headers: {
			Authorization: `Bearer ${token}`,
			...config.defaultHeaders
		}
	})).json();
};

/**
 *
 * @param {{ "affiliate_type": 1,"biz_info": { "entity_name": "", "biz_const": "", "auth_sec_name": "", "phone_number": "", "email": "", "gst_no":"", "comp_pan":"", "auth_sec_pan":"", "com_addr":"" },"bank_info":{ "account_name": "", "account_number": "", "ifsc": "","bank_name": "","bank_branch": "},"part_docs": {"file_id": 1,"file_type":"check","file_name": "274923749873498.jpg"},{"file_id": 1,"file_type":"pan","file_name": "274923749873498.jpg"]}}} body
 */
export const savePartnerKyc = async (body, partId, type) => {
	const token = getCookie(btoa('token'));
	let url = `${config.baseUrl[type]}`;
	url += type === 'admin' ? `/affiliate/detail/kyc` : '/detail/kyc';
	if (partId) {
		url += `/${partId}`;
	}
	return await (await fetch(url, {
		method: 'POST',
		body: JSON.stringify(body),
		headers: {
			Authorization: `Bearer ${token}`,
			...config.defaultHeaders
		}
	})).json();
};

export const uploadPartDoc = async (
	file,
	type = 'pan',
	partId,
	partEntityDocId,
	userType,
	selectedDoc,
	promoterId
) => {
	const token = getCookie(btoa('token'));
	const formData = new FormData();

	formData.append('file', file);
	formData.append('type', type);
	formData.append('part_id', partId);
	formData.append(
		'part_entity_doc_id',
		selectedDoc ? selectedDoc : partEntityDocId
	);
	if (selectedDoc) formData.append('part_promoter_id', promoterId);
	return await (await fetch(
		`${config.baseUrl[userType]}/doc/onboard-doc-upload`,
		{
			method: 'POST',
			headers: {
				Authorization: `Bearer ${token}`,
				Accept: 'application/json'
			},
			body: formData
		}
	)).json();
};

export const getKycDetails = async (type, part_id) => {
	const token = getCookie(btoa('token'));
	let url = `${config.baseUrl[type]}`;
	url += part_id ? `/affiliate/detail/kyc/${part_id}` : '/detail/kyc';
	return await (await fetch(url, {
		headers: {
			Authorization: `Bearer ${token}`,
			...config.defaultHeaders
		}
	})).json();
};

export const getConfigByLeadId = async (type, lead_id) => {
	const token = getCookie(btoa('token'));
	let url = `${config.baseUrl[type]}/lead/config-by-leadid/${lead_id}`;
	return await (await fetch(url, {
		headers: {
			Authorization: `Bearer ${token}`,
			...config.defaultHeaders
		}
	})).json();
};

export const savePartnerKycDetails = async (type, partId, body, infoType) => {
	const token = getCookie(btoa('token'));
	let url = `${config.baseUrl[type]}`;
	if (type === 'admin') url += `/affiliate`;
	url += `/detail/partner-detail`;
	if (type === 'admin') url += `/${partId}`;
	return await axios(url, {
		method: 'POST',
		data: infoType === 2 ? body : JSON.stringify(body),
		headers: {
			Authorization: `Bearer ${token}`,
			'Content-Type':
				infoType === 2 ? 'multipart/form-data' : 'application/json',
			...config.defaultHeaders
		}
	});
};

export const generateCode = async (type, part_contact_id) => {
	const token = getCookie(btoa('token'));
	let url = `${config.baseUrl[type]}/affiliate/detail/partner-user-code-creation`;
	// if (type === 'admin') url += `/${partId}`;

	return await axios(url, {
		method: 'POST',
		data: JSON.stringify({ part_contact_id }),
		headers: {
			Authorization: `Bearer ${token}`,

			'Content-Type': 'application/json',
			...config.defaultHeaders
		}
	});
};

export const getPartnerKycDetails = async (type, partId) => {
	const token = getCookie(btoa('token'));
	let url = `${config.baseUrl[type]}`;
	if (type === 'admin') url += `/affiliate`;
	url += `/detail/partner-detail`;
	if (type === 'admin') url += `/${partId}`;
	return await axios.get(url, {
		method: 'GET',
		headers: {
			Authorization: `Bearer ${token}`,
			...config.defaultHeaders
		}
	});
};

export const downloadKycDoc = async (type, fileId) => {
	const token = getCookie(btoa('token'));
	let url = `${config.baseUrl[type]}/doc/download?file_id=${fileId}`;
	return await axios.get(url, {
		method: 'GET',
		headers: {
			Authorization: `Bearer ${token}`,
			...config.defaultHeaders
		},
		responseType: 'blob'
	});
};

/**
 *
 * @param {{part_id :"String", user_id: "String", status: "String"}} body
 */

export const updateApproveRejectStatusService = async body => {
	const token = getCookie(btoa('token'));
	return await (await fetch(
		`${config.baseUrl.admin}/affiliate/detail/approve-partner`,
		{
			method: 'POST',
			body: JSON.stringify(body),
			headers: {
				Authorization: `Bearer ${token}`,
				...config.defaultHeaders
			}
		}
	)).json();
};

import React from 'react';
import './index.css';
import { Context } from '../../../config/store';
import LenderPieChart from './LenderPie';
import LenderLineChart from './LenderLine';
import LenderBar from './LenderBar';
import TotalAssignedPortfolio from './TotalPortfolio';
import MasterDataService from '../../../services/master-data';
import { objectsForEach } from '../../../common/objectToArray';
import { monthNames } from '../../../common/date';

function PortfolioDashboard() {
	const { state, dispatch } = React.useContext(Context);
	const [productTypes, setProductTypes] = React.useState([]);
	const [lenders, setLenders] = React.useState([]);
	const [months, setMonths] = React.useState([]);

	const productTypeList = async () => {
		const response = await MasterDataService.getProductTypeList(
			state.userType,
			state.userType === 'admin' ? 'co-lender' : ''
		);
		if (response?.data?.status?.code === 1) {
			let responseList = objectsForEach(
				response.data.data.product_types,
				'STRING_VAL'
			);
			let options = responseList.map(response => ({
				label: response.value,
				value: response.id
			}));
			// move parthamSecured to top
			const parthamSecured = options.find(
				item => item.label === 'Partham Secured'
			);
			if (parthamSecured) {
				options = options.filter(item => item.label !== parthamSecured.label);
				options.unshift(parthamSecured);
			}
			setProductTypes(options);
		}
	};

	const lenderList = async () => {
		const response = await MasterDataService.getLenderList(
			state.userType,
			state.userType === 'admin' ? 'co-lender' : ''
		);
		if (response?.data?.status?.code === 1) {
			let responseList = objectsForEach(
				response.data.data.lender_list,
				'STRING_VAL'
			);
			let lenderItems = responseList.map(response => ({
				label: response.value,
				value: response.id
			}));
			setLenders(lenderItems);
		}
	};

	const monthsDrop = () => {
		var current_year = '';
		var next_year = '';
		var today = new Date();
		var current_month = today.getMonth() + 1;
		if (current_month <= 3) {
			current_year = today.getFullYear() - 1;
			next_year = today.getFullYear();
		} else {
			current_year = today.getFullYear();
			next_year = today.getFullYear() + 1;
		}

		let list = [];
		for (let i = 2021; i <= current_year; i++) {
			let currentYearArray = monthNames.map((month, index) => ({
				month: i,
				date: `01/${(index + 1).toString().padStart(2, '0')}/${i}`,
				label: month.substring(0, 3) + ' ' + i,
				year: i
			}));
			if (i === 2021) {
				currentYearArray = currentYearArray.filter(
					(month, index) => current_month <= index + 1
				);
			}
			if (i === current_year) {
				currentYearArray = currentYearArray.filter(
					(month, index) => current_month >= index + 1
				);
			}
			list.push(...currentYearArray);
		}

		setMonths(
			list.reduce((a, b) => {
				a.push({ label: b.label, value: b.date });
				return a;
			}, [])
		);
	};

	React.useEffect(() => {
		monthsDrop();
		productTypeList();
		lenderList();
		dispatch({
			type: 'selectedColender',
			selectedColender: '/portfolio/dashboard'
		});
		localStorage.setItem('selectedColender', '/portfolio/dashboard');
	}, []);
	return (
		<div className='portfolio-dashboard'>
			<div className='row'>
				<div className='col-sm-12 col-md-6 col-lg-4'>
					<div className='assigned-portfolio-info chart-card'>
						<TotalAssignedPortfolio
							productTypes={productTypes}
							lenders={lenders}
						/>
					</div>
				</div>
				<div className='col-sm-12 col-md-6 col-lg-4'>
					<div className='chart-card'>
						{state.userType === 'admin' ? (
							<LenderPieChart productTypes={productTypes} months={months} />
						) : (
							<LenderBar barType='approved' productTypes={productTypes} />
						)}
					</div>
				</div>
				<div className='col-sm-12 col-md-6 col-lg-4'>
					<div className='chart-card'>
						{state.userType === 'admin' ? (
							<LenderLineChart productTypes={productTypes} />
						) : (
							<LenderBar barType='pending' productTypes={productTypes} />
						)}
					</div>
				</div>
			</div>
			{state.userType === 'admin' && (
				<div className='row mt-4'>
					<div className='col-sm-12 col-md-6 col-lg-6'>
						<div className='chart-card'>
							<LenderBar barType='approved' productTypes={productTypes} />
						</div>
					</div>
					<div className='col-sm-12 col-md-6 col-lg-6'>
						<div className='chart-card'>
							<LenderBar barType='pending' productTypes={productTypes} />
						</div>
					</div>
				</div>
			)}
		</div>
	);
}

export default PortfolioDashboard;

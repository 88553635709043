import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import Routing from './Routing';

export default function Router({ basePath, routes }) {
	return (
		<BrowserRouter>
			{/* <MainContainer> */}
			<Routing routes={routes.filter(route => !route.private)} />
			{/* </MainContainer> */}
		</BrowserRouter>
	);
}

import React from 'react';
import objectToQueryParams from '../../common/objectToQueryParams';
import { getManageLeadParams } from '../../services/manage-leads';
import config from '../../services/config';
import Select from 'react-select';
import { Context } from '../../config/store';
import { objectsForEach } from '../../common/objectToArray';
import splitHyphenToCaptialise from '../../common/splitHyphenToCaptialise';
const SearchFilter = ({ userType, setQueryParams }) => {
	const [leadName, setLeadName] = React.useState('');
	const [leadId, setLeadId] = React.useState('');
	const [leadEmail, setLeadEmail] = React.useState('');
	const [leadMobile, setLeadMobile] = React.useState('');
	const [startDate, setStartDate] = React.useState('');
	const [endDate, setEndDate] = React.useState('');
	const [leadStatus, setLeadStatus] = React.useState('');
	const [assignedTo, setAssignedTo] = React.useState('');
	const [partName, setPartName] = React.useState('');
	const [partId, setPartId] = React.useState('');
	const [product, setProduct] = React.useState('');
	const [businessType, setBusinessType] = React.useState('');
	const [sourceType, setSourceType] = React.useState('');
	const [cities, setCities] = React.useState([]);
	const [city, setCity] = React.useState('');
	const [userState, setUserState] = React.useState('');

	const [params, setParams] = React.useState({
		assigned_users: [],
		lead_status: []
	});

	const [campaignCode, setCampaignCode] = React.useState();
	const { state } = React.useContext(Context);
	React.useEffect(() => {
		getProductList();
		getManageLeadsProps();
	}, []);
	const getManageLeadsProps = async () => {
		const response = await getManageLeadParams(userType);
		if (response?.status?.code === 1) {
			setParams(response.data);
		}
	};
	const [products, setProducts] = React.useState([]);
	const getProductList = async () => {
		const response = await config.getProducts();
		if (response?.status?.code === 1) {
			setProducts(response?.data?.resource || []);
			// setBusinessTypes()
		}
	};
	const submitQueryParams = () => {
		const searchObject = {
			lead_email: leadEmail,
			lead_name: leadName,
			lead_id: leadId,
			lead_mobile: leadMobile,
			start_date: startDate,
			end_date: endDate,
			lead_status_id: leadStatus,
			assigned_user_id: assignedTo?.value || '',
			product_id: product,
			business_type: businessType,
			source_id: sourceType,
			state_id: userState,
			city_id: city
		};
		if (userType === 'admin') {
			searchObject['part_id'] = partId;
			searchObject['part_name'] = partName;
		}
		const queryString = objectToQueryParams(searchObject);
		setQueryParams(queryString);
	};
	const onStateChange = async e => {
		setUserState(e.target.value);
		await getCityData();
	};
	const getCityData = async () => {
		const response = await config.getCityByState(userState);
		if (response?.status?.code === 1) {
			setCities(objectsForEach(response.data.cities, 'STRING_VAL'));
		}
	};
	React.useEffect(() => {
		getCityData();
	}, [userState]);
	const resetQueryParams = () => {
		setLeadEmail('');
		setLeadId('');
		setLeadName('');
		setLeadMobile('');
		setStartDate('');
		setEndDate('');
		setLeadStatus('');
		setAssignedTo('');
		setPartName('');
		setPartId('');
		setProduct('');
		setBusinessType('');
		setQueryParams('');
		setSourceType('');
		setCity('');
		setUserState('');
		setCampaignCode('');
	};

	return (
		<div className='tab panel'>
			<input type='checkbox' id='accordion'></input>
			<label for='accordion' className='w-100 head_top tab-label'>
				<h4>Search Filter</h4>
			</label>
			<form className='tab-content'>
				<div className='p-3'>
					<div className='row'>
						<div className='col-md-4'>
							<div className='form-group'>
								<label>Lead Name</label>
								<input
									value={leadName}
									onChange={e => setLeadName(e.target.value)}
									type='text'
									className='form-control'
									placeholder='Lead Name'
								/>
							</div>
						</div>
						<div className='col-md-4'>
							<div className='form-group'>
								<label>Lead ID</label>
								<input
									value={leadId}
									onChange={e => setLeadId(e.target.value)}
									type='text'
									className='form-control'
									placeholder='Lead ID'
								/>
							</div>
						</div>
						<div className='col-md-4'>
							<div className='form-group'>
								<label>Lead Email</label>
								<input
									value={leadEmail}
									onChange={e => setLeadEmail(e.target.value)}
									type='text'
									className='form-control'
									placeholder='Lead Email'
								/>
							</div>
						</div>
						<div className='col-md-4'>
							<div className='form-group'>
								<label>Lead Mobile</label>
								<input
									value={leadMobile}
									onChange={e => setLeadMobile(e.target.value)}
									type='text'
									className='form-control'
									placeholder='Lead Mobile'
								/>
							</div>
						</div>
						{userType === 'admin' && (
							<div className='col-md-4'>
								<div className='form-group'>
									<label>Part Name</label>
									<input
										value={partName}
										onChange={e => setPartName(e.target.value)}
										type='text'
										className='form-control'
										placeholder='Part Name'
									/>
								</div>
							</div>
						)}
						{userType === 'admin' && (
							<div className='col-md-4'>
								<div className='form-group'>
									<label>Part Id</label>
									<input
										value={partId}
										onChange={e => setPartId(e.target.value)}
										type='text'
										className='form-control'
										placeholder='Part Id'
									/>
								</div>
							</div>
						)}
						<div className='col-md-4'>
							<div className='form-group'>
								<label>Start Date</label>
								<input
									value={startDate}
									onChange={e => setStartDate(e.target.value)}
									type='date'
									className='form-control'
								/>
							</div>
						</div>
						<div className='col-md-4'>
							<div className='form-group'>
								<label>End Date</label>
								<input
									value={endDate}
									onChange={e => setEndDate(e.target.value)}
									type='date'
									className='form-control'
								/>
							</div>
						</div>
						<div className='col-md-4'>
							<div className='form-group'>
								<label>Lead Status</label>
								<select
									value={leadStatus}
									onChange={e => setLeadStatus(e.target.value)}
									className='form-control'
								>
									<option value={null}>Lead Status</option>
									{params.lead_status.map(({ name, id }) => (
										<option key={id} value={id}>
											{name}
										</option>
									))}
								</select>
							</div>
						</div>
						<div className='col-md-4'>
							<div className='form-group'>
								<label>Assigned To</label>
								<Select
									value={assignedTo}
									onChange={user => setAssignedTo(user)}
									options={params.assigned_users.map(
										({ f_name, l_name, user_id, emp_code }) => ({
											value: user_id,
											label: `${f_name || ''} ${l_name || ''}
									${(emp_code && ` - ${emp_code || ''}`) || ''}`
										})
									)}
								/>
								{/* <select
									value={assignedTo}
									onChange={e => setAssignedTo(e.target.value)}
									className='form-control'
								>
									<option value={null}>Assigned To</option>
									{params.assigned_users.map(
										({ f_name, l_name, user_id, emp_code }) => (
											<option key={user_id} value={user_id}>
												{f_name} {l_name}
												{emp_code && ` - ${emp_code || ''}`}
											</option>
										)
									)}
								</select> */}
							</div>
						</div>
						<div className='col-md-4'>
							<div className='form-group'>
								<label>Product</label>
								<select
									value={product}
									onChange={e => setProduct(e.target.value)}
									className='form-control'
								>
									<option value={null}>Select Product</option>
									{products.map(({ prod_name, prod_id }) => (
										<option value={prod_id} key={prod_name}>
											{prod_name}
										</option>
									))}
								</select>
							</div>
						</div>
						<div className='col-md-4'>
							<div className='form-group'>
								<label>Business Type</label>
								<select
									value={businessType}
									onChange={e => setBusinessType(e.target.value)}
									className='form-control'
								>
									<option value={null}>Select Business Type</option>
									{state.config?.businesstype.map(({ name, id }) => (
										<option value={id} key={name}>
											{name}
										</option>
									))}
								</select>
							</div>
						</div>
						{state.userType === 'admin' && (
							<div className='col-md-4'>
								<div className='form-group'>
									<label>Source Type</label>
									<select
										value={sourceType}
										onChange={e => setSourceType(e.target.value)}
										className='form-control'
									>
										<option value={''}>Select Source Type</option>
										{(state.config?.sourcetype || []).map(({ name, id }) => (
											<option value={id} key={name}>
												{name}
											</option>
										))}
									</select>
								</div>
							</div>
						)}
						<div className='col-md-4'>
							<div className='form-group'>
								<label>State </label>
								<select
									value={userState}
									onChange={onStateChange}
									className='form-control'
								>
									<option value={''}>State</option>

									{state.config?.states.map(({ name, id }) => (
										<option value={id}>{name}</option>
									))}
								</select>
							</div>
						</div>
						<div className='col-md-4'>
							<div className='form-group'>
								<label>City </label>
								<select
									disabled={!cities.length}
									value={city}
									onChange={e => setCity(e.target.value)}
									className='form-control'
								>
									<option>City</option>
									{(cities || []).map(({ id, value }) => (
										<option key={id} value={value}>
											{id}
										</option>
									))}
								</select>
							</div>
						</div>
						<div className='col-md-4'>
							<div className='form-group'>
								<label>Campaign Code</label>{' '}
								<Select
									className='border-none'
									value={campaignCode}
									options={(state.config?.campaign_code || []).map(c_code => ({
										label: splitHyphenToCaptialise(c_code),
										value: c_code
									}))}
									onChange={camp_code => setCampaignCode(camp_code)}
								/>
							</div>
						</div>
					</div>

					<div className='row'>
						<div className='col-md-12'>
							<button
								onClick={submitQueryParams}
								type='button'
								className='btn btn-custom'
							>
								Search
							</button>
							<button
								onClick={resetQueryParams}
								type='button'
								className='btn btn-custom ml-4'
							>
								Reset All
							</button>
						</div>
					</div>
				</div>
			</form>
		</div>
	);
};

export default SearchFilter;

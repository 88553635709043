import { getCookie } from './cookie';
import config from './config';

/**
 *
 * @param {String} type
 * @param {Object} queryParams
 *
 */
export const sharedLeadsStatus = async ({ type, queryParams }) => {
	const token = getCookie(btoa('token'));
	return await (await fetch(
		`${config.baseUrl[type]}/dashboard/status-shared-leads?${queryParams}`,
		{
			headers: {
				Authorization: `Bearer ${token}`,
				...config.defaultHeaders
			}
		}
	)).json();
};

/**
 *
 * @param {String} type
 * @param {Object} queryParams
 *
 */
export const dailyActivity = async ({ type, queryParams }) => {
	const token = getCookie(btoa('token'));
	return await (await fetch(
		`${config.baseUrl[type]}/dashboard/daily-activities?${queryParams}`,
		{
			headers: {
				Authorization: `Bearer ${token}`,
				...config.defaultHeaders
			}
		}
	)).json();
};

/**
 *
 * @param {String} type
 *
 */

export const assignes = async ({ type, queryParams }) => {
	const token = getCookie(btoa('token'));
	return await (await fetch(
		`${config.baseUrl[type]}/dashboard/assignees?${queryParams}`,
		{
			headers: {
				Authorization: `Bearer ${token}`,
				...config.defaultHeaders
			}
		}
	)).json();
};

/**
 *
 * @param {String} type
 * @param {String} queryParams
 *
 */

export const sharedLeads = async ({ type, queryParams }) => {
	const token = getCookie(btoa('token'));
	return await (await fetch(
		`${config.baseUrl[type]}/dashboard/shared-leads?${queryParams}`,
		{
			headers: {
				Authorization: `Bearer ${token}`,
				...config.defaultHeaders
			}
		}
	)).json();
};

/**
 *
 * @param {String} type
 * @param {Object} queryParams
 *
 */

export const productLeads = async ({ type, queryParams }) => {
	const token = getCookie(btoa('token'));
	return await (await fetch(
		`${config.baseUrl[type]}/dashboard/product-leads?${queryParams}`,
		{
			headers: {
				Authorization: `Bearer ${token}`,
				...config.defaultHeaders
			}
		}
	)).json();
};

export const getConfigUsers = async ({ type }) => {
	const token = getCookie(btoa('token'));
	return await (await fetch(`${config.baseUrl[type]}/dashboard/filters`, {
		headers: {
			Authorization: `Bearer ${token}`,
			...config.defaultHeaders
		}
	})).json();
};

export const getProducts = async ({ type }) => {
	const token = getCookie(btoa('token'));
	return await (await fetch(`${config.baseUrl[type]}/products`, {
		headers: {
			Authorization: `Bearer ${token}`,
			...config.defaultHeaders
		}
	})).json();
};

import RequiredUserDetails from '../pages/OnboardingForm/RequiredUserDetails';
import DocumentsUpload from '../pages/OnboardingForm/DocumentsUpload';
import BankDetails from '../pages/OnboardingForm/BankDetails';
import Details from '../pages/OnboardingForm/Details';
const pages = [
	{
		component: Details,
		path: '/details/partner',
		title: 'Partner Details',
		private: true,
		showPage: true
	},
	{
		component: BankDetails,
		path: '/details/bank',
		title: 'Bank Details',
		private: true,
		showPage: true
	},
	{
		component: DocumentsUpload,
		path: '/details/documents',
		title: 'Document Upload',
		private: true,
		showPage: true
	},
	{
		component: RequiredUserDetails,
		path: '/details/user',
		title: 'Required User Details',
		private: true,
		showPage: true
	}
];
export const getRoutes = type => {
	if (type === 'admin')
		return pages.map(page => ({ ...page, path: page.path + '/:id' }));
	return pages;
};

export const getSteps = () => {
	return pages.map(page => ({ ...page, path: page.path.substring(1) }));
};

import React, { useEffect } from 'react';
import objectToQueryParams from '../../common/objectToQueryParams';
import Select from '../Common/Input/Select';
import MasterDataService from '../../services/master-data';
import { Context } from '../../config/store';
import { objectsForEach } from '../../common/objectToArray';
import DatePicker from 'react-datepicker';
import splitCamelCase from '../../common/splitCamelCase';
import { getFormattedDate } from '../../common/date';
import { useParams, useLocation } from "react-router";

let isOutside = false;

const SearchFilter = ({ setQueryParams, statusOptions, subType = '', loading }) => {
	let searchData = JSON.parse(sessionStorage.getItem('searchData'));
	const getParamsFromSessionStorage = (key, type = null) => {
		let searchDataResult = searchData ? searchData[key] : '';
		if (type === 'date' && searchDataResult) {
			let convertedDate = searchDataResult?.split('/')
			return new Date(`${convertedDate[1]}/${convertedDate[0]}/${convertedDate[2]}`)
		}
		return searchDataResult;

	}
	//  setting search filter for status fid 
	useEffect(() => {
		let statusId = getParamsFromSessionStorage('status_ids');
		let leadTypeResult = getParamsFromSessionStorage('lead_type');
		if (statusId) {
			let StatusIDResult = (statusOptions || [])?.filter(val => (statusId || []).includes(val?.value))
			setSelectedStatus(StatusIDResult)
		}
		if (leadTypeResult && leadTypeResult == 1) {
			setLeadType({ label: 'Option 1', value: 1 })
		}
		if (leadTypeResult && leadTypeResult == 2) {
			setLeadType({ label: 'Option 2', value: 2 })
		}
		if (leadTypeResult && leadTypeResult == 3) {
			setLeadType({ label: 'Option 1', value: 3 })
		}
	}, [])

	const [productType, setProductType] = React.useState(getParamsFromSessionStorage('prod_cat'));
	const [eligibleLender, setEligibleLender] = React.useState([]);
	const [disbursedDateFrom, setDisbursedDateFrom] = React.useState(getParamsFromSessionStorage('disbursed_date_from', 'date'));
	const [disbursedDateTo, setDisbursedDateTo] = React.useState(getParamsFromSessionStorage('disbursed_date_to', 'date'));
	const [loanAmountMin, setLoanAmountMin] = React.useState(getParamsFromSessionStorage('loan_amount_min'));
	const [lafId, setLafId] = React.useState(getParamsFromSessionStorage('laf_id'));
	const [loanAmountMax, setLoanAmountMax] = React.useState(getParamsFromSessionStorage('loan_amount_max'));
	const [productName, setProductName] = React.useState([]);
	const [lenders, setLenders] = React.useState([]);
	const [productNames, setProducts] = React.useState([]);
	const [statuses, setStatuses] = React.useState([]);
	const [productTypes, setProductTypes] = React.useState([]);
	const [selectedStatus, setSelectedStatus] = React.useState([]);
	const [leadType, setLeadType] = React.useState(null);
	const { state } = React.useContext(Context);
	const location = useLocation();
	const searchFilterRef = React.useRef()
	const [isLoading, setIsLoading] = React.useState(false)

	let SearchByLocation = (location?.search?.split('?')[1])

	React.useLayoutEffect(() => {
		if (SearchByLocation == 'lead_type=1') {
			setLeadType({ label: 'Option 1', value: 1 })
		} else if (SearchByLocation == 'lead_type=2') {
			setLeadType({ label: 'Option 2', value: 2 })
		} else {
			setLeadType(null)
		}
		{
			SearchByLocation && setTimeout(() => {
				document.getElementById("submitButton").click();
			}, 800)
		}
	}, [SearchByLocation])

	const submitQueryParams = () => {
		// setIsLoading(true)
		const searchObject = {
			// prod_types: productType,
			prod_cat: productType,
			eligibleLender: (eligibleLender || []).map((lender) => lender.value),
			disbursed_date_from: disbursedDateFrom
				? getFormattedDate(disbursedDateFrom, 'dd/MM/yyyy')
				: '',
			disbursed_date_to: disbursedDateTo
				? getFormattedDate(disbursedDateTo, 'dd/MM/yyyy')
				: '',
			loan_amount_min: loanAmountMin,
			loan_amount_max: loanAmountMax,
			lead_type: leadType?.value || '',
			laf_id: lafId,
			prod_names: (productName || []).map((product) => product.value),
			...(statusOptions && {
				status_ids: (selectedStatus || []).map((status) => status.value),
			}),
		};
		sessionStorage.setItem('searchData', JSON.stringify({ ...searchObject, org_page: 1 }))
		const queryString = objectToQueryParams(searchObject);
		setQueryParams(queryString);
		setTimeout(() => {
			setIsLoading(false)
		}, 500);
	};

	const resetQueryParams = () => {
		sessionStorage.removeItem('searchData')
		isOutside = false;
		setProductType('');
		setEligibleLender('');
		setDisbursedDateFrom('');
		setDisbursedDateTo('');
		setLoanAmountMin('');
		setLoanAmountMax('');
		setSelectedStatus('');
		setProductName('');
		setLeadType(null);
		setLafId('');
		setQueryParams('');
	};
	React.useEffect(() => {
		if (state.userType === 'admin') {
			lenderList();
		}
		// if (showStatusFilter) {
		// 	statusList();
		// }
		productList();
		productTypeList();
	}, []);
	const lenderList = async () => {
		const response = await MasterDataService.getLenderList(
			state.userType,
			state.userType === 'admin' ? 'co-lender' : ''
		);
		if (response?.data?.status?.code === 1) {
			let responseList = objectsForEach(
				response.data.data.lender_list,
				'STRING_VAL'
			);
			let lenderItems = responseList.map((response) => ({
				label: response.value,
				value: response.id,
			}));
			setLenders(lenderItems);
		}
	};
	const productList = async () => {
		const response = await MasterDataService.getProductList(
			state.userType,
			state.userType === 'admin' ? 'co-lender' : ''
		);
		if (response?.data?.status?.code === 1) {
			let responseList = objectsForEach(
				response.data.data.product_list,
				'STRING_VAL'
			);
			let products = responseList.map((response) => ({
				label: response.value,
				value: response.id,
			}));
			setProducts(products);
			let prod_names = getParamsFromSessionStorage('prod_names');
			if (prod_names) {
				let prod_names_options = (products || []).filter(val => (prod_names || []).includes(val?.value))
				setProductName(prod_names_options);
			}
		}
	};
	const productTypeList = async () => {
		const response = await MasterDataService.getProductTypeList(
			state.userType,
			state.userType === 'admin' ? 'co-lender' : ''
		);
		if (response?.data?.status?.code === 1) {
			let responseList = objectsForEach(
				response.data.data.product_category,
				'STRING_VAL'
			);
			let options = responseList.map((response) => ({
				label: response.value,
				value: response.id,
			}));
			setProductTypes(options);
		}
	};
	const statusList = async () => {
		const response = await MasterDataService.getStatusList(
			state.userType,
			state.userType === 'admin' ? 'co-lender' : ''
		);
		if (response?.data?.status?.code === 1) {
			let responseData = response.data.data.status_list;
			if (subType === 'lender') {
				responseData.splice(0, 3);
			}
			let responseList = objectsForEach(responseData, 'STRING_VAL');
			let statuses = responseList.map((response) => ({
				label: splitCamelCase(
					subType === 'lender'
						? response.value.lender_status
						: response.value.admin_status
				),
				value: response.value.id,
			}));
			setStatuses(statuses);
		}
	};

	React.useEffect(() => {
		const listener = event => {
			if (event.code === "Enter" || event.code === "NumpadEnter") {
				if (isOutside) {
					document.getElementById("submitButton").click();
				}
				event.preventDefault();
			}
		};
		document.addEventListener("keydown", listener);
		return () => {
			document.removeEventListener("keydown", listener);
		};
	}, []);

	React.useEffect(
		() => {
			const listener1 = (event) => {
				if (!searchFilterRef.current || searchFilterRef.current.contains(event.target)) {
					isOutside = true
					return;
				} else {
					isOutside = false
				}
			};
			document.addEventListener("mousedown", listener1);
			document.addEventListener("touchstart", listener1);
			return () => {
				document.removeEventListener("mousedown", listener1);
				document.removeEventListener("touchstart", listener1);
			};
		},
		[searchFilterRef, isOutside]
	);

	return (
		<div className='tab panel overflow-unset' ref={searchFilterRef}>
			<form>
				<div className='p-2'>
					<div className='row'>
						<div className='col-md-3'>
							<div className='form-group'>
								<label>LAF/LAN ID</label>
								<input
									className='form-control'
									value={lafId}
									onChange={(e) => setLafId(e.target.value)}
								/>
							</div>
						</div>
						<div className='col-md-3'>
							<div className='form-group'>
								{/* <Select
									label='Product Type'
									options={productTypes}
									portalTarget="datepickerportal"
									onChange={data => setProductType(data)}
									value={productType}
									isMulti={false}
								/> */}
								<label>Program Name</label>
								<select
									value={productType}
									onChange={(e) => setProductType(e.target.value)}
									className='form-control'
									placeholder=''
								>
									<option value={''}>Select Program Name</option>
									{(productTypes || []).map((type) => (
										<option key={type.value} value={type.value}>
											{type.label}
										</option>
									))}
								</select>
							</div>
						</div>
						{state.userType === 'admin' && (
							<div className='col-md-3'>
								<div className='form-group'>
									<Select
										label='Assigned Lender'
										options={lenders}
										onChange={(data) => setEligibleLender(data)}
										value={eligibleLender}
									/>
								</div>
							</div>
						)}
						<div className='col-md-3'>
							<div className='form-group'>
								<label>Disbursal Date</label>
								<div className='d-flex justify-content-between'>
									<div className='d-flex w-50 align-items-center'>
										<span className='mr-2'>From</span>
										<DatePicker
											selected={disbursedDateFrom}
											portalId='datepickerportal'
											showMonthDropdown
											yearDropdownItemNumber={100}
											scrollableYearDropdown={true}
											showYearDropdown
											className='form-control'
											dateFormat='dd/MM/yyyy'
											onChange={(selectedDate) =>
												setDisbursedDateFrom(selectedDate)
											} //only when value has changed
										/>
									</div>

									<div className='d-flex w-50 align-items-center'>
										<span className='ml-2 mr-2'>To</span>
										<DatePicker
											selected={disbursedDateTo}
											portalId='datepickerportal'
											yearDropdownItemNumber={100}
											scrollableYearDropdown={true}
											showMonthDropdown
											className='form-control'
											dateFormat='dd/MM/yyyy'
											onChange={(selectedDate) =>
												setDisbursedDateTo(selectedDate)
											} //only when value has changed
										/>
									</div>
								</div>
							</div>
						</div>

						<div className='col-md-3'>
							<div className='form-group'>
								<label>Loan Amount</label>
								<div className='d-flex justify-content-between'>
									<div className='d-flex w-50 align-items-center'>
										<span className='mr-2'>Min</span>
										<input
											value={loanAmountMin}
											onChange={(e) => setLoanAmountMin(e.target.value)}
											type='number'
											className='form-control'
										/>
									</div>

									<div className='d-flex w-50 align-items-center'>
										<span className='ml-2 mr-2'>Max</span>
										<input
											value={loanAmountMax}
											onChange={(e) => setLoanAmountMax(e.target.value)}
											type='number'
											className='form-control'
										/>
									</div>
								</div>
							</div>
						</div>

						<div className='col-md-4'>
							<div className='form-group'>
								<Select
									label='Product Type'
									options={productNames}
									onChange={(data) => setProductName(data)}
									value={productName}
								/>
							</div>
						</div>
						{/* <div className='col-md-3'>
							<div className='form-group'>
								<Select
									isMulti={false}
									label='Lead Type'
									options={[
										{ label: 'Option 1', value: 1 },

										{ label: 'Option 2', value: 2 },
										{
											label: 'DA',
											value: 4,
										},
									]}
									value={leadType}
									onChange={(data) => setLeadType(data)}
								/>
							</div>
						</div> */}
						{
							(location.pathname == "/portfolio/pre-sanction" ||
								location.pathname == "/portfolio/pre-sanction-approve" ||
								location.pathname == "/portfolio/pre-sanction-rejected")
								? <div className='col-md-3'>
									<div className='form-group'>
										<Select
											isMulti={false}
											label='CLM Type'
											options={[
												{ label: 'Option 1', value: 1 },
												// { label: 'Option 1 PS', value: 3 },

											]}
											value={leadType}
											onChange={(data) => setLeadType(data)}
										/>
									</div>
								</div> : <div className='col-md-3'>
									<div className='form-group leadType'>
										<Select
											isMulti={false}
											label='CLM Type'
											options={[
												{ label: 'Option 1', value: 1 },
												// { label: 'Option 1 PS', value: 3 },
												{ label: 'Option 2', value: 2 },
											]}
											value={leadType}
											onChange={(data) => setLeadType(data)}
										/>
									</div>
								</div>}
						{statusOptions && (
							<div className='col-md-3'>
								<div className='form-group'>
									<Select
										label='Status'
										options={statusOptions}
										value={selectedStatus}
										onChange={(data) => setSelectedStatus(data)}
									/>
								</div>
							</div>
						)}

					</div>
					{/* <div className='row'>
						<div className='col-md-12 d-flex'>
							<button
								onClick={submitQueryParams}
								type='button'
								className='btn btn-custom'
							>
								Submit
							</button>
							<button
								onClick={resetQueryParams}
								type='button'
								className='btn btn-custom ml-2'
							>
								Reset All
							</button>
						</div>
					</div> */}
					<div className='row d-flex'>
						<div className='col-md-12 d-flex'>
							<button
								onClick={submitQueryParams}
								type='button'
								className='btn btn-custom btn-custom-height'
								id='submitButton'
							>
								{isLoading || loading ? <div className="loader"></div> : 'Search'}
							</button>
							<button
								onClick={resetQueryParams}
								type='button'
								className='btn btn-custom btn-custom-height ml-2'
							>
								Reset All
							</button>
						</div>
					</div>
				</div>
			</form>
		</div>
	);
};

export default SearchFilter;

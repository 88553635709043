import { useState } from 'react';
import { toast } from 'react-toastify';
import Button from '../../../components/Common/Button';
import Modal from '../../../components/Common/Modal';
import message from '../../../constant/message';
import LenderUgroService from '../../../services/lender';

const ApproveModal = ({
	showApproveModal,
	userType,
	leadId,
	toggleApproveModal,
	redirectToAssigned,
}) => {
	const [approvingLead, setApprovingLead] = useState(false);
	const [approveComment, setApproveComment] = useState('');
	const approveLead = async () => {
		setApprovingLead(true);
		try {
			const response = await LenderUgroService.approveLead({
				type: userType,
				requestBody: {
					cl_lead_ids: [`${leadId}`],
					message: approveComment,
				},
			});
			if (response?.data?.status?.code === 1) {
				const success = response?.data?.messages?.success;
				toast.success(
					success?.form?.[0] || success?.others?.[0] || message.SAVED_SUCCESS
				);
				setApprovingLead(false);
				toggleApproveModal();
				redirectToAssigned();
			}
		} catch (err) {
			setApprovingLead(false);
			let error = err?.response?.data?.messages?.error;
			toast.error(error?.form?.[0] || error?.others?.[0] || message.ERROR_FORM);
		}
	};
	return (
		<Modal state={showApproveModal} closeModal={toggleApproveModal} style={{ width: '450px' }} removeBorderSpace={true}>
			<div className="modal-header-container">
				<div className="modal-title">Sanction Case</div>
				<div className="closeModalView" onClick={toggleApproveModal}>
					<span className="closeModalIcon">x</span>
				</div>
			</div>
			<div className="p-4 modalData">

				<p className='font-weight-bold'>
					Are you sure you want to sanction this case?
				</p>
				<div class='form-group mt-2'>
					<label>Comment</label>
					<textarea
						class='form-control custom-form-control'
						cols='2'
						rows='4'
						value={approveComment}
						onChange={(e) => setApproveComment(e.target.value)}
					></textarea>
				</div>
				<div className='d-flex align-items-center justify-content-center'>
					<Button
						clickHandler={approveLead}
						disabled={approvingLead}
						classNames='btn-custom'
					>
						{approvingLead ? <div className='loader' /> : 'Submit'}
					</Button>
					<Button
						clickHandler={toggleApproveModal}
						classNames='btn-outline-custom ml-2'
					>
						Cancel
					</Button>
				</div>
			</div>
		</Modal>
	);
};

export default ApproveModal;

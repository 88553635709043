import { objectsForEach } from '../../../common/objectToArray';

const OptionGroup = ({ documentData }) => {
	let docs = (documentData?.document || []).reduce(function(r, a) {
		r[a.doc_type] = r[a.doc_type] || [];
		r[a.doc_type].push(a);
		return r;
	}, Object.create(null));
	return (
		<>
			{' '}
			{(objectsForEach(docs) || []).map(({ id, value }, i) => (
				<optgroup label={id}>
					{value.map(({ doc_name, partner_entity_doc_id, id }, i) => (
						<option value={partner_entity_doc_id}>{doc_name}</option>
					))}
				</optgroup>
			))}
		</>
	);
};

export default OptionGroup;

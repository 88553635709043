import Login from '../pages/login';
import NotFound from '../components/NotFound';
import Register from '../pages/register';
import ForgotPassword from '../pages/forgot-password';
import Dashboard from '../pages/dashboard';
import ResetPassword from '../pages/reset-password';
import RegisterThankingNote from '../pages/register-thanking-note';
import ContainerRouter from '../components/ContainerRouter';
import AssignedPortfolio from '../Views/Portfolio/AssignedPortfolio';
import ApprovedPortfolio from '../Views/Portfolio/ApprovedPortfolio';
import ViewPortfolio from '../Views/Portfolio/ViewPortfolio';
import PortfolioDashboard from '../Views/Portfolio/Dashboard';
import ViewUser from '../pages/view-user';
import ManageUsers from '../pages/manage-users';
import ViewRole from '../pages/view-role';
import ManageRoles from '../pages/manage-roles';
import ChangePassword from '../pages/change-password';
import NewDashboard from '../Views/Portfolio/ViewPortfolio/NewDashboard';
import Home from '../pages/home';
import PostDisbursalCaseView from '../Views/Portfolio/PostDisbursalCaseView';
import GlobalSearch from '../Views/Portfolio/GlobalLafSearch/GlobalSearch';

export default [
	{
		component: Login,
		path: '/lender/login',
		title: 'login',
		exact: true,
		type: 'lender',
	},
	{
		component: Register,
		path: '/lender/register',
		title: 'register',
		exact: true,
	},
	{
		component: RegisterThankingNote,
		path: '/lender/register/thank-you',
		title: 'register',
		exact: true,
	},
	{
		component: ForgotPassword,
		path: '/lender/password/forget',
		title: 'forgotPassword',
		exact: true,
	},
	{
		component: ResetPassword,
		path: '/lender/password/reset',
		title: 'resetPassword',
		exact: true,
	},
	{
		component: ContainerRouter,
		path: '/*',
		title: 'container',
		type: 'lender',
	},
	{
		component: AssignedPortfolio,
		path: '/portfolio/pre-sanction',
		title: 'assignedPortfolio',
		exact: true,
		private: true,
		customComponentProps: { leadType: 'pre-sanction' },
		permission: 'pre-sanction-portfolio',
	},
	{
		component: AssignedPortfolio,
		path: '/',
		title: 'assignedPortfolio',
		exact: true,
		private: true,
		// permission:"assigned-portfolio",
		customComponentProps: { leadType: 'assigned' },
	},
	{
		component: ViewPortfolio,
		path: '/portfolio/view/:id/*',
		private: true,
		permission: 'details-info,documents,additional-info,queries,drf-info,fundouts,lender-demand,audit-trail',
	},
	{
		component: AssignedPortfolio,
		path: '/portfolio/assigned',
		title: 'assignedPortfolio',
		exact: true,
		private: true,
		customComponentProps: { leadType: 'assigned' },
		permission:"assigned-portfolio"
	},
	{
		component: AssignedPortfolio,
		path: '/portfolio/sanction-rejected',
		title: 'sanctionrejectedPortfolio',
		exact: true,
		private: true,
		permission:'sanction-rejected',
		customComponentProps: { leadType: 'rejected' },
	},
	{
		component: AssignedPortfolio,
		path: '/portfolio/pre-sanction-approve',
		title: 'sanctionrejectedPortfolio',
		exact: true,
		private: true,
		permission: 'pre-sanction-approved',
		customComponentProps: { leadType: 'pre-sanction-approved' },
	},{
		component: AssignedPortfolio,
		path: '/portfolio/pre-sanction-rejected',
		title: 'sanctionrejectedPortfolio',
		exact: true,
		private: true,
		permission: 'pre-sanction-rejected',
		customComponentProps: { leadType: 'pre-sanction-rejected' },
	},
	{
		component: ApprovedPortfolio,
		path: '/portfolio/disbursal-pending',
		title: 'disbursalPendingPortfolio',
		exact: true,
		private: true,
		customComponentProps: { leadType: 'disbursal-pending' },
		permission:'disbursal-pending'
	},
	{
		component: AssignedPortfolio,
		path: '/portfolio/in-disbursal-approve',
		title: 'disbursalPendingPortfolio',
		exact: true,
		private: true,
		permission: 'disbursal-approval',
		customComponentProps: { isOps: true, leadType: 'indisbursal-lender-pending' },
	},
	{
		component: ApprovedPortfolio,
		path: '/portfolio/disbursal-approved',
		title: 'disbursalPendingPortfolio',
		exact: true,
		private: true,
		customComponentProps: { leadType: 'disbursal-approved' },
		permission:'disbursed-portfolio'
	},
	{
		component: ApprovedPortfolio,
		path: '/portfolio/disbursal-rejected',
		title: 'disbursalRejectedPortfolio',
		exact: true,
		private: true,
		customComponentProps: { leadType: 'disbursal-rejected' },
		permission: 'disbursed-rejected'
	},

	{
		component: ApprovedPortfolio,
		path: '/portfolio/approved',
		title: 'assignedPortfolio',
		exact: true,
		private: true,
		customComponentProps: { leadType: 'approve' },
		permission:'sanction-portfolio'
	},
	{
		component: NewDashboard,
		path: '/portfolio/dashboard',
		title: 'portfolioDashboard',
		exact: true,
		private: true,
		permission: 'lender-dashboard'
	},
	{
		component: ManageUsers,
		path: '/lender/manage-users',
		title: 'manageUsers',
		exact: true,
		private: true,
		// permission: '',
		customComponentProps: { type: 'lender' },
	},
	{
		component: ViewUser,
		path: '/lender/manage-users/add',
		title: 'addUser',
		exact: true,
		private: true,
		permission: 'update-backend-user,view-backend-user',
		customComponentProps: { type: 'lender' },
	},
	{
		component: ViewUser,
		path: '/lender/manage-users/edit/:userId',
		title: 'editUser',
		exact: true,
		private: true,
		permission: 'update-backend-user,view-backend-user',
		customComponentProps: { type: 'lender' },
	},

	{
		component: ViewUser,
		path: '/lender/manage-users/:userId',
		title: 'viewUsers',
		exact: true,
		private: true,
		permission: 'update-backend-user,view-backend-user',
		customComponentProps: { type: 'lender' },
	},
	{
		component: ViewRole,
		path: '/lender/role/add',
		title: 'manageRole',
		exact: true,
		private: true,
		permission: 'update-role-permission,view-role-permissions',
		customComponentProps: { type: 'lender' },
	},
	{
		component: ViewRole,
		path: '/lender/role/:roleId',
		title: 'manageRole',
		exact: true,
		private: true,
		permission: 'update-role-permission,view-role-permissions',
		customComponentProps: { type: 'lender' },
	},
	{
		component: ViewRole,
		path: '/lender/role/edit/:roleId',
		title: 'manageRole',
		exact: true,
		private: true,
		permission: 'update-role-permission,view-role-permissions',
		customComponentProps: { type: 'lender' },
	},
	{
		component: ManageRoles,
		path: '/lender/roles',
		title: 'manageRole',
		exact: true,
		private: true,
		customComponentProps: { type: 'lender' },
	},
	{
		component: ChangePassword,
		path: '/password/change',
		title: 'changePassword',
		exact: true,
		private: true,
	},
	{ component: NotFound, path: '/lender/404' },
	{
		component: Home,
		path: '/portfolio/home',
		title: 'Home',
		exact: true,
		private: true,
		permission: 'lender-home',
	},
	{
		component: PostDisbursalCaseView,
		path: '/portfolio/post-disbursal',
		title: 'post-disbursal',
		exact: true,
		private: true,
		permission: 'post-disbursal-case-view'
	},
	{
		component: ViewPortfolio,
		path: '/portfolio/views/post-disbursal/:id/*',
		permission: 'repayment-details,repayment-schedule,repayment-soa-documents',
		private: true,
	},
	{
		component: GlobalSearch,
		path: '/portfolio/global-search',
		title: 'global-search',
		exact: true,
		private: true,
	},
];

import React from 'react';
import { Link } from 'react-router-dom';
import ReactApexChart from 'react-apexcharts';
import { useRouter } from '../../hooks/useRouter';

const Bureau = () => {
	const router = useRouter();
	const [options] = React.useState({
		chart: {
			height: 350,
			type: 'radialBar'
		},
		plotOptions: {
			radialBar: {
				hollow: {
					size: '70%'
				}
			}
		},
		labels: ['Report']
	});
	const [series] = React.useState([70]);
	const submit = () => {
		router.navigate(`/onboarding/documentation/fi/${router.params.id}`);
	};
	return (
		<div>
			<div className='content'>
				<div className='row'>
					<div className='col-md-12'>
						<Link
							to={`/onboarding/details/partner/${router.params.id}`}
							className='btn btn-close mb-3'
						>
							<i className='fa fa-chevron-left' aria-hidden='true' /> Back
						</Link>
					</div>
					<div className='col-md-12 d-flex align-items-center'>
						<p className='font-18 mb-0'>
							<b>Documentation</b>
						</p>{' '}
					</div>
				</div>
			</div>
			<div class='content'>
				{/* <div>Application ID: </div> */}
				<h6 className='mt-2 text-orange'>Bureau Run</h6>
				<p>
					<b>CIBIL Score</b>
				</p>
				<div style={{ width: 120 }}>
					<ReactApexChart
						options={options}
						series={series}
						type='radialBar'
						height={180}
					/>
				</div>
				<div>Based on CIBIL Score version 1.0</div>
			</div>

			<div className='content'>
				<button
					// disabled={loading}
					onClick={submit}
					type='submit'
					className='btn btn-custom'
				>
					Next {/* {loading ? <div className='loader' /> : 'Submit'} */}
				</button>
				<button
					onClick={e => {
						e.preventDefault();
						router.navigate(`/onboarding/details/partner/${router.params.id}`);
					}}
					type='submit'
					className='btn btn-close ml-2'
				>
					Cancel
				</button>
			</div>
		</div>
	);
};

export default Bureau;

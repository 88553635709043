import React from 'react';
import { getComissionConfig } from '../services/comission';
import { useRouter } from '../hooks/useRouter';
import arrayToObject from '../common/arrayToObject';
import VolumeLinked from '../components/ConfigureComission/VolumeLinked';
import PerLead from '../components/ConfigureComission/PerLead';
import PerLogged from '../components/ConfigureComission/PerLogged';
import Actions from '../components/ConfigureComission/Actions';
import PageHeader from '../components/AffiliateMapping/AffiliateKyc/PageHeader';
import { Context } from '../config/store';
import Steps from '../components/AffiliateMapping/Steps';

const ConfigureComission = () => {
	const [body, setBody] = React.useState({});
	const router = useRouter();
	const [error, setError] = React.useState({});
	const [partId, setPartId] = React.useState('');
	const [loading, setLoading] = React.useState(true);

	const { state } = React.useContext(Context);
	const partComissionConfig = async () => {
		const part_id = router.params.partId;
		setPartId(part_id);
		const response = await getComissionConfig({ part_id });
		if (response?.status?.code === 1) {
			const config = arrayToObject(
				response.data.resource,
				'comm_type_name',
				null,
				formatComissionConfig
			);
			setBody({
				vli: config['Volume linked'] || undefined,
				per_lead: config['Per Lead'] || undefined,
				logged_lead: config['Per convert-to-app case'] || undefined
			});
		}

		setLoading(false);
	};

	const formatComissionConfig = (curr, object) => {
		let val;
		switch (curr.comm_type_name) {
			case 'Volume linked':
				val = [...(object?.['Volume linked'] || [])];
				val.push({
					product_id: curr.product_id,
					min_value: curr.min_value,
					max_value: curr.max_value,
					base_payout: curr.base_payout,
					amt_type: curr.amt_type
				});
				break;
			case 'Per Lead':
				val = {
					amt_type: curr.amt_type,
					base_payout_sec: object?.['Per Lead']?.base_payout_sec
						? object?.['Per Lead']?.base_payout_sec
						: curr.loan_type_name === 'Secured'
						? curr.base_payout
						: '',
					base_payout_unsec: object?.['Per Lead']?.base_payout_unsec
						? object?.['Per Lead']?.base_payout_unsec
						: curr.loan_type_name === 'Unsecured'
						? curr.base_payout
						: ''
				};
				break;
			case 'Per convert-to-app case':
				val = {
					amt_type: curr.amt_type,
					base_payout_sec: object?.['Per convert-to-app case']?.base_payout_sec
						? object?.['Per convert-to-app case']?.base_payout_sec
						: curr.loan_type_name === 'Secured'
						? curr.base_payout
						: '',
					base_payout_unsec: object?.['Per Lead']?.base_payout_unsec
						? object?.['Per Lead']?.base_payout_unsec
						: curr.loan_type_name === 'Unsecured'
						? curr.base_payout
						: ''
				};
				break;
			default:
				val = curr;
		}
		return val;
	};
	React.useEffect(() => {
		partComissionConfig();
	}, []);
	return (
		<>
			<div className='panel w-100'>
				{state.userType === 'admin' && partId && <PageHeader partId={partId} />}
				{state.userType === 'admin' && (
					<>
						<div>
							<Steps pathName={router.pathname} partId={partId} active={2} />
						</div>
						<hr className='m-0' />
					</>
				)}
				<div className='head_txop2 mx-3 mt-3'>
					<h4>Affiliate Commission</h4>
				</div>
				{!loading && (
					<>
						<VolumeLinked error={error} body={body} setBody={setBody} />
						{error && (
							<p className='mt-2 ml-2 mb-0 text-danger'>
								{error?.fields?.['vli']}
							</p>
						)}
						<PerLead error={error} body={body} setBody={setBody} />
						{error && (
							<p className='mt-2 ml-2 mb-0 text-danger'>
								{error?.fields?.['per_lead']}
							</p>
						)}
						<PerLogged error={error} body={body} setBody={setBody} />
						{error && (
							<p className='mt-2 ml-2 mb-0 text-danger'>
								{error?.fields?.['logged_lead']}
							</p>
						)}
					</>
				)}
				<Actions router={router} setError={setError} body={body} />
			</div>
		</>
	);
};

export default ConfigureComission;

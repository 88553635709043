import DateFormatter from '../../../../components/Common/DateFormatter';
import Action from './Action';

export default [
 
	{ title: 'LAN Number', field: 'lan_no' },
	{ title: 'Cheque / UTR Number', field: 'utr_no' },
	{ title: 'Payment Amount', field: 'netpay_amount' },
	{ title: 'Account Number', field: 'account_no' },
	{ title: 'Customer Name', field: 'cust_name' },
	// { title: 'UTR Date', field: 'utr_date' },
	{ title: 'Payment Date', field: 'payout_date' },
	{ title: 'Disbursal Type', field: 'disbursal_type_name' },
	{ title: 'Amount Split %', field: 'amount_split' },
	{ title: 'UGRO Branch Name', field: 'branch_name' },
	{ title: 'Created At', field: 'created_at', component: DateFormatter },
	// { title: 'Action', field: '', component: Action },
];

import React from 'react';
import splitUnderscoreToCaptialise from '../../../common/splitUnderscoreToCaptialise';
import splitCamelCase from '../../../common/splitCamelCase';
import DatePicker from 'react-datepicker';
import { objectsForEach } from '../../../common/objectToArray';
import Error from '../../../components/Error';

const DocumentUploadField = ({
	id,
	onChange,
	value,
	options,
	required,
	inputType,
	dataType,
	label,
	error,
}) => {
	const inputRenderHandler = () => {
		if (inputType === 'select') {
			return (
				<SelectInput
					onChange={onChange}
					value={value}
					options={options}
					inputKey={id}
					dataType={dataType}
				/>
			);
		} else if (inputType === 'date') {
			return (
				<DatePicker
					selected={value}
					portalId='datepickerportal'
					showMonthDropdown
					className='form-control'
					dateFormat='dd/MM/yyyy'
					onChange={(value) => onChange(value, id)}
				/>
			);
		} else {
			return (
				
				<input
				// disabled={id === "odcc_limit" ? false :true}
					value={value}
					onChange={(e) => onChange(e.target.value, id)}
					type={inputType}
					className='form-control'

				/>
			);
		}
	};

	return (
		<div className='form-group'>
			<label>
				{label ? label : splitCamelCase(splitUnderscoreToCaptialise(id)) || id}
				{required && <tt>*</tt>}
			</label>
			{inputRenderHandler()}
			{error}
			<Error errorMessage={error} />
		</div>
	);
};

export default DocumentUploadField;

const SelectInput = ({ onChange, value, options, inputKey, dataType }) => (
	<select
		onChange={(e) => onChange(e.target.value, inputKey)}
		value={value}
		className='form-control custom-form-control'
	>
		<option value={''}>Select</option>
		{dataType === 'array'
			? (options || []).map(({ name, id }, i) => (
					<option value={i}>
						{name == 'Technical Report Generated by Ugro' ? 'FI Report' : name}
					</option>
			  ))
			: Object.keys(options || {}).map((key, i) => {
					return (
						<option value={key}>
							{options[key] == 'Technical Report Generated by Ugro'
								? 'FI Report'
								: options[key]}
						</option>
					);
			  })}
	</select>
);



import React from 'react';
import Pagination from '../components/Pagination';
import { Context } from '../config/store';
import { getApplications } from '../services/manage-applications';
import ApplicationTable from '../components/ManageApplication/ApplicationTable';
import SearchFilter from '../components/ManageApplication/SearchFilter';
import { downloadFile } from '../common/download';
import { toast } from 'react-toastify';
import message from '../constant/message';
const ManageApplications = () => {
	const [leads, setLeads] = React.useState([]);
	const [pagination, setPaginationData] = React.useState({});
	const [page, setPage] = React.useState(1);
	const [queryParams, setQueryParams] = React.useState('');
	const { state } = React.useContext(Context);
	const [loading, setState] = React.useState(false);
	const [branches, setBranches] = React.useState([]);
	const [assignedTo, setAssignedTo] = React.useState([]);
	const [products, setProducts] = React.useState([]);
	const [statuses, setStatuses] = React.useState([]);
	const [flag, setFlag] = React.useState(false);
	React.useEffect(() => {
		getLeads();
	}, [queryParams]);

	const getLeads = async exportLeads => {
		setState(true);

		const response = await getApplications({
			queryParams,
			exportLeads
		});

		if (response?.status?.code === 1) {
			let leads = response.data?.resource || [];

			setFlag(true);

			if (!flag) {
				setBranches(
					[...new Set(leads.map(lead => lead?.LOAN_DETAILS?.BRANCH))].filter(
						branch => branch
					)
				);
				setStatuses([
					...new Set(
						leads.map(lead => lead?.APPLICATION_STATUS?.CURRENT_LOS_QUEUE)
					)
				]);
				setAssignedTo(
					[...new Set(leads.map(lead => lead?.LOAN_DETAILS?.BDM_NAME))].filter(
						assigned => assigned
					)
				);
				setProducts(
					getUniqueListBy(leads.map(lead => lead.LOAN_DETAILS), 'PRODUCT_CODE')
						.map(product => ({
							label: product.PRODUCT,
							value: product.PRODUCT_CODE
						}))
						.filter(product => product.label)
				);
			}
			setLeads(leads || []);
			const paginationData = {
				total_pages: Math.ceil(leads.length / 10)
			};
			setPaginationData(paginationData || {});
		} else if (exportLeads && !response?.status) {
			downloadFile(response);
		} else {
			let error = response?.messages?.error;
			toast.error(error?.form?.[0] || error?.others?.[0] || message.ERROR);
		}
		setState(false);
	};
	function getUniqueListBy(arr, key) {
		return [...new Map(arr.map(item => [item[key], item])).values()];
	}
	function paginate(array, page_size, page_number) {
		// human-readable page numbers usually start with 1, so we reduce 1 in the first argument
		return array.slice((page_number - 1) * page_size, page_number * page_size);
	}
	const exportLeads = async () => {
		getLeads(true);
	};
	return (
		<>
			<SearchFilter
				branches={branches}
				products={products}
				assignedTo={assignedTo}
				statuses={statuses}
				setQueryParams={setQueryParams}
			/>

			<div className='d-flex justify-content-end mt-4'>
				<button
					onClick={() => exportLeads()}
					type='button'
					className='btn btn-custom ml-3'
				>
					Export Applications
				</button>
			</div>

			<ApplicationTable
				loading={loading}
				leads={paginate(leads || [], 10, page)}
			/>
			<Pagination
				setPage={setPage}
				total_pages={pagination.total_pages}
				total={pagination.total}
			/>
		</>
	);
};

export default ManageApplications;

import React from 'react';
import { toast } from 'react-toastify';
import message from '../../constant/message';
import config from '../../services/config';
import { Context } from '../../config/store';
import { objectsForEach } from '../../common/objectToArray';

const BranchCode = ({
	rule,
	label,
	id,
	errors,
	type,
	parentIndex,
	parentId,
	setBody,
	body,
	edit,
	leadId,
	required,
	params
}) => {
	const { state } = React.useContext(Context);
	const [stateCityData, setStateCityData] = React.useState();
	const [disableState, setDisableState] = React.useState(false);
	const onZipChange = async () => {
		if (body?.[parentId]?.[id].length === 6) {
			const response = await config.getStateCityByZip(body?.[parentId]?.[id]);
			if (response?.status?.code === 1) {
				setStateCityData(response.data);
				setDisableState(response.data.branch ? true : false);
				setStateCity(response.data.branch);
			} else {
				toast.error(message.ERROR);
			}
			return;
		}
		setDisableState(false);
		return toast.error(message.PLEASE_ENTER + ' correct zipcode');
	};
	// const onStateChange= ()=>{

	// }
	const setStateCity = branch => {
		onChange({ target: { value: branch.branch_city } }, 'lead_city');
		onChange({ target: { value: branch.state_id } }, 'lead_state', true);
	};
	const onChange = async (e, customId, stateCity) => {
		let value;
		let inputValue = e.target.value;

		if (type === 'multiple') {
			value = body[parentId];
			value[parentIndex] = {
				...value[parentIndex],
				[customId ? customId : id]: inputValue
			};
		} else {
			value = {
				...body[parentId],
				[customId ? customId : id]: inputValue
			};
		}
		setBody(values => {
			return {
				...values,
				...body,
				[parentId]: value
			};
		});
		if (!stateCity && customId === 'lead_state') {
			await getCityData(inputValue);
		}
	};
	const getCityData = async userState => {
		const response = await config.getCityByState(userState);
		if (response?.status?.code === 1) {
			setStateCityData({
				cities: objectsForEach(response.data.cities, 'STRING_VAL').map(
					city => ({ name: city.id, id: city.value })
				)
			});
		}
	};

	return (
		<>
			<div className='col-md-4'>
				<div className='form-group'>
					<label>
						{label} {required == '1' && <tt>*</tt>}
					</label>
					<input
						disabled={rule.disabled || (!edit && leadId)}
						onChange={onChange}
						type={rule.input_type}
						className='form-control'
						value={body?.[parentId]?.[id]}
						onBlur={onZipChange}
					/>
					{errors && (
						<p className='mt-2 mb-0 text-danger'>
							{type === 'multiple'
								? errors?.[parentId]?.[parentIndex]?.[id]
								: errors?.[parentId]?.[id]}
						</p>
					)}
				</div>
			</div>
			<div className='col-md-4'>
				<div className='form-group'>
					<label>State {required == '1' && <tt>*</tt>}</label>
					<select
						value={body?.[parentId]?.['lead_state']}
						onChange={e => onChange(e, 'lead_state')}
						disabled={disableState}
						className='form-control'
					>
						<option value={''}>State</option>

						{(state.config?.states || []).map(({ name, id }) => (
							<option value={id}>{name}</option>
						))}
					</select>

					{errors && (
						<p className='mt-2 mb-0 text-danger'>
							{type === 'multiple'
								? errors?.[parentId]?.[parentIndex]?.['lead_state']
								: errors?.[parentId]?.['lead_state']}
						</p>
					)}
				</div>
			</div>
			<div className='col-md-4'>
				<div className='form-group'>
					<label>City {required == '1' && <tt>*</tt>}</label>
					<select
						disabled={!stateCityData}
						value={body?.[parentId]?.['lead_city']}
						onChange={e => onChange(e, 'lead_city')}
						className='form-control'
					>
						<option value={''}>City</option>

						{(stateCityData?.cities || []).map(({ name, id }) => (
							<option value={id}>{name}</option>
						))}
					</select>

					{errors && (
						<p className='mt-2 mb-0 text-danger'>
							{type === 'multiple'
								? errors?.[parentId]?.[parentIndex]?.['lead_city']
								: errors?.[parentId]?.['lead_city']}
						</p>
					)}
				</div>
			</div>
		</>
	);
};

export default BranchCode;

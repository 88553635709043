import React from 'react';
import ParameterSection from './ParameterSection';
import { objectsForEach } from '../../../../common/objectToArray';

const ApiParameters = ({
	affiliateConfig,
	setAffiliateConfig,
	part_id,
	config
}) => {
	return (
		<div className='content'>
			<div className='d-flex flex-row justify-content-between'>
				{' '}
				<h5>API Parameters</h5>
			</div>
			<table className='api-parameters-table mt-2'>
				<tr>
					<th className='background-none'></th>
					<th> Add</th>
					<th>Next</th>
				</tr>

				{objectsForEach(config || []).map(({ title, type, id, ...rest }) => (
					<ParameterSection
						key={id}
						title={title}
						id={id}
						type={type}
						parameters={rest}
						globalConfig={config}
						config={affiliateConfig}
						setConfig={setAffiliateConfig}
					/>
				))}
			</table>
		</div>
	);
};

export default ApiParameters;

import { getCookie } from './cookie';
import config from './config';
import objectToQueryParams from '../common/objectToQueryParams';

/**
 *
 * @param {String} partId
 * @param {*} params
 */
export const offerList = async (partId, params) => {
	const token = getCookie(btoa('token'));
	return await (await fetch(
		`${
			config.baseUrl.admin
		}/lead/list-lead-offer-files/${partId}?${objectToQueryParams(params)}`,
		{
			headers: {
				Authorization: `Bearer ${token}`,
				...config.defaultHeaders
			}
		}
	)).json();
};

/**
 *
 * @param {*} body
 * @param {*} param
 */
export const offerLeadList = async (body, params) => {
	const token = getCookie(btoa('token'));
	return await (await fetch(
		`${config.baseUrl.admin}/lead/get-lead-offers?${objectToQueryParams(
			params
		)}`,
		{
			method: 'POST',
			body: JSON.stringify(body),
			headers: {
				Authorization: `Bearer ${token}`,
				...config.defaultHeaders
			}
		}
	)).json();
};

/**
 *
 * @param {String} file_id
 */

export const downloadOfferFile = async ({ file_id }) => {
	const token = getCookie(btoa('token'));
	const res = await fetch(
		`${config.baseUrl.admin}/download-offer-file/${file_id}`,
		{
			headers: {
				Authorization: `Bearer ${token}`,
				...config.defaultHeaders
			}
		}
	);
	if (res.status === 200 || res.status === 201) {
		return await res.blob();
	} else throw new Error(JSON.stringify(await res.json()));
};

import React from 'react';
import { useContext, useLayoutEffect, useState, useEffect } from 'react';
import Table from '../../../../components/Common/Table';
import TableNoRecords from '../../../../components/Common/Table/TableNoRecords';
import { Context } from '../../../../config/store';
import LenderUgroService from '../../../../services/lender';
import { getColumns } from './tableHelpers';
import { toast } from 'react-toastify';
import message from '../../../../constant/message';
import { addOnboardingQuery } from '../../../../services/onboarding-form-service';

const ReplyQuery = (props) => {
	const { state } = useContext(Context);
	const [loading, setLoading] = useState(true);
	const [tableData, setTableData] = useState([]);
	const [queryComment, setQueryComment] = React.useState('');
	const [QueryStatus, SetQueryStatus] = React.useState(1);
	const [file, setFile] = React.useState(null);
	const [queryId, setQueryId] = React.useState('');
	const [QueryStatusShow, SetQueryStatusshow] = React.useState('');

	const PrentqueryId = props.parentId.queryId;
	let inputRef = React.useRef(null);

	const getQueryList = async () => {
		setLoading(true);
		const response = await LenderUgroService.getQueryDetails({
			type: state.userType,
			isChild: PrentqueryId,
		});

		const data = response.data.data?.['cl_lender_query'];

		SetQueryStatusshow(data[data.length - 1].query_status);

		data.forEach((data) => {
			if (data.query_status === 1) {
				data.query_status = 'open';
			} else if (data.query_status === 2) {
				data.query_status = 'resolved';
			} else if (data.query_status === 3) {
				data.query_status = 'closed';
			} else if (data.query_status === 4) {
				data.query_status = 'Re-Opened';
			}
		});
		data.forEach((data) => {
			if (data.priority === 1) {
				data.priority = 'Low';
			} else if (data.priority === 2) {
				data.priority = 'Medium';
			} else if (data.priority === 3) {
				data.priority = 'High';
			}
		});
		setTableData(data);
		setLoading(false);
	};

	useEffect(() => {
		getQueryList();
	}, []);

	const HandlePageBack = () => {
		props.replyQuery();
		props.getQueryList();
	};
	const setComment = (e) => {
		let comment = e.target.value.replace(/[<>]+/g, '');
		setQueryComment(comment);
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		addQuery();
	};
	//post api
	const addQuery = async () => {
		setLoading(true);
		const formData = new FormData();
		if (props.type === 'onboarding-form') {
			props.leadId &&
				formData.append(
					'part_id',
					props.globalState.userType === 'affiliate'
						? props.globalState.user.part_id
						: props.leadId
				);
		} else {
			props.leadId && formData.append('cl_lead_id', props.leadId);
			!props.parentQueryId &&
				QueryStatus &&
				formData.append('query_status', QueryStatus);
			// props.parentQueryId && formData.append('parent_query_id', props.parentQueryId);
		}
		props.queryId && formData.append('query_type', queryId);
		file && formData.append('file', file);
		queryComment && formData.append('comment', queryComment);
		props.lenderId && formData.append('lender_id', props.lenderId);
		PrentqueryId && formData.append('parent_query_id', PrentqueryId);

		let response;

		if (props.type === 'onboarding-form') {
			response = await addOnboardingQuery({
				type: props.userType,
				body: formData,
				subType:
					props.userType === 'admin'
						? 'affiliate/detail/save-partner-query'
						: 'detail/save-partner-query',
			});
		} else {
			response = await LenderUgroService.addLeadQuery(
				props.userType,
				formData,
				props.userType === 'admin' ? 'co-lender' : ''
			);
		}
		try {
			if (response?.data?.status?.code === 1) {
				getQueryList();
				toast.success(
					response?.data?.messages?.[0]?.[0] || message.SAVED_SUCCESS
				);
				inputRef.current.value = '';
				setFile(null);
				setQueryComment('');
				setQueryId('');
			} else {
				let error = response?.data?.messages?.error;
				toast.error(error?.form?.[0] || error?.others?.[0] || message.ERROR_FORM);
			}
			setLoading(false);
			return response;

		} catch (error) {
			setLoading(false);
			error = response?.data?.messages?.error;
			toast.error(error?.form?.[0] || error?.others?.[0] || message.ERROR_FORM);
		}

	};
	const getStatusOptions = () => {
		const queryOptions = {
			initiator: [
				{
					value: 1,
					label: 'Open',
				},
				{
					value: 3,
					label: 'Close',
				},
			],
			responder: [
				{
					value: 1,
					label: 'Open',
				},
				{
					value: 2,
					label: 'Resolve',
				},

			],
		};


		if (tableData?.[0]?.created_by === state?.user?.user_id) {
			return queryOptions['initiator'].map(({ value, label }) => (
				<option value={value}>{label}</option>
			));
		} else {
			return queryOptions['responder'].map(({ value, label }) => (
				<option value={value}>{label}</option>
			));
		}
	};
	return (
		<div>
			<div class='overflow-auto'>
				<div
					className='query-reply-container border' style={{ height: "320px", maxHeight: '500px' }}>
					<Table
						checkbox={false}
						columns={getColumns(props.type, state)}
						rows={tableData}
						loading={loading}
						type='querydocuments'
						tableExtraClass={''}
						reloadTable={getQueryList}
					>
						{Boolean(!tableData.length && !loading) && <TableNoRecords />}
					</Table>
				</div>
				{/*  this is the Reply Models  */}
				<form onSubmit={handleSubmit}>
					<div class='container-fluid border mt-1'>
						<div class='col-12 my-1'>
							<div class='form-group comentBox'>
								<label>Comment <tt>*</tt></label>
								<textarea
									style={{ height: '100px' }}
									class='form-control custom-form-control'
									value={queryComment}
									onChange={setComment}
									required
									maxLength={500}
								></textarea>
							</div>
						</div>
						<div class='row'>
							<div class='col-2 mx-2'>
								<div class='form-group ml-2'>
									<label>Status</label>
									<div class='row'>
										<div class='col-12 '>
											<select
												class='form-control custom-form-control'
												onChange={(e) => {
													SetQueryStatus(e.target.value);
													SetQueryStatusshow(e.target.value);
												}}
												value={QueryStatusShow}
											>
												{getStatusOptions()}
											</select>
										</div>
									</div>
								</div>
							</div>
							<div class='col-3'>
								<div class='form-group'>
									<label>Upload File</label>
									<div class='row'>
										<div class='col-12 queryReplyUpload'>
											<input
												ref={inputRef}
												file={file}
												onChange={(e) => setFile(e.target.files[0])}
												type='file'
												className='form-control'
											/>
										</div>
									</div>
								</div>
							</div>
							<div class='col-4 mx-2'>
							</div>
							<div class='col mb-3'>
								<div class='mt-4 d-flex mx-2'>
									<button type='submit' class='btn btn-custom mx-1'>
										Reply
									</button>
									<button
										type='button'
										class='btn btn-custom  lm-2 mx-1'
										onClick={HandlePageBack}
									>
										Back
									</button>
								</div>
							</div>
						</div>
					</div>
				</form>
			</div>

		</div>
	);
};

export default ReplyQuery;

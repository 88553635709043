import React from 'react';
import ReactTooltip from 'react-tooltip';
const Hint = ({ text, id, place }) => {

	return (
        text ?
        <>
            <ReactTooltip id={id} place={place} multiline={true}>
            </ReactTooltip>
            <i
                data-tip={text}
                data-for={id}
                className='fa fa-info-circle'
                aria-hidden='true'
            ></i>
        </>
        :
        <div></div>
	);
};

export default Hint;

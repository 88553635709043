import React from 'react';
import { Context } from '../../../../config/store';
import LenderUgroService from '../../../../services/lender';

export const numDifferentiation = (value) => {
	var val = Math.abs(value);
	return (val / 10000000).toFixed(2);
};
const TotalAssignedPortfolio = ({ lenders, productTypes }) => {
	const { state } = React.useContext(Context);
	const [productType, setProductType] = React.useState('');
	const [lender, setLender] = React.useState('');
	const [totalAssignedData, setTotalAssignedData] = React.useState({
		count: 100,
		amount: 1233,
	});

	const [loading, setLoading] = React.useState(false);
	const [error, setError] = React.useState(false);

	const getDashboardTotalAssigned = async () => {
		setLoading(true);
		setError(false);
		const response = await LenderUgroService.getDashboardTotalAssigned(
			state.userType,
			state.userType === 'admin' ? 'co-lender' : '',
			productType,
			state.userType === 'admin' ? lender : null
		);
		if (response?.data?.status?.code === 1) {
			setTotalAssignedData(response.data.data.total_assigned_portfolio);
			setLoading(false);
		} else {
			setError(true);
			setLoading(false);
		}
	};

	React.useEffect(() => {
		if (
			productType &&
			((state.userType === 'admin' && lender) || state.userType !== 'admin')
		) {
			getDashboardTotalAssigned();
		}
	}, [productType, lender]);

	React.useEffect(() => {
		if (productTypes.length > 0) {
			setProductType(productTypes[0].value);
		}

		if (lenders.length > 0) {
			setLender(lenders[0].value);
		}
	}, [productTypes, lenders]);

	return (
		<div className='lender-portfolio-pie'>
			<div>
				<div className='chart-heading'>Portfolios/Lender</div>
				<div className='d-flex align-items-center'>
					{state.userType === 'admin' && (
						<select
							value={lender}
							onChange={(e) => setLender(e.target.value)}
							className='form-control mr-1'
							placeholder=''
						>
							{(lenders || []).map((type) => (
								<option key={type.value} value={type.value}>
									{type.label}
								</option>
							))}
						</select>
					)}
					<select
						value={productType}
						onChange={(e) => setProductType(e.target.value)}
						className='form-control'
						placeholder=''
					>
						{(productTypes || []).map((type) => (
							<option key={type.value} value={type.value}>
								{type.label}
							</option>
						))}
					</select>
				</div>
			</div>
			<div className='chart-container'>
				{/* {loading && <div className='loader' />}
                {(error || Object.keys(totalAssignedData).length === 0) && !loading && <div className="text-center">
                        {error ? "Failed to load chart data" : "No data found"}
                    </div>
                } */}
				{Object.keys(totalAssignedData).length > 0 && (
					<>
						<div className='row no-gutters totalAssignedCounts'>
							<div className='col-6'>
								<h1 className='text-center'>#{totalAssignedData.count}</h1>
							</div>
							<div className='col-6'>
								<h1 className='text-center crores'>
									{numDifferentiation(totalAssignedData.amount)}{' '}
									<span>Crores</span>
								</h1>
							</div>
						</div>
						<div className='mt-4 totalAssignedText'>
							Total number of portfolios assigned in the month of February and
							its Amount.
						</div>
					</>
				)}
			</div>
		</div>
	);
};

export default TotalAssignedPortfolio;

import React from 'react';
import { useRouter } from '../../hooks/useRouter';
import { saveUser, updateUser } from '../../services/manage-user';
import message from '../../constant/message';
import { toast } from 'react-toastify';
const Actions = ({
	type,
	setErrors,
	f_name,
	l_name,
	mobile_no,
	role_id,
	parent_id,
	is_active,
	email,
	branch,
	product,
	emp_code,
	business_type,
	user_id,
	viewType,
}) => {
	const router = useRouter();

	const [loading, setLoading] = React.useState(false);
	const createUser = async () => {
		let body = {
			f_name,
			l_name,
			mobile_no,
			role_id,
			parent_id: parent_id?.value || '',
			is_active,
			emp_code,
			business_type,
			email,

			product:
				viewType === 'admin'
					? product.map((productItem) => ({
							prod_id: productItem.prod_id,
							max_limit: productItem.max_limit,
							min_limit: productItem.min_limit,
					  }))
					: product.map((productItem) => ({
							prod_id: productItem.value,
					  })),

			user_id,
		};
		setLoading(true);
		const response = await saveUser(body, viewType);
		if (response?.status?.code === 1) {
			toast.success(message.SAVED_SUCCESS);
			router.navigate(`/${viewType ? viewType : 'admin'}/manage-users`);
		} else {
			setErrors(response?.messages?.error);
			toast.error(response?.messages?.error?.form?.[0] || message.ERROR_FORM);
		}
		setLoading(false);
	};
	const saveUserDetails = async () => {
		setLoading(true);
		let body = {
			f_name,
			l_name,
			mobile_no,
			role_id,
			parent_id: parent_id?.value || '',
			is_active,
			email,
			emp_code,
			business_type,

			product:
				viewType === 'admin'
					? product.map((productItem) => ({
							prod_id: productItem.prod_id,
							max_limit: productItem.max_limit,
							min_limit: productItem.min_limit,
					  }))
					: product.map((productItem) => ({
							prod_id: productItem.value,
					  })),
			user_id,
		};

		const response = await updateUser(body, viewType);
		if (response?.status?.code === 1) {
			toast.success(message.UPDATE_SUCCESS);
			router.navigate(`/${viewType ? viewType : 'admin'}/manage-users`);
		} else {
			let error = response?.messages?.error;
			toast.error(error?.form?.[0] || error?.others?.[0] || message.ERROR_FORM);
			setErrors(error);
		}
		setLoading(false);
	};

	return (
		<div className='content d-flex'>
			{type === 'UPDATE_USER' && (
				<button
					disabled={loading}
					onClick={saveUserDetails}
					type='submit'
					className='btn btn-custom'
				>
					{loading ? <div className='loader' /> : 'Save Details'}
				</button>
			)}
			{type === 'ADD_USER' && (
				<button
					disabled={loading}
					type='submit'
					onClick={createUser}
					className='btn btn-custom'
				>
					{loading ? <div className='loader' /> : 'Create'}
				</button>
			)}
			{type !== 'VIEW_USER' && (
				<button
					onClick={() =>
						router.navigate(`/${viewType ? viewType : 'admin'}/manage-users`)
					}
					type='submit'
					className='btn btn-close ml-2'
				>
					Cancel
				</button>
			)}
			{/* {type === 'UPDATE_USER' && (
				<button type='submit' className='btn btn-close ml-2'>
					Block User
				</button>
			)} */}
		</div>
	);
};

export default Actions;

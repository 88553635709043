import React from 'react';
const VolumeLinkedDsaItem = ({
	product_code,
	vol_amt,
	vli_percent,
	comm_amt,
	loan_type
}) => {
	return (
		<tr>
			<td>{product_code}</td>
			<td>{loan_type}</td>
			<td width='25%'>{parseFloat(vol_amt).toLocaleString('en-IN')} INR</td>
			<td>{vli_percent}%</td>
			{/* <td>{vli_percent}</td> */}

			<td width='25%'>{parseFloat(comm_amt).toLocaleString('en-IN')} INR</td>
		</tr>
	);
};

export default VolumeLinkedDsaItem;

/**
 * @author Dhruv Sachdeva <dhruvsach18@gmail.com>
 */

// extensions.js  offers multiple solution to your extension problem
// you can get all the extensions available in our map (obj)
// you can get common name resolver to a particular extension
// get list of extensions in array

// Map of file extensions we currenly offer
// and we can resolve

let map = {};

// zip extensions
map['zip'] = 'zip';
map['gz'] = 'zip';
map['tar'] = 'zip';
map['7z'] = 'zip';

// excel extensions
map['xls'] = 'excel';
map['xlt'] = 'excel';
map['xlm'] = 'excel';
map['xla'] = 'excel';
map['xlw'] = 'excel';
map['ods'] = 'excel';
map['ots'] = 'excel';
map['sdc'] = 'excel';
map['stc'] = 'excel';
map['uos'] = 'excel';
map['xlsx'] = 'excel';
map['xlsm'] = 'excel';
map['xltx'] = 'excel';
map['xltm'] = 'excel';
map['xlsb'] = 'excel';
map['xlam'] = 'excel';
map['fods'] = 'excel';
map['numbers'] = 'excel';

// doc extensions
map['doc'] = 'doc';
map['dot'] = 'doc';
map['wbk'] = 'doc';
map['odt'] = 'doc';
map['ott'] = 'doc';
map['odm'] = 'doc';
map['rtf'] = 'doc';
map['oxt'] = 'doc';
map['docx'] = 'doc';
map['docm'] = 'doc';
map['dotx'] = 'doc';
map['dotm'] = 'doc';
map['docb'] = 'doc';
map['pages'] = 'doc';

// powerpoint extensions
map['ppt'] = 'powerpoint';
map['pot'] = 'powerpoint';
map['pps'] = 'powerpoint';
map['ppa'] = 'powerpoint';
map['pptx'] = 'powerpoint';
map['ppsx'] = 'powerpoint';
map['pptm'] = 'powerpoint';
map['potx'] = 'powerpoint';
map['potm'] = 'powerpoint';
map['sldx'] = 'powerpoint';
map['sldm'] = 'powerpoint';
map['ppsm'] = 'powerpoint';
map['ppaam'] = 'powerpoint';
map['key'] = 'powerpoint';

// access extensions
map['accdb'] = 'access';
map['accde'] = 'access';
map['accdt'] = 'access';
map['accdr'] = 'access';

// html extensions
map['html'] = 'html';
map['htm'] = 'html';

// text extensions
map['text'] = 'text';
map['txt'] = 'text';
map['octet-stream'] = 'msg';
map['plain'] = 'text';
// audio extensions

map['aa'] = 'audio';
map['aac'] = 'audio';
map['aax'] = 'audio';
map['act'] = 'audio';
map['aiff'] = 'audio';
map['alac'] = 'audio';
map['amr'] = 'audio';
map['ape'] = 'audio';
map['au'] = 'audio';
map['awb'] = 'audio';
map['dct'] = 'audio';
map['dss'] = 'audio';
map['dvf'] = 'audio';
map['flac'] = 'audio';
map['gsm'] = 'audio';
map['iklax'] = 'audio';
map['ivs'] = 'audio';
map['m4a'] = 'audio';
map['m4b'] = 'audio';
map['m4p'] = 'audio';
map['mmf'] = 'audio';
map['mp3'] = 'audio';
map['mpc'] = 'audio';
map['msv'] = 'audio';
map['nmf'] = 'audio';
map['ogg'] = 'audio';
map['oga'] = 'audio';
map['mogg'] = 'audio';
map['opus'] = 'audio';
map['ra'] = 'audio';
map['rm'] = 'audio';
map['raw'] = 'audio';
map['rf64'] = 'audio';
map['sln'] = 'audio';
map['tta'] = 'audio';
map['voc'] = 'audio';
map['vox'] = 'audio';
map['wav'] = 'audio';
map['wma'] = 'audio';
map['wv'] = 'audio';
map['webm'] = 'audio';
map['8svx'] = 'audio';
map['cda'] = 'audio';

// video extensions

map['webm'] = 'video';
map['mkv'] = 'video';
map['flv'] = 'video';
map['vob'] = 'video';
map['ogv'] = 'video';
map['ogg'] = 'video';
map['drc'] = 'video';
map['gifv'] = 'video';
map['mng'] = 'video';
map['avi'] = 'video';
map['mts'] = 'video';
map['m2ts'] = 'video';
map['ts'] = 'video';
map['mov'] = 'video';
map['qt'] = 'video';
map['wmv'] = 'video';
map['yuv'] = 'video';
map['rm'] = 'video';
map['rmvb'] = 'video';
map['asf'] = 'video';
map['amv'] = 'video';
map['mp4'] = 'video';
map['m4p'] = 'video';
map['m4v'] = 'video';
map['mpg'] = 'video';
map['mp2'] = 'video';
map['mpeg'] = 'video';
map['mpe'] = 'video';
map['mpv'] = 'video';
map['mpg'] = 'video';
map['mpeg'] = 'video';
map['m2v'] = 'video';
map['m4v'] = 'video';
map['svi'] = 'video';
map['3gp'] = 'video';
map['3g2'] = 'video';
map['mxf'] = 'video';
map['roq'] = 'video';
map['nsv'] = 'video';
map['flv'] = 'video';
map['f4v'] = 'video';
map['f4p'] = 'video';
map['f4a'] = 'video';
map['f4b'] = 'video';

// pdf extensions
map['pdf'] = 'pdf';

// image extensions
map['tif'] = 'img';
map['tiff'] = 'img';
map['bmp'] = 'img';
map['jpg'] = 'img';
map['jpeg'] = 'img';
map['gif'] = 'img';
map['png'] = 'img';
map['eps'] = 'img';
map['ai'] = 'img';
map['psd'] = 'img';
map['xcf'] = 'img';
map['cdr'] = 'img';
map['raw'] = 'img';
map['cr2'] = 'img';
map['nef'] = 'img';
map['orf'] = 'img';
map['sr2'] = 'img';
map['webp'] = 'img';

//  get extension and resolver map
export function extensions() {
	return map;
}
// resolve a extension common name
export function getExtension(extension) {
	return map[extension];
}

// extensions list available
export function extensionsList() {
	return Object.values(map);
}

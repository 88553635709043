import React, { useEffect, useState, useContext, } from 'react';
import { useRouter } from '../../../hooks/useRouter';
import { DRFdetailGet, DRFdetailsave } from '../../../services/drfDetail';
import { useLocation } from 'react-router';
import { useParams } from 'react-router';
import { toast } from 'react-toastify';
import { Context } from '../../../config/store';
import Modal from '../../../components/Modal';
import RenderFile from '../../../pages/OnboardingForm/RenderFile';
import { viewOnboardingDoc } from '../../../services/onboarding-form-service';
import { getFormattedDateFromString } from '../../../common/date';
import { downloadFile } from '../../../common/download';
import { downloadKycDoc } from '../../../services/manage-affiliate';
import './drf.css'
import TableNoRecords from '../../../components/Common/Table/TableNoRecords';
import Table from '../../../components/Common/Table';
import BtCaseColumns from './BtCaseColumns';

const DRF = () => {
	const { state } = React.useContext(Context);
	const [type, setType] = React.useState('ADD_USER');
	const [errors, setErrors] = React.useState(false);
	const leadId = useParams().id;
	const [loader, setStatus] = React.useState(false);
	const [bankNameshow, setbankNmaeshow] = React.useState('');
	const [bankAccidshow, setbankAccidshow] = React.useState('');
	const [AccNameshow, setAccnameshow] = React.useState('');
	const [bankBranchshow, setBankBranchshow] = React.useState('');
	const [Remetenceshow, setRementenceshow] = React.useState('');
	const [TypeOfAccountshow, setTypOfAccountshow] = React.useState('');
	const [IFSCshow, setIFSCshow] = React.useState('');
	const [amountshow, setAmountshow] = React.useState('');
	const [Dateshow, setDateshow] = React.useState('');
	const [loading, setLoading] = React.useState(false);
	const [FindData, SetFindData] = useState({})
	const [DrfDetail, SetDrfDetail] = useState();
	const [supDrfDetail, SetSupDrfDetail] = useState();
	const [filename, setFilename] = useState()
	const [file_id, setFileId] = useState()
	const [supporting_file, setsupporting_file] = useState()
	const [modalState, setModalState] = React.useState(false);
	const [fileUrl, setFileUrl] = React.useState('');
	const [responseData, setResponseData] = React.useState(null);
	const [response, setResponse] = useState([])
	const [transType, setTransType] = useState({});
	const [isBTCase, setIsBTCase] = useState(0)
	const [btBankCaseResponse, setBtBankCaseResponse] = useState([])
	const [btCustomerCaseResponse, setBtCustomerCaseResponse] = useState([])

	const toggleModal = () => {
		setModalState(!modalState);
		if (!matchMedia) setFileUrl('');
	};
	const GetDrfDetail = async (leadId) => {
		setStatus(true);
		const response = await DRFdetailGet(leadId);
		setResponse(response)
		if (response?.http?.code === 200) {
			setIsBTCase(response?.data?.is_bt_case)
			let btdata = response?.data?.bt_drf_detail;
			let btBankData = [];
			let btCustomerData = [];
			(btdata || [])?.map((item) => {
				if (item?.disbursal_to == 1) {
					btBankData.push(item)
				} else {
					btCustomerData.push(item)
				}
			})
			setBtBankCaseResponse(btBankData);
			setBtCustomerCaseResponse(btCustomerData);
			SetDrfDetail(response?.data?.lead_drf_detail);
			SetSupDrfDetail(response?.data?.supplier_drf_detail);
			setTransType(response?.data?.trans_types || {});
			setTypOfAccountshow(response?.data?.lead_drf_detail?.acc_type);
			setbankNmaeshow(response?.data?.lead_drf_detail?.bank_name);
			setAccnameshow(response?.data?.lead_drf_detail?.acc_name);
			setbankAccidshow(response?.data?.lead_drf_detail?.bank_acc_id);
			setBankBranchshow(response?.data?.lead_drf_detail?.bank_branch);
			setRementenceshow(response?.data?.lead_drf_detail?.remittance_type);
			setAmountshow(response?.data?.lead_drf_detail?.amount);
			setIFSCshow(response?.data?.lead_drf_detail?.ifsc);
			setFilename(response?.data?.lead_drf_detail?.file?.file_name)
			setFileId(response?.data?.lead_drf_detail?.file?.file_id)
			setFileUrl(response?.data?.lead_drf_detail?.file?.file_path)
			let Date = response?.data?.lead_drf_detail?.date;
			let date = Date ? Date.split('-').reverse().join('-') : '---';
			SetFindData(response)
			setDateshow(date);
			setLoading(false);
		} else {
			if (response?.http?.code == 400) {
				setErrors(true);
				SetDrfDetail(null);
				SetSupDrfDetail(null);
			}
		}
		setStatus(false);
	};
	useEffect(() => {
		GetDrfDetail(leadId);
	}, []);
	const downloadDoc = async () => {
		try {
			const response = await downloadKycDoc(
				state.userType,
				file_id ? file_id : supporting_file
			);
			downloadFile(response.data);
		} catch (err) {
			toast.error('failed to display');
		}
	};

	const acc_types = {
		'01': 'Saving',
		'02': 'Current',
		'03': 'Casa',
		'04': 'CC',
	}

	const viewFile = async () => {
		try {
			const response = await viewOnboardingDoc({
				type: state.userType,
				fileId: file_id,
			});
			setResponseData(response.data);
			let url = window.URL.createObjectURL(response.data);
			setFileUrl(url);
			toggleModal();
		} catch (err) {
			toast.error('Download failed');
		}
	};
	return (
		<>
			{/*  New DRF details design  */}
			{loader ? <div className='Tableloader' style={{ margin: "100px auto" }}></div> : <div>
				{DrfDetail && !isBTCase &&
					<div className='mt-2'>
						<div style={{ borderRadius: "5px", width: "100%", border: "1px solid #CBCBCB" }}>
							<div className='border p-2 ' style={{ backgroundColor: "#F8F8F8", borderRadius: "5px 5px 0 0 " }}>
								<h5 className='content-title '>DRF details</h5>
							</div>
							<div className='row p-2'>
								<div className='col-md-2'>
									<p className='detailsRightLabel'>{'ACCOUNT TYPE'}</p>
									<p className='detailsRightValue-drf'>{acc_types[DrfDetail?.ACT_TYPE] ?? DrfDetail?.ACT_TYPE}</p>
								</div>
								<div className='col-md-2'>
									<p className='detailsRightLabel'>{'DISBURSED AMOUNT'}</p>
									<p className='detailsRightValue-drf'>{DrfDetail?.AMOUNT}</p>
								</div>
								<div className='col-md-2'>
									<p className='detailsRightLabel'>{'BANK ACCOUNT NUMBER'}</p>
									<p className='detailsRightValue-drf'>{DrfDetail?.BANK_ACCOUNT_ID}</p>
								</div>
								<div className='col-md-3'>
									<p className='detailsRightLabel'>{'NAME'}</p>
									<p className='detailsRightValue-drf'> {DrfDetail?.NAME}</p>
								</div>
								<div className='col-md-3'>
									<p className='detailsRightLabel'>{'BANK NAME'}</p>
									<p className='detailsRightValue-drf'> {DrfDetail?.BANK_NAME}</p>
								</div>

								<div className='col-md-2 mt-3'>
									<p className='detailsRightLabel'>{'DRF DATE'}</p>
									<p className='detailsRightValue-drf'> {DrfDetail?.DRF_DATE}</p>
								</div>
								<div className='col-md-2 mt-3'>
									<p className='detailsRightLabel'>{'IFSC CODE'}</p>
									<p className='detailsRightValue-drf'> {DrfDetail?.IFSC_CODE}</p>
								</div>
								<div className='col-md-2 mt-3'>
									<p className='detailsRightLabel'>{'TRANSACTION TYPE'}</p>
									<p className='detailsRightValue-drf'> {transType[DrfDetail?.TRANS_TYPE] ?? DrfDetail?.TRANS_TYPE}</p>
								</div>
								<div className='col-md-3 mt-3'>
									<p className='detailsRightLabel'>{'BANK BRANCH'}</p>
									<p className='detailsRightValue-drf'>  {DrfDetail?.CR_BANK_BRANCH}</p>
								</div>
							</div>
						</div>
					</div>}
				{!DrfDetail &&
					<div className='mt-2'>
						<div style={{ borderRadius: "5px", width: "100%", border: "1px solid #CBCBCB" }}>
							<div className='border p-2 ' style={{ backgroundColor: "#F8F8F8", borderRadius: "5px 5px 0 0 " }}>
								<h5 className='content-title '>No DRF details</h5>
							</div>
							<div className='Error-msg-drf' style={{ 'margin-top': '10px' }}>No DRF Details</div>
						</div>
					</div>}

				{(supDrfDetail) &&
					<div className='mt-2'>
						<div style={{ borderRadius: "5px", width: "100%", border: "1px solid #CBCBCB" }}>
							<div className='border p-2 ' style={{ backgroundColor: "#F8F8F8", borderRadius: "5px 5px 0 0 " }}>
								<h5 className='content-title '>Supplier DRF details</h5>
							</div>
							<div className='row p-2'>
								<div className='col-md-2'>
									<p className='detailsRightLabel'>{'ASSET AMOUNT'}</p>
									<p className='detailsRightValue-drf'>{supDrfDetail?.asset_amt}</p>
								</div>
								<div className='col-md-2'>
									<p className='detailsRightLabel'>{'DISBURSED AMOUNT'}</p>
									<p className='detailsRightValue-drf'>{supDrfDetail?.finance_amount}</p>
								</div>
								<div className='col-md-2'>
									<p className='detailsRightLabel'>{'BANK ACCOUNT NUMBER'}</p>
									<p className='detailsRightValue-drf'> {supDrfDetail?.bank_acc_id}</p>

								</div>
								<div className='col-md-3'>
									<p className='detailsRightLabel'>{'NAME'}</p>
									<p className='detailsRightValue-drf'> {supDrfDetail?.acc_name}</p>
								</div>
								<div className='col-md-3'>
									<p className='detailsRightLabel'>{'BANK NAME'}</p>
									<p className='detailsRightValue-drf'>  {supDrfDetail?.bank_name}</p>
								</div>

								<div className='col-md-2 mt-3'>
									<p className='detailsRightLabel'>{'DRF DATE'}</p>
									<p className='detailsRightValue-drf'> {supDrfDetail?.date}</p>
								</div>
								<div className='col-md-2 mt-3'>
									<p className='detailsRightLabel'>{'IFSC CODE'}</p>
									<p className='detailsRightValue-drf'>{supDrfDetail?.ifsc}</p>
								</div>
								<div className='col-md-2 mt-3'>
									<p className='detailsRightLabel'>{'TRANSACTION TYPE'}</p>
									<p className='detailsRightValue-drf'> {supDrfDetail?.txn_type}</p>
								</div>
								<div className='col-md-3 mt-3'>
									<p className='detailsRightLabel'>{'SUPPLIER ADDRESS'}</p>
									<p className='detailsRightValue-drf'> {supDrfDetail?.address}</p>
								</div>
								<div className='col-md-3 mt-3'>
									<p className='detailsRightLabel'>{'SUPPLIER CITY'}</p>
									<p className='detailsRightValue-drf'> {supDrfDetail?.city}</p>
								</div>
							</div>
						</div>
					</div>}

				{(btBankCaseResponse?.length > 0) &&
					<div style={{ marginTop: "10px" }}>
						<div style={{ borderRadius: "5px", width: "100%", border: "1px solid #CBCBCB" }}>
							<div className='border p-2' style={{ backgroundColor: "#F8F8F8", borderRadius: "5px 5px 0 0 " }}>
								<h5 className='content-title'>BT DRF Details</h5>
							</div>
							<div className="bt_drf_table">
								<Table
									checkbox={false}
									columns={BtCaseColumns}
									rows={btBankCaseResponse}
									loading={loading}
									reloadTable={GetDrfDetail}
								>
									{Boolean(!btBankCaseResponse?.length && !loading) && <TableNoRecords />}
								</Table>
							</div>
						</div>
					</div>}
			</div>}
			{(btBankCaseResponse.length == 0 && btCustomerCaseResponse.length == 0 && isBTCase) ?
				<div className='mt-5'>
					<div style={{ borderRadius: "5px", width: "100%", border: "1px solid #CBCBCB" }}>
						<div className='border p-2' style={{ backgroundColor: "#F8F8F8", borderRadius: "5px 5px 0 0 " }}>
							<h5>BT Bank DRF Details</h5>
						</div>
						<div className='Error-msg-drf' style={{ 'margin-top': '10px' }}>No Bank DRF Details Found</div>
					</div>
				</div>
				:
				null
			}
			{(btCustomerCaseResponse?.length > 0) &&
				<div style={{ marginTop: "40px" }}>
					<div style={{ borderRadius: "5px", width: "100%", border: "1px solid #CBCBCB" }}>
						<div className='border p-2' style={{ backgroundColor: "#F8F8F8", borderRadius: "5px 5px 0 0 " }}>
							<h5>BT Customer DRF Details</h5>
						</div>
						<div className="bt_drf_table">
							<Table
								checkbox={false}
								columns={BtCaseColumns}
								rows={btCustomerCaseResponse}
								loading={loading}
								reloadTable={GetDrfDetail}
								editBtDrf={true}
							>
								{Boolean(!btCustomerCaseResponse.length && !loading) && <TableNoRecords />}
							</Table>
						</div>
					</div>
				</div>}
		</>
	);
};

export default DRF;




import React from 'react';
import { productLeads } from '../../services/dashboard';
import objectToQueryParams from '../../common/objectToQueryParams';
import { monthNames, generateArrayOfYears } from '../../common/date';
import { objectsForEach } from '../../common/objectToArray';
import splitUnderscoreToCaptialise from '../../common/splitUnderscoreToCaptialise';
import ApexCharts from 'react-apexcharts';

import { productWiseLeads } from '../../constant/options';
const ProductWiseLeads = ({
	type,
	businessType,
	userTypes,
	loanType,
	productType,
	campaignCode,
	leadType,
	sourceId,
	stateId,
	cityId
}) => {
	const [month, setMonth] = React.useState(new Date().getMonth() + 1);
	const [year, setYear] = React.useState(new Date().getFullYear());
	const [data, setData] = React.useState([]);
	const [options, setOptions] = React.useState(productWiseLeads);
	React.useEffect(() => {
		loadProductWiseLeads();
	}, [
		month,
		year,
		type,
		businessType,
		userTypes,
		loanType,
		productType,
		campaignCode,
		leadType,
		sourceId,
		stateId,
		cityId
	]);

	const loadProductWiseLeads = async () => {
		const queryParams = objectToQueryParams({
			year,
			month,
			business_type: (businessType || []).map(
				business_type => business_type.value
			),
			user_ids: (userTypes || []).map(user_id => user_id.value),
			loan_type: (loanType || []).map(loan_type => loan_type.value),
			product_type: (productType || []).map(product_type => product_type.value),
			campaign_code: (campaignCode || []).map(camp_code => camp_code.value),
			lead_type: leadType?.value || '',
			source_id: sourceId?.value || '',
			state_id: stateId?.value || '',
			city_id: cityId?.value || ''
		});
		const response = await productLeads({ type, queryParams });
		if (response?.status?.code === 1) {
			let data = objectsForEach(response?.data?.product_leads);
			let labels = (response?.data?.product_leads?.lead_approved || []).map(
				({ label }) => splitUnderscoreToCaptialise(label)
			);
			data = data.map(({ id, value }) => ({
				name: splitUnderscoreToCaptialise(id),
				data: value.map(({ y }) => y)
			}));
			setData(data);
			let tempOptions = {
				...productWiseLeads,
				xaxis: {
					categories: labels
				}
			};
			setOptions(tempOptions);
		}
	};
	return (
		<div className='panel'>
			<div className='head_top'>
				<div className='row  align-items-center '>
					<div className='col-6'>
						<h5>Product Wise {type === 'dsa' ? 'Applications' : 'Leads'}</h5>
					</div>
					<div className='col-3 px-0 pr-2'>
						<select
							value={month}
							onChange={e => setMonth(e.target.value)}
							className='form-control custom-textfield height w-100'
						>
							{monthNames.map((month, index) => (
								<option value={index + 1} key={index}>
									{month}
								</option>
							))}
						</select>
					</div>
					<div className='col-3 px-0 pr-2'>
						<select
							value={year}
							onChange={e => setYear(e.target.value)}
							className='form-control custom-textfield height w-100'
						>
							{generateArrayOfYears(5).map((year, i) => (
								<option value={year} key={i}>
									{year}
								</option>
							))}
						</select>
					</div>
				</div>
			</div>
			<ApexCharts options={options} series={data} type='bar' height={240} />
		</div>
	);
};

export default ProductWiseLeads;

import React, { Component } from 'react';
import { default as ReactSelect } from 'react-select';
import { components } from 'react-select';
import ReactTooltip from 'react-tooltip';

const Option = (props) => {
	return (
		<div>
			<components.Option {...props}>
				{props.isMulti && (
					<>
						<input
							type='checkbox'
							checked={props.isSelected}
							onChange={() => null}
						/>{' '}
					</>
				)}
				{/* <div className='tooltipSelect' data-title={(props?.value?.prod_types !== '' || props?.value?.prod_types !== null || props?.value?.prod_types !== undefined) ? props?.value?.prod_types : null}> */}
					<label title={props?.value?.prod_types}>{props.label}</label>
				{/* </div> */}
			</components.Option>
		</div>
	);
};

const customStyles = {
	control: (provided, state) => ({
		...provided,
		background: "#fff",
		borderColor: "#9e9e9e",
		minHeight: "25px",
		boxShadow: state.isFocused ? null : null,
		padding: "0px 2px"
	}),

	valueContainer: (provided, state) => ({
		...provided,
		minHeight: "20px",
		padding: "0px 2px"
	}),

	input: (provided, state) => ({
		...provided,
		margin: "0px"
	}),
	indicatorSeparator: (state) => ({
		display: "none",
		marginTop: '3px'
	}),
	indicatorsContainer: (provided, state) => ({
		...provided,
		height: "0px",
		marginTop: '12px'
	})
};
/**
 *
 * @param {Array} options
 * @param {String} label
 * @param {String} selectClass
 * @param {Function} update
 */

const Select = ({
	options = [],
	label,
	update,
	portalTarget,
	isMulti = true,
	className,
	...rest
}) => {
	const portalTargetNode = document.getElementById(portalTarget);
	return (
		<div
			className={`form-group ${className}`}
			id='select-container'
			data-toggle='popover'
			data-trigger='focus'
			data-content='Please selecet account(s)'
		>
			<label>{label}</label>
			<ReactSelect
				options={options}
				isMulti={isMulti}
				closeMenuOnSelect={!isMulti}
				hideSelectedOptions={false}
				components={{
					Option,
				}}
				allowSelectAll={true}
				{...rest}
				menuPortalTarget={portalTargetNode || null}
				styles={customStyles}
			/>
		</div>
	);
};

export default Select;

import React, { useEffect, useState } from 'react';
import './style.css';
import { toast } from "react-toastify";
import Table from '../../../components/Common/Table';
import Pagination from '../../../components/Pagination';
import PortfolioContainer from '../../../components/Portfolio/PortfolioContainer';
import LenderUgroService from '../../../services/lender';
import { Context } from '../../../config/store';
import AssignLenderLeadModal from '../../../components/Portfolio/AssignLenderLeadModal';
import TableNoRecords from '../../../components/Common/Table/TableNoRecords';
import portfolio from '../../../constant/tableHeaders/portfolio';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import LenderName from '../TableItems/LenderName';
import LeadStatus from '../TableItems/LeadStatus';
import LafId from '../TableItems/LafId';
import LeadType from '../TableItems/LeadType';

let isOutside = false;

function GlobalSearch() {
  const location = useLocation()
  let lafData = location?.search?.split('?')?.[1]

  // localStorage.setItem('lafData', lafData)
  let globalLafId = sessionStorage.getItem('globalLafId')

  const [tableData, setTableData] = useState({
    columns: portfolio,
    rows: [],
  });
  const [page, setCurrentPage] = useState(1);
  const [pagination, setPagination] = useState({});
  const [selectedIds, setSelectedIds] = React.useState([]);
  const [modalState, setModalState] = React.useState(false);
  const [assignCoLenders, setAssignCoLender] = React.useState([]);
  const { state, dispatch } = React.useContext(Context);
  const [loading, setLoading] = React.useState(false);
  const [lafId, setLafId] = React.useState(globalLafId)
  const [lafId2, setLafId2] = React.useState(globalLafId)
  // const [lafId, setLafId] = React.useState(location?.search.split('?')?.[1] ? location?.search.split('?')?.[1] : '')
  const searchFilterRef = React.useRef()
  // const [persistLaf, setPersistLaf] = useState(localStorage.getItem('lafData'))
  const getGlobalSearch = async (type = 'search') => {
    try {
      setLoading(true);
      const response = await LenderUgroService.getGlobalSearchList({
        type: state.userType,
        laf_id: type === 'search' ? lafId : ''
      });
      const data = response.data.data?.cl_leads;
      setTableData({
        ...tableData,
        rows: data?.data[0] || [],
      });
      // console.log("data ", data?.data[0]);
      setLoading(false);
      setCurrentPage(page);
      setPagination(response?.data?.data.cl_leads.pagination);
    } catch (err) {
      setLoading(false);
      toast.error(
        err.response?.data?.messages?.error?.fields.laf_id?.[0] ||
        err.response?.data?.messages?.error?.form?.[0] ||
        err.response?.data?.messages?.error?.others?.[0] ||
        "Some fields may be missing or have incorrect data."
      );
    }
  };

  const getGlobalSearch2 = () => {
    if (lafId2)
      setLafId(lafId2);
    else
      toast.info('Please enter a LAF/LAN to search')
  }

  const toggleModal = () => {
    getAssignLenders();
    setModalState(!modalState);
  };

  const reloadTable = () => {
    setSelectedIds([]);
    getGlobalSearch();
  };

  useEffect(() => {
    if (lafId) {
      getGlobalSearch();
    }
  }, [lafId])

  useEffect(() => {
    setLafId(globalLafId)
    setLafId2(globalLafId)
    return () => {
      // if (!lafId) {
      localStorage.setItem('selectedColender', null);
      sessionStorage.removeItem('globalLafId')
      // }
    }
  }, [globalLafId])

  const getAssignLenders = async () => {
    const response = await LenderUgroService.getCoLenders({
      type: state.userType,
      leadId: selectedIds[0].cl_lead_id,
    });
    return response;
  };

  React.useEffect(() => {
    dispatch({
      type: 'selectedColender',
      selectedColender: `/portfolio/global-search${globalLafId ? '?laf_id=${globalLafId}' : ''}`,
    });
    localStorage.setItem('selectedColender', `/portfolio/global-search${globalLafId ? '?laf_id=${globalLafId}' : ''}`);
  }, [globalLafId]);

  return (
    <PortfolioContainer ref={searchFilterRef}>
      <div className="globalSearchContainer">
        <div className='px-2 subHeaderbar w-100'>
          Search result for LAF/LAN  : {lafId}
        </div>
        {!tableData?.rows?.cl_lead_id ?
          <div className="d-flex justify-content-center align-items-center" style={{ height: '80px', fontSize: '14px', fontWeight: '600' }}>
            {loading ?
              <div className="Tableloader"></div>
              :
              'No Data Found'
            }
          </div>
          :
          <div className="row px-2 my-2">
            <div className="col-md-4 d-flex justify-content-start align-items-center my-1">
              <div className="detailsRightLabel">
                LAF ID :
              </div>
              <div className="detailsRightValue ml-1">
                {tableData?.rows?.laf_id ?
                  <Link
                    to={`/portfolio/view/${tableData?.rows?.cl_lead_id}/details`}
                  >
                    {tableData?.rows?.laf_id}
                  </Link>
                  :
                  "NA"
                }
              </div>
            </div>
            <div className="col-md-4 d-flex justify-content-start align-items-center my-1">
              <div className="detailsRightLabel">
                LAN ID :
              </div>
              <div className="detailsRightValue ml-1">
                {tableData?.rows?.lan_id ?
                  <Link
                    to={`/portfolio/view/${tableData?.rows?.cl_lead_id}/details`}
                  >
                    {tableData?.rows?.lan_id}
                  </Link>
                  :
                  "NA"
                }
              </div>
            </div>
            <div className="col-md-4 d-flex justify-content-start align-items-center my-1">
              <div className="detailsRightLabel">
                CLM Type :
              </div>
              <div className="detailsRightValue ml-1">
                {tableData?.rows?.lead_type ? <LeadType row={tableData?.rows} /> : "NA"}
              </div>
            </div>
            <div className="col-md-4 d-flex justify-content-start align-items-center my-1">
              <div className="detailsRightLabel">
                Customer Name :
              </div>
              <div className="detailsRightValue ml-1">
                {tableData?.rows?.cust_name ? tableData?.rows?.cust_name : "NA"}
              </div>
            </div>
            <div className="col-md-4 d-flex justify-content-start align-items-center my-1">
              <div className="detailsRightLabel">
                Program Name :
              </div>
              <div className="detailsRightValue ml-1">
                {tableData?.rows?.prod_name ? tableData?.rows?.prod_name : "NA"}
              </div>
            </div>
            <div className="col-md-4 d-flex justify-content-start align-items-center my-1">
              <div className="detailsRightLabel">
                Loan Amount :
              </div>
              <div className="detailsRightValue ml-1">
                {tableData?.rows?.loan_amount ? tableData?.rows?.loan_amount : "NA"}
              </div>
            </div>
            <div className="col-md-4 d-flex justify-content-start align-items-center my-1">
              <div className="detailsRightLabel">
                Disbursed Date :
              </div>
              <div className="detailsRightValue ml-1">
                {tableData?.rows?.disbursed_date ? tableData?.rows?.disbursed_date : "NA"}
              </div>
            </div>
            {/* <div className="col-md-4 d-flex justify-content-start align-items-center my-1">
              <div className="detailsRightLabel">
                Lender Eligibility :
              </div>
              <div className="detailsRightValue globalELegibleLender ml-1">
                <LenderName row={tableData?.rows} />
              </div>
            </div> */}
            <div className="col-md-4 d-flex justify-content-start align-items-center my-1">
              <div className="detailsRightLabel">
                Status :
              </div>
              <div className="detailsRightValue globalSearchButton ml-1">
                <LeadStatus row={tableData?.rows} />
              </div>
            </div>
          </div>
        }
      </div>

      {/* <div className="my-2">
        {pagination?.total_pages ? (
          <Pagination
            disabled={loading}
            setPage={setCurrentPage}
            total_pages={pagination.total_pages}
            total={pagination.total}
            showTotal={true}
            currentPageNumber={page}
          />
        ) : (
          ''
        )}
      </div> */}

      {modalState && selectedIds.length && (
        <AssignLenderLeadModal
          bulkAssign={true}
          modelState={modalState}
          toggleModal={toggleModal}
          colenders={assignCoLenders}
          selectedIds={selectedIds}
          reloadTable={reloadTable}
        />
      )}
    </PortfolioContainer>
  );
}

export default GlobalSearch;
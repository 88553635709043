import { useEffect, useState } from 'react';
import objectToQueryParams from '../../../../common/objectToQueryParams';
import GraphContainer from '../../../../components/Portfolio/GraphContainer';
import { bg } from '../../../../constant/options';
import LenderUgroService from '../../../../services/lender';

function LenderPending({ userType, financialYear, graphType }) {
	const [loading, setLoading] = useState(true);
	const [queryParams, setQueryParams] = useState({});
	const [error, setError] = useState(false);
	const [series, setSeries] = useState([]);
	const [options, setOptions] = useState({
		chart: {
			type: 'donut',
		},
		plotOptions: {
			pie: {
				startAngle: -90,
				endAngle: 90,
				offsetY: 10,
			},
		},
		grid: {
			padding: {
				bottom: -200,
			},
		},
		responsive: [
			{
				breakpoint: 480,
				options: {
					chart: {
						width: 200,
					},
					legend: {
						position: 'bottom',
					},
				},
			},
		],
		legend: {
			show: true,
			position: 'bottom',
		},
		dataLabels: {
			formatter: function (val, opts) {
				return opts.w.config.series[opts.seriesIndex];
			},
		},
		colors: bg.map(({ color }) => color),
	});
	const getDashboardLenderPending = async () => {
		setLoading(true);
		setError(false);
		try {
			const response = await LenderUgroService.dashboardLenderPending3({
				type: userType,
				params: queryParams,
				subType: userType == 'admin' ? 'co-lender' : '',
			});
			if (response?.data?.status?.code === 1) {
				const categories = response?.data?.data?.labels;

				setOptions((previous) => {
					return {
						...previous,
						labels: categories,
					};
				});
				let seriesData = response?.data?.data?.series;

				setSeries(seriesData);
			}
		} catch (err) {
			setError();
			console.log(err);
		}
		setLoading(false);
	};

	useEffect(() => {
		getDashboardLenderPending();
	}, [queryParams]);

	useEffect(() => {
		if (financialYear || graphType) {
			const params = {};
			if (financialYear) params['fin_year'] = financialYear?.value;
			if (graphType) params['graph_type'] = graphType?.value;
			setQueryParams(params);
		}
	}, [financialYear, graphType]);
	return (
		<GraphContainer
			height={240}
			options={options}
			loading={loading}
			series={series}
			type={'donut'}
			error={error}
		/>
	);
}

export default LenderPending;

import { useEffect, useState } from 'react';
import objectToQueryParams from '../../../../common/objectToQueryParams';
import GraphContainer from '../../../../components/Portfolio/GraphContainer';
import { bg } from '../../../../constant/options';
import LenderUgroService from '../../../../services/lender';

function LenderPending({ userType, financialYear, graphType }) {
	const [loading, setLoading] = useState(true);
	const [queryParams, setQueryParams] = useState({});
	const [error, setError] = useState(false);
	const [series, setSeries] = useState([]);
	const [options, setOptions] = useState({
		chart: {
			type: 'bar',
		},
		plotOptions: {
			bar: {
				dataLabels: {
					position: 'top',
				},
			},
		},
		dataLabels: {
			enabled: true,
			offsetY: -20,
			style: {
				fontSize: '12px',
				colors: ['#5A5B79'],
			},
		},
		stroke: {
			show: true,
			width: 1,
			colors: ['#fff'],
		},
		tooltip: {
			shared: true,
			intersect: false,
		},
		legend: {
			show: true,
			position: 'bottom',
		},
		colors: bg.map(({ color }) => color),
	});
	const getDashboardLenderPending = async () => {
		setLoading(true);
		setError(false);
		try {
			const response = await LenderUgroService.dashboardLenderPending2({
				type: userType,
				params: queryParams,
				subType: userType == 'admin' ? 'co-lender' : '',
			});
			if (response?.data?.status?.code === 1) {
				const categories = response?.data?.data?.map((item) => item.label);

				setOptions((previous) => {
					return {
						...previous,
						xaxis: {
							categories: categories,
						},
					};
				});
				let seriesData = [
					{
						name: 'DA',
						data: response?.data?.data?.map((item) => item.DA),
					},
					{
						name: 'Option 2',
						data: response?.data?.data?.map((item) => item['Option 2']),
					},
					{
						name: 'Option 1',
						data: response?.data?.data?.map((item) => item['Option 1']),
					},
				];
				setSeries(seriesData);
			}
		} catch (err) {
			setError();
			console.log(err);
		}
		setLoading(false);
	};

	useEffect(() => {
		getDashboardLenderPending();
	}, [queryParams]);

	useEffect(() => {
		if (financialYear || graphType) {
			const params = {};
			if (financialYear) params['fin_year'] = financialYear?.value;
			if (graphType) params['graph_type'] = graphType?.value;
			setQueryParams(params);
		}
	}, [financialYear, graphType]);
	return (
		<GraphContainer
			height={240}
			options={options}
			loading={loading}
			series={series}
			type={'bar'}
			error={error}
		/>
	);
}

export default LenderPending;

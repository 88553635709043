import React, { useState, useEffect } from 'react';
import Modal from '../Modal';
import LenderUgroService from '../../services/lender';
import MasterDataService from '../../services/master-data';
import message from '../../constant/message';
import { toast } from 'react-toastify';
import {
	addOnboardingQuery,
	GetQueryCild,
} from '../../services/onboarding-form-service';
import ViewQuery from '../../Views/Portfolio/ViewPortfolio/QueryList/ViewQuery';
import { Context } from '../../config/store';
import { objectsForEach } from '../../common/objectToArray';
const AddQueryModal = ({
	// state,
	toggleModal,
	leadId,
	userType,
	queryOptions: options = [],
	globalState,
	lenderId,
	type,
	parentQueryId,
	viewQuery,
}) => {
	let inputRef = React.useRef(null);
	const [file, setFile] = React.useState(null);
	const [queryComment, setQueryComment] = React.useState('');
	const [loading, setLoading] = React.useState(false);
	const [queryId, setQueryId] = React.useState('');
	const [queryFor, setQueryFor] = React.useState(1);
	const [queryOptions, setQueryOptions] = React.useState([]);
	const [lenderList, SetlenderList] = React.useState([]);
	const { state } = React.useContext(Context);
	const [PriorityStatus, SetPriorityStatus] = React.useState(1);
	const [childlenderList, SetChildLenderList] = useState([]);
	const [ChilList, SetChildList] = useState('');

	
	//  const lenderId = 0;

	const formSubmitHandler = (e) => {
		e.preventDefault();
		if (queryComment !== '' && !loading) {
			addQuery();
		}
	};
	const setComment = (e) => {
		let comment = e.target.value.replace(/[<>]+/g, '');
		setQueryComment(comment);
	};
	const setQueryIdHandler = (e) => {
		let queryType = queryOptions.find((type) => {
			return type.name === e.target.value;
		});
		if (queryType) {
			setQueryId(queryType.id);
		}
	};
	const changeQueryType = (e) => {
		let queryType = (globalState?.config?.query_type || []).find((query) => {
			return query.name === e.target.value;
		});
		if (queryType) {
			setQueryId(queryType.id);
		}
	};

	const addQuery = async () => {
		setLoading(true);
		const formData = new FormData();
		if (type === 'onboarding-form') {
			leadId &&
				formData.append(
					'part_id',
					globalState.userType === 'affiliate'
						? globalState.user.part_id
						: leadId
				);
		} else {
			leadId && formData.append('cl_lead_id', leadId);
			!parentQueryId && queryFor && formData.append('query_for', queryFor);
			!parentQueryId &&
				PriorityStatus &&
				formData.append('priority', PriorityStatus);
			parentQueryId && formData.append('parent_query_id', parentQueryId);
			ChilList && formData.append('child_user_id', ChilList);
		}

		queryId && formData.append('query_type', queryId);
		file && formData.append('file', file);
		queryComment && formData.append('comment', queryComment);
		lenderId && formData.append('lender_id', lenderId); // if admin selected admin then key will 0

		let response;

		if (type === 'onboarding-form') {
			response = await addOnboardingQuery({
				type: userType,
				body: formData,
				subType:
					userType === 'admin'
						? 'affiliate/detail/save-partner-query'
						: 'detail/save-partner-query',
			});
		} else {
			response = await LenderUgroService.addLeadQuery(
				userType,
				formData,
				userType === 'admin' ? 'co-lender' : ''
			);
		}
		if (response?.data?.status?.code === 1) {
			toast.success(
				response?.data?.messages?.[0]?.[0] || message.SAVED_SUCCESS
			);
			inputRef.current.value = '';
			setFile(null);
			setQueryComment('');
			setQueryId('');
			toggleModal(true);
		} else {
			let error = response?.data?.messages?.error;
			toast.error(error?.form?.[0] || error?.others?.[0] || message.ERROR_FORM);
		}
		setLoading(false);
		return response;
	};

	const getQueryOptions = async () => {
		const response = await MasterDataService.getProductList(
			userType,
			userType === 'admin' ? 'co-lender' : ''
		);
		if (response?.data?.status?.code === 1) {
			setQueryOptions(response.data.data.query_type);
		}
	};

	React.useEffect(() => {
		getQueryOptions();
	}, []);

 

	const GetChild_lander = async (param) => {
		const response = await GetQueryCild(param);
		let Data = response.data;
	 
		let arr = [];
		Data.length > 0  && Data?.map((ele, ind) => {
			arr.push({ label: ele.full_name, value: ele.user_id });
		});
		// console.log(arr)
		SetChildLenderList(arr);
	};

	useEffect(() => {
		GetChild_lander(queryFor);
	}, [queryFor]);

	//  getting the lender List
	const getlenders = async () => {
		const response = await MasterDataService.getLenderListByLeadId(
			state.userType,
			'co-lender',
			leadId,
			'pullback'
		);

		let responseList = objectsForEach(
			response.data.data.lender_list,
			'STRING_VAL'
		);
		SetlenderList(responseList);
	};

	React.useEffect(() => {
		getlenders();
	}, []);

	return (
		<Modal state={state} closeModal={toggleModal} style={{ width: '450px' }} removeBorderSpace={true}>
			<div className="modal-header-container">
				<div className="modal-title">Add New Query</div>
				<div className="closeModalView" onClick={toggleModal}>
					<span className="closeModalIcon">x</span>
				</div>
			</div>
			<div className="p-4 modalData">
				{viewQuery && (
					<>
						<ViewQuery queryId={parentQueryId} addQuery={addQuery} />
					</>
				)}
				<form onSubmit={formSubmitHandler}>
					<>
						<div class='form-group'>
							<label>Category</label>
							<div class='row'>
								<div class='col-12'>
									<select
										class='form-control custom-form-control'
										onChange={setQueryIdHandler}
									>
										<option value={''}>Select</option>

										{(options.length ? options : queryOptions).map(
											(option, i) => {
												return <option key={i}>{option.name}</option>;
											}
										)}
									</select>
								</div>
							</div>
						</div>
						{state.userType === 'admin' && (
							<>
								<div class='form-group'>
									<label>Query For</label>
									<div class='row'>
										<div class='col-12'>
											<select
												class='form-control custom-form-control'
												onChange={(e) => setQueryFor(e.target.value)}
												value={queryFor}
											>
												<option value={0}>Admin</option>
												{(lenderList.length ? lenderList : lenderList).map(
													(lenderList, i) => {
														return (
															<option value={lenderList.id}>{lenderList.value}</option>
														);
													}
												)}
											</select>
										</div>
									</div>
								</div>
								<div class='form-group'>
									<label>Lender list</label>
									<div class='row'>
										<div class='col-12'>
											<select
												class='form-control custom-form-control'
												onChange={(e) => SetChildList(e.target.value)}
												value={ChilList}
											>
												<option value={0}>Select Lender</option>
												{(childlenderList.length
													? childlenderList
													: childlenderList
												).map((childlenderList, i) => {
													return (
														<option key={i} value={childlenderList.value}>
															{childlenderList.label}
														</option>
													);
												})}
											</select>
										</div>
									</div>
								</div>
							</>
						)}

						<div class='form-group'>
							<label>Priority</label>
							<div class='row'>
								<div class='col-12'>
									<select
										class='form-control custom-form-control'
										onChange={(e) => SetPriorityStatus(e.target.value)}
										value={PriorityStatus}
									>
										<option value={1}>Low</option>
										<option value={2}>Medium</option>
										<option value={3}>High</option>
									</select>
								</div>
							</div>
						</div>

						<div class='form-group'>
							<label>Upload File</label>
							<div class='row'>
								<div class='col-12'>
									<input
										ref={inputRef}
										file={file}
										onChange={(e) => setFile(e.target.files[0])}
										type='file'
										className='form-control'
									/>
								</div>
							</div>
						</div>
						<div class='form-group'>
							<label>
								Comment <tt>*</tt>
							</label>
							<textarea
								class='form-control custom-form-control'
								cols='4'
								rows='4'
								value={queryComment}
								onChange={setComment}
								maxLength={500}
							></textarea>
						</div>
						<div class='d-flex align-items-center justify-content-center'>
							<button
								type='submit'
								class='btn btn-custom'
								disabled={!queryComment}
							>
								{loading ? <div className='loader' /> : 'OK'}
							</button>
							<button
								type='button'
								class='btn btn-custom ml-2'
								data-dismiss='modal'
								onClick={toggleModal}
							>
								Cancel
							</button>
						</div>
					</>
				</form>
			</div>
		</Modal>
	);
};

export default AddQueryModal;

import { monthNames } from '../common/date';

export const productWiseLeads = {
	chart: {
		type: 'bar',
		height: 350,
		stacked: true
	},
	plotOptions: {
		bar: {
			horizontal: true
		}
	},
	stroke: {
		width: 1,
		colors: ['#fff']
	},

	xaxis: {
		categories: []
		//   labels: {
		//     formatter: function (val) {
		//       return val + "K"
		//     }
		//   }
	},

	// tooltip: {
	//   y: {
	//     formatter: function (val) {
	//       return val + "K"
	//     }
	//   }
	// },
	fill: {
		opacity: 1
	},
	legend: {
		position: 'top',
		horizontalAlign: 'left',
		offsetX: 40
	}
};

export const sharedLeadPerformance = {
	chart: {
		height: 350,
		type: 'area'
	},
	dataLabels: {
		enabled: false
	},
	stroke: {
		curve: 'smooth'
	},
	xaxis: {
		// type: 'datetime',
		// allowDecimals: false,
		categories: monthNames.map(name => name.substr(0, 3)),
		labels: {
			rotation: 0
		}
	}
};

export const bg = [
	//colorSet Array

	{ color: '#093076', bg: 'blue-bg' },
	{ color: '#f0874e', bg: 'orange-bg' },
	{ color: '#2f68c3', bg: 'light-blue-bg' },
	{ color: '#f79942', bg: 'yellow-bg' },
	{ color: '#8f9496', bg: 'light-bg' },
	{ color: '#B43210', bg: 'maroon-bg' },
	{ color: '#7A2E90', bg: 'purple-bg' },
	{ color: '#63A11B', bg: 'green-bg' },
	{ color: '#d5a4cf', bg: 'light-purple' },
	{ color: '#e59572', bg: 'light-orange' },
	{ color: '#fad3cf', bg: 'skin-bg' },
	{ color: '#a39391', bg: 'light-brown-bg' },
	{ color: '#f1ac9d', bg: 'peach-bg' },
	{ color: '#663300', bg: 'dark-brown-bg' },
	{
		color: '#b8d00a',
		bg: 'light-green-bg'
	},
	{ color: '#525564', bg: 'dark-gray-bg' },
	{ color: '#E5446D', bg: 'cherry-pink-bg' },
	{
		color: '#fffadf',
		bg: 'light-white-bg'
	},
	{ color: '#4FD5D6', bg: 'sky-blue-bg' },
	{ color: '#1c1c20', bg: 'black-bg' }
];

export const sharedLeadStatus = {
	chart: {
		height: 350,
		type: 'bar'
	},
	colors: bg.map(({ color }) => color),
	plotOptions: {
		bar: {
			columnWidth: '45%',
			distributed: true
		}
	},
	dataLabels: {
		enabled: false
	},
	legend: {
		show: false
	},
	xaxis: {
		categories: [],
		labels: {
			style: {
				colors: bg.map(({ color }) => color),
				fontSize: '12px'
			}
		}
	}
};

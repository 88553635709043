import React from 'react';
import { useParams } from 'react-router';
import Block from './Block';
import LenderUgroService from '../../../services/lender';
import MasterDataService from '../../../services/master-data';
import { Context } from '../../../config/store';
import { objectsForEach } from '../../../common/objectToArray';
import Select from '../../../components/Common/Input/Select';
import './index.css';
import isStringArray from '../../../common/isStringArray';

const Details = () => {
	const { state } = React.useContext(Context);
	const [firstHalfLeadList, setFirstHalfLeadList] = React.useState([]);
	const [secondHalfLeadList, setSecondHalfLeadList] = React.useState([]);
	const [error, setError] = React.useState(false);
	const [errorMessage, setErrorMessage] = React.useState('');
	const [loading, setLoading] = React.useState(false);
	const [detailsData, setDetailsData] = React.useState(null);
	const [selectedDetailData, setSelectedDetailData] = React.useState([])
	const [detailsHeader, setDetailsHeader] = React.useState('Applicant Detail')
	const [finalDetailsData, setFinalDetailsData] = React.useState([])

	const leadId = useParams().id;

	const getLeadDetail = async () => {
		setLoading(true);
		const response = await LenderUgroService.getPortfolioNewDetail({
			type: state.userType,
			leadId,
		});

		let details = [];
		let detailsKeys = [];

		Object.entries(response.data.data.details).forEach(([key, value]) => {
			if (typeof value === 'object' && key !== 'cl_lead') {
				details.push({ key: key, list: value });
				detailsKeys.push(key)
			}
		});

		let newFirstData = [{ key: details[0].key, list: details[0].list }]
		setDetailsHeader("Applicant Detail")
		setSelectedDetailData(newFirstData)
		setFinalDetailsData(details)
		setDetailsData(detailsKeys)

		// const middleIndex = Math.ceil(details.length / 2);
		// setFirstHalfLeadList(details.splice(0, middleIndex));
		// setSecondHalfLeadList(details.splice(-middleIndex));
		setLoading(false);
	};

	React.useLayoutEffect(() => {
		getLeadDetail();
	}, []);

	const splitUnderscoreToCaptialise = (word) => {
		return word.replace(/(^|_)(\w)/g, function ($0, $1, $2) {
			return ($1 && ' ') + $2.toUpperCase();
		});
	};

	const splitUnderscoreToUpercase = (word) => {
		return word.toUpperCase().replace(/(^|_)(\w)/g, function ($0, $1, $2) {
			return ($1 && ' ') + $2.toUpperCase();
		});
	};

	const filterBlockForLender = (blockArray) =>
		state.userType === 'lender'
			? blockArray.filter((block) => block.list)
			: blockArray;

	const handleLedtItemClick = (item, itemIndex) => {

		setDetailsHeader(splitUnderscoreToCaptialise(item))

		finalDetailsData?.map((itemValue, index) => {
			let data = []
			if (itemIndex === index) {
				data.push(itemValue)
				setSelectedDetailData(data)
			}
		})
	}

	const detailRenderer = (list, title) => {

		if (isStringArray(list))
			return list.map((item, i) => (
				<div className="row">
					<div className="col-3">
						<p>{item}</p>
					</div>
				</div>
			));
		if (list) {
			if (Array.isArray(list)) {
				return (
					<>
						{list.length > 0 ? list.map((item, i) => {
							return (
								<div className={(list.length > 1 && (i < (list.length - 1))) ? "newDetailsBlock" : ""}>
									{list.length > 1 ?
										<>
											{list.length > 1 && (
												<div className="multi-block-heading font-weight-bold">
													{title + " - " + (i + 1)}
												</div>
											)}
											<div className="row">
												{Object.entries(item).map((newItem, i) => {
													return (
														<div className='col-md-3'>
															<p className='detailsRightLabel'>{splitUnderscoreToUpercase(newItem[0])}</p>
															<p className='detailsRightValue mb-1'>{newItem[1] ? newItem[1] : "---"}</p>
														</div>
													);
												})}
											</div>

										</>
										:
										<div className='row'>
											{Object.entries(item).map((newItem, i) => {
												return (
													<div className="col-md-3">
														<p className='detailsRightLabel'>{splitUnderscoreToUpercase(newItem[0])}</p>
														<p className='detailsRightValue'>{newItem[1] ? newItem[1] : "---"}</p>

													</div>
												)
											})
											}
										</div>
									}

								</div>
							);
						}) : "N/A"}
					</>
				);
			} else {
				return (
					<div className=" row">
						{
							Object.entries(list).map((newItem, i) => {
								return (
									<div className='col-md-3'>
										<p className='detailsRightLabel'>{splitUnderscoreToUpercase(newItem[0])}</p>
										<p className='detailsRightValue mb-2'>{newItem[1] ? newItem[1] : "---"}</p>
									</div>
								);
							})
						}
					</div>
				)
			}
		} else {
			return <>
				N/A
			</>
		}
	};

	return (
		<>
			<div className='row view-container' id=''>
				{loading ? (
					<div className='col-sm-12'>
						<div className='Tableloader' style={{ margin: "10% auto auto auto" }}></div>
					</div>
				) : (
					<>
						<div className='col-md-2'>
							<div className='detailsLeftPart'>
								{detailsData?.map((item, index) => {
									return (
										<p
											className={`detailsLeftItem ${detailsHeader === splitUnderscoreToCaptialise(item) ? 'activeDetail' : ''}`}
											onClick={() => { handleLedtItemClick(item, index) }}
										>
											<div className="d-flex justify-content-between">
												{splitUnderscoreToCaptialise(item)}
												{detailsHeader === splitUnderscoreToCaptialise(item) && <span className='pr-2'><i class="fa fa-angle-right fa-lg" aria-hidden="true"></i></span>}

											</div>
										</p>
									)
								})}
							</div>
						</div>
						<div className='col-md-10'>
							<div className='detailsRightPart '>
								<div className='detailsHeader'>
									{detailsHeader}
								</div>
								{selectedDetailData?.length > 0 ?
									(selectedDetailData || [])?.map((detail, i) => {
										return (
											<div className="p-2 detailsRightDataContainer">
												{detailRenderer(detail.list, splitUnderscoreToCaptialise(detail.key))}
											</div>
										)
									})
									:
									<div className='NoDetailsFound'>No Details Found</div>
								}

							</div>
						</div>
					</>
					// <>
					// 	<div className='col-md-6'>
					// 		{filterBlockForLender(firstHalfLeadList).map((detail, i) => {
					// 			return (
					// 				<Block
					// 					key={i}
					// 					titleKey={detail.key}
					// 					title={splitUnderscoreToCaptialise(detail.key)}
					// 					list={detail.list}
					// 				/>
					// 			);
					// 		})}
					// 	</div>
					// 	<div className='col-md-6'>
					// 		{filterBlockForLender(secondHalfLeadList).map((detail, i) => {
					// 			return (
					// 				<Block
					// 					key={i}
					// 					titleKey={detail.key}
					// 					title={splitUnderscoreToCaptialise(detail.key)}
					// 					list={detail.list}
					// 				/>
					// 			);
					// 		})}
					// 	</div>
					// </>
				)}
			</div>
		</>
	);
};

export default Details;

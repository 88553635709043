import React from 'react';
import { Context } from '../config/store';
import { getCookie } from '../services/cookie';
// import { useRouter } from '../hooks/useRouter';

const NotFound = () => {
	const token = getCookie(btoa('token'));
	// const router = useRouter();

	// const handleNavigation = () => {
	// 	token ? router.navigate("/portfolio/home") : router.navigate("/lender/login")
	// }
	return (
		<div className='d-flex h-100 flex-column align-items-center justify-content-center flex-1'>
			<img src={"../pageNotFound.png"} alt="Page Not Found" height={"90%"} width={"50%"} />
			{token ?
				<a href='/portfolio/home' className='btn mb-3 btn-blueButton '>
					<i className="fa fa-home fa-lg" aria-hidden="true"></i> &nbsp; Back to Home
				</a>
			:
				<a href='/admin/login' className='btn mb-3 btn-blueButton '>
					<i className="fa fa-user fa-lg" aria-hidden="true"></i> &nbsp; Back to Login
				</a>
			}
			{/* <button
				onClick={handleNavigation}
				type='button'
				className='btn btn-custom'
			>
				{token ? "Back to Home" : "Back to Login"}
			</button> */}
		</div>
	);
};

export default NotFound;

import React from 'react';
import { getFullDate } from '../../common/date';
import { Link } from 'react-router-dom';
import LeadStatus from './LeadStatus';
import LeadHistory from './LeadHistory';
import { textState } from '../../constant/constants';
const LeadListing = ({
	userType,
	lead_city,
	lead_mobile,
	lead_email,
	lead_name,
	lead_id,
	created_at,
	setSelectedLeadId,
	toggleModal,
	lead_assigned,
	partner,
	lead_type,
	state,
	status,
	assigned_product,
	lead_status,
	source_type,
	updated_at,
	reloadTable,
	leadId,
	toggleLeadActionModalState,
	app,
	setSelectedLead
}) => {
	const [showDropDown, setDropDownStatus] = React.useState(false);
	const [statusModalState, setStatusModalState] = React.useState(false);
	const [historyModalState, setHistoryModalState] = React.useState(false);

	const assignLead = (e, lead_id) => {
		e.preventDefault();
		setDropDownStatus(false);
		toggleModal(true);
		setSelectedLeadId([lead_id]);
	};
	const checkHasPermission = checkPermission => {
		if (state.is_super_admin === 1) return true;
		const permission = state.permission.find(
			permission => permission === checkPermission
		);

		if (permission) return true;
		else return false;
	};
	const openLeadModal = e => {
		e.preventDefault();
		setDropDownStatus(false);
		toggleLeadActionModalState(true);
		setSelectedLead({ lead_id, lead_type });
	};
	const changeSelectedValue = e => {
		if (e.target.checked) {
			setSelectedLeadId([...leadId, lead_id]);
		} else {
			const filteredLeads = (leadId || []).filter(lead => lead !== lead_id);
			setSelectedLeadId(filteredLeads);
		}
	};
	return (
		<>
			{statusModalState && (
				<LeadStatus
					toggleModal={setStatusModalState}
					type={userType}
					state={statusModalState}
					reloadTable={reloadTable}
					leadId={lead_id}
				/>
			)}
			{historyModalState && (
				<LeadHistory
					leadId={lead_id}
					type={userType}
					state={historyModalState}
					toggleModal={setHistoryModalState}
				/>
			)}
			<tr>
				<td>
					{' '}
					{state.userType === 'admin' && (
						<div>
							<input
								checked={(leadId || []).find(lead => lead === lead_id)}
								onChange={changeSelectedValue}
								type='checkbox'
								id='flexCheckChecked'
							/>
						</div>
					)}
				</td>
				<td>LD{lead_id}</td>
				<td>{lead_name}</td>
				{userType !== 'admin' && (
					<>
						<td>{lead_email}</td>
						<td>{lead_mobile}</td>
					</>
				)}

				<td>{app?.loan_info?.loan_amt?.toLocaleString('en-IN') || ''}</td>

				<td>{lead_assigned?.assigned_to || 'Not assigned'}</td>
				{state.userType === 'admin' && <td>{assigned_product?.prod_name}</td>}
				{/* <td>{lead_city}</td> */}

				<td>
					<span className='text-success'>
						{status}{' '}
						{lead_status?.status_child_name &&
							`( ${lead_status?.status_child_name || ''} )`}
					</span>
				</td>
				<td>
					{lead_type && (
						<span className={textState[lead_type].value}>
							{textState[lead_type].label}
						</span>
					)}
				</td>
				{state.userType === 'admin' && <td>{source_type}</td>}
				<td>{getFullDate(new Date(created_at))}</td>
				{state.userType === 'admin' && (
					<td>{getFullDate(new Date(updated_at))}</td>
				)}
				<td>
					<div className={`dropdown ${showDropDown && 'show'}`}>
						<button
							// onBlur={() => setDropDownStatus(false)}
							onClick={() => setDropDownStatus(!showDropDown)}
							className='btn dropdown-toggle'
							type='button'
							data-toggle='dropdown'
							aria-haspopup='true'
							aria-expanded={showDropDown}
						>
							<span className='text-gray'>Action</span>
						</button>
						<div
							className={`dropdown-menu min-width-auto ${showDropDown &&
								'show'}`}
							aria-labelledby='dropdownMenuButton'
						>
							<Link className='dropdown-item' to={`/lead/${lead_id}`} href='#'>
								View
							</Link>
							<Link className='dropdown-item' to={`/lead/edit/${lead_id}`}>
								Edit
							</Link>
							{userType === 'admin' && (
								<a
									onClick={e => {
										e.preventDefault();
										setDropDownStatus(false);
										setStatusModalState(true);
									}}
									className='dropdown-item'
									href='#'
								>
									Status
								</a>
							)}
							{userType === 'admin' && (
								<a onClick={openLeadModal} className='dropdown-item' href='#'>
									Lead Status
								</a>
							)}
							<a
								className='dropdown-item'
								onClick={e => {
									e.preventDefault();
									setHistoryModalState(true);
									setDropDownStatus(false);
								}}
								href='#'
							>
								History
							</a>
							{userType === 'admin' && checkHasPermission('assign-lead') && (
								<a
									className='dropdown-item'
									onClick={e => assignLead(e, lead_id)}
									href='#'
								>
									Assign
								</a>
							)}
						</div>
					</div>
				</td>
			</tr>
		</>
	);
};

export default LeadListing;

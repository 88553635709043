import React from 'react';
import config from '../../../services/config';
import { objectsForEach } from '../../../common/objectToArray';
const BusinessAddressDetails = ({
	states,
	affiliateKyc,
	setAffiliateKyc,
	view,
	errors
}) => {
	const onChange = (value, type) => {
		setAffiliateKyc(values => ({
			...values,
			[type]: value
		}));
	};
	const [cities, setCities] = React.useState([]);
	const getCityData = async () => {
		const response = await config.getCityByState(affiliateKyc.state);
		if (response?.status?.code === 1) {
			setCities(objectsForEach(response.data.cities, 'STRING_VAL'));
		}
	};
	React.useEffect(() => {
		getCityData();
	}, [affiliateKyc.state]);
	let affiliate_type = parseInt(affiliateKyc.affiliate_type) || 1;
	return (
		<div className='content'>
			<h5>{affiliate_type === 2 ? 'Business' : ''} Address Details</h5>
			<div className='row '>
				<div className='col-md-4'>
					<div className='form-group'>
						<label>
							{' '}
							Address 1 <tt>*</tt>
						</label>
						<input
							disabled={view}
							value={affiliateKyc.address_one}
							onChange={e => onChange(e.target.value, 'address_one')}
							type='text'
							className='form-control'
							placeholder='Address 1'
						/>
						{errors && (
							<p className='mt-2 mb-0 text-danger'>
								{errors?.fields?.address_one?.[0]}
							</p>
						)}
					</div>
				</div>
				<div className='col-md-4'>
					<div className='form-group'>
						<label> Address 2</label>
						<input
							disabled={view}
							value={affiliateKyc.address_two}
							onChange={e => onChange(e.target.value, 'address_two')}
							type='text'
							className='form-control'
							placeholder='Address 2'
						/>
					</div>
				</div>
				<div className='col-md-4'>
					<div className='form-group'>
						<label> Address 3</label>
						<input
							disabled={view}
							value={affiliateKyc.address_three}
							onChange={e => onChange(e.target.value, 'address_three')}
							type='text'
							className='form-control'
							placeholder='Address 3'
						/>
					</div>
				</div>
				<div className='col-md-4'>
					<div className='form-group'>
						<label>
							State <tt>*</tt>
						</label>
						<select
							disabled={view}
							value={affiliateKyc.state}
							onChange={e => onChange(e.target.value, 'state')}
							className='form-control'
						>
							<option>State</option>
							{(states || []).map(({ id, name }) => (
								<option key={id} value={id}>
									{name}
								</option>
							))}
						</select>
						{errors && (
							<p className='mt-2 mb-0 text-danger'>
								{errors?.fields?.state?.[0]}
							</p>
						)}
					</div>
				</div>
				<div className='col-md-4'>
					<div className='form-group'>
						<label>
							{' '}
							City <tt>*</tt>
						</label>
						<select
							disabled={view}
							value={affiliateKyc.city}
							onChange={e => onChange(e.target.value, 'city')}
							className='form-control'
						>
							<option>City</option>
							{(cities || []).map(({ id, value }) => (
								<option key={id} value={value}>
									{id}
								</option>
							))}
						</select>
						{/* <input
							disabled={view}
							value={affiliateKyc.city}
							onChange={e => onChange(e.target.value, 'city')}
							type='text'
							className='form-control'
							placeholder='City'
						/> */}
						{errors && (
							<p className='mt-2 mb-0 text-danger'>
								{errors?.fields?.city?.[0]}
							</p>
						)}
					</div>
				</div>

				<div className='col-md-4'>
					<div className='form-group'>
						<label>
							{' '}
							Pin Code <tt>*</tt>
						</label>
						<input
							disabled={view}
							value={affiliateKyc.zip}
							onChange={e => onChange(e.target.value, 'zip')}
							type='text'
							className='form-control'
							placeholder='Pin Code'
						/>
						{errors && (
							<p className='mt-2 mb-0 text-danger'>
								{errors?.fields?.zip?.[0]}
							</p>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

export default BusinessAddressDetails;

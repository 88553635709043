import React from 'react';
import { Link } from 'react-router-dom';
const PendingAffiliateActions = ({
	showDropDown,
	setDropDownStatus,
	part_id
}) => {
	return (
		<div className={`dropdown ${showDropDown && 'show'}`}>
			<button
				// onBlur={() => setDropDownStatus(false)}
				onClick={() => setDropDownStatus(!showDropDown)}
				className='btn dropdown-toggle ActionBtn'
				type='button'
				data-toggle='dropdown'
				aria-haspopup='true'
				aria-expanded={showDropDown}
			>
				<span className='text-gray'>Action</span>
			</button>
			<div
				className={`dropdown-menu min-width-auto ${showDropDown && 'show'}`}
				aria-labelledby='dropdownMenuButton'
			>
				<Link
					className='dropdown-item'
					to={`/onboarding/details/partner/${part_id}`}
				>
					View / Edit
				</Link>
			</div>
		</div>
	);
};

export default PendingAffiliateActions;

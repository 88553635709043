import React from 'react';
import { leadListing, getLead } from '../services/manage-leads';
import Pagination from '../components/Pagination';
import { Context } from '../config/store';
import LeadListing from '../components/ManageLeads/LeadListing';
import AssignLead from '../components/ManageLeads/AsignLead';
import SearchFilter from '../components/ManageLeads/SearchFilter';
import { downloadFile } from '../common/download';
import { offerLeadList } from '../services/manage-offer';
import { useRouter } from '../hooks/useRouter';
import LeadActionStatus from '../components/ManageLeads/LeadActionStatus';
const ManageLeads = () => {
	const [leads, setLeads] = React.useState([]);
	const [pagination, setPaginationData] = React.useState({});
	const [page, setPage] = React.useState(1);
	const { state } = React.useContext(Context);
	const [queryParams, setQueryParams] = React.useState('');
	const [modalState, toggleModalState] = React.useState(false);
	const [leadId, setSelectedLeadId] = React.useState([]);
	const [selectedLead, setSelectedLead] = React.useState();
	const [offerLeads, setShowOfferLeads] = React.useState(false);
	const [selectAll, setSelectAll] = React.useState(false);
	const [leadActionModalState, setLeadActionModalState] = React.useState(false);
	const [exportLoading, setExportLoading] = React.useState(false);
	const router = useRouter();
	const getLeads = async exportLead => {
		const response = await leadListing({
			type: state.userType,
			page,
			queryParams,
			exportLead: exportLead
		});
		if (response?.status?.code === 1) {
			let leads = response.data?.leads?.data;

			setLeads(leads || []);
			setPaginationData(response.data?.leads?.pagination || {});
		} else if (exportLead) {
			downloadFile(response);
		}
	};
	const selectAllLeads = e => {
		setSelectAll(e.target.checked);
		if (e.target.checked) {
			setSelectedLeadId(leads.map(lead => lead.lead_id));
		} else {
			setSelectedLeadId([]);
		}
	};
	const exportLeads = async () => {
		setExportLoading(true);
		await getLeads(true);
		setExportLoading(false);
	};
	const getOfferLeads = async () => {
		const { partId, offerId } = router.params;
		const body = { part_id: partId, temp_offer_file_id: offerId };
		const response = await offerLeadList(body, { page });
		if (response?.status?.code === 1) {
			let leads = response.data?.leads?.data;
			setLeads(leads || []);
			setPaginationData(response.data?.leads?.pagination || {});
		}
	};

	React.useEffect(() => {
		if (router.pathname.indexOf('offers') !== -1 || offerLeads) {
			if (!offerLeads) {
				setShowOfferLeads(true);
			}
			return getOfferLeads();
		}
		getLeads();
	}, [page, queryParams]);

	return (
		<>
			{state.userType === 'admin' && modalState && (
				<AssignLead
					setSelectAll={setSelectAll}
					leadId={leadId}
					setSelectedLeadId={setSelectedLeadId}
					state={modalState}
					reloadTable={getLeads}
					toggleModal={toggleModalState}
				/>
			)}
			{state.userType === 'admin' && leadActionModalState && (
				<LeadActionStatus
					type={state.userType}
					selectedLead={selectedLead}
					setSelectedLeadId={setSelectedLeadId}
					state={leadActionModalState}
					reloadTable={getLeads}
					toggleModal={setLeadActionModalState}
				/>
			)}
			<>
				<div className='clearfix' />
				{!offerLeads && (
					<SearchFilter
						userType={state.userType}
						setQueryParams={setQueryParams}
					/>
				)}
				{state.userType === 'admin' && (
					<div className='d-flex justify-content-end mt-4'>
						{Boolean(leadId.length) && (
							<button
								onClick={() => toggleModalState(true)}
								type='button'
								className='btn btn-custom'
							>
								Assign
							</button>
						)}

						<button
							disabled={exportLoading}
							onClick={() => exportLeads()}
							type='button'
							className='btn btn-custom ml-3'
						>
							{exportLoading ? <div className='loader' /> : 'Export Leads'}
						</button>
					</div>
				)}
				<div className='panel '>
					<div className='table-responsive '>
						<table className='table table-striped'>
							<thead>
								<tr>
									<th>
										{state.userType === 'admin' && (
											<div>
												<input
													checked={selectAll}
													onChange={selectAllLeads}
													type='checkbox'
													id='flexCheckChecked'
												/>
											</div>
										)}
									</th>
									<th>Lead ID</th>
									<th>Lead Name</th>
									{state.userType !== 'admin' && (
										<>
											<th>Email Address</th>
											<th>Mobile Number</th>
										</>
									)}

									<th>Loan Amount</th>

									<th>Assigned to</th>
									{state.userType === 'admin' && <th>Assigned to Product</th>}
									{/* <th>Branch</th> */}
									<th>Status</th>
									<th>Lead Status</th>
									{state.userType === 'admin' && <th>Source Type</th>}
									<th>Date Added</th>
									{state.userType === 'admin' && <th>Updated at</th>}
									<th>Action</th>
								</tr>
							</thead>
							<tbody>
								{leads.map(lead => (
									<LeadListing
										leadId={leadId}
										reloadTable={getLeads}
										state={state}
										setSelectedLead={setSelectedLead}
										setSelectedLeadId={setSelectedLeadId}
										userType={state.userType}
										toggleLeadActionModalState={setLeadActionModalState}
										toggleModal={toggleModalState}
										{...lead}
									/>
								))}
							</tbody>
						</table>
					</div>
				</div>
				<Pagination
					setPage={setPage}
					total_pages={pagination.total_pages}
					total={pagination.total}
				/>
			</>
		</>
	);
};

export default ManageLeads;

// There is a possible chance for moving it to common
//

import React from "react";
import "../../../css/custom-accordion.css";
import Accordion from "../../../components/Portfolio/Accordion";
import BlockRow from "./BlockRow";
import isStringArray from "../../../common/isStringArray";

const splitUnderscoreToCaptialise = (word) => {
  return word.replace(/(^|_)(\w)/g, function ($0, $1, $2) {
    return (
      ($1 && " ") +
      $2
        .toUpperCase()
        .toLowerCase()
        .split(" ")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ")
    );
  });
};

const detailRenderer = (list, title) => {
  if (isStringArray(list))
    return list.map((item, i) => (
      <div className="row">
        <div className="col-6">
          <p>{item}</p>
        </div>
      </div>
    ));
    if (list) {
      if (Array.isArray(list)) {
        return (
          <>
            {list.length > 0 ? list.map((item, i) => {
              return (
                <div className={list.length > 1 ? "multi-block-rows" : ""}>
                  {list.length > 1 && (
                    <div className="multi-block-heading">
                      {title + " - " + (i + 1)}
                    </div>
                  )}
                  {Object.keys(item).map((k, i) => {
                    return (
                      <BlockRow
                        label={splitUnderscoreToCaptialise(k)}
                        value={item[k]}
                        key={i}
                      />
                    );
                  })}
                </div>
              );
            }) : "N/A"}
          </>
        );
      } else {
        return Object.keys(list).map((k, i) => {
          return (
            <BlockRow
              label={splitUnderscoreToCaptialise(k)}
              value={list[k]}
              key={i}
            />
          );
        });
      }
    } else {
      return <>
        N/A
      </>
    }
};

const Block = ({ title, list, titleKey, isPostDisbursalCase = false }) => {
  return (
    <Accordion
      label={title}
      headerId={"detail_" + titleKey}
      collapseId={"collapse_" + titleKey}
      isPostDisbursalCase={isPostDisbursalCase}
    >
      {detailRenderer(list, title)}
    </Accordion>
    // <div className='block'>
    // 	<div className="title">{title}</div>
    // 	{Object.keys(list).map((k, i) => {
    // 		return <BlockRow label={splitUnderscoreToCaptialise(k)} value={list[k]} key={i}/>
    // 	})}
    // </div>
  );
};

export default Block;

import React from "react";
// import { downloadKycDoc } from "../../../services/manage-affiliate";
// import { downloadFile } from "../../../common/download";
import { toast } from "react-toastify";
// import { Context } from "../../../config/store";
// import { viewOnboardingDoc } from "../../../services/onboarding-form-service";
// import Modal from "../../../components/Modal";
// import message from "../../../constant/message";
// import RenderFile from "../RenderFile";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";
// import Button from "../../../components/Common/Button";
// import LenderUgroService from "../../../services/lender";
import { Tooltip } from "bootstrap";
import ReactTooltip from "react-tooltip";
const Actions = (props) => {
  const location = useLocation()
  return (
    <div className={`row no-gutters ${props.className}`}>
      {/* {location.pathname.includes('conditional-approve') ? <>
        {props?.row?.file && <><Download {...props} />
          <ViewFile {...props} />
        </>
        }
        <Approve {...props} /></>
        :
        <>
          {(props?.row?.file || props?.row?.file_id) &&
            <> <Download {...props} />
              <ViewFile {...props} />
            </>}
        </>
      } */}
    </div >
  );
};

// export const Download = ({ title, row: { file_id, supporting_file } }) => {
//   const location = useLocation()
//   const { state } = React.useContext(Context);
//   const [loading, setState] = React.useState(false);
//   const downloadDoc = async () => {
//     setState(true);
//     try {
//       const response = await downloadKycDoc(
//         state.userType,
//         file_id ? file_id : supporting_file
//       );
//       downloadFile(response.data);
//     } catch (err) {
//       toast.error(message.ERROR);
//     }
//     setState(false);
//   };
//   return (
//     <>
//       <button className='btn btn-custom mx-1'
//         onClick={downloadDoc}
//         disabled={loading}
//         data-for='Download-file'
//         style={{ width: "30px", height: "30px", display: "flex", justifyContent: "center", alignItems: "center" }} data-tip>
//         <i className='fa fa-download'></i>
//       </button>
//       <ReactTooltip id='Download-file'>Download Document</ReactTooltip>
//     </>
//   );
// };

// const ViewFile = ({ row: { file_id, doc_type, ...rest } }) => {
//   const location = useLocation()
//   const { state } = React.useContext(Context);
//   const [modalState, setModalState] = React.useState(false);
//   const [responseData, setResponseData] = React.useState(null);
//   const [fileUrl, setFileUrl] = React.useState("");
//   const [loading, setState] = React.useState(false);
//   const toggleModal = () => {
//     setModalState(!modalState);
//     if (!matchMedia) setFileUrl("");
//   };
//   const viewFile = async () => {
//     setState(true);
//     try {
//       const response = await viewOnboardingDoc({
//         type: state.userType,
//         fileId: file_id,
//       });
//       setResponseData(response.data);
//       let url = window.URL.createObjectURL(response.data);
//       setFileUrl(url);
//       toggleModal();
//     } catch (err) {
//       toast.error(message.ERROR);
//     }
//     setState(false);
//   };
//   return (
//     <>
//       {/* <Modal
//         state={modalState}
//         closeModal={toggleModal}
//         style={fileUrl ? { width: "70%", height: "80%" } : { height: "80%" }}
//       >
//         {!fileUrl ? (
//           "No file to view"
//         ) : (
//           <RenderFile
//             extension={(String(responseData?.type) || "").split("/")?.[1]}
//             fileUrl={fileUrl}
//           />
//         )}
//       </Modal>
//        <button className='btn btn-custom mx-1'
//           onClick={viewFile}
//           disabled={loading}
//           data-for='view-file'
//           style={{ width: "30px", height: "30px", display: "flex", justifyContent: "center", alignItems: "center" }} data-tip>
//           <i className='fa fa-eye'>
//           </i>
//         </button>
//         <ReactTooltip id='view-file'>View Document</ReactTooltip> */}
//     </>
//   );
// };
// const Approve = ({ row: { file_id, doc_type, ...rest }, row, reloadTable }) => {
//   const { state } = React.useContext(Context);
//   const [modalState, setModalState] = React.useState(false);
//   const [loading, setState] = React.useState(false);
//   const [comment, setComment] = React.useState('')
//   const [paramID, setparamId] = React.useState('')
//   const toggleModal = () => {
//     setparamId(row?.cl_pre_cond_params_id)
//     setModalState(!modalState);
//   };
//   const OnloadModal = async () => {
//     setState(true);
//     try {
//       toggleModal();
//     } catch (err) {
//       toast.error(message.ERROR);
//     }
//     setState(false);
//   };

//   const formSubmitHandler = async (e) => {
//     e.preventDefault();
//     const formData = new FormData();
//     comment && formData.append('comment', comment);
//     paramID && formData.append('pre_cond_id', paramID)
//     try {
//       let apiResponse;
//       apiResponse = await LenderUgroService.conditionSanction_approve({
//         type: state?.userType,
//         formData,
//         subType: state?.userType === 'admin' ? 'co-lender' : '',
//       });
//       if (apiResponse?.data?.status?.code === 1) {
//         toast.success(
//           apiResponse?.data?.data?.messages?.[0]?.[0] || message.SAVED_SUCCESS
//         );
//         reloadTable()
//         setComment('')
//         setModalState(!modalState);
//       }
//     } catch (err) {
//       let error = err?.response?.data?.messages?.error;
//       toast.error(error?.form?.[0] || error?.others?.[0] || message.ERROR_FORM);
//     }
//   };

//   return (
//     <>
//     <h1>hello</h1>
//       {/* {row?.is_verified == 0 ? <Modal
//         state={modalState}
//         closeModal={toggleModal}>
//         <div className='p-1'>
//           <p className='font-weight-bold'>
//             Are you sure you want to approve this sanction condition?
//           </p>
//           <div class='form-group'>
//             <label>
//               Comment <tt>*</tt>
//             </label>
//             <textarea
//               class='form-control custom-form-control'
//               cols='4'
//               rows='4'
//               value={comment}
//               onChange={(e) => setComment(e.target.value)}
//               maxLength="240"
//             ></textarea>
//           </div>
//           <div className='d-flex align-items-center '>
//             <Button
//               clickHandler={formSubmitHandler}
//               classNames='btn-custom'>
//               Approve
//             </Button>

//             <Button
//               clickHandler={toggleModal}
//               classNames='btn-outline-custom ml-2'>
//               Cancel
//             </Button>
//           </div>
//         </div>
//       </Modal> :
//         <Modal
//           state={modalState}
//           closeModal={toggleModal}>
//           <div className="my-3 text-bold h6">
//             This sanction condition is Verified
//           </div>
//           <Button
//             clickHandler={toggleModal}
//             classNames='btn-outline-custom ml-2 btn-custom'>
//             OK
//           </Button>
//         </Modal>
//       }
//       <button
//         className={`btn btn-sm mx-1 border border-none ${row?.is_verified === 0 ? 'verifyBtn' : 'verifiedBtn'}`}
//         type="button"
//         onClick={OnloadModal}
//         disabled={loading}
//         data-for={`${row?.is_verified === 0 ? 'verify' : 'verified'}`}
//         style={{ width: "30px", height: "30px", display: "flex", justifyContent: "center", alignItems: "center" }} data-tip
//       >
//         {row?.is_verified === 0 ? <i class="fa fa-pencil verify"></i> : <i class="fa fa-check verifyed" aria-hidden="true"></i>}
//       </button> */}
//       {/* <ReactTooltip id={`${row?.is_verified === 0 ? 'verify' : 'verified'}`}>{`${row?.is_verified === 0 ? 'Verify' : 'Verified'}`}</ReactTooltip> */}
//     </>
//   );
// };

export default Actions;

import config from './config';
import { getCookie } from './cookie';
import axios from 'axios';

export default class APIService {
	static get(url, cancelToken, responseType) {
		const token = getCookie(btoa('token'));
		return axios({
			url: url,
			responseType: responseType,
			method: 'GET',
			cancelToken: cancelToken,
			headers: {
				Authorization: `Bearer ${token}`,
				...config.defaultHeaders
			}
		})
	}

	static post(url, postBody, cancelToken, responseType, otherHeaders, sendAuth = true) {
		const token = getCookie(btoa('token'));
		return axios({
			url: url,
			method: 'POST',
			responseType,
			data: postBody,
			cancelToken,
			headers: {
				...(sendAuth && {Authorization: `Bearer ${token}`}),
				...config.defaultHeaders,
				...otherHeaders
			}
		})
	}

	static put(url, putBody) {
		return axios({
			url: url,
			method: 'PUT',
			data: putBody
		})
	}

	static patch(url, data) {
		return axios({
			url: url,
			method: 'PATCH',
			data
		})
	}

	static delete(url) {
		return axios({
			url: url,
			method: 'DELETE'
		})
	}
}

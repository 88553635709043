import React from 'react';
import { useParams, useNavigate } from 'react-router';
import Button from '../../../components/Common/Button';
import BackButton from '../../../components/BackButton';
// import Button from '../../../components/Common/Button';
import LenderUgroService from '../../../services/lender';
import PortfolioContainer from '../../../components/Portfolio/PortfolioContainer';
import { Context } from '../../../config/store';
import './index.css';
import ResolveQueryModal from '../../../components/Portfolio/ResolveQueryModal';

const PendingView = () => {
	const [queryInfo, setQueryInfo] = React.useState({});
	const { state } = React.useContext(Context);
	const leadId = useParams().id;
	const [modalState, setModalState] = React.useState(false);
	const navigate = useNavigate();
	const getQueryDetail = async () => {
		const response = await LenderUgroService.getQueryDetails({
			type: state.userType,
			params: { cl_lead_id: leadId }
		});
		if (response?.data?.status?.code === 1) {
			setQueryInfo(response.data.data?.cl_lender_query?.data?.[0] || [])
		}
	};
	const toggleModal = () => {
		setModalState(!modalState);
	};
	React.useLayoutEffect(() => {
		getQueryDetail();
	}, []);

	const submitHandler = () => {
		toggleModal();
	};
	return (
		<PortfolioContainer>
			<div className='row view-container'>
				<div className='col-md-12 mb-3'>
					<BackButton type='btn-outline-custom'>
						<i className='fa fa-chevron-left mr-1'></i>Back
					</BackButton>
				</div>
				<div className='col-md-6 col-sm-12 col-lg-6 details'>
					<div className='card border-0'>
						<div className='card-body custom'>
							<div className='row'>
								<div className='col-3'>
									<p>LAF ID</p>
								</div>
								<div className='col-9'>
									<p>
										<b>: {queryInfo.laf_id || 'NA'}</b>
									</p>
								</div>

								<div className='col-3'>
									<p>Customer Name</p>
								</div>
								<div className='col-9'>
									<p>
										<b>: {queryInfo.cust_name || 'NA'}</b>
									</p>
								</div>

								<div className='col-3'>
									<p>Lender Name</p>
								</div>
								<div className='col-9'>
									<p>
										<b>: {queryInfo.lender_name || 'NA'}</b>
									</p>
								</div>

								<div className='col-3'>
									<p>Query Type</p>
								</div>
								<div className='col-9'>
									<p>
										<b>: {queryInfo.query_type || 'NA'}</b>
									</p>
								</div>

								<div className='col-3'>
									<p>Comment</p>
								</div>
								<div className='col-9'>
									<p>
										<b>
											: {queryInfo.query_txt || 'NA'}
										</b>
									</p>
								</div>
							</div>
						</div>
					</div>
					{queryInfo.cl_lender_id && <div className='d-flex align-items-center mt-3'>
						<Button
							clickHandler={toggleModal}
							type='btn-custom'
							classNames='small'
						>
							Resolve
						</Button>
						<Button
							classNames='btn-outline-custom small text-orange ml-2 mr-2'
							clickHandler={() => navigate('/portfolio/pending')}
						>
							Cancel
						</Button>
						<Button
							clickHandler={() => navigate(`/portfolio/view/${leadId}/details`)}
							classNames='btn-outline-custom small text-orange mr-2'
						>
							View Details
						</Button>
					</div>}
				</div>
			</div>
			{queryInfo.cl_lender_id && modalState && <ResolveQueryModal
				modelState={modalState}
				toggleModal={toggleModal}
				submitHandler={submitHandler}
				leadId={leadId}
				lafId={queryInfo.laf_id}
				lenderId={queryInfo.cl_lender_id}
				submitCallback={getQueryDetail}
			/>}
		</PortfolioContainer>
	);
};

export default PendingView;

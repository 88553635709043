import Actions, {
	Download,
} from '../../pages/OnboardingForm/DocumentsUpload/Actions';
import { useState, useEffect } from 'react';

const Cheque = ({ value, onChange, disabled, body }) => {
	const [currentFile, setCurrentFile] = useState(null);
	const [isValidId, setIsVaildId] = useState(false);
	useEffect(() => {
		if (typeof value == 'number') setIsVaildId(true);
	}, [value]);
	return (
		<div>
			<div className='row no-gutters flex-nowrap'>
				<input
					disabled={disabled}
					onChange={(e) => {
						setCurrentFile(e.target.files[0]);
						onChange({ target: { value: e.target.files[0] } });
					}}
					type={'file'}
					className='form-control mr-2'
				/>
			</div>
			<div className='row no-gutters align-items-center justify-content-between mt-2'>
				<span>{body?.cheque_file_name}</span>
				{isValidId && value ? (
					<Actions className='flex-nowrap' row={{ file_id: value }} />
				) : (
					''
				)}
			</div>
			<div className='mt-2'>{currentFile?.name}</div>
		</div>
	);
};

export default Cheque;

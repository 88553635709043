import React from 'react';
const LeadUploadOptions = ({ leadOptions, setLeadOptions }) => {
	const changeState = (state, value) => {
		if (state) {
			setLeadOptions([...leadOptions, value]);
		} else {
			const newLeadsOptions = leadOptions.filter(option => option !== value);
			setLeadOptions(newLeadsOptions);
		}
	};
	const validate = value =>
		leadOptions.find(lead_option => lead_option === value);

	return (
		<div className='content'>
			<h5>Lead Upload Options</h5>
			<div className='row'>
				<div className='col-md-2'>
					<div className='theme-checkbox'>
						<input
							checked={validate(1)}
							onChange={e => changeState(e.target.checked, 1)}
							type='checkbox'
						/>
						<label>Lead via API</label>
					</div>
				</div>
				<div className='col-md-2'>
					<div className='theme-checkbox'>
						<input
							type='checkbox'
							checked={validate(2)}
							onChange={e => changeState(e.target.checked, 2)}
						/>
						<label>Bulk Upload</label>
					</div>
				</div>

				<div className='col-md-3'>
					<div className='theme-checkbox'>
						<input
							type='checkbox'
							checked={validate(3)}
							onChange={e => changeState(e.target.checked, 3)}
						/>
						<label>WhatsApp Chatbot Integration</label>
					</div>
				</div>
			</div>
		</div>
	);
};

export default LeadUploadOptions;

import React from 'react';
import { Link } from 'react-router-dom';
import { getFullDate } from '../../common/date';
const ApplicationTableRow = ({
	LOAN_DETAILS,
	APPLICANT_CONTACT_DETAILS,
	APPLICATION_STATUS
}) => {
	return (
		<tr>
			<td>{LOAN_DETAILS?.LAF_ID}</td>
			<td>{APPLICANT_CONTACT_DETAILS?.CONTACT_PERSON_NAME}</td>
			{/* <td>{APPLICANT_CONTACT_DETAILS?.EMAIL_ID}</td> */}
			<td>{APPLICANT_CONTACT_DETAILS?.MOBILE_NUMBER}</td>
			<td>{getFullDate(new Date(LOAN_DETAILS?.LAF_CREATION_DATE))}</td>
			<td>{LOAN_DETAILS?.BRANCH}</td>
			<td>{LOAN_DETAILS?.PRODUCT}</td>
			{/* <td>{LOAN_DETAILS?.DSA_CODE}</td> */}
			<td>{LOAN_DETAILS?.LOAN_AMOUNT}</td>
			<td>{LOAN_DETAILS?.BDM_NAME}</td>
			<td>{APPLICATION_STATUS?.CURRENT_LOS_QUEUE}</td>
			<td>
				<Link to={`/manage-applications/view/${LOAN_DETAILS?.LAF_ID || ''}`}>
					View
				</Link>
			</td>
		</tr>
	);
};

export default ApplicationTableRow;

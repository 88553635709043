import React from 'react'
import { useNavigate } from 'react-router-dom';
import './Dashboard.css'

const Cards = ({portfolio}) => {
  const navigate = useNavigate()
  const handleRoute =(route)=>{
    navigate(`/portfolio/${route}`)
  }
  const convertToCr = (amt) => {
    return (isNaN(amt) || amt == 0) ? 0 : (amt/10000000).toFixed(2)+'Cr';
  }
  return (
    <>
    <span className='ml-2' style={{fontWeight:700,fontSize:13, color:"grey"}}>Option 1</span>
    <div className='cardsRow'>
    <div className='Frist-cards' style={{cursor:'default'}}>
      <span className='card_count'>
        <span>{portfolio?.option_1?.assigned_portfolio}</span>
        <span style={{'float': 'right', 'margin-right': '9px'}}>{convertToCr(portfolio?.option_1?.assigned_portfolio_amt)}</span>
      </span>
      <span className='card_title-frist'>Assigned</span>
      {/* <span className='Navigation_icons'><span className='Card_icon'>&#x2192;</span></span> */}
    </div>
    <div className='cards' onClick={()=>handleRoute("assigned?lead_type=1")}>
      <span className='card_count'>
        <span>{portfolio?.option_1?.lender_pending}</span>
        <span style={{'float': 'right', 'margin-right': '9px'}}>{convertToCr(portfolio?.option_1?.lender_pending_amt)}</span>
      </span>
      <span className='card_title'>Pending</span>
      {/* <span className='Navigation_icons'><span className='Card_icon'>&#x2192;</span></span> */}
    </div>
    <div className='cards' onClick={()=>handleRoute("sanction-rejected?lead_type=1")}>
      <span className='card_count'>
        <span>{portfolio?.option_1?.sanction_rejected}</span>
        <span style={{'float': 'right', 'margin-right': '9px'}}>{convertToCr(portfolio?.option_1?.sanction_rejected_amt)}</span>
      </span>
      <span className='card_title'>Rejected</span>
      {/* <span className='Navigation_icons'><span className='Card_icon'>&#x2192;</span></span> */}
    </div>
    <div className='cards' onClick={()=>handleRoute("approved?lead_type=1")}>
      <span className='card_count'>
        <span>{isNaN(portfolio?.option_1?.sanction_approved + portfolio?.option_1?.pre_sanction_approved) ? 0 : portfolio?.option_1?.sanction_approved + portfolio?.option_1?.pre_sanction_approved}</span>
        <span style={{'float': 'right', 'margin-right': '9px'}}>{convertToCr(portfolio?.option_1?.sanction_approved_amt+portfolio?.option_1?.pre_sanction_approved_amt)}</span>
      </span>
      <span className='card_title'>Sanctioned</span>
      {/* <span className='Navigation_icons'><span className='Card_icon'>&#x2192;</span></span> */}
    </div>
    <div className='cards'onClick={()=>handleRoute("disbursal-pending?lead_type=1")}>
      <span className='card_count'>
        <span>{portfolio?.option_1?.disbursal_pending}</span>
        <span style={{'float': 'right', 'margin-right': '9px'}}>{convertToCr(portfolio?.option_1?.disbursal_pending_amt)}</span>
      </span>
      <span className='card_title'>Disbursal Pending</span>
      {/* <span className='Navigation_icons'><span className='Card_icon'>&#x2192;</span></span> */}
    </div>
    <div className='cards' onClick={()=>handleRoute("disbursal-rejected?lead_type=1")}>
      <span className='card_count'>
      <span>{portfolio?.option_1?.disbursal_rejected}</span>
        <span style={{'float': 'right', 'margin-right': '9px'}}>{convertToCr(portfolio?.option_1?.disbursal_rejected_amt)}</span>
      </span>
      <span className='card_title'>Disbursal Rejected</span>
      {/* <span className='Navigation_icons'><span className='Card_icon'>&#x2192;</span></span> */}
    </div>
    <div className='cards' onClick={()=>handleRoute("disbursal-approved?lead_type=1")}>
      <span className='card_count'>
        <span>{isNaN(portfolio?.option_1?.disbursal_portfolio + portfolio?.option_1?.disbursal_lender_decision) ? 0 : portfolio?.option_1?.disbursal_portfolio + portfolio?.option_1?.disbursal_lender_decision}</span>
        <span style={{'float': 'right', 'margin-right': '9px'}}>{convertToCr(portfolio?.option_1?.disbursal_portfolio_amt + portfolio?.option_1?.disbursal_lender_decision_amt)}</span>
      </span>
      <span className='card_title'>Disbursed</span>
      {/* <span className='Navigation_icons'><span className='Card_icon'>&#x2192;</span></span> */}
    </div>
    </div>
    <span className='ml-2' style={{fontWeight:700,fontSize:13,color:'grey'}}>Option 2</span>
    <div className='cardsRow'>
    <div className='Frist-cards' style={{cursor:'default'}}>
      <span className='card_count'>
        <span>{portfolio?.option_2?.assigned_portfolio}</span>
        <span style={{'float': 'right', 'margin-right': '9px'}}>{convertToCr(portfolio?.option_2?.assigned_portfolio_amt)}</span>
      </span>
      <span className='card_title-frist'>Assigned</span>
      {/* <span className='Navigation_icons'><span className='Card_icon'>&#x2192;</span></span> */}
    </div>
    <div className='cards' onClick={()=>handleRoute("assigned?lead_type=2")}>
      <span className='card_count'>
        <span>{portfolio?.option_2?.lender_pending}</span>
        <span style={{'float': 'right', 'margin-right': '9px'}}>{convertToCr(portfolio?.option_2?.lender_pending_amt)}</span>
      </span>
      <span className='card_title'>Pending</span>
      {/* <span className='Navigation_icons'><span className='Card_icon'>&#x2192;</span></span> */}
    </div>
    <div className='cards' onClick={()=>handleRoute("sanction-rejected?lead_type=2")}>
      <span className='card_count'>
        <span>{portfolio?.option_2?.sanction_rejected}</span>
        <span style={{'float': 'right', 'margin-right': '9px'}}>{convertToCr(portfolio?.option_2?.sanction_rejected_amt)}</span>
      </span>
      <span className='card_title'>Rejected</span>
      {/* <span className='Navigation_icons'><span className='Card_icon'>&#x2192;</span></span> */}
    </div>
    <div className='cards' onClick={()=>handleRoute("approved?lead_type=2")}>
      <span className='card_count'>
        <span>{isNaN(portfolio?.option_2?.sanction_approved + portfolio?.option_2?.pre_sanction_approved) ? 0 :portfolio?.option_2?.sanction_approved + portfolio?.option_2?.pre_sanction_approved}</span>
        <span style={{'float': 'right', 'margin-right': '9px'}}>{convertToCr(portfolio?.option_2?.sanction_approved_amt+portfolio?.option_2?.pre_sanction_approved_amt)}</span>
      </span>
      <span className='card_title'>Sanctioned</span>
      {/* <span className='Navigation_icons'><span className='Card_icon'>&#x2192;</span></span> */}
    </div>
    <div className='cards'onClick={()=>handleRoute("disbursal-pending?lead_type=2")}>
      <span className='card_count'>
        <span>{portfolio?.option_2?.disbursal_pending}</span>
        <span style={{'float': 'right', 'margin-right': '9px'}}>{convertToCr(portfolio?.option_2?.disbursal_pending_amt)}</span>
      </span>
      <span className='card_title'>Disbursal Pending</span>
      {/* <span className='Navigation_icons'><span className='Card_icon'>&#x2192;</span></span> */}
    </div>
    <div className='cards' onClick={()=>handleRoute("disbursal-rejected?lead_type=2")}>
      <span className='card_count'>
      <span>{portfolio?.option_2?.disbursal_rejected}</span>
        <span style={{'float': 'right', 'margin-right': '9px'}}>{convertToCr(portfolio?.option_2?.disbursal_rejected_amt)}</span>
      </span>
      <span className='card_title'>Disbursal Rejected</span>
      {/* <span className='Navigation_icons'><span className='Card_icon'>&#x2192;</span></span> */}
    </div>
    <div className='cards' onClick={()=>handleRoute("disbursal-approved?lead_type=2")}>
      <span className='card_count'>
        <span>{isNaN(portfolio?.option_2?.disbursal_portfolio + portfolio?.option_2?.disbursal_lender_decision)? 0 :portfolio?.option_2?.disbursal_portfolio + portfolio?.option_2?.disbursal_lender_decision}</span>
        <span style={{'float': 'right', 'margin-right': '9px'}}>{convertToCr(portfolio?.option_2?.disbursal_portfolio_amt +portfolio?.option_2?.disbursal_lender_decision_amt )}</span>
      </span>
      <span className='card_title'>Disbursed</span>
      {/* <span className='Navigation_icons'><span className='Card_icon'>&#x2192;</span></span> */}
    </div>
    </div>
    </>
  )
}

export default Cards
import React from 'react';
import Permission from './Permission';
const PermissionEach = ({
	display_name,
	childrens = [],
	userPermissions = [],
	parent_permission_id,
	parentId,
	parentDefaultSelected,
	parentPermissions,
	childrenPermissions,
	setParentPermissions,
	setChildrenPermissions,
	permissions,
	id,
	edit
}) => {
	const [showList, toggleList] = React.useState(false);
	const [defaultSelected, setDefaultSelected] = React.useState(false);
	const toggleAll = () => {
		if (!defaultSelected) {
			if (!parent_permission_id) {
				setParentPermissions([...parentPermissions, id]);
			} else {
				setChildrenPermissions([...childrenPermissions, id]);
			}
			setDefaultSelected(true);
		} else {
			if (!parent_permission_id) {
				let newPermission = parentPermissions.filter(
					permission => permission !== id
				);
				setParentPermissions(newPermission);
			} else {
				let newPermission = childrenPermissions.filter(
					permission => permission !== id
				);
				setChildrenPermissions(newPermission);
			}
			setDefaultSelected(false);
		}
	};
	// React.useEffect(() => {
	// 	setDefaultSelected(parentDefaultSelected);
	// }, [parentDefaultSelected]);
	return (
		<div className='mx-2'>
			<i
				onClick={() => toggleList(!showList)}
				className={`fa ${showList ? 'fa-minus' : 'fa-plus'}   permission-icon`}
			/>
			<label
				onClick={toggleAll}
				className='opactiy-style form-check-label ml-1 font-weight-bold '
			>
				{display_name}
			</label>
			{childrens.map(
				(
					{ childrens, display_name, id, parent_permission_id, is_display },
					index
				) =>
					is_display ? (
						<div
							key={index}
							className={`${!showList && 'd-none'} ml-3 mt-2 mb-3`}
						>
							<Permission
								userPermissions={userPermissions}
								edit={edit}
								permissions={permissions}
								parent_permission_id={parent_permission_id}
								childrenPermissions={childrenPermissions}
								parentPermissions={parentPermissions}
								setChildrenPermissions={setChildrenPermissions}
								setParentPermissions={setParentPermissions}
								id={id}
								key={index}
								parentId={parentId}
								defaultSelected={defaultSelected}
								display_name={display_name}
								subChilds={childrens}
							/>
						</div>
					) : null
			)}
		</div>
	);
};

export default PermissionEach;

let flatArray = [];
let keyObject = {};
function treeToList(array, key) {
	flatArray = [];
	keyObject = {};
	recursiveTree(array, key);
	return key ? keyObject : flatArray;
}

function recursiveTree(array, key) {
	array.map(child => {
		if (key === child.id) keyObject = child;
		flatArray.push(child.id);
		if (child.childrens) recursiveTree(child.childrens, key);
	});
}

export default treeToList;

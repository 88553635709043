import React from "react";
import { useLocation } from "react-router";

export default function Accordion({
	children,
	label,
	headerId,
	collapseId,
	required = false,
	count,
	isDocumentAccordion = false,
	isPostDisbursalCase = false
}) {
	const location = useLocation();
	const [openId, setOpenId] = React.useState('')
	const [isPostDisbursal, setIsPostDisbursal] = React.useState(isPostDisbursalCase)

	const handleClick = () => {
		setIsPostDisbursal(!isPostDisbursal)
		if ((openId === collapseId) || isPostDisbursal) {
			setOpenId('')
		} else {
			setOpenId(collapseId)
		}
	}

	return (
		<div className='card'>
			<div
				className='card-header'
				id={headerId}
				// onClick={() => {
				// 	openId === collapseId ? setOpenId('') : setOpenId(collapseId)
				// }}
			>
				<h5
					className="d-flex align-items-center justify-content-around mb-0 accordion-heading collapsed"
					data-toggle="collapse"
					aria-expanded={(collapseId === openId) || isPostDisbursal}
					aria-controls={collapseId}
					onClick={() => {
						handleClick()
					}}
				>
					<span class="flex-1">
					{required || !isNaN(count) && !location.pathname.includes('post-disbursal') ? (
							<>
								({!isNaN(count) && <span>Docs: {count}</span>}
								{required && <span className='text-danger'>required</span>})
							</>
						) : (
							''
						)}
						<span className={isDocumentAccordion && "ml-2"}>{label}</span>
						
					</span>

					<div className='options'>
						<i className='fa fa-chevron-down ml-1'></i>
					</div>
				</h5>
			</div>
			<div
				id={collapseId}
				className={`collapse ${((collapseId === openId) || isPostDisbursal) && 'show'}`}
				aria-labelledby={headerId}
			>
				<div class="card-body">{children}</div>
			</div>
		</div >
	);
}

import React from 'react';
import { Link } from 'react-router-dom';
const PageHeader = ({ partId }) => {
	return (
		<div className='content'>
			<div className='row'>
				<div className='col-md-12'>
					<Link to='/manage-affiliate' className='btn btn-close mb-3'>
						<i className='fa fa-chevron-left' aria-hidden='true' /> Back
					</Link>
				</div>
				<div className='col-md-12 d-flex align-items-center'>
					<p className='font-18 mb-0'>
						<b>Affiliate ID :</b> AF{partId}{' '}
					</p>{' '}
					{/* <button className='btn btn-success ml-2'> Approved </button> */}
				</div>
			</div>
		</div>
	);
};

export default PageHeader;

import React, { useLayoutEffect } from 'react';
const Pagination = ({ total_pages, setPage, total, showTotal, disabled }) => {
	let { org_page = 1 } = JSON.parse(sessionStorage.getItem('searchData')) || {};
	const [currentPage, setCurrentPage] = React.useState(org_page);

	useLayoutEffect(()=>{
		setCurrentPage(org_page);
	}, [org_page])

	const onChange = (e, page) => {
		if (disabled) return;
		e.preventDefault();
		setCurrentPage(page);
		setPage(page);

		setStartEnd();
	};
	const setStartEnd = (start, end) => {
		if (total_pages === 0) {
			return;
		}
		const padding = 2;
		start = currentPage - padding;
		end = currentPage + padding;
		if (start < 1) {
			end = start === 0 ? end + 1 : end + padding;
			start = 1;
		}
		if (end > total_pages) {
			start =
				end - 1 === total_pages
					? start - 1
					: start - padding < 1
					? 1
					: start - padding;
			end = total_pages;
			start = start < 1 ? 1 : start;
		}

		let pageNumberLinks = [];
		for (let i = start; i <= end; i++) {
			pageNumberLinks.push(i);
		}
		return pageNumberLinks;
	};
	return (
		<div>
			{' '}
			<label className='mt-1 results' v-if='currentPageIndex'>
				Showing {(currentPage - 1) * 10 || 1} -{' '}
				{!showTotal
					? currentPage * 10
					: currentPage * 10 < total
					? currentPage * 10
					: total}{' '}
				of {showTotal ? total : total} records
			</label>
			<nav aria-label='...' className='mb-5 mt-1 float-right'>
				<ul className='pagination'>
					<li
						className={`page-item ${currentPage === 1 && 'disabled'} ${
							disabled && 'disabled'
						}`}
					>
						<button
							disabled={disabled || currentPage === 1}
							onClick={(e) => onChange(e, currentPage - 1)}
							className='page-link'
						>
							Previous
						</button>
					</li>
					{setStartEnd().map((item, index) => (
						<li
							key={index}
							className={`page-item ${currentPage === item ? 'active' : ''}`}
						>
							<a
								href='#'
								onClick={(e) => onChange(e, item)}
								className='page-link'
							>
								{item}
							</a>
						</li>
					))}

					<li
						className={`page-item ${
							currentPage === total_pages && 'disabled'
						} ${disabled && 'disabled'}`}
					>
						<button
							disabled={disabled || currentPage === total_pages}
							onClick={(e) => onChange(e, currentPage + 1)}
							className='page-link'
						>
							Next
						</button>
					</li>
				</ul>
			</nav>
		</div>
	);
};

export default Pagination;

import React from 'react';
import Steps from '../components/AffiliateMapping/Steps';
import LeadUploadOptions from '../components/AffiliateMapping/ConfigureAffiliate/LeadUploadOptions';
import ApiParameters from '../components/AffiliateMapping/ConfigureAffiliate/ApiParameters/index';
import Actions from '../components/AffiliateMapping/ConfigureAffiliate/Actions';
import { useRouter } from '../hooks/useRouter';
import { getAffiliateConfig } from '../services/manage-affiliate';
import PageHeader from '../components/AffiliateMapping/AffiliateKyc/PageHeader';

const AffiliateConfigurationSettings = () => {
	const [affiliateConfig, setAffiliateConfig] = React.useState({});
	const [part_id, setPartId] = React.useState('');
	const [leadOptions, setLeadOptions] = React.useState([]);
	const [config, setConfig] = React.useState([]);
	const router = useRouter();

	const getDefaultAffiliateConfig = async part_id => {
		const response = await getAffiliateConfig({ part_id });
		if (response?.status?.code === 1) {
			const sections = response.data.resource?.[0].aff_lead_config;
			setAffiliateConfig(response.data.resource?.[1]?.aff_lead_config || []);
			setConfig(sections);

			const options = response.data.resource?.[1]?.lead_tools?.map(
				lead_tool => lead_tool.tool_id
			);
			setLeadOptions(options || []);
		}
	};
	React.useEffect(() => {
		if (router.params.partId) {
			setPartId(router.params.partId);
			getDefaultAffiliateConfig(router.params.partId);
		}
	}, []);

	return (
		<>
			<div className='p-3'>
				{part_id && <PageHeader partId={part_id} />}
				<Steps partId={part_id} active={1} />
				<hr className='m-0' />
				<LeadUploadOptions
					leadOptions={leadOptions}
					setLeadOptions={setLeadOptions}
				/>
				<hr className='m-0' />
				<ApiParameters
					config={config}
					part_id={part_id}
					affiliateConfig={affiliateConfig}
					setAffiliateConfig={setAffiliateConfig}
				/>
				{/* <hr className='m-0' />
					<LeadDisrtibutionRule /> */}
				<hr className='m-0' />
				<Actions
					leadOptions={leadOptions}
					config={affiliateConfig}
					part_id={part_id}
				/>
			</div>
		</>
	);
};

export default AffiliateConfigurationSettings;

import { useEffect } from 'react';
import { useContext, useState } from 'react';
import { toast } from 'react-toastify';
import { Context } from '../../../config/store';
import message from '../../../constant/message';
import LenderUgroService from '../../../services/lender';
import Select from '../../Common/Input/Select';
import Modal from '../../Modal';

const AssignLenderUserModal = ({
	modelState,
	toggleModal,
	selectedIds,
	reloadTable,
}) => {
	const { state } = useContext(Context);
	const [users, setUsers] = useState([]);
	const [errors, setErrors] = useState({});
	const [currentUser, setCurrentUser] = useState(null);
	const assignLender = async (e) => {
		e.preventDefault();
		try {
			let response;
			response = await LenderUgroService.assignUserLead({
				type: state.userType,
				requestBody: {
					user_id: currentUser,

					cl_lead_id: selectedIds[0],
				},
			});
			if (response?.data?.status?.code === 1) {
				const successMessage = response?.data?.messages?.success;
				toast.success(
					successMessage?.form?.[0] ||
					successMessage?.others?.[0] ||
					message.SAVED_SUCCESS
				);
			}

			if (reloadTable) reloadTable();
			toggleModal();
		} catch (err) {
			console.log(err);
			let error = err?.response?.data?.messages?.error;
			toast.error(error?.form?.[0] || error?.others?.[0] || message.ERROR_FORM);
			setErrors(error);
		}
	};
	const getAssignUsers = async () => {
		let response;

		response = await LenderUgroService.getLenderUsers({
			type: state.userType,
			leadId: selectedIds[0],
		});

		if (response?.data?.status?.code === 1) {
			console.log(response?.data?.data);
			let usersData = response?.data?.data;
			let data = [];
			usersData.map((user) => {
				data.push({
					label: user.full_name,
					value: user,
				})
			});
			setUsers(data);
		}
	};
	const setSelectedUser = (id) => {
		setCurrentUser(id);
		// console.log(id);
	};
	useEffect(() => {
		getAssignUsers();
	}, []);
	return (
		<div className='custom_modal_style'>
			<Modal state={modelState} closeModal={toggleModal} style={{ width: '450px' }} removeBorderSpace={true} isOverflow={true}>
				<div className="modal-header-container">
					<div className="modal-title">Assign Lender User</div>
					<div className="closeModalView" onClick={toggleModal}>
						<span className="closeModalIcon">x</span>
					</div>
				</div>
				<div className="p-4 modalData">
					<form onSubmit={assignLender}>
						<p>Assign to</p>
						<Select
							options={users}
							isMulti={false}
							onChange={(data) => setSelectedUser(data?.value?.user_id)}
							className={'m-0'}
							maxMenuHeight={175}
						/>
						{errors && (
							<p className='mt-2 mb-0 text-danger'>
								{errors?.fields?.lender_id?.[0]}
							</p>
						)}
						<div className='d-flex justify-content-center align-items-center'>
							<button type='submit' className='btn btn-custom'>
								OK
							</button>
							<button
								type='button'
								className='btn btn-outline-custom ml-2'
								data-dismiss='modal'
								onClick={toggleModal}
							>
								Cancel
							</button>
						</div>
					</form>
				</div>
			</Modal>
		</div>
	);
};

export default AssignLenderUserModal;

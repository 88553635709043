import React, { useEffect } from 'react';
import Hint from '../../components/Hint';
import Input from './Input';
import BranchCode from './BranchCode';
const SectionItem = ({
	hint,
	label,
	id,
	parentId,
	rule,
	body,
	type,
	parentIndex,
	setBody,
	errors,
	required,
	sectionIndex,
	edit,
	params,
	leadId
}) => {
	
	const getSplittedLabel = (label) => {
		let newLabel = label;
		if (label?.includes('Add/')) {
			newLabel = label.split('Add/')[1]
		}
		return newLabel;
	}

	if (id === 'lead_state' || id === 'lead_city') return '';
	if (id === 'lead_zipcode') {
		return (
			<BranchCode
				label={label}
				sectionIndex={sectionIndex}
				required={required}
				parentIndex={parentIndex}
				type={type}
				id={id}
				rule={rule}
				body={body}
				parentId={parentId}
				setBody={setBody}
				params={params}
				errors={errors}
				edit={edit}
				leadId={leadId}
			/>
		);
	}
	return (
		<div className='col-md-4'>
			<div className='form-group'>
				<label style={{minHeight: '25px'}}>
					{getSplittedLabel(label)} {required == '1' && <tt>*</tt>} <Hint text={hint} id={id} place={'bottom'} />
				</label>
				<Input
					sectionIndex={sectionIndex}
					required={required}
					parentIndex={parentIndex}
					type={type}
					id={id}
					rule={rule}
					body={body}
					label={label}
					parentId={parentId}
					setBody={setBody}
					params={params}
					errors={errors}
					edit={edit}
					leadId={leadId}
				/>
			</div>
		</div>
	);
};

export default SectionItem;

import React from 'react';
import { Link } from 'react-router-dom';
import { forgotPassword } from '../services/auth';
import { toast } from 'react-toastify';
import message from '../constant/message';
import { Context } from '../config/store';
import { validateEmail } from '../common/helper';

const ForgotPassword = () => {
	const [email, setEmail] = React.useState('');
	const [errors, setErrors] = React.useState(null);
	const { state } = React.useContext(Context);
	const [loading, setStatus] = React.useState(false);
	const submitSendEmail = async e => {
		e.preventDefault();
		if(!validateEmail(email)){
			toast.error('Please enter valid email');
		}else{
			setStatus(true);
			const response = await forgotPassword({ email, type: state.userType });
			if (response?.status?.code === 1) {
				toast.success('An email has been sent to your mailbox');
			} else {
				let error = response.messages.error;
				toast.success('An email has been sent to your mailbox');
				// toast.error(error?.form?.[0] || error?.others?.[0] || message.ERROR_FORM);
				// setErrors(error);
			}
			setStatus(false);
		}
	};
	return (
		<section className='forget-password-container'>
			<div className='forget-user-bg'>
				<div className='logo text-center mb-2'>
					<img src='/images/logo.png' width={230} />
				</div>

				<form className='mt-5'>
					<div className='form-group'>
						<label style={{ fontSize: '18px' }}>Email</label>
						<div className="input-group">
							<input
								value={email}
								onChange={e => setEmail(e.target.value)}
								type='email'
								className='form-control'
								placeholder={'xyz@company.com'}
								style={{ height: '40px' }}
								maxlength={40}
							/>
						</div>
						{errors && (
							<p className='mt-2 mb-0 text-danger'>
								{errors?.fields?.email?.[0]}
							</p>
						)}
						<div className='d-flex justify-content-start align-items-center mb-3 mt-2'>
							<h6 style={{ fontSize: '10px' }}> Please enter the registered email address</h6>
						</div>
					</div>
					<div className="d-flex justify-content-center align-items-center mt-2">
						<div className='form-group'>
							<button
								disabled={loading}
								onClick={submitSendEmail}
								className='btn btn-blueButton hoverStyle'
								style={{ width: '70px' }}
							>
								{loading ? <div className='loader' /> : 'Continue'}
							</button>
						</div>
					</div>
					<p className='text-center font-18 mb-0'>
						<Link to={`/${state.userType}/login`} style={{ fontSize: '16px', color: '#EE6B33' }}>
							<b>Login</b>
						</Link>
					</p>
				</form>
			</div>
		</section>
	);
};

export default ForgotPassword;

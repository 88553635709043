import React from 'react';
const OtherIncentive = ({ incentives = [] }) => {
	return (
		<div className='panel mt-0'>
			<div className='head_top'>
				<h4>Other Incentive</h4>
			</div>

			{incentives.map(({ id, value }) => (
				<>
					<div key={id} className='p-2 row no-gutters bg-white '>
						<div className='col-md-3'>
							<span>
								<b>{id}</b>
							</span>
						</div>
						<div className='col-md-3'></div>
						<div className='col-md-3'></div>
						<div className='col-md-3'>
							<span className='ml-4'>{value.toLocaleString('en-IN')}</span>
						</div>
					</div>
					<hr className='m-0' />
				</>
			))}
		</div>
	);
};

export default OtherIncentive;

export default [
	{
		title: 'Dashboard',
		path: '/',
		id: 'menu_1',
		sidebarIcon: (
			<i
				className='item-icon material-icons-round fa fa-th-large mt-1'
				aria-hidden='true'
			></i>
		),
		permission: 'view-dashboard',
	},

	{
		sidebarIcon: (
			<i className='material-icons-round fa fa-th-large' aria-hidden='true'></i>
		),
		title: `Dashboard`,
		path: '/portfolio/dashboard',
		id: 'menu_8',
		permission: 'view-colender-dashboard',
	},
	// {
	// 	title: 'Manage Leads',
	// 	path: '/manage-leads',
	// 	id: 'menu_2',
	// 	sidebarIcon: (
	// 		<i
	// 			className='item-icon material-icons-round fa fa-user-plus  mt-1'
	// 			aria-hidden='true'
	// 		></i>
	// 	),
	// 	permission: 'lead-list',
	// 	subChilds: [
	// 		{
	// 			title: 'Manage Leads',
	// 			path: '/manage-leads',
	// 			icon: (
	// 				<i
	// 					className='item-icon material-icons-round fa fa-hand-pointer-o  mt-1'
	// 					aria-hidden='true'
	// 				></i>
	// 			),
	// 			permission: 'lead-list',
	// 		},
	// 		{
	// 			icon: <i className='fa fa-plus-circle' aria-hidden='true'></i>,
	// 			title: 'Add Lead',
	// 			path: '/lead/add',
	// 			permission: 'admin-add-lead',
	// 		},
	// 	],
	// },
	// {
	// 	title: 'Manage Affiliate/DSA',
	// 	path: '/manage-affiliate',
	// 	sidebarIcon: (
	// 		<i
	// 			className='item-icon material-icons-round fa fa-user-plus  mt-1'
	// 			aria-hidden='true'
	// 		></i>
	// 	),
	// 	subChilds: [
	// 		{
	// 			title: 'BH Approval Status',
	// 			path: '/nsm/approval',
	// 			id: 'menu_8',
	// 			icon: (
	// 				<i
	// 					className='item-icon material-icons-round fa fa-hand-pointer-o  mt-1'
	// 					aria-hidden='true'
	// 				></i>
	// 			),
	// 			permission: 'nsm-partner-listing',
	// 		},
	// 		{
	// 			title: 'Manage Affiliate',
	// 			path: '/manage-affiliate',
	// 			icon: (
	// 				<i
	// 					className='item-icon material-icons-round fa fa-hand-pointer-o  mt-1'
	// 					aria-hidden='true'
	// 				></i>
	// 			),
	// 		},
	// 		// {
	// 		// 	title: 'Affiliate Mapping',
	// 		// 	path: '/affiliate-mapping',
	// 		// 	icon: <i className='item-icon material-icons-round fa fa-plus-circle' aria-hidden='true'></i>
	// 		// },
	// 		// {
	// 		// 	title: ' Block/Remove Affiliate',
	// 		// 	path: '/block-affiliate',
	// 		// 	icon: <i className='item-icon material-icons-round fa fa-minus-circle' aria-hidden='true'></i>
	// 		// }
	// 	],
	// 	permission: 'manage_affiliate',
	// },
	{
		title: 'Manage ACL',
		path: '#',
		permission: 'manage_acl',
		// sidebarIcon: (
		// 	<i
		// 		className='item-icon material-icons-round fa fa-user-plus mt-1'
		// 		aria-hidden='true'
		// 	></i>
		// ),
		id: 'menu_9',
		subChilds: [
			{
				path: '/admin/roles',
				title: 'Manage Role',
				// icon: (
				// 	<i
				// 		className='item-icon material-icons-round fa fa-hand-pointer-o mt-1'
				// 		aria-hidden='true'
				// 	></i>
				// ),
			},
			{
				path: '/admin/role/add',
				title: 'Add Role',
				// icon: (
				// 	<i
				// 		className='item-icon material-icons-round fa fa-plus-circle mt-1'
				// 		aria-hidden='true'
				// 	></i>
				// ),
			},
			{
				path: '/admin/manage-users',
				title: 'Manage User',
				// icon: (
				// 	<i
				// 		className='item-icon material-icons-round fa fa-hand-pointer-o mt-1'
				// 		aria-hidden='true'
				// 	></i>
				// ),
				permission: 'backend-user',
			},
			{
				path: '/admin/manage-users/add',
				title: 'Add User',
				// icon: (
				// 	<i
				// 		className='item-icon material-icons-round fa fa-plus-circle mt-1'
				// 		aria-hidden='true'
				// 	></i>
				// ),
				permission: 'add-backend-user',
			},
		],
	},

	// {
	// 	path: '/comission-report',
	// 	title: 'Commission Reports',
	// 	id: 'menu_3',
	// 	sidebarIcon: (
	// 		<i
	// 			className='item-icon material-icons-round fa fa-file-image-o mt-1'
	// 			aria-hidden='true'
	// 		></i>
	// 	),
	// 	permission: 'generate-partner-commission',
	// },
	// {
	// 	path: '#',
	// 	title: 'Partner Onboarding',
	// 	id: 'menu_5',
	// 	permission: 'manage-partner-onboarding',
	// 	sidebarIcon: (
	// 		<i
	// 			className='item-icon material-icons-round fa fa-cog mt-1'
	// 			aria-hidden='true'
	// 		></i>
	// 	),
	// 	subChilds: [
	// 		{
	// 			path: '/partner/onboarding/add',
	// 			title: 'Add Partner',
	// 			icon: <i className='fa fa-plus-circle' aria-hidden='true'></i>,
	// 			permission: 'add_partner',
	// 		},
	// 		{
	// 			path: '/partner/onboarding/status',
	// 			title: 'Onboarding Status',
	// 			icon: <i className='fa fa-plus-circle' aria-hidden='true'></i>,
	// 			permission: 'partner_onboarding_list',
	// 		},
	// 	],
	// },
	{
		path: '/portfolio/unassigned',
		title: 'Co-Lender Applications',
		id: 'menu_4',
		// sidebarIcon: <i className='fa fa-cog' aria-hidden='true'></i>,
		permission: 'manage_colender_application',
		subChilds: [
			{
				// icon: <i className='fa fa-shopping-bag' aria-hidden='true'></i>,
				title: `Unassigned Portfolio`,
				path: '/portfolio/unassigned',
				permission: 'get-unassigned-lead-list',
			},
			{
				// icon: <i className='fa fa-tags' aria-hidden='true'></i>,
				title: 'Lender Pending',
				path: '/portfolio/pending',
				permission: 'get-pending-lead-list',
			},
			{
				// icon: <i className='fa fa-tags' aria-hidden='true'></i>,
				title: 'Lender Decisioned',
				path: '/portfolio/pre-approved',
				permission: 'get-decision-lead-list',
			},
			{
				// icon: <i className='fa fa-file-text-o' aria-hidden='true'></i>,
				title: 'Sanction Portfolio',
				path: '/portfolio/assigned',
				permission: 'get-sanctioned-lead-list',
			},
			{
				// icon: <i className='fa fa-file-text-o' aria-hidden='true'></i>,
				title: 'Sanction Rejected',
				path: '/portfolio/sanction-rejected',
				permission: 'get-sanctioned-lead-list',
			},
			{
				// icon: <i className='fa fa-tags' aria-hidden='true'></i>,
				title: 'Disbursal Pending',
				path: '/portfolio/disbursal-pending',
				permission: 'get-disbursal-pending-lead-list',
			},
			{
				// icon: <i className='fa fa-file-text-o' aria-hidden='true'></i>,
				title: 'Disbursal Portfolio',
				path: '/portfolio/disbursal-approved',
				permission: 'get-disbursal-approved-lead-list',
			},
			{
				// icon: <i className='fa fa-file-text-o' aria-hidden='true'></i>,
				title: 'Disbursal Rejected',
				path: '/portfolio/disbursal-rejected',
				permission: 'get-disbursal-rejected-lead-list',
			},
		],
	},
	// {
	// 	path: '#',
	// 	title: 'Settings',
	// 	id: 'menu_41',
	// 	sidebarIcon: (
	// 		<i
	// 			className='item-icon material-icons-round fa fa-cog mt-1'
	// 			aria-hidden='true'
	// 		></i>
	// 	),
	// 	subChilds: [
	// 		{
	// 			path: '/password/change',
	// 			title: 'Change password',
	// 			icon: (
	// 				<i
	// 					className='item-icon material-icons-round fa fa-hand-pointer-o mt-1'
	// 					aria-hidden='true'
	// 				></i>
	// 			),
	// 		},
	// 	],
	// },
];



/**
 * Turns object to array with key as id and other value
 * @param {Object} obj
 */
export function objectsForEach(obj, type) {
	let tempArr = [];
	if (typeof obj !== 'object') {
		return tempArr;
	}
	for (const [key, value] of Object.entries(obj)) {
		if (isObjectArray(value)) {
			tempArr.push({ id: key, value });
		} else if (type === 'STRING_VAL') {
			tempArr.push({ id: key, value });
		} else {
			tempArr.push({ id: key, ...value });
		}
	}
	return tempArr;
}
export function getAddInfoArray(obj, type) {
	let tempArr = [];
	if (typeof obj !== 'object') {
		return tempArr;
	}
	for (const [key, value] of Object.entries(obj)) {
		if (isObjectArray(value)) {
			tempArr.push({ id: key, ...value[0] });
		} else if (type === 'STRING_VAL') {
			tempArr.push({ id: key, value });
		} else {
			tempArr.push({ id: key, ...value });
		}
	}
	return tempArr;
}

/**
 * check wether a variable passed is array of objects
 * @param {*} variable
 */
export function isObjectArray(variable) {
	return Object.prototype.toString.call(variable) === '[object Array]';
}

import { useState, useEffect } from 'react';
import Table from '../../../components/Common/Table';
import TableNoRecords from '../../../components/Common/Table/TableNoRecords';
import Pagination from '../../../components/Pagination';
import PortfolioContainer from '../../../components/Portfolio/PortfolioContainer';
import { eligibleForLender } from '../../../constant/selectOptions';
import LenderUgroService from '../../../services/lender';
import historyColumns from './historyColumns';
import SearchFilter from './SearchFilter';
function History() {
	const [queryParams, setQueryParams] = useState('');
	const [tableData, setTableData] = useState({
		columns: historyColumns,
		rows: [],
	});
	const [loading, setLoading] = useState(true);
	const [pagination, setPagination] = useState({});
	const [page, setCurrentPage] = useState(1);
	const getAuitTrailHistory = async (paramsChanged) => {
		setLoading(true);
		const response = await LenderUgroService.getLeadAuditTrailHistory({
			page: paramsChanged ? 1 : page,
			queryParams,
		});
		const data = response.data.data;
		setTableData({
			...tableData,
			rows: data?.data || [],
		});
		setLoading(false);
		setCurrentPage(page);
		setPagination(response?.data?.data?.pagination);
	};
	useEffect(() => {
		getAuitTrailHistory();
	}, [page]);
	useEffect(() => {
		if (!loading) getAuitTrailHistory(true);
	}, [queryParams]);
	return (
		<PortfolioContainer>
			<SearchFilter
				setQueryParams={setQueryParams}
				lenders={eligibleForLender}
			></SearchFilter>
			<div className='mt-3' />
			<Table
				columns={tableData.columns}
				rows={tableData.rows}
				loading={loading}
			>
				{Boolean(!tableData.rows.length && !loading) && <TableNoRecords />}
			</Table>

			{pagination?.total_pages ? (
				<Pagination
					setPage={setCurrentPage}
					total_pages={pagination.total_pages}
					total={pagination.total}
					showTotal={true}
				/>
			) : (
				''
			)}
		</PortfolioContainer>
	);
}

export default History;

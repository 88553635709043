import React from 'react';
import SelectParameter from './SelectParameter';
import { objectsForEach } from '../../../../common/objectToArray';
import arrayToObject from '../../../../common/arrayToObject';
const ParameterSection = ({
	title: sectionTitle,
	config,
	setConfig,
	parameters,
	globalConfig,
	id: parentId,
	type
}) => {
	const params = parameters ? objectsForEach(parameters) : [];
	const [allSelected, setSelectAll] = React.useState(false);
	const select = type => {
		setSelectAll(type);
	};

	return (
		<>
			{params.map(({ label, value, id }, index) => (
				<SelectParameter
					defaultSelected={allSelected}
					key={id}
					index={index}
					globalConfig={globalConfig}
					sectionTitle={sectionTitle}
					title={label}
					parentTitle={sectionTitle}
					defaultValue={value}
					type={type}
					value={config?.[parentId]?.[id]?.value}
					config={config}
					setConfig={setConfig}
					parentId={parentId}
					id={id}
				/>
			))}

			<tr>
				<td></td>
				<td className='select-all link' onClick={() => select(false)}>
					Select All
				</td>
				<td className='select-all link' onClick={() => select(true)}>
					Select All
				</td>
			</tr>
		</>
	);
};

export default ParameterSection;

import React, { useState } from 'react';
import './index.css';
import SearchFilter from '../../../components/Portfolio/SearchFilter';
// import unassignedTableData from '../../../constant/unassignedTable';
import Table from '../../../components/Common/Table';
import Pagination from '../../../components/Pagination';
import Button from '../../../components/Common/Button';
import { productType } from '../../../constant/selectOptions';
import { eligibleForLender } from '../../../constant/selectOptions';
import { productName } from '../../../constant/selectOptions';
import PortfolioContainer from '../../../components/Portfolio/PortfolioContainer';
import LenderUgroService from '../../../services/lender';
import { Context } from '../../../config/store';
import TableNoRecords from '../../../components/Common/Table/TableNoRecords';
import portfolio from '../../../constant/tableHeaders/portfolio';
import { downloadFile } from '../../../common/download';
import { useNavigate } from 'react-router';

function PreApproved() {
	const [queryParams, setQueryParams] = React.useState('');
	const [tableData, setTableData] = useState({
		columns: portfolio,
		rows: [],
	});
	const [page, setCurrentPage] = useState(1);
	const [pagination, setPagination] = useState({});
	const [loading, setLoading] = React.useState(true);
	const [sortParams, setSortParams] = React.useState({
		sortBy: '',
		sortOrder: '',
	});
	const { state, dispatch } = React.useContext(Context);
	const navigate = useNavigate();

	const getPortfolio = async (paramsChanged, sortOrder, sortBy) => {
		setLoading(true);
		const response = await LenderUgroService.getAdminFilteredPortfolio({
			type: state.userType,
			page: paramsChanged ? 1 : page,
			leadType: 'lender-preapproved',
			dataExport: 0,
			sortBy: sortBy ? sortBy : sortParams.sortBy,
			sortOrder: sortOrder ? sortOrder : sortParams.sortOrder,
			queryParams,
		});
		const data = response.data.data?.cl_leads;
		setTableData({
			...tableData,
			rows: data?.data || [],
		});

		setCurrentPage(page);
		setPagination(response?.data?.data.cl_leads.pagination);
		setLoading(false);
	};
	const onSortChange = ({ sortOrder, sortBy }) => {
		setSortParams({ sortBy, sortOrder });
		getPortfolio(false, sortOrder, sortBy);
	};
	const exportLead = async () => {
		const response = await LenderUgroService.getAdminFilteredPortfolio({
			type: state.userType,
			page,
			leadType: 'lender-preapproved',
			dataExport: 1,
			queryParams,
			sortBy: sortParams.sortBy,
			sortOrder: sortParams.sortOrder,
			responseType: 'blob',
		});
		downloadFile(response.data, 'lender_preapproved_leads' + +new Date());
	};

	const redirectToDetails = (leadId) => {
		navigate('/portfolio/view/' + leadId + '/details');
	};

	React.useEffect(() => {
		getPortfolio();
	}, [page]);
	React.useEffect(() => {
		if (!loading) getPortfolio(true);
	}, [queryParams]);
	React.useEffect(() => {
		dispatch({
			type: 'selectedColender',
			selectedColender: '/portfolio/pre-approved',
		});
		localStorage.setItem('selectedColender', '/portfolio/pre-approved');
	}, []);

	return (
		<PortfolioContainer>
			<SearchFilter
				setQueryParams={setQueryParams}
				productTypes={productType}
				productNames={productName}
				lenders={eligibleForLender}
				loading={loading}
			></SearchFilter>
			<div className='d-flex justify-content-end'>
				<Button
					type='btn-export'
					classNames='float-right  mt-2 mb-2'
					clickHandler={exportLead}
				>
					<i class="fa fa-file-excel-o mr-1" aria-hidden="true"></i>
					Export Excel
				</Button>
			</div>
			<Table
				checkbox={false}
				columns={tableData.columns}
				rows={tableData.rows}
				loading={loading}
				onSortChange={onSortChange}
				reloadTable={redirectToDetails}
			>
				{Boolean(!tableData.rows.length && !loading) && <TableNoRecords />}
			</Table>

			{pagination?.total_pages ? (
				<Pagination
					disabled={loading}
					setPage={setCurrentPage}
					total_pages={pagination.total_pages}
					total={pagination.total}
					showTotal={true}
				/>
			) : (
				''
			)}
		</PortfolioContainer>
	);
}

export default PreApproved;

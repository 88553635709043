import React from 'react';
import { Link } from 'react-router-dom';
const SuccessPage = ({
	type,
	handleAction,
	showActions = true,
	actionTitle,
	showCancel = true,
	message
}) => {
	if (type === 'partner')
		return (
			<div className='h-100 d-flex flex-column w-100 align-items-center justify-content-center'>
				<p>
					{message || 'Mail has been sent to initiate the onboarding process'}
				</p>
				{showActions && (
					<div>
						<button onClick={handleAction} className='btn btn-custom'>
							{actionTitle || 'Add Another'}
						</button>

						{showCancel && (
							<Link
								to={`/partner/onboarding/status`}
								className='btn btn-close ml-2'
							>
								Cancel
							</Link>
						)}
					</div>
				)}
			</div>
		);
	if (type === 'onboarding-success')
		return (
			<div className='h-100 d-flex flex-column w-100 align-items-center justify-content-center'>
				<p>
					{message ||
						'Your onboarding application has been submitted successfully'}
				</p>
				{showActions && (
					<div>
						<button onClick={handleAction} className='btn btn-custom'>
							{actionTitle || 'Ok'}
						</button>
					</div>
				)}
			</div>
		);
	return '';
};

export default SuccessPage;

import React from 'react';
import Modal from '../../../components/Modal';
import MasterDataService from '../../../services/master-data';
import { Context } from '../../../config/store';
import { toast } from 'react-toastify';
import { useParams } from 'react-router';
import message from '../../../constant/message';
import { objectsForEach } from '../../../common/objectToArray';
import Button from '../../../components/Common/Button';
import { useNavigate } from 'react-router';

const FinalSubmitToLender = ({ modelState, toggleModal }) => {
	const { state } = React.useContext(Context);
	const [comment, setComment] = React.useState('');
	const [errors, setErrors] = React.useState({});
	const [preapprovedLenders, setPreapprovedLenders] = React.useState([]);
	const [lenderListLoading, setLenderListLoading] = React.useState(false);
	const [lenderListError, setLenderListError] = React.useState(false);
	const [lenderListErrorMessage, setLenderListErrorMessage] =
		React.useState(false);
	const [submitLoading, setSubmitLoading] = React.useState(false);
	const [showConfirmation, setShowConfirmation] = React.useState(false);
	const [lenderId, setLenderId] = React.useState('');
	const leadId = useParams()['id'];
	const navigate = useNavigate();
	React.useEffect(() => {
		getPreapprovedLenders();
	}, []);
	const getPreapprovedLenders = async () => {
		setLenderListLoading(true);
		setLenderListError(false);
		setLenderListErrorMessage('');
		try {
			const response = await MasterDataService.getPreapprovedLenders(
				state.userType,
				'co-lender',
				leadId
			);

			let responseList = objectsForEach(
				response.data.data.lender_list,
				'STRING_VAL'
			);
			let lenderItems = responseList.map((response) => ({
				label: response.value,
				value: response.id,
			}));
			setLenderListLoading(false);
			if (lenderItems.length === 0) {
				setLenderListError(true);
				setLenderListErrorMessage('No Lender Found');
			} else {
				setPreapprovedLenders(lenderItems);
				setLenderId(lenderItems[0].value);
			}
		} catch (err) {
			setLenderListLoading(false);
			setLenderListError(true);
			setLenderListErrorMessage('Server side error occured fetching lenders');
		}
	};
	const savePreapprovedLenderLead = async () => {
		setSubmitLoading(true);
		try {
			const response = await MasterDataService.savePreapprovedLenderLead(
				state.userType,
				'co-lender',
				leadId,
				lenderId,
				comment
			);

			if (response?.data?.status?.code === 1) {
				const success = response?.data?.messages?.success;
				toast.success(
					success?.form?.[0] || success?.others?.[0] || message.SAVED_SUCCESS
				);
				toggleModal();
			}
			setSubmitLoading(false);
			redirectToDetails();
		} catch (err) {
			setSubmitLoading(false);
			let error = err?.response?.data?.messages?.error;
			toast.error(error?.form?.[0] || error?.others?.[0] || message.ERROR_FORM);
			setErrors(error);
		}
	};

	const getSelectedLender = () => {
		let lender = preapprovedLenders.find((lender) => {
			return lender.value === lenderId;
		});
		return lender.label || '';
	};

	const redirectToDetails = () => {
		navigate('/portfolio/assigned/');
	};

	const formSubmitHandler = (e) => {
		e.preventDefault();
		if (lenderId && !submitLoading) {
			savePreapprovedLenderLead();
		}
	};

	return (
		<Modal state={modelState} closeModal={toggleModal}>
			{!showConfirmation ? (
				<form
					onSubmit={() => {
						setShowConfirmation(true);
					}}
				>
					{lenderListLoading ||
					lenderListError ||
					preapprovedLenders.length === 0 ? (
						<div>
							{lenderListLoading && <div className='loader'></div>}
							{lenderListError && (
								<div className='lender-error'>{lenderListErrorMessage}</div>
							)}
							{preapprovedLenders.length === 0 && !lenderListLoading && (
								<div className='d-flex'>
									<button
										type='button'
										className='btn btn-outline-custom ml-2'
										data-dismiss='modal'
										onClick={toggleModal}
									>
										Cancel
									</button>
								</div>
							)}
						</div>
					) : (
						<>
							<div className='form-group mb-0'>
								<div className='custom_control d-flex '>
									{(preapprovedLenders || []).map(({ value, label }) => (
										<div className='custom_radio_control mr-4' key={value}>
											<input
												onChange={(e) => setLenderId(e.target.value)}
												type='radio'
												name='entity-type'
												value={value}
												checked={value === lenderId}
											/>
											<label className='ml-1'>{label}</label>
										</div>
									))}
								</div>
								{errors && (
									<p className='mt-2 mb-0 text-danger'>
										{errors?.fields?.lender_id?.[0]}
									</p>
								)}
							</div>
							<div className='form-group'>
								<label>Comment</label>
								<textarea
									className='form-control custom-form-control'
									cols={4}
									rows={4}
									value={comment}
									onChange={(e) => setComment(e.target.value)}
									defaultValue={''}
								/>
								{errors && (
									<p className='mt-2 mb-0 text-danger'>
										{errors?.fields?.comment?.[0]}
									</p>
								)}
							</div>
							<div className='d-flex'>
								<button
									type='submit'
									className='btn btn-custom'
									disabled={!lenderId}
								>
									Submit
								</button>
								<button
									type='button'
									className='btn btn-outline-custom ml-2'
									data-dismiss='modal'
									onClick={toggleModal}
								>
									Cancel
								</button>
							</div>
						</>
					)}
				</form>
			) : (
				<div>
					<p className='font-weight-bold text-center'>
						Do you really want to Final submit this application to the{' '}
						{getSelectedLender()}?
					</p>
					<div className='d-flex align-items-center justify-content-center'>
						<Button
							clickHandler={() => {
								setShowConfirmation(false);
							}}
							classNames='btn-outline-custom small text-orange mr-2'
						>
							No
						</Button>
						<Button
							clickHandler={formSubmitHandler}
							classNames='btn-custom small'
						>
							{submitLoading ? <div className='loader' /> : 'Yes'}
						</Button>
					</div>
				</div>
			)}
		</Modal>
	);
};

export default FinalSubmitToLender;

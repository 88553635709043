import './index.css';
import TableLoading from './TableLoading';
import React from 'react';

/**
 *
 * @param {Boolean} checkbox
 * @param {Array} tableHeaders
 * @param {Array} rows
 * @param {Function} update
 */
//  replyQuery={changePage}
export default function Table({
	rows,
	headerCheckboxUpdateCallback,
	rowCheckboxUpdateCallback,
	checkbox,
	customCheck,
	headerChecked,
	columns,
	children,
	onSortChange,
	type,
	loading,
	tableExtraClass,
	reloadTable,
	showHeaderCheckbox,
	click,
	...rest
}) {
	const headerCheckboxHandler = (e) => {
		let tableData = rows;

		for (var i = 0; i < tableData.length; i++) {
			if (customCheck(tableData[i])) tableData[i].checked = e.target.checked;
		}

		headerCheckboxUpdateCallback(e.target.checked, tableData);
	};

	const rowCheckboxHandler = (e, i) => {
		let tableData = rows;
		tableData[i].checked = e.target.checked;

		let selectedRowCount = tableData.filter((el) => {
			return el.checked;
		}).length;

		rowCheckboxUpdateCallback(tableData, selectedRowCount, e.target.checked, i);
	};

	const rowRenderer = () => {
		const checkboxHandler = (i) => (e) => {
			rowCheckboxHandler(e, i);
		};

		return rows.map((row, i) => {
			return (
				<tr key={i}>
					{Boolean(checkbox) &&
						((customCheck ? customCheck(row, i) : Boolean(checkbox)) ? (
							<td>
								<input
									type='checkbox'
									checked={row.checked}
									id={'row-checkbox-' + i}
									onChange={checkboxHandler(i)}
								></input>
							</td>
						) : (
							<td></td>
						))}
					{columns.map(
						(
							{ field, rowClass, component: Component, ...otherProps },
							index
						) => (
							<td key={index} className={rowClass}>
								{Component ? (
									<Component
										row={row}
										field={field}
										reloadTable={reloadTable}
										index={i}
										otherProps={otherProps}
										{...rest}
									/>
								) : row[field] || typeof row[field] === 'number' ? (
									row[field]
								) : (
									'---'
								)}
							</td>
						)
					)}
				</tr>
			);
		});
	};

	const childrenWithProps = React.Children.map(children, (child) => {
		// Checking isValidElement is the safe way and avoids a typescript
		// error too.
		if (React.isValidElement(child)) {
			return React.cloneElement(child, {});
		}
		return child;
	});

	return (
		<div className='table_section'>
			<div className='row'>
				<div className='col-md-12'>
					<div className='table-responsive'>
						<table className={'table table-striped ' + tableExtraClass}>
							<Header
								columns={columns}
								headerCheckboxHandler={headerCheckboxHandler}
								headerChecked={headerChecked}
								checkbox={checkbox}
								onSortChange={onSortChange}
								showHeaderCheckbox={showHeaderCheckbox}
							/>
							<tbody className='position-relative'>
								{loading && <TableLoading />}
								{children ? childrenWithProps : rowRenderer()}
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
	);
}
const Thead = ({ title, sort, field, onSortChange, i }) => {
	const [sortOrder, toggleSortOrder] = React.useState(false);
	const onSort = (sortFeild) => {
		if (onSortChange) {
			onSortChange({
				sortBy: sortFeild,
				sortOrder: sortOrder ? 'ASC' : 'DESC',
			});
		}
		toggleSortOrder(!sortOrder);
	};
	return (
		<th
			onClick={sort ? () => onSort(field) : () => {}}
			className={title === 'Action' || title === 'ROI' ? 'no-wrap' : ''}
		>
			{title}
			{sort && (
				<span style={{cursor:'pointer'}}>
					{' '}
					{sortOrder ? (
						<i class='fa fa-arrow-up' aria-hidden='true'></i>
					) : (
						<i class='fa fa-arrow-down' aria-hidden='true'></i>
					)}
				</span>
			)}
		</th>
	);
};

const Header = ({
	showHeaderCheckbox,
	checkbox,
	columns,
	headerChecked,
	headerCheckboxHandler,
	onSortChange,
}) => {
	return (
		<thead>
			<tr>
				{Boolean(checkbox) &&
					(Boolean(showHeaderCheckbox) ? (
						<th>
							<input
								type='checkbox'
								checked={headerChecked}
								id='header-checkbox'
								onChange={headerCheckboxHandler}
							></input>
						</th>
					) : (
						<th></th>
					))}

				{columns.map(({ title, sort, field }, i) => (
					<Thead
						title={title}
						sort={sort}
						onSortChange={onSortChange}
						field={field}
						key={field}
					/>
				))}
			</tr>
		</thead>
	);
};

import React from 'react';
import SectionContent from './SectionContent';
const Section = ({ title, params }) => {
	return (
		<>
			{' '}
			<div className='content'>
				<h5>{title}</h5>
				<SectionContent params={params} />
			</div>
			<hr className='m-0' />{' '}
		</>
	);
};

export default Section;

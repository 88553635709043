import React from "react";
import { useParams } from "react-router";
import LenderUgroService from "../../../../services/lender";
import Table from "../../../../components/Common/Table";
import TableNoRecords from "../../../../components/Common/Table/TableNoRecords";
import message from "../../../../constant/message";
import { toast } from "react-toastify";
import { Context } from "../../../../config/store";

export default function AuditTrail({ selectedLender }) {
  const { state, dispatch } = React.useContext(Context);
  const leadId = useParams().id;

  const columns = [
    { title: "Description", field: "activity_desc" },
    // { title: 'Browser Info', field: 'browser_info' },
    {
      title: "CLM Type",
      field: "lead_type",
      component: ({ row }) => (
        <>
          {" "}
          {row.lead_type == 1 && "Pre Approved"}
          {row.lead_type == 2 && "Post Approved"}
          {row.lead_type == 3 && "Pre and then Post Approved"}
        </>
      ),
    },
    { title: "Created", field: "created_at" },
  ];
  const [tableData, setTableData] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const getAuditTrailList = async () => {
    setLoading(true);
    try {
      const response = await LenderUgroService.getLeadAuditTrail(
        leadId,
        selectedLender,
        "cl_lead"
      );
      const data = response.data.data?.activity_logs;
      const newData = data.map((value) => {
        value.activity_desc = value.organization + " " + value.activity_desc;
        return value;
      });
      setTableData(newData);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      let error = err?.response?.newData?.messages?.error;
      toast.error(error?.form?.[0] || error?.others?.[0] || message.ERROR_FORM);
    }
  };

  React.useEffect(() => {
    getAuditTrailList();
  }, [selectedLender]);

  return (
    <>
      <Table
        checkbox={false}
        columns={columns}
        rows={tableData}
        loading={loading}
        tableExtraClass={""}
        reloadTable={getAuditTrailList}
      >
        {Boolean(!tableData.length && !loading) && <TableNoRecords />}
      </Table>
      {/* queryInfo.cl_lender_id  &&*/}
    </>
  );
}

import React from 'react';
import { registerAffiliate } from '../services/manage-affiliate';
import { toast } from 'react-toastify';
import message from '../constant/message';
import SuccessPage from '../pages/SucessPage';
const AddAffiliate = ({}) => {
	const [affiliateType, setAffiliateType] = React.useState(1);
	const [companyName, setCompanyName] = React.useState('');
	const [email, setEmail] = React.useState('');
	const [phone, setPhone] = React.useState('');
	const [errors, setErrors] = React.useState(null);
	const [loading, setLoading] = React.useState(false);
	const [partType, setPartType] = React.useState('');
	const [showSuccessPage, setSuccessPageStatus] = React.useState(false);
	const submit = async e => {
		setLoading(true);
		e.preventDefault();
		const user = {
			name: companyName,
			email: email,
			mobile_no: phone,
			part_type: partType,
			affiliate_type: affiliateType
		};
		const response = await registerAffiliate(user);
		if (response?.status?.code === 1) {
			toast.success(message.ADD_SUCCESS);
			// toggleModal(false);
			// reloadTable();

			setSuccessPageStatus(true);
			clearValues();
		} else {
			let error = response.messages.error;
			setErrors(error);
			toast.error(error?.form?.[0] || error?.others?.[0] || message.ERROR);
		}
		setLoading(false);
	};
	const clearValues = () => {
		setCompanyName('');
		setEmail('');
		setPhone('');
		setAffiliateType(1);
	};
	if (showSuccessPage)
		return (
			<SuccessPage
				handleAction={() => setSuccessPageStatus(false)}
				type={'partner'}
			/>
		);
	return (
		<div className=' mt-4'>
			{/* Modal body */}
			<div className='modal-body col-6'>
				<h5 className=' text-orange font-r'>Add Partner</h5>
				<form>
					<div className='form-group pt-2'>
						{' '}
						<div className='row no-gutters'>
							<div
								className='custom-control custom-radio'
								onClick={e => setPartType(1)}
							>
								<input
									type='radio'
									className='custom-control-input'
									name='leadStatus'
									value={1}
									id={1}
									checked={partType == 1}
								/>
								<label
									className='custom-control-label mt-1'
									for='defaultUnchecked'
								>
									Retail DSA
								</label>
							</div>
							<div
								className='custom-control custom-radio ml-2'
								onClick={e => setPartType(2)}
							>
								<input
									type='radio'
									className='custom-control-input'
									name='leadStatus'
									value={2}
									id={2}
									checked={partType == 2}
								/>
								<label
									className='custom-control-label mt-1'
									for='defaultUnchecked'
								>
									Corporate DSA
								</label>
							</div>
							<div
								className='custom-control custom-radio ml-2'
								onClick={e => setPartType(3)}
							>
								<input
									type='radio'
									className='custom-control-input'
									name='leadStatus'
									value={2}
									id={2}
									checked={partType == 3}
								/>
								<label
									className='custom-control-label mt-1'
									for='defaultUnchecked'
								>
									Connector
								</label>
							</div>
							<div
								className='custom-control custom-radio ml-2'
								onClick={e => setPartType(4)}
							>
								<input
									type='radio'
									className='custom-control-input'
									name='leadStatus'
									value={2}
									id={2}
									checked={partType == 4}
								/>
								<label
									className='custom-control-label mt-1'
									for='defaultUnchecked'
								>
									Vendor
								</label>
							</div>
						</div>
						{errors && (
							<p className='mt-2 mb-0 text-danger'>
								{errors?.fields?.part_type?.[0]}
							</p>
						)}
					</div>

					<div className='form-group'>
						<label>
							Affiliate Type<tt>*</tt>
						</label>
						<select
							value={affiliateType}
							onChange={e => setAffiliateType(e.target.value)}
							className='form-control'
						>
							<option value={1}>Individual </option>
							<option value={2}>Corporate</option>
						</select>
					</div>
					<div className='form-group'>
						<label>
							{affiliateType == 2 ? 'Company Name' : 'Name'}
							<tt>*</tt>
						</label>
						<input
							value={companyName}
							onChange={e => setCompanyName(e.target.value)}
							type='text'
							className='form-control'
							placeholder={affiliateType == 2 ? 'Company Name' : 'Name'}
						/>
						{errors && (
							<p className='mt-2 mb-0 text-danger'>
								{errors?.fields?.name?.[0]}
							</p>
						)}
					</div>
					<div className='form-group'>
						<label>
							Email<tt>*</tt>
						</label>
						<input
							value={email}
							onChange={e => setEmail(e.target.value)}
							type='email'
							className='form-control'
							placeholder='Name@company.com'
						/>
						{errors && (
							<p className='mt-2 mb-0 text-danger'>
								{errors?.fields?.email?.[0]}
							</p>
						)}
					</div>
					<div className='form-group'>
						<label>
							Phone Number<tt>*</tt>
						</label>
						<input
							value={phone}
							onChange={e => setPhone(e.target.value)}
							type='number'
							className='form-control'
							placeholder='Phone Number'
						/>
						{errors && (
							<p className='mt-2 mb-0 text-danger'>
								{errors?.fields?.mobile_no?.[0]}
							</p>
						)}
					</div>
					<button
						disabled={loading}
						onClick={submit}
						type='submit'
						className='btn btn-custom mr-2'
					>
						{loading ? <div className='loader' /> : 'Submit'}
					</button>
				</form>
			</div>
		</div>
	);
};

export default AddAffiliate;

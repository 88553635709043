import React, { useEffect, useState } from 'react'
import Table from '../../../components/Common/Table'
import TableNoRecords from '../../../components/Common/Table/TableNoRecords'
import Pagination from '../../../components/Pagination'
import PortfolioContainer from '../../../components/Portfolio/PortfolioContainer'
import SearchFilter from '../../../components/Portfolio/SearchFilter'
import { Context } from '../../../config/store'
import { eligibleForLender, productName, productType } from '../../../constant/selectOptions'
import objectToQueryParams from '../../../common/objectToQueryParams';
import LenderUgroService from '../../../services/lender'
import TableColumns from './TableColumns'
import './index.css'
import Button from '../../../components/Common/Button'
import { downloadFile } from '../../../common/download'
import { toast } from 'react-toastify'

const PostDisbursalCaseView = () => {
  let sessionStorageData = JSON.parse(sessionStorage.getItem('searchData')) || {};
  let { org_page = 1 } = sessionStorageData;
  const queryString = objectToQueryParams(sessionStorageData);
  const [page, setCurrentPage] = useState(org_page);
  const [pagination, setPagination] = React.useState({});
  const [tableData, setTableData] = React.useState([])
  const [queryParams, setQueryParams] = React.useState(queryString);
  const [loading, setLoading] = React.useState(true)
  const { state, dispatch } = React.useContext(Context);
  const [excelLoader, setExcelLoader] = React.useState(false)
  const [sortParams, setSortParams] = React.useState({
    sortBy: "",
    sortOrder: "",
  });

  React.useEffect(() => {
    dispatch({
      type: 'selectedColender',
      selectedColender: '/portfolio/post-disbursal',
    });
    localStorage.setItem('selectedColender', '/portfolio/post-disbursal')
  }, []);

  useEffect(() => {
    getPostDisbursalCases()
  }, [page, queryParams])

  const getPostDisbursalCases = async (queryParams, sortBy,sortOrder) => {
    setLoading(true);
    try {
      const response = await LenderUgroService.getFilteredPortfolio({
        type: state.userType,
        leadType: 'disbursal-approved',
        isOps: true,
        page: page,
        dataExport: 0,
        queryParams,
        sortBy: sortBy ? sortBy : sortParams.sortBy,
        sortOrder: sortOrder ? sortOrder : sortParams.sortOrder,
      }
      );
      const data = response.data.data?.cl_leads?.data;
      setTableData(data || [])
      setCurrentPage(page);
      // Update session storage data if page changed
      let searchDataNew = { ...sessionStorageData, org_page: page }
      sessionStorage.setItem('searchData', JSON.stringify(searchDataNew))
      setPagination(response?.data?.data?.cl_leads?.pagination || {});
      setLoading(false)
    } catch (err) {
      console.log("error ", err);
    }
  };

  const exportLead = async () => {
    let response = null;
    setExcelLoader(true)
    try {
      response = await LenderUgroService.getAdminFilteredPortfolio({
        type: state.userType,
        page,
        leadType: 'disbursal-approved',
        dataExport: 1,
        queryParams,
        sortBy: sortParams.sortBy,
        sortOrder: sortParams.sortOrder,
        responseType: "blob",
      });
      downloadFile(response.data, "post_disbursal" + new Date().toLocaleDateString())
      setExcelLoader(false)
    } catch (error) {
      toast.error("Something went wrong")
      setExcelLoader(false)
    }
  }
  const onSortChange = ({ sortOrder, sortBy }) => {
    setSortParams({ sortBy, sortOrder });
    getPostDisbursalCases(false, sortOrder, sortBy);
  };
  return (
    <PortfolioContainer>
      <SearchFilter
        setQueryParams={setQueryParams}
        productTypes={productType}
        productNames={productName}
        lenders={eligibleForLender}
        loading={loading}
      ></SearchFilter>
      <div className='d-flex justify-content-end'>
        {excelLoader ? <Button classNames='excelDownloadLoader my-3'> <div className="loader"></div></Button> :
          <Button
            type='btn-export'
            classNames='float-right  mt-2 mb-2'
            clickHandler={exportLead}
          >
            <i class="fa fa-file-excel-o mr-1" aria-hidden="true"></i>
            Export Excel
          </Button>
        }
      </div>
      <div className='post_disbursal_case'>
        <Table
          columns={TableColumns}
          rows={tableData || []}
          loading={loading}
          onSortChange={onSortChange}
        >
          {Boolean(!tableData.length) && <TableNoRecords />}
        </Table>
      </div>

      <div className="my-2">
        {pagination?.total_pages ? (
          <Pagination
            // disabled={loading}
            setPage={setCurrentPage}
            total_pages={pagination.total_pages}
            total={pagination.total}
          />
        ) : (
          ''
        )}
      </div>
    </PortfolioContainer>
  )
}

export default PostDisbursalCaseView;
import { useContext, useLayoutEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import Table from '../../../../components/Common/Table';
import TableNoRecords from '../../../../components/Common/Table/TableNoRecords';
import Pagination from '../../../../components/Pagination';
import { Context } from '../../../../config/store';
import LenderUgroService from '../../../../services/lender';
import AddUTRModal from './AddUTRModal';
import tableColumns from './tableColumns';
// import moment from 'moment'	

const UTRInfo = ({ selectedLender }) => {
	const { state } = useContext(Context);
	const leadId = useParams().id;
	const queryId = useParams().queryId;
	const location = useLocation();
	const [tableData, setTableData] = useState([]);
	const [page, setCurrentPage] = useState(1);
	const [pagination, setPagination] = useState({});
	const [loading, setLoading] = useState(true);
	const getUtrList = async () => {
		try {
			setLoading(true);
			let response = await LenderUgroService.getUTRDetails({
				type: state.userType,
				subType: state.userType === 'admin' ? 'co-lender' : '',
				leadId: leadId,
			});
			setTableData(response?.data?.data);
			setLoading(false);
			setCurrentPage(page);
			setPagination(response?.data?.data?.cl_leads?.pagination);
		} catch (error) {
			console.log(error)
			setLoading(false);
		}
	};

	const [modalState, setModalState] = useState(false);
	const toggleModal = () => {
		setModalState(!modalState);
	};

	useLayoutEffect(() => {
		getUtrList();
	}, [page, selectedLender, location.pathname]);

	return (
		<div>
			<div className='row no-gutters justify-content-between align-items-center mt-2'>
				<div></div>
				<div className='mb-2'>
					<button className='btn btn-custom' onClick={toggleModal}>
						Add UTR
					</button>
					{modalState && (
						<AddUTRModal
							userType={state.userType}
							state={modalState}
							toggleModal={toggleModal}
							leadId={leadId}
							lenderId={selectedLender}
							reloadTable={getUtrList}
						/>
					)}
				</div>
			</div>
			<Table
				checkbox={false}
				columns={tableColumns}
				rows={tableData}
				loading={loading}
				reloadTable={getUtrList}
			>
				{Boolean(!tableData.length && !loading) && <TableNoRecords />}
			</Table>

			{pagination?.total_pages ? (
				<Pagination
					setPage={setCurrentPage}
					total_pages={pagination.total_pages}
					total={pagination.total}
					showTotal={true}
				/>
			) : (
				''
			)}
		</div>
	);
};

export default UTRInfo;

import React from 'react';
import Sidebar from './Sidebar';
import Header from './Header';
import { Context } from '../config/store';
import { getLoggedInUser } from '../services/auth';

const Container = ({ children }) => {
	return (
		<>
			<Header />
			<section className='section h-100'>
				<div className='container-fluid pl-0 overflow-hidden'>
					<div className='row flex-row flex-nowrap h-100'>
						<input type='checkbox' id='handler' hidden />
						<div>
							<Sidebar />
						</div>
						<div className='main-container content-width overflow-scroll page_view' style={{width:"98%" , padding:"3px 17px"}}>{children}</div>
					</div>
				</div>
			</section>
		</>
	);
};

export default Container;

import React from 'react';
import SharedLeadStatus from '../components/Dashboard/SharedLeadStatus';
import LeadSharedPerformance from '../components/Dashboard/LeadSharedPerfromance';
import ProductWiseLeads from '../components/Dashboard/ProductWiseLeads';
import DailyActivities from '../components/Dashboard/DailyActivities';
import Assignes from '../components/Dashboard/Assignes';
import { Context } from '../config/store';
import { getConfigUsers } from '../services/dashboard';
import Select from 'react-select';
import { leadTypes } from '../constant/constants';
import splitHyphenToCaptialise from '../common/splitHyphenToCaptialise';
import config from '../services/config';
import { objectsForEach } from '../common/objectToArray';
import { useNavigate } from 'react-router';
const Dashboard = () => {
	const { state } = React.useContext(Context);
	const [configData, setConfigData] = React.useState({});
	const [businessType, setBusinessType] = React.useState();
	const [userTypes, setUserTypes] = React.useState();
	const navigate = useNavigate();
	const [type, setType] = React.useState(
		state?.user?.part_type === 1 ? 'affiliate' : 'dsa'
	);
	const [leadType, setLeadType] = React.useState();
	const [stateId, setStateId] = React.useState();
	const [cityId, setCityId] = React.useState();
	const [cities, setCities] = React.useState([]);
	const [loanType, setLoanType] = React.useState();
	const [productType, setProductType] = React.useState();
	const [campaignCode, setCampaignCode] = React.useState();
	const [sourceId, setSourceId] = React.useState('');
	React.useEffect(() => {
		configUsers();
	}, []);
	const configUsers = async () => {
		const response = await getConfigUsers({ type: state.userType });
		let business_type = (response.data?.business_type || []).map(
			business_id => ({
				label: business_id.name,
				value: business_id.id
			})
		);
		let configResponse = {
			user_type: response.data.user_type,
			business_type,
			user_ids: (response.data?.user_ids || []).map(user_id => ({
				label: `${user_id.full_name || ''} ${(Boolean(user_id.emp_code) &&
					'-') ||
					''} ${`${user_id.emp_code || ''}` || ''}`,
				value: user_id.user_id
			})),
			loan_type: (response.data?.loan_type || []).map(loan => ({
				label: loan.type,
				value: loan.value
			})),
			campaign_code: (response.data?.campaign_code || []).map(c_code => ({
				label: splitHyphenToCaptialise(c_code),
				value: c_code
			})),
			product_type: (response.data?.product_type || []).map(product => ({
				label: product.prod_name,
				value: product.prod_id
			})),
			sourceTypes: (state.config?.sourcetype || []).map(sourceType => ({
				label: sourceType.name,
				value: sourceType.id
			})),
			states: (state.config?.states || []).map(state => ({
				label: state.name,
				value: state.id
			}))
		};

		if (response.data.user_type !== 1) {
			setBusinessType(business_type);
		}
		setConfigData(configResponse);
	};
	const onStateChange = async state => {
		setStateId(state);
		const response = await config.getCityByState(
			state.map(stateId => stateId.value).join(',')
		);
		if (response?.status?.code === 1) {
			setCityId('');
			setCities(
				(objectsForEach(response.data.cities, 'STRING_VAL') || []).map(
					city => ({
						label: city.id,
						value: city.value
					})
				)
			);
		}
	};
	return (
		<>
			<div className=' pl-0'>
				{' '}
				{state.userType === 'admin' && (
					<div className='d-flex flex-wrap  mt-2'>
						<div className='w-240p'>
							<label>Business Type</label>{' '}
							<Select
								isMulti
								isDisabled={configData.user_type !== 1}
								className='border-none'
								value={businessType}
								options={configData.business_type}
								onChange={selectBusinessType =>
									setBusinessType(selectBusinessType)
								}
							/>
						</div>

						<div className='w-240p  ml-2'>
							<label>Assigned To</label>{' '}
							<Select
								isMulti
								className='border-none'
								value={userTypes}
								options={configData.user_ids}
								onChange={selectUserTypes => setUserTypes(selectUserTypes)}
							/>
						</div>
						<div className='w-240p  ml-2'>
							<label>User Type</label>{' '}
							<Select
								isMulti
								className='border-none'
								value={userTypes}
								options={configData.user_ids}
								onChange={selectUserTypes => setUserTypes(selectUserTypes)}
							/>
						</div>

						<div className='w-240p ml-2'>
							<label>Loan Type</label>{' '}
							<Select
								isMulti
								className='border-none'
								value={loanType}
								options={configData.loan_type}
								onChange={loan => setLoanType(loan)}
							/>
						</div>
						<div className='w-240p ml-2'>
							<label>Product Type</label>{' '}
							<Select
								isMulti
								className='border-none '
								value={productType}
								options={configData.product_type}
								onChange={product => setProductType(product)}
							/>
						</div>
						<div className='w-240p ml-2'>
							<label>Campaign Code</label>{' '}
							<Select
								isMulti
								className='border-none'
								value={campaignCode}
								options={configData.campaign_code}
								onChange={camp_code => setCampaignCode(camp_code)}
							/>
						</div>
						<div className='w-80p  ml-2 mt-2'>
							<label>CLM Type</label>{' '}
							<Select
								className='border-none'
								value={leadType}
								options={leadTypes}
								onChange={leadType => setLeadType(leadType)}
							/>
						</div>
						<div className='w-80p  ml-2 mt-2'>
							<label>Source Type</label>{' '}
							<Select
								className='border-none'
								value={sourceId}
								options={configData.sourceTypes || []}
								onChange={source => setSourceId(source)}
							/>
						</div>
						<div className='w-80p  ml-2 mt-2'>
							<label>State</label>{' '}
							<Select
								isMulti
								className='border-none'
								value={stateId}
								options={configData.states || []}
								onChange={onStateChange}
							/>
						</div>
						{Boolean(cities.length) && (
							<div className='w-80p  ml-2 mt-2'>
								<label>City</label>{' '}
								<Select
									isMulti
									className='border-none'
									value={cityId}
									options={cities}
									onChange={city => setCityId(city)}
								/>
							</div>
						)}
					</div>
				)}
				<div className='row'>
					{state?.user?.part_type === 3 && (
						<div className='col-md-12 mt-4'>
							<select
								value={type}
								onChange={e => setType(e.target.value)}
								className='form-control custom-textfield height float-right mb-2'
							>
								<option value={'affiliate'}>Leads</option>
								<option value={'dsa'}>Application</option>{' '}
							</select>
						</div>
					)}
					<div className='col-md-8'>
						<SharedLeadStatus
							type={
								state.userType !== 'admin' && state?.user?.part_type !== 1
									? type
									: state.userType
							}
							businessType={businessType}
							userTypes={userTypes}
							loanType={loanType}
							productType={productType}
							campaignCode={campaignCode}
							leadType={leadType}
							sourceId={sourceId}
							stateId={stateId}
							cityId={cityId}
						/>

						{(state.userType === 'admin' || type !== 'dsa') && (
							<DailyActivities
								type={
									state.userType !== 'admin' && state?.user?.part_type !== 1
										? type
										: state.userType
								}
							/>
						)}
					</div>
					<div className='col-md-4 pl-0'>
						<LeadSharedPerformance
							type={
								state.userType !== 'admin' && state?.user?.part_type !== 1
									? type
									: state.userType
							}
							businessType={businessType}
							userTypes={userTypes}
							loanType={loanType}
							productType={productType}
							campaignCode={campaignCode}
							leadType={leadType}
							sourceId={sourceId}
							stateId={stateId}
							cityId={cityId}
						/>
						<ProductWiseLeads
							type={
								state.userType !== 'admin' && state?.user?.part_type !== 1
									? type
									: state.userType
							}
							businessType={businessType}
							userTypes={userTypes}
							loanType={loanType}
							productType={productType}
							campaignCode={campaignCode}
							leadType={leadType}
							sourceId={sourceId}
							stateId={stateId}
							cityId={cityId}
						/>
						<Assignes
							type={
								state.userType !== 'admin' && state?.user?.part_type !== 1
									? type
									: state.userType
							}
							businessType={businessType}
							userTypes={userTypes}
							loanType={loanType}
							productType={productType}
							campaignCode={campaignCode}
							leadType={leadType}
							sourceId={sourceId}
							stateId={stateId}
							cityId={cityId}
						/>
					</div>
				</div>
			</div>
		</>
	);
};

export default Dashboard;

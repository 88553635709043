import React from 'react';
import { useParams, useNavigate } from 'react-router';
import { objectsForEach } from '../../../common/objectToArray';
import {
	getPartnerKycDetails,
	savePartnerKycDetails,
} from '../../../services/manage-affiliate';
import { Context } from '../../../config/store';
import GenerateCode from './GenerateCode';
import Table from '../../../components/Common/Table';
import TableNoRecords from '../../../components/Common/Table/TableNoRecords';
import RequiredUserModal from './RequiredUserModal';
import RequiredUserActions from './RequiredUserActions';
import { toast } from 'react-toastify';
import message from '../../../constant/message';
const RequiredUserDetails = () => {
	const { state } = React.useContext(Context);
	const [loading, setLoading] = React.useState(false);
	const [body, setBody] = React.useState([]);
	const [responseData, setResponseData] = React.useState(null);
	// const [consentOne, setConsentOne] = React.useState(false);
	// const [consentTwo, setConsentTwo] = React.useState(false);
	const [editIndex, setEditIndex] = React.useState();
	const [showModal, setShowModal] = React.useState(false);
	const params = useParams();
	const navigate = useNavigate();
	React.useEffect(() => {
		getPartnerInfo();
	}, []);
	const toggleModal = (index) => {
		if (typeof index == 'number') {
			setEditIndex(index);
		} else {
			setEditIndex(null);
		}
		setShowModal(!showModal);
	};
	const getPartnerInfo = async () => {
		setLoading(true);
		const response = await getPartnerKycDetails(state.userType, params.id);
		let partner_users = response.data.data.partner_user || [];
		setBody(partner_users.length ? partner_users : []);
		setResponseData(response.data.data);
		// setConsentOne(response.data.data.resource.consent_one);
		// setConsentTwo(response.data.data.resource.consent_two);
		// setDetails
		setLoading(false);
	};

	const checkHasPermission = (checkPermission) => {
		// if (state.is_super_admin === 1) return true;
		const permission = state.permission.find(
			(permission) => permission === checkPermission
		);

		if (permission) return true;
		else return false;
	};
	const showAddEdit = () =>
		state.userType == 'admin'
			? checkHasPermission('edit-partner-detail')
			: true;
	const submitHandler = async () => {
		setLoading(true);

		const requestBody = {
			info_type: 5,
		};
		let response;
		try {
			response = await savePartnerKycDetails(
				state.userType,
				params.id,
				requestBody
			);

			if (response?.data?.status?.code === 1) {
				let success = response?.data?.messages?.success;
				toast.success(
					success?.form?.[0] || success?.others?.[0] || message.SAVED_SUCCESS
				);
				navigate(
					state.userType === 'admin'
						? '/partner/onboarding/status'
						: `/onboarding/thanks`
				);
			}
		} catch (err) {
			let error = err?.response?.data?.messages?.error;
			toast.error(error?.form?.[0] || error?.others?.[0] || message.ERROR_FORM);
		}

		setLoading(false);
	};
	const requiredUserDetails = {
		User_Details: [
			{ field: 'f_name', title: 'First Name', required: true },
			{ field: 'l_name', title: 'Last Name', required: true },
			{ field: 'email', title: 'Email', type: 'email', required: true },
			{ field: 'mobile_no', title: 'Mobile No.', required: true },
			{
				field: 'branch_name',
				id: 'branch_id',
				title: 'Branch',
				type: 'select',
				options: responseData?.branch_list_master
					? objectsForEach(responseData?.branch_list_master, 'STRING_VAL').map(
							({ id, value }) => ({ value: id, label: value })
					  )
					: [],
				required: true,
			},
			{
				field: 'part_user_code',
				title: 'User Code',
				hideField: true,
			},

			{
				title: 'Actions',
				field: 'part_user_code',
				hideField: true,
				toggleModal,
				component: RequiredUserActions,
				showAddEdit,
				responseData,
			},
		],
	};

	return (
		<div>
			<div className='d-flex align-items-center justify-content-between'>
				<div>
					<div className='form-group'>
						<label className='text-orange'>Partner Code</label>
						<div>
							<span>{responseData?.resource?.channel_code}</span>
						</div>
					</div>
				</div>
				{showAddEdit() && (
					<button
						onClick={toggleModal}
						type='button'
						className='btn btn-custom mt-2 mb-2'
					>
						Add User
					</button>
				)}
			</div>
			<Table
				columns={requiredUserDetails.User_Details}
				rows={body}
				loading={loading}
				reloadTable={getPartnerInfo}
			>
				{Boolean(!body.length && !loading) && <TableNoRecords />}
			</Table>
			<div>
				{/* I hereby grant my unconditional consent to Ugro Capital Limited
				(“Agent”) to receive my credit information offered by Credit Information
				Bureau (India) Limited (“CIBIL”) on my behalf and use it in the manner
				consistent with the Agent’s End Use Policy or the Terms of
				Understanding, as the case may be, and the Agent has granted its consent
				for being appointed for the aforesaid purpose. I hereby represent and
				acknowledge that: */}
				{/* <div className='mt-2'>
					<div>
						<input
							type='checkbox'
							checked={consentOne}
							onChange={(e) => changeState(e.target.checked, 1)}
							disabled={
								state.userType === 'admin'
									? !checkHasPermission('edit-partner-detail')
									: false
							}
						/>
						<label className='ml-2'>
							<b>
								(a) I have carefully read the terms and conditions of the
								Agents’s End Use Policy and understood the same; or
							</b>
						</label>
						<Error errors={errors} id={'consent_one'} />
					</div>
				</div>{' '} */}
				{/* <div className='mt-2'>
					<div>
						<input
							type='checkbox'
							checked={consentTwo}
							onChange={(e) => changeState(e.target.checked, 2)}
							disabled={
								state.userType === 'admin'
									? !checkHasPermission('edit-partner-detail')
									: false
							}
						/>
						<label className='ml-2'>
							<b>
								(b) the Terms of Understanding in relation to the use of the
								Product has been agreed between me and my Agent.
							</b>
						</label>
						<Error errors={errors} id={'consent_two'} />
					</div>
				</div> */}
				{showAddEdit() && responseData?.resource?.nsm_status < 2 && (
					<>
						<button
							onClick={submitHandler}
							type='button'
							className='btn btn-custom mt-2 ml-2'
						>
							{loading ? <div className='loader' /> : 'Submit'}
						</button>
					</>
				)}
			</div>
			{showModal && (
				<RequiredUserModal
					editIndex={editIndex}
					reloadTable={getPartnerInfo}
					userType={state.userType}
					partId={params.id}
					toggleModal={toggleModal}
					responseData={responseData}
					state={showModal}
					requiredUserDetails={requiredUserDetails.User_Details.filter(
						({ hideField }) => !hideField
					)}
					body={body}
				/>
			)}
		</div>
	);
};
export default RequiredUserDetails;

// This function uses the sum key and data to find the sum
// This only finds sum for integers and eliminate decimals
// You can modify it for decimals by using parseFloat ins-
// -tead of parseInt
export default function dynamicSum({ data, sumKey }) {
	const filteredSum = data.reduce(
		(a, b) => a + (b[sumKey] ? parseFloat(b[sumKey]) : 0),
		0
	);
	return filteredSum;
}

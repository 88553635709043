const LegalDispute = ({ onChange, value, disabled }) => {
	return (
		<div className='row no-gutters'>
			<div
				className='custom-control custom-radio'
				onClick={e => onChange({ target: { value: 1 } })}
			>
				<input
					disabled={disabled}
					type='radio'
					className='custom-control-input'
					name='leadStatus'
					value={1}
					id={1}
					checked={value == 1}
				/>
				<label className='custom-control-label mt-1' for='defaultUnchecked'>
					Yes
				</label>
			</div>
			<div
				className='custom-control custom-radio ml-2'
				onClick={e => onChange({ target: { value: 0 } })}
			>
				<input
					disabled={disabled}
					type='radio'
					className='custom-control-input'
					name='leadStatus'
					value={2}
					id={2}
					checked={!value}
				/>
				<label className='custom-control-label mt-1' for='defaultUnchecked'>
					No
				</label>
			</div>
		</div>
	);
};

export default LegalDispute;

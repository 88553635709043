import React, { useContext, useEffect, useState } from 'react'
import Cards from './Cards'
import Tables from './Tables'
import TotalCount from './TotalCount';
import { toast } from 'react-toastify';
import './Dashboard.css'
import { objectsForEach } from '../../../../common/objectToArray';
import { Context } from '../../../../config/store';
import Select from '../../../../components/Common/Input/Select';
import MasterDataService from '../../../../services/master-data';
import { downloadFile } from '../../../../common/download';
import excel from '../../../../assets/excel.png'
import DatePicker from 'react-datepicker';
import { getFormattedDate } from '../../../../common/date';


const NewDashboard = () => {
  const { state, dispatch } = useContext(Context);
  const [activeTab, setActiveTab] = useState('program')
  const [activePath, setActivePath] = useState('program-portfolio')
  const [selectedMonths, setSelectedMonths] = useState('')
  const [selectedlenderData, setSelectedlenderData] = useState('')
  const [selectedprograam, setSelectedprograam] = useState('')
  const [selectedyear, setSelectedyear] = useState('')
  const [showCrossIcon, setShowCrossIcon] = useState(false)
  const [portfolio, setportfolio] = useState([])
  const [productNames, setProducts] = useState([]);
  const [defaultProgram, setDefaultProgram] = useState([])
  const [total, setTotal] = useState()
  const [tableData, setTableData] = useState()
  const [loading, setLoading] = useState(false)
  const [assignDateFrom, setAssignDateFrom] = React.useState('');
  const [assignDateTo, setAssignDateTo] = React.useState('');
  const [leadType, setLeadType] = React.useState(null);
  const [exportLoader, setExportLoader] = useState(false)


  React.useEffect(() => {
    dispatch({
      type: 'selectedColender',
      selectedColender: '/portfolio/dashboard',
    });
    localStorage.setItem('selectedColender', '/portfolio/dashboard');
  }, []);

  const portfoliodetail = async () => {
    setLoading(true)
    const response = await MasterDataService.getportfoliodetail(
      state.userType,
      state.userType === 'admin' ? 'co-lender' : ''
    );
    if (response?.data?.status?.code === 1) {
      setportfolio(response?.data?.data?.portfolio)
    }
  };

  useEffect(() => {
    portfoliodetail()
  }, []);

  useEffect(() => {
    if ((assignDateFrom && assignDateTo) || (!assignDateFrom && !assignDateTo)) {
      getTableData(activeTab, activePath)
    }
  }, [selectedMonths, activeTab, activePath, selectedlenderData, selectedyear, selectedprograam, leadType, assignDateFrom, assignDateTo])

  const getTableData = async (activeStatus, path) => {
    let toDate = assignDateTo ? getFormattedDate(assignDateTo, 'dd/MM/yyyy') : ''
    let fromDate = assignDateFrom ? getFormattedDate(assignDateFrom, 'dd/MM/yyyy') : ''
    setLoading(true)
    setActiveTab(activeStatus)
    const response = await MasterDataService.getTableData(
      state.userType,
      state.userType === 'admin' ? 'co-lender' : '',
      path,
      selectedMonths,
      selectedlenderData,
      selectedprograam,
      selectedyear,
      leadType?.value ?? '',
      fromDate,
      toDate,
    );

    if (response?.data?.status?.code === 1) {
      setLoading(false)
      let TableDataGet = objectsForEach(response?.data?.data?.program_portfolio)
      let filter_tableData = TableDataGet.filter(TableDataGet => TableDataGet?.lead_assigned != 0)
      setTableData(filter_tableData)
      setTotal(response?.data?.data?.total)
    }
    // if (response.status === 200) {
    //   downloadFile(response.data, `${activeStatus}-${(new Date()).toLocaleDateString('en-GB').split('/').join('-')}`);
    // } else {
    //   toast.warning('downloaded faild')
    //   setLoading(false)
    // }
  }
  const getexcelTableData = async (activeStatus, path, exportCount) => {
    setExportLoader(true)
    let toDate = assignDateTo ? getFormattedDate(assignDateTo, 'dd/MM/yyyy') : ''
    let fromDate = assignDateFrom ? getFormattedDate(assignDateFrom, 'dd/MM/yyyy') : ''
    try {
      const response = await MasterDataService.getExcelTableData(
        state.userType,
        state.userType === 'admin' ? 'co-lender' : '',
        path,
        selectedMonths,
        selectedlenderData,
        selectedprograam,
        selectedyear,
        leadType?.value ?? '',
        fromDate,
        toDate,
        exportCount
      );
      if (response.status === 200) {
        downloadFile(response.data, `${activeStatus}-${(new Date()).toLocaleDateString('en-GB').split('/').join('-')}`);
        setExportLoader(false)
      } else {
        toast.warning('downloaded faild')
        setExportLoader(false)
        setLoading(false)
      }
    } catch (error) {
      toast.error(error.message)
    }
  }
  const productList = async () => {
    const response = await MasterDataService.getProductTypeList(
      state.userType,
      state.userType === 'admin' ? 'co-lender' : ''
    );
    if (response?.data?.status?.code === 1) {
      setLoading(false)
      let responseList = objectsForEach(
        response.data.data.product_category,
        'STRING_VAL'
      );
      let products = responseList.map((response) => ({
        label: response.value,
        value: response.id,
      }));
      setProducts(products);
    }
  };
  useEffect(() => {
    productList();
  }, []);
  // const MonthData = [
  //   { label: "Jan", value: "01" },
  //   { label: "Feb", value: "02" },
  //   { label: "Mar", value: "03" },
  //   { label: "Apr", value: "04" },
  //   { label: "May", value: "05" },
  //   { label: "Jun", value: "06" },
  //   { label: "Jul", value: "07" },
  //   { label: "Aug", value: "08" },
  //   { label: "Sep", value: "09" },
  //   { label: "Oct", value: "10" },
  //   { label: "Nov", value: "11" },
  //   { label: "Dec", value: "12" }
  // ]

  // const FYData = [
  //   { label: "2020-2021", value: "2020-2021" },
  //   { label: "2021-2022", value: "2021-2022" },
  //   { label: "2022-2023", value: "2022-2023" },
  // ]

  // const handleMonthData = (data) => {
  //   let months = '';
  //   let max = data?.length;
  //   data?.map((mon, index) => {
  //     if (index === max - 1) {
  //       months += mon.value
  //     } else {
  //       months += `${mon.value},`
  //     }
  //   })
  //   setSelectedMonths(months)
  // }
  const handleprograamData = (data) => {
    let months = '';
    let progItem = [];
    let max = data?.length;
    data?.map((mon, index) => {
      if (index === max - 1) {
        months += mon.value
        progItem.push(mon)
      } else {
        months += `${mon.value},`
        progItem.push(mon)
      }
    })
    setSelectedprograam(months)
    setDefaultProgram(progItem)
  }
  return (
    <>
      <div className='container subHeaderbar'>
        Dashboard
      </div>
      <Cards portfolio={portfolio} />
      <div style={{ padding: "5px 10px", backgroundColor: "#FFFF", borderRadius: "10px", marginTop: "0px", margin: '0px 8px', }}>
        <div >
          <div className='TabsContainer'>
            <div className='tab-item' onClick={() => {
              setActiveTab('program')
              setActivePath('program-portfolio')
            }}>
              <p className='activetab' >
                PROGRAM
              </p>
            </div>
          </div>
          <div className="filter_main_container">
            <div className="filter_view_container">
              <div className='filter_view_item form_input'>
                <Select
                  label='Program'
                  className={'dropdown'}
                  options={productNames}
                  onChange={(data) => handleprograamData(data)}
                  defaultValue={defaultProgram}
                />
              </div>
              {/* <div className='filter_view_item form_input'>
                <Select
                  label='Month'
                  options={MonthData}
                  className={'dropdown'}
                  onChange={(data) => handleMonthData(data)}
                  style={{ width: '100px' }}
                />
              </div> */}
              {/* <div className='filter_view_item form_input' style={{ width: '120px' }}>
                <label>{'Financial Year'}</label>
                <select
                  value={selectedyear}
                  className={'dropdown'}
                  onChange={(e) => {
                    setShowCrossIcon(true)
                    setSelectedyear(e.target.value)
                  }}
                  style={{ color: '#808080', width: "120px", backgroundColor: 'white', border: '1px solid #9e9e9e', display: 'flex', flexDirection: 'column', marginBottom: '0px', height: "25px" }}
                >
                  <option disabled selected value={''}>{'Select...'}</option>
                  {FYData.map((opt) => {
                    return <option style={{ color: 'black' }} value={opt?.value}>{opt?.label}</option>
                  })}
                </select>
                {showCrossIcon &&
                  <img onClick={() => {
                    setShowCrossIcon(false)
                    setSelectedyear("")
                  }}
                    src="https://cdn-icons-png.flaticon.com/512/1828/1828778.png"
                    alt="cross"
                    className='crossIcon'
                  />
                }
              </div> */}
              <div className='filter_view_item form_input' style={{ width: "130px" }}>
                <div className='form-group'>
                  <Select
                    isMulti={false}
                    label='CLM Type'
                    options={[
                      { label: 'All', value: '' },
                      { label: 'Option 1', value: 1 },
                      { label: 'Option 2', value: 2 },
                    ]}
                    value={leadType}
                    onChange={(data) => setLeadType(data)}
                  />
                </div>
              </div>
              <div className='col-md-6 filter_view_item form_input' >
                <div className='form-group'>
                  <label>Assigned Date </label>
                  <div className='d-flex justify-content-between'>
                    <div className='d-flex w-50 align-items-center'>
                      <span className='mr-2'>From</span>
                      <DatePicker
                        selected={assignDateFrom}
                        portalId='datepickerportal'
                        showMonthDropdown
                        yearDropdownItemNumber={100}
                        scrollableYearDropdown={true}
                        showYearDropdown
                        className='form-control'
                        dateFormat='dd/MM/yyyy'
                        isClearable={assignDateFrom ? true : false}
                        onChange={(selectedDate) =>
                          setAssignDateFrom(selectedDate)
                        } //only when value has changed
                      />
                    </div>

                    <div className='d-flex w-50 align-items-center'>
                      <span className='ml-2 mr-2'>To</span>
                      <DatePicker
                        selected={assignDateTo}
                        portalId='datepickerportal'
                        showMonthDropdown
                        yearDropdownItemNumber={100}
                        scrollableYearDropdown={true}
                        showYearDropdown
                        className='form-control'
                        dateFormat='dd/MM/yyyy'
                        isClearable={assignDateTo ? true : false}
                        onChange={(selectedDate) =>
                          setAssignDateTo(selectedDate)
                        } //only when value has changed
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='filter_right'>
              {/* {exportLoader ? <div className='loader'></div> : <img src={excel} alt="excel" className='iconItem' onClick={() => getexcelTableData(activeTab, activePath, 1)} />} */}
              {/* <img src={graph} alt="graph" className='iconItem' /> */}
              <button
                type='submit'
                className='btn btn-custom ml-2'
                onClick={() => getexcelTableData(activeTab, activePath, 1)} 
              >
                {exportLoader ? <div className='loader' /> : <> <i class="fa fa-file-excel-o mr-1" aria-hidden="true"></i>Export Excel </>}
              </button>
            </div>

          </div>
          {/* {loading ? <div className='loader' style={{ margin: "10% auto  auto auto" }}></div> : <>
            <div style={{ minHeight: 140 }}>
            <Tables tableData={tableData} />  
            </div>
          </>} */}
          {loading ? <div className='loader' style={{ margin: "10% auto  auto auto" }}></div> : <>
            <div style={{ minHeight: 140 }}>
              {tableData?.length > 0 ? <Tables activeTab={activeTab} tableData={tableData} /> : <div style={{ margin: "8% auto  auto 35%", fontWeight: 600, fontSize: 18 }}>This Lender has no Case assigned</div>}
            </div>
          </>}
        </div>


        {tableData?.length > 0 && <TotalCount activeTab={activeTab} total={total} />}
      </div>
    </>
  )
}

export default NewDashboard
import Login from '../pages/login';
import Dashboard from '../pages/dashboard';
import ManageLeads from '../pages/mange-leads';
import ManageAffiliate from '../pages/manage-affiliate';
import AddLead from '../pages/add-lead';
import ForgotPassword from '../pages/forgot-password';
import ResetPassword from '../pages/reset-password';
import AffiliateConfigurationSettings from '../pages/affiliate-configuration-settings';
import ManageUsers from '../pages/manage-users';
import ViewUser from '../pages/view-user';
import ManageRoles from '../pages/manage-roles';
import ViewRole from '../pages/view-role';
import ApiDetails from '../pages/api-details';
import AffiliateKyc from '../pages/affiliate-kyc';
import ChangePassword from '../pages/change-password';
import ComissionReport from '../pages/comission-report';
import ManageOffers from '../pages/manage-offers';
import ConfigureComission from '../pages/configure-comission';
import ContainerRouter from '../components/ContainerRouter';
import AssignedPortfolio from '../Views/Portfolio/AssignedPortfolio';
import UnassignedPortfolio from '../Views/Portfolio/UnassignedPortfolio';
import PendingPortfolio from '../Views/Portfolio/PendingPortfolio';
import ViewPortfolio from '../Views/Portfolio/ViewPortfolio';
import PendingView from '../Views/Portfolio/ViewPortfolio/PendingView';
import PortfolioDashboard from '../Views/Portfolio/Dashboard/PortfolioDashboard';
// import AdditionalInformation from '../Views/Portfolio/ViewPortfolio/AdditionalInformation';
import ApplicationDetails from '../pages/application-details';
import AddAffiliate from '../pages/add-affiliate';
import OnboardingForm from '../pages/onboarding-form';
import PreApproved from '../Views/Portfolio/PreapprovedPortfolio';
import History from '../Views/Portfolio/History';
import BatchHistory from '../Views/Portfolio/History/BatchHistory';
export default [
	{
		component: Login,
		path: '/admin/login',
		title: 'login',
		exact: true,
	},
	{
		component: ForgotPassword,
		path: '/admin/password/forget',
		title: 'forgotPassword',
		exact: true,
	},
	{
		component: ResetPassword,
		path: '/admin/password/reset',
		title: 'resetPassword',
		exact: true,
	},
	{
		component: ContainerRouter,
		path: '/*',
		title: 'container',
		type: 'admin',
		// exact: true
	},
	{
		component: Dashboard,
		path: '/',
		title: 'dashboard',
		exact: true,
		private: true,
		permission: 'view-dashboard',
	},
	{
		component: ManageAffiliate,
		path: '/nsm/approval',
		title: 'manageNSMApproval',
		exact: true,
		private: true,
		listType: 'nsm-partner-list',
	},
	{
		component: ManageAffiliate,
		path: '/manage-affiliate',
		title: 'manageAffiliate',
		exact: true,
		private: true,
		permission: 'manage_affiliate',
	},
	{
		component: ManageRoles,
		path: '/admin/roles',
		title: 'manageRole',
		exact: true,
		private: true,
	},
	{
		component: ViewRole,
		path: '/admin/role/add',
		title: 'manageRole',
		exact: true,
		private: true,
	},
	{
		component: ViewRole,
		path: '/admin/role/:roleId',
		title: 'manageRole',
		exact: true,
		private: true,
	},
	{
		component: ViewRole,
		path: '/admin/role/edit/:roleId',
		title: 'manageRole',
		exact: true,
		private: true,
	},
	{
		component: ManageLeads,
		path: '/manage-leads',
		title: 'manageLeads',
		exact: true,
		private: true,
		permission: 'lead-list',
	},
	{
		component: AddLead,
		path: '/lead/add',
		title: 'addNewLead',
		exact: true,
		private: true,
	},
	{
		component: ChangePassword,
		path: '/password/change',
		title: 'changePassword',
		exact: true,
		private: true,
	},
	{
		component: AddLead,
		path: '/lead/edit/:id',
		title: 'editLead',
		exact: true,
		private: true,
		permission: 'edit-lead',
	},
	{
		component: AddLead,
		path: '/lead/:id',
		title: 'leadDetails',
		exact: true,
		private: true,
	},
	{
		component: AffiliateKyc,
		path: '/admin/affiliate-kyc/*',
		title: 'affiliateKyc',
		exact: true,
		private: true,
		permission: 'affiliate-detail-kyc',
	},
	{
		component: AffiliateKyc,
		path: '/admin/affiliate-kyc/view/:partId',
		title: 'affiliateKyc',
		exact: true,
		private: true,
		permission: 'get-affiliate',
	},
	{
		component: AffiliateConfigurationSettings,
		path: '/admin/configure-affiliate/:partId',
		title: 'affiliateConfiguration',
		exact: true,
		private: true,
		permission: 'get-affiliate',
	},

	{
		component: ApiDetails,
		path: '/manage-affiliate/api-details/:partId',
		title: 'apiDetails',
		exact: true,
		private: true,
	},

	{
		component: ManageUsers,
		path: '/admin/manage-users',
		title: 'manageUsers',
		exact: true,
		private: true,
		permission: 'backend-user',
	},
	{
		component: ViewUser,
		path: '/admin/manage-users/add',
		title: 'addUser',
		exact: true,
		private: true,
		permission: 'add-backend-user',
	},
	{
		component: ViewUser,
		path: '/admin/manage-users/edit/:userId',
		title: 'editUser',
		exact: true,
		private: true,
		permission: 'get-backend-user',
	},

	{
		component: ViewUser,
		path: '/admin/manage-users/:userId',
		title: 'viewUsers',
		exact: true,
		private: true,
		permission: 'get-backend-user',
	},
	{
		component: ComissionReport,
		path: '/comission-report',
		title: 'comissionReports',
		exact: true,
		private: true,
		permission: 'generate-partner-commission',
	},

	{
		component: ComissionReport,
		path: '/comission-report/:partId',
		title: 'comissionReports',
		exact: true,
		private: true,
		permission: 'manage-commission',
	},
	{
		component: ManageOffers,
		path: '/offers/:partId',
		title: 'comissionReports',
		exact: true,
		private: true,
		permission: 'list-lead-offer-files',
	},
	{
		component: ManageLeads,
		path: '/offers/:partId/leads/:offerId',
		title: 'comissionReports',
		exact: true,
		private: true,
	},
	{
		component: ConfigureComission,
		path: '/manage-affiliate/cofigure-comission/:partId',
		title: 'comissionReports',
		exact: true,
		private: true,
		permission: 'get-partner-commission-config',
	},
	{
		component: AssignedPortfolio,
		path: '/portfolio/assigned',
		title: 'assignedPortfolio',
		exact: true,
		private: true,
		permission: 'get-sanctioned-lead-list',
		customComponentProps: { leadType: 'assigned' },
	},
	{
		component: AssignedPortfolio,
		path: '/portfolio/sanction-rejected',
		title: 'sanctionrejectedPortfolio',
		exact: true,
		private: true,
		permission: 'get-sanctioned-lead-list',
		customComponentProps: { leadType: 'rejected' },
	},
	{
		component: AssignedPortfolio,
		path: '/portfolio/disbursal-pending',
		title: 'disbursalPendingPortfolio',
		exact: true,
		private: true,
		permission: 'get-disbursal-pending-lead-list',
		customComponentProps: { isOps: true, leadType: 'disbursal-pending' },
	},
	{
		component: AssignedPortfolio,
		path: '/portfolio/disbursal-approved',
		title: 'disbursalPendingPortfolio',
		exact: true,
		private: true,
		permission: 'get-disbursal-approved-lead-list',
		customComponentProps: { isOps: true, leadType: 'disbursal-approved' },
	},
	
	{
		component: AssignedPortfolio,
		path: '/portfolio/disbursal-rejected',
		title: 'disbursalRejectedPortfolio',
		exact: true,
		private: true,
		permission: 'get-disbursal-rejected-lead-list',
		customComponentProps: { isOps: true, leadType: 'disbursal-rejected' },
	},
	{
		component: UnassignedPortfolio,
		path: '/portfolio/unassigned',
		title: 'unassignedPortfolio',
		exact: true,
		private: true,
		permission: 'get-unassigned-lead-list',
	},
	{
		component: PortfolioDashboard,
		path: '/portfolio/dashboard',
		title: 'portfolioDashboard',
		exact: true,
		private: true,
		permission: 'view-colender-dashboard',
	},
	{
		component: History,
		path: '/portfolio/audit/history',
		title: 'auditHistory',
		exact: true,
		private: true,
	},
	{
		component: BatchHistory,
		path: '/portfolio/audit/history/:batchId',
		title: 'batchAuditHistory',
		exact: true,
		private: true,
	},
	// {
	// 	component: AdditionalInformation,
	// 	path: '/portfolio/unassigned/additional/:id',
	// 	title: 'additionalInformation',
	// 	exact: true,
	// 	private: true
	// },
	{
		component: PendingPortfolio,
		path: '/portfolio/pending',
		title: 'pendingPortfolio',

		private: true,
		permission: 'get-pending-lead-list',
	},
	{
		component: PreApproved,
		path: '/portfolio/pre-approved',
		title: 'preapprovedPortfolio',
		private: true,
		permission: 'get-decision-lead-list',
	},
	{
		component: ViewPortfolio,
		path: '/portfolio/view/:id/*',

		private: true,
	},
	{
		component: PendingView,
		path: '/portfolio/pending/:id',
		private: true,
	},
	{
		component: AddAffiliate,
		path: '/partner/onboarding/add',
		title: 'manageRole',
		exact: true,
		private: true,
	},
	{
		component: ManageAffiliate,
		path: '/partner/onboarding/status',
		title: 'manageRole',
		exact: true,
		private: true,
		listType: 'pending-affiliate-dsa-list',
	},
	{
		component: ApplicationDetails,
		path: '/onboarding/*',
		private: true,
	},

	// { component: NotFound, path: '*' }
];


import APIService from './api-service';
import config from './config';
import { getCookie } from './cookie';
 


export const getQueries = (type, subType, partId) => {
	let url = config.baseUrl[type];
	if (subType) {
		url += `/${subType}`;
	}
	url += '/detail/get-partner-query';
	if (subType) {
		url += `?part_id=${partId}`;
	}
	return APIService.get(url);
};

export const GetQueryCild  = async(param)=>{
	console.log(param ,"param find")
	const token = getCookie(btoa('token'));
	return await (await fetch(`${config.baseUrl.admin}/get-query-child-users/${param}`, {
		headers: {
			Authorization: `Bearer ${token}`,
			...config.defaultHeaders
		}
	})).json();
}

export const addOnboardingQuery = ({ type, subType, body }) => {
	let url = config.baseUrl[type];
	if (subType) {
		url += `/${subType}`;
	}
	return APIService.post(url, body, null, null, {
		'Content-Type': 'multipart/form-data',
	});
};
export const viewOnboardingDoc = ({ type, fileId }) => {
	let url = config.baseUrl[type];

	return APIService.get(`${url}/doc/view-doc?file_id=${fileId}`, null, 'blob');
};

export const triggerFI = ({ type, body, partId }) => {
	let url = `${config.baseUrl[type]}`;
	if (type === 'admin') url += `/affiliate`;
	url += `/detail/partner-detail`;
	if (type === 'admin') url += `/${partId}`;
	return APIService.post(url, body, null, null, {
		'Content-Type': 'multipart/form-data',
	});
};

export const createPartnerCode = ({ type, body }) => {
	let url = `${config.baseUrl[type]}`;
	if (type === 'admin') url += `/affiliate`;
	url += `/detail/partner-code-creation`;
	return APIService.post(url, body);
};

/**
 *
 * @param {string} type
 * @param {object} body
 */
export const changePartnerType = ({ type, body }) => {
	let url = `${config.baseUrl[type]}`;
	url += `/change-partner-type`;
	return APIService.post(url, body);
};

export const getOnboardingStatus = ({ type }) => {
	let url = `${config.baseUrl[type]}`;
	url += `/detail/get-partner-onboarding-status`;
	return APIService.get(url);
};
export const getOnboardingFIList = ({ type, partner_id }) => {
	let url = `${config.baseUrl[type]}/affiliate/detail/partner-fi-fcu-detail/${partner_id}`;
	return APIService.get(url);
};

export const getPartnerPayoutGrid = ({ type, body }) => {
	let url = `${config.baseUrl[type]}/affiliate/detail/partner-payout-grid`;
	return APIService.post(url, body, null, 'blob');
};

export const getIFSCData = ({ type, IFSC }) => {
	let url = `${config.baseUrl[type]}/get-bank-detail-by-ifsc/${IFSC}`;
	return APIService.get(url);
};

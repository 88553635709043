
import { useContext, useLayoutEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import Table from '../../../../components/Common/Table';
import TableNoRecords from '../../../../components/Common/Table/TableNoRecords';
import Pagination from '../../../../components/Pagination';
import { Context } from '../../../../config/store';
import LenderUgroService from '../../../../services/lender';
import FundOutModal from './FoundoutModal';
import tableColumns from './tableColum';
import './style.css'
// import moment from 'moment'	

const FundOut = ({ selectedLender }) => {
  const { state } = useContext(Context);
  const leadId = useParams().id;
  const queryId = useParams().queryId;
  const location = useLocation();
  const [tableData, setTableData] = useState([]);
  const [page, setCurrentPage] = useState(1);
  const [pagination, setPagination] = useState({});
  const [loading, setLoading] = useState(true);

  const fundoutList = async () => {
    setLoading(true);
    let response = await LenderUgroService.getfundOutDetail({
      type: state.userType,
      subType: state.userType === 'admin' ? 'co-lender' : '',
      leadId: leadId,
    });
    setTableData(response?.data?.data?.lead_fundout_detail);
    setLoading(false);
    setCurrentPage(page);
    setPagination(response?.data?.data?.cl_leads?.pagination);
  };

  const [modalState, setModalState] = useState(false);

  const toggleModal = () => {
    setModalState(!modalState);
  };

  useLayoutEffect(() => {
    fundoutList();
  }, [page, selectedLender, location.pathname]);

  return (
    <div>
      <div className='row no-gutters justify-content-between align-items-center mt-4'>
        <div></div>
        <div className='mb-4'>
          {/* <button className='btn btn-custom' onClick={toggleModal}>
            Add Fundout
          </button> */}
          {modalState && (
            <FundOutModal
              userType={state.userType}
              state={modalState}
              toggleModal={toggleModal}
              leadId={leadId}
              lenderId={selectedLender}
              reloadTable={fundoutList}
            />
          )}
        </div>
      </div>
      <div className='fund_out_table'>
        <Table
          checkbox={false}
          columns={tableColumns}
          rows={tableData}
          loading={loading}
          reloadTable={fundoutList}
        >
          {Boolean(!tableData.length && !loading) && <TableNoRecords />}
        </Table>
      </div>

      {pagination?.total_pages ? (
        <Pagination
          setPage={setCurrentPage}
          total_pages={pagination.total_pages}
          total={pagination.total}
          showTotal={true}
        />
      ) : (
        ''
      )}
    </div>
  );
};

export default FundOut;

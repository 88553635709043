import React from 'react';
import Modal from '../Modal';
import LenderUgroService from '../../services/lender';
import { Context } from '../../config/store';
import message from '../../constant/message';
import { toast } from 'react-toastify';
const ResolveQueryModal = ({
	modelState,
	toggleModal,
	leadId,
	lenderId,
	lenderQueryId,
	lafId,
	submitCallback
}) => {
	let inputRef = React.useRef(null);
	const { state } = React.useContext(Context);
	const [loading, setLoading] = React.useState(false);
	const [file, setFile] = React.useState(null);
	const [comment, setComment] = React.useState('');

	const formSubmitHandler = e => {
		e.preventDefault();
		if (comment !== '') {
			resolveQueryHandler(e);
		}
	};

	const resolveQueryHandler = async e => {
		e.preventDefault();
		// if (!file) {
		// 	return toast.error(message.SELECT_FILE);
		// }
		setLoading(true);
		try {
			const formData = new FormData();
			formData.append('cl_lead_id', leadId);
			formData.append('cl_lender_id', lenderId);
			formData.append('lender_query_id', lenderQueryId);
			formData.append('file', file);
			formData.append('comment', comment);

			const response = await LenderUgroService.resolveQuery(
				state.userType,
				formData
			);
			if (response?.data?.status?.code === 1) {
				let success = response?.messages?.success;
				toast.success(
					success?.form?.[0] || success?.others?.[0] || message.SAVED_SUCCESS
				);
				inputRef.current.value = '';
				setFile(null);
				setComment('');
				submitCallback();
				toggleModal();
			}
		} catch (err) {
			let error = err?.response?.messages?.error;
			toast.error(error?.form?.[0] || error?.others?.[0] || message.ERROR_FORM);
		}
		setLoading(false);
	};

	const setCommentHandler = e => {
		setComment(e.target.value);
	};

	return (
		<Modal state={modelState} closeModal={toggleModal}>
			<form onSubmit={formSubmitHandler}>
				<div class='form-group mb-0'>
					<div class='row'>
						<div class='col-5'>
							<label>Assign to</label>
						</div>
						<div class='col-7'>
							<label>
								: IDBI User
								<br /> LAF {lafId || 'NA'}
							</label>
						</div>
					</div>
				</div>
				<div class='form-group'>
					<label>Upload File</label>
					<div class='row'>
						<div class='col-12'>
							<input
								ref={inputRef}
								file={file}
								onChange={e => setFile(e.target.files[0])}
								type='file'
								className='form-control'
							/>
						</div>
					</div>
				</div>
				<div class='form-group'>
					<label>Comment</label>
					<textarea
						class='form-control custom-form-control'
						cols='4'
						rows='4'
						value={comment}
						onChange={setCommentHandler}
					></textarea>
				</div>
				<div class='d-flex align-items-center'>
					<button
						type='submit'
						class='btn btn-custom'
						disabled={!comment && loading}
					>
						{loading ? <div className='loader' /> : 'OK'}
					</button>
					<button
						type='button'
						class='btn btn-outline-custom ml-2'
						data-dismiss='modal'
						onClick={toggleModal}
					>
						Cancel
					</button>
				</div>
			</form>
		</Modal>
	);
};

export default ResolveQueryModal;

import React from 'react';
import Table from '../../components/Common/Table';
import TableNoRecords from '../../components/Common/Table/TableNoRecords';
import Pagination from '../../components/Pagination';
import { Context } from '../../config/store';
import { useRouter } from '../../hooks/useRouter';
import { getOnboardingFIList } from '../../services/onboarding-form-service';
import Ficolumns from './Ficolumns';
const FIList = () => {
	const [tableData, setTableData] = React.useState({
		columns: Ficolumns,
		rows: [],
	});
	const [page, setCurrentPage] = React.useState(1);
	const [pagination, setPagination] = React.useState({});
	const [loading, setLoading] = React.useState(false);
	const router = useRouter();
	const { state } = React.useContext(Context);
	const getFIList = async () => {
		const { id } = router.params;
		const response = await getOnboardingFIList({
			type: state.userType,
			page,
			partner_id: id,
		});

		const data = response.data.data.resource;

		setTableData({
			...tableData,
			rows: data || [],
		});
		setLoading(false);
		setCurrentPage(page);
		setPagination(response?.data?.data?.pagination || {});
	};
	React.useEffect(() => {
		getFIList();
	}, [page]);
	return (
		<>
			<Table
				checkbox={false}
				columns={tableData.columns}
				rows={tableData.rows}
				loading={loading}
			>
				{Boolean(!tableData?.rows?.length && !loading) && <TableNoRecords />}
			</Table>

			{pagination?.total_pages ? (
				<Pagination
					setPage={setCurrentPage}
					total_pages={pagination.total_pages}
					total={pagination.total}
					showTotal={true}
				/>
			) : (
				''
			)}
		</>
	);
};

export default FIList;

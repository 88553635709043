import React from 'react';
import Modal from '../Modal';
import { getOfferData } from '../../services/manage-leads';
const OfferTable = ({ state, toggleModal, userType, leadId }) => {
	const style = { width: '80%' };
	const [offersDetails, setOfferDetails] = React.useState({});
	const [loading, setLoadingState] = React.useState(true);
	const getLeadOfferListing = async () => {
		const response = await getOfferData({ leadId, type: userType });
		if (response?.status?.code === 1) {
			setOfferDetails(response?.data?.lead_offer);
			// setOfferListing
		}
		setLoadingState(false);
	};
	React.useState(() => {
		getLeadOfferListing();
	}, []);
	return (
		<Modal state={state} style={style} closeModal={() => toggleModal(false)}>
			<div className='modal-content gray-bg'>
				{/* Modal body */}
				<div className='modal-body'>
					<h5 className=' text-orange font-r'>Lead Offer</h5>
					<div className='panel '>
						<div className='table-responsive '>
							{loading && <div className='loader' />}
							{!loading && offersDetails && (
								<table className='table table-striped'>
									<thead>
										<tr>
											<th>Loan Amount(INR)</th>
											<th>Rate of Interest (%)</th>
											<th>Loan Tenor (Years)</th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<td>
												{parseFloat(offersDetails.offer_amt).toLocaleString(
													'en-IN'
												)}
											</td>
											<td>{offersDetails.roi}</td>
											<td>{offersDetails.tenor}</td>
										</tr>
									</tbody>
								</table>
							)}
							{!loading && !offersDetails && (
								<h4 className='text-center mt-3 mb-3'>No data found</h4>
							)}
						</div>
					</div>
				</div>
			</div>
		</Modal>
	);
};

export default OfferTable;

import { useContext } from 'react';
import { Context } from '../../../config/store';
import FinallyAssigned from './FinallyAssigned';
import LenderLastRow from './LenderLastRow';
import LenderPending from './LenderPending';
import LenderPortfolio from './LenderPortfolio';
import UnassignedLeads from './UnassignedLeads';

function PortolioDashboard() {
	const { state } = useContext(Context);
	return (
		<div className='portfolio-dashboard'>
			<div className='row'>
				<div className='col-sm-12 col-md-6 col-lg-6'>
					<div className='chart-card h-100'>
						<LenderPortfolio userType={state.userType} />
					</div>
				</div>
				<div className='col-sm-12 col-md-6 col-lg-6'>
					<div className='chart-card h-100'>
						<FinallyAssigned userType={state.userType} />
					</div>
				</div>
			</div>
			<div className='row mt-3'>
				<div className='col-sm-12 col-md-6 col-lg-6'>
					<div className='chart-card '>
						<UnassignedLeads userType={state.userType} />
					</div>
				</div>
				<div className='col-sm-12 col-md-6 col-lg-6'>
					<div className='chart-card'>
						<LenderPending userType={state.userType} />
					</div>
				</div>
			</div>
			<LenderLastRow userType={state.userType} />
		</div>
	);
}

export default PortolioDashboard;

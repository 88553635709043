import React from 'react';
import OfferListingItem from './OfferListingItem';
const OffersTable = ({ offers, partId, page }) => {
	return (
		<div className='panel '>
			<div className='table-responsive '>
				<table className='table table-striped'>
					<thead>
						<tr>
							<th>S. No</th>
							{/* <th>File Name</th> */}
							<th>No. of records</th>
							<th>No. of offers converted</th>
							<th>Created Date/Time</th>
							<th>Action</th>
						</tr>
					</thead>
					<tbody>
						{offers.map((offer, index) => (
							<OfferListingItem
								page={page}
								key={index}
								{...offer}
								index={index}
								part_id={partId}
							/>
						))}
					</tbody>
				</table>
			</div>
		</div>
	);
};

export default OffersTable;

import './index.css';
import React from 'react';
import { NavLink } from 'react-router-dom';
import { useLocation } from 'react-router';
import { Context } from '../../../config/store';
// import { tabs } from './tabs';

export default function ViewTabs({ portfolioId, tabs }) {
	const location = useLocation();
	const checkActive = path => {
		return location.pathname.includes('post-disbursal') ?
			location.pathname.split(`/portfolio/views/post-disbursal/${portfolioId}`)[1] === path
			: location.pathname.split(`/portfolio/view/${portfolioId}`)[1] === path;
	}
	const { state } = React.useContext(Context);
	// const renderTab = (title, path, forAdmin) => {
	// 	if (forAdmin) {
	// 		return (
	// 			<NavLink key={`${path}`} to={`/portfolio/view/${portfolioId}${path}`}>
	// 				<li className={`${checkActive(path) && 'active'}`}>{title}</li>
	// 			</NavLink>
	// 		);
	// 	} else {
	// 		return (
	// 			<NavLink key={`${path}`} to={`/portfolio/view/${portfolioId}${path}`}>
	// 				<li className={`${checkActive(path) && 'active'}`}>{title}</li>
	// 			</NavLink>
	// 		);
	// 	}
	// };
	const renderTab = (title, path) => {
		return (
			<NavLink key={`${path}`} to={location.pathname.includes('post-disbursal') ? `/portfolio/views/post-disbursal/${portfolioId}${path}` : `/portfolio/view/${portfolioId}${path}`}>
				<li className={`${checkActive(path) && 'active'}`}>{title}</li>
			</NavLink>
		);
	};
	return (
		<div className='custom_sub_tab'>
			<ul className='nav nav-tabs border-0'>
				{tabs
					.filter(tab => {
						return tab.forAdmin ? state.userType === 'admin' : tab;
					})
					.map(({ title, path, forAdmin }) => renderTab(title, path, forAdmin))}
			</ul>
		</div>
	);
}

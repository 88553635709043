import Accordion from '../../../components/Portfolio/Accordion';
import Table from '../../../components/Common/Table';
import { objectsForEach } from '../../../common/objectToArray';

const PromoterDocItem = ({ promoter, documentFileTableHeaders, docLength }) => {
	let docs = (promoter?.promotorDoc || []).reduce(function (r, a) {
		r[a.mst_doc.doc_name] = r[a.mst_doc.doc_name] || [];
		r[a.mst_doc.doc_name].push(a);
		return r;
	}, Object.create(null));
	return (
		<div className='mt-4'>
			<h5>
				<span>{promoter.name}</span>
			</h5>
			<div className='card border-0'>
				<div className='card-body p-0'>
					<div id={'accordian' + promoter.name} className='custom_accordion'>
						{(objectsForEach(docs) || []).map((document, i) => {
							return (
								<Accordion
									key={i}
									label={document.id || document.doc_name}
									headerId={
										'heading' +
										document.doc_name +
										promoter.part_promoter_id +
										i
									}
									collapseId={
										'collapse' +
										document.doc_name +
										promoter.part_promoter_id +
										i
									}
									count={docLength}
								>
									{document?.value && document?.value?.length > 0 ? (
										<Table
											checkbox={false}
											columns={documentFileTableHeaders}
											rows={document.value}
											type='documents'
										></Table>
									) : (
										'---'
									)}
								</Accordion>
							);
						})}
					</div>
				</div>
			</div>
		</div>
	);
};

export default PromoterDocItem;

import React from 'react';
import { triggerFI } from '../../services/onboarding-form-service';
import { toast } from 'react-toastify';
import message from '../../constant/message';
import { useRouter } from '../../hooks/useRouter';
import { Context } from '../../config/store';
import { Link } from 'react-router-dom';
const FI = () => {
	const router = useRouter();
	let inputRef = React.useRef(null);
	const [applicable, setApplicable] = React.useState(1);
	const [file, setFile] = React.useState(null);
	const [loading, setLoading] = React.useState(false);
	const { state } = React.useContext(Context);
	const submit = async () => {
		setLoading(true);
		const formData = new FormData();
		formData.append('info_type', 8);
		file && formData.append('file', file);
		formData.append('is_fi_fcu_applicable', applicable);

		try {
			let response = await triggerFI({
				partId: router.params.id,
				type: state.userType,
				body: formData,
			});

			if (response?.data?.status?.code === 1) {
				let success = response?.data?.messages?.success;
				toast.success(
					success?.form?.[0] || success?.others?.[0] || message.SAVED_SUCCESS
				);
				if (inputRef.current) inputRef.current.value = '';
				setFile(null);
				router.navigate(`/partner/onboarding/status`);
			}
		} catch (err) {
			let error = err?.response?.data?.messages?.error;
			toast.error(error?.form?.[0] || error?.others?.[0] || message.ERROR_FORM);
		}
		setLoading(false);
	};
	return (
		<div>
			{' '}
			<div class='content'>
				<div className='row'>
					<div className='col-md-12'>
						<Link
							to={`/onboarding/details/partner/${router.params.id}`}
							className='btn btn-close mb-3'
						>
							<i className='fa fa-chevron-left' aria-hidden='true' /> Back
						</Link>
					</div>
					<div className='col-md-12 d-flex align-items-center'>
						<p className='font-18 mb-0'>
							<b>Documentation</b>
						</p>{' '}
					</div>
				</div>

				{/* <div>Application ID: </div> */}
				<h6 className='mt-2 text-orange'>FI/FCU Applicability</h6>

				<div className='form-group pt-2'>
					{' '}
					<div className='row no-gutters'>
						<div
							className='custom-control custom-radio'
							onClick={(e) => setApplicable(1)}
						>
							<input
								type='radio'
								className='custom-control-input'
								name='leadStatus'
								value={1}
								id={1}
								checked={applicable === 1}
							/>
							<label
								className='custom-control-label mt-1'
								for='defaultUnchecked'
							>
								Yes
							</label>
						</div>
						<div
							className='custom-control custom-radio ml-2'
							onClick={(e) => setApplicable(0)}
						>
							<input
								type='radio'
								className='custom-control-input'
								name='leadStatus'
								value={2}
								id={2}
								checked={applicable === 0}
							/>
							<label
								className='custom-control-label mt-1'
								for='defaultUnchecked'
							>
								No
							</label>
						</div>
					</div>
				</div>

				{Boolean(applicable) && (
					<div class='form-group'>
						<label>Upload File</label>
						<div class='row'>
							<div class='col-3'>
								<input
									ref={inputRef}
									file={file}
									onChange={(e) => setFile(e.target.files[0])}
									type='file'
									className='form-control'
								/>
							</div>
						</div>
					</div>
				)}
			</div>
			<div className='content'>
				<button
					disabled={loading}
					onClick={submit}
					type='submit'
					className='btn btn-custom'
				>
					Send to BH
				</button>
				<button
					onClick={(e) => {
						e.preventDefault();
						router.navigate(`/onboarding/details/partner/${router.params.id}`);
					}}
					type='submit'
					className='btn btn-close ml-2'
				>
					Cancel
				</button>
			</div>
		</div>
	);
};

export default FI;

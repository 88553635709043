import DisbursalTo from "./DisbursalTo";

export default [
    { title: 'Disbursal To', field: 'disbursal_to', component: DisbursalTo },
    { title: 'Bank Name', field: 'bank_name' },
    { title: 'Transaction Type', field: 'txn_type' },
    { title: 'Disbursed Amount', field: 'finance_amount' },
    { title: 'Cheque Number', field: 'cheque_no' },
    { title: `Account Holder's Name`, field: 'acc_name' },
    { title: 'Account Type', field: 'acc_type' },
    { title: 'Account Number', field: 'bank_acc_id' },
    { title: 'IFSC Code', field: 'ifsc' },
    { title: 'Branch Name', field: 'bank_branch' },
];
import React from 'react';
import {
	addLead,
	updateLead,
	getLead,
	getDdupeDetails,
	getLeadTools
} from '../services/manage-leads';
import { toast } from 'react-toastify';
import message from '../constant/message';
import { useRouter } from '../hooks/useRouter';
import EditLeadHeader from '../components/AddLead/EditLeadHeader';
import BulkUpload from '../components/AddLead/BulkUpload';
import {
	partnerConfig,
	getConfigByLeadId,
	affiliatesListing
} from '../services/manage-affiliate';
import Section from '../components/AddLead/Section';
import { objectsForEach } from '../common/objectToArray';
import { Context } from '../config/store';
import arrayToObject from '../common/arrayToObject';
import OfferTable from '../components/AddLead/OfferTable';
const AddLead = () => {
	const [errors, setErrors] = React.useState(null);
	const router = useRouter();
	const [showBulkUpload, activeTabStatus] = React.useState(false);
	const [edit, setEditType] = React.useState(false);
	const [leadId, setLeadId] = React.useState('');
	const [affiliateConfig, setAffiliateConfig] = React.useState({});
	const [body, setBody] = React.useState({});
	const [dDupeDetails, setDdupeDetails] = React.useState({});
	const [partId, setPartId] = React.useState('');
	const { state } = React.useContext(Context);
	const [offerModalState, toggleOfferModal] = React.useState(false);
	const [loading, setLoading] = React.useState(false);
	const [partner, setPartner] = React.useState('');
	const [partnerList, setPartnerListing] = React.useState([]);
	const [leadTool, setLeadTool] = React.useState([]);
	const getAffiliatesListing = async () => {
		const response = await affiliatesListing();
		if (response?.status?.code === 1) {
			setPartnerListing(response?.data?.resource || []);
		}
		return response?.data?.resource?.[2];
	};
	const submit = async e => {
		e.preventDefault();
		setLoading(true);
		let response;
		if (leadId) {
			response = await updateLead(leadId, body, state.userType);
		} else {
			response = await addLead(body, state.userType, partner);
		}

		if (response?.status?.code === 1) {
			toast.success(message.ADD_SUCCESS);
			router.navigate('/manage-leads');
		} else {
			toast.error(response?.data?.message || message.ERROR_FORM);
			let mgmt_info = response.data.err_data.mgmt_info || [];
			const docs = response.data.err_data.mgmt_docs;

			if (docs) {
				delete response.data.err_data.mgmt_docs;
			}
			if (mgmt_info?.length > docs?.length) {
				mgmt_info = (mgmt_info || []).map((mgmt_inf, index) => ({
					...mgmt_inf,
					...docs[index]
				}));
			} else {
				mgmt_info = (docs || []).map((doc, index) => ({
					...doc,
					...mgmt_info[index]
				}));
			}
			setErrors({
				...response.data.err_data,
				mgmt_info
			});
		}
		setLoading(false);
	};

	const toggleStatus = (e, status) => {
		e.preventDefault();
		activeTabStatus(status);
	};
	const getConfig = async (leadId, partId) => {
		let response;

		if (
			(state.userType === 'admin' && router.params.id) ||
			(state.userType === 'affiliate' && router.pathname.indexOf('edit') !== -1)
		) {
			response = await getConfigByLeadId(state.userType, leadId);
		} else {
			response = await partnerConfig({
				type: state.userType,
				partId: partId || partner
			});
		}

		if (response?.status?.code === 1) {
			const affiliateConfig = response?.data?.resource?.aff_lead_config;
			if (state.userType === 'admin') {
				setPartId(response.data.resource.part_id);
			}
			if (leadId) {
				await getLeadDetails(
					leadId,
					state.userType === 'admin'
						? response.data.resource.part_id
						: undefined
				);
			}
			const docs = affiliateConfig.mgmt_docs;
			if (docs) {
				delete affiliateConfig.mgmt_docs;
				delete docs.title;
				delete docs.type;
			}
			let constitutions = affiliateConfig.biz_info.biz_const;
			if (constitutions)
				affiliateConfig.biz_info.biz_const = {
					...constitutions,
					rule: {
						...constitutions.rule,
						options: arrayToObject(
							state.config?.constitutions || [],
							'name',
							'id'
						)
					}
				};
			let industry = affiliateConfig.biz_info.industry;
			let assignedTo = affiliateConfig.per_info.assigned_to;
			if (assignedTo) {
				affiliateConfig.per_info.assigned_to = {
					...assignedTo,
					rule: {
						...assignedTo.rule,
						options: arrayToObject(
							state.config?.assigned_to || [],
							'full_name_code',
							'user_id'
						)
					}
				};
			}
			if (industry)
				affiliateConfig.biz_info.industry = {
					...industry,
					rule: {
						...industry.rule,
						options: arrayToObject(state.config?.industries || [], 'name', 'id')
					}
				};

			setAffiliateConfig({
				...affiliateConfig,
				mgmt_info: { ...affiliateConfig.mgmt_info, ...docs }
			});
		} else {
			toast.error(response?.data?.message || message.ERROR_FORM);
		}
	};
	const init = async () => {
		let id;
		if (state.userType === 'admin' && !router.params.id) {
			id = await getAffiliatesListing();
			setPartner(id.part_id);
		}

		await getConfig(router.params.id, id?.part_id);

		if (router.params.id) {
			setLeadId(router.params.id);
			if (router.pathname.indexOf('edit') !== -1) setEditType(true);
			setEditLeadVariables(router.params.id);
		}
	};
	React.useEffect(() => {
		if (partner) {
			getConfig();
			leadTools();
		}
	}, [partner]);
	React.useEffect(() => {
		init();
	}, []);

	/**
	 *
	 * @param {String} leadId
	 */
	const setEditLeadVariables = async leadId => {
		const response = await getLead({ type: state.userType, leadId });
		if (response?.status?.code === 1) {
			const leadData = response.data.resource;
			setBody(leadData);
		}
	};
	const getLeadDetails = async (leadId, partId) => {
		const params = { lead_id: leadId, part_id: partId };
		const response = await getDdupeDetails({ type: state.userType, params });

		if (response?.status?.code === 1) {
			setDdupeDetails(response?.data?.lead_dedupes || []);
		}
	};
	const checkHasPermission = checkPermission => {
		if (state.is_super_admin === 1) return true;
		const permission = state.permission.find(
			permission => permission === checkPermission
		);

		if (permission) return true;
		else return false;
	};
	const leadTools = async () => {
		const response = await getLeadTools(state.userType, partner);
		let leadToolsList = response?.data?.resource;
		if (!leadToolsList.find(tool => tool === 2)) {
			activeTabStatus(false);
		}
		setLeadTool(leadToolsList);
	};
	return (
		<>
			<div className='p-3'>
				{leadId ? (
					<EditLeadHeader
						checkHasPermission={checkHasPermission}
						toggleOfferModal={toggleOfferModal}
						userType={state.userType}
						body={body}
						ddupeData={dDupeDetails}
						leadId={leadId}
					/>
				) : (
					<div className='d-flex row no-gutter justify-content-between'>
						<ul className='nav nav-tabs mt-3 custom-tabs pb-4'>
							<li className='nav-item'>
								<a
									onClick={e => toggleStatus(e, false)}
									href=''
									className={`nav-link  ${!showBulkUpload && 'active'}`}
								>
									Add New Lead
								</a>
							</li>

							{checkHasPermission('admin-bulk-lead-upload') &&
								(leadTool.find(tool => tool === 2) ||
									(state.leadTools || []).find(tool => tool === 2)) && (
									<li className='nav-item'>
										<a
											className={`nav-link  ${showBulkUpload && 'active'}`}
											href=''
											onClick={e => toggleStatus(e, true)}
										>
											Bulk Upload
										</a>
									</li>
								)}
						</ul>
						{state.userType === 'admin' && (
							<div className='form-group'>
								<select
									className='form-control custom-textfield-selectAffiliate  mr-2  text-black'
									value={partner}
									onChange={e => setPartner(e.target.value)}
								>
									<option value={null}>Select Affiliate</option>
									{partnerList.map(({ part_id, name }) => (
										<option value={part_id}>{name}</option>
									))}
								</select>
							</div>
						)}
					</div>
				)}
				{checkHasPermission('get-lead-offer') && offerModalState && (
					<OfferTable
						userType={state.userType}
						leadId={leadId}
						state={offerModalState}
						toggleModal={toggleOfferModal}
					/>
				)}
				<div className='tab-content'>
					<div className={`tab-pane ${!showBulkUpload ? 'active' : 'fade'}`}>
						{objectsForEach(affiliateConfig || []).map(
							({ title, type, id, ...rest }) => (
								<Section
									body={body}
									setBody={setBody}
									key={id}
									title={title}
									id={id}
									edit={edit}
									leadId={leadId}
									type={type}
									parameters={rest}
									errors={errors}
								/>
							)
						)}
						{((leadId && edit) || !leadId) && (
							<div className='content'>
								<button
									disabled={loading}
									onClick={submit}
									type='submit'
									className='btn btn-custom'
								>
									{loading ? <div className='loader' /> : 'Submit'}
								</button>
								<button
									onClick={e => {
										e.preventDefault();
										router.navigate('/manage-leads');
									}}
									type='submit'
									className='btn btn-close ml-2'
								>
									Cancel
								</button>
							</div>
						)}
					</div>
					<BulkUpload
						partId={partner}
						userType={state.userType}
						activeTab={showBulkUpload}
					/>
				</div>
			</div>
		</>
	);
};

export default AddLead;

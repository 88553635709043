import React from 'react';
import objectToQueryParams from '../../common/objectToQueryParams';
const SearchFilter = ({ setQueryParams }) => {
	const [startDate, setStartDate] = React.useState('');
	const [endDate, setEndDate] = React.useState('');
	const [email, setEmail] = React.useState('');
	const [mobileNo, setMobileNo] = React.useState('');
	const [affiliateId, setAffiliateId] = React.useState('');
	const [partnerCode, setPartnerCode] = React.useState('');
	const [affiliateName, setAffiliateName] = React.useState('');
	const [status, setStatus] = React.useState('');
	const submitQueryParams = () => {
		const searchObject = {
			affiliate_id: affiliateId,
			partner_code: partnerCode,
			name: affiliateName,
			email,
			mobile_no: mobileNo,
			start_date: startDate,
			end_date: endDate,
			status
		};

		const queryString = objectToQueryParams(searchObject);
		setQueryParams(queryString);
	};

	const resetQueryParams = () => {
		setStartDate('');
		setEndDate('');
		setEmail('');
		setMobileNo('');
		setAffiliateId('');
		setPartnerCode('');
		setAffiliateName('');
		setStatus('');
		setQueryParams('');
	};
	return (
		<div className='tab panel'>
			<input type='checkbox' id='accordion'></input>
			<label for='accordion' className='w-100 head_top tab-label'>
				<h4>Search Filter</h4>
			</label>
			<form className='tab-content'>
				<div className='p-3'>
					<div className='row'>
						<div className='col-md-4'>
							<div className='form-group'>
								<label>Affiliate Name</label>
								<input
									value={affiliateName}
									onChange={e => setAffiliateName(e.target.value)}
									type='text'
									className='form-control'
								/>
							</div>
						</div>
						<div className='col-md-4'>
							<div className='form-group'>
								<label>Partner Code</label>
								<input
									value={partnerCode}
									onChange={e => setPartnerCode(e.target.value)}
									type='text'
									className='form-control'
								/>
							</div>
						</div>
						<div className='col-md-4'>
							<div className='form-group'>
								<label>Email</label>
								<input
									value={email}
									onChange={e => setEmail(e.target.value)}
									type='text'
									className='form-control'
								/>
							</div>
						</div>
						<div className='col-md-4'>
							<div className='form-group'>
								<label>Mobile No</label>
								<input
									value={mobileNo}
									onChange={e => setMobileNo(e.target.value)}
									type='text'
									className='form-control'
								/>
							</div>
						</div>
						<div className='col-md-4'>
							<div className='form-group'>
								<label>Start Date</label>
								<input
									value={startDate}
									onChange={e => setStartDate(e.target.value)}
									type='date'
									className='form-control'
								/>
							</div>
						</div>
						<div className='col-md-4'>
							<div className='form-group'>
								<label>End Date</label>
								<input
									value={endDate}
									onChange={e => setEndDate(e.target.value)}
									type='date'
									className='form-control'
								/>
							</div>
						</div>
						<div className='col-md-4'>
							<div className='form-group'>
								<label>Status</label>
								<select
									value={status}
									onChange={e => setStatus(e.target.value)}
									className='form-control'
								>
									<option value={null}>Status</option>
									<option value={0}>In active</option>
									<option value={1}>Active</option>
								</select>
							</div>
						</div>
					</div>
					<div className='row'>
						<div className='col-md-12'>
							<button
								onClick={submitQueryParams}
								type='button'
								className='btn btn-custom'
							>
								Search
							</button>
							<button
								onClick={resetQueryParams}
								type='button'
								className='btn btn-custom ml-2'
							>
								Reset All  
							</button>
						</div>
					</div>
				</div>
			</form>
		</div>
	);
};

export default SearchFilter;

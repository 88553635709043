import React, { useState } from "react";
import "./index.css";
import SearchFilter from "../../../components/Portfolio/SearchFilter";
// import unassignedTableData from '../../../constant/unassignedTable';
import Table from "../../../components/Common/Table";
import Pagination from "../../../components/Pagination";
import Button from "../../../components/Common/Button";
import { productType } from "../../../constant/selectOptions";
import { eligibleForLender } from "../../../constant/selectOptions";
import { productName } from "../../../constant/selectOptions";
import PortfolioContainer from "../../../components/Portfolio/PortfolioContainer";
import LenderUgroService from "../../../services/lender";
import { Context } from "../../../config/store";
import TableNoRecords from "../../../components/Common/Table/TableNoRecords";
import portfolio from "../../../constant/tableHeaders/portfolio";
import { downloadFile } from "../../../common/download";
import objectToQueryParams from '../../../common/objectToQueryParams';

function AssignedPortfolio({ isOps, leadType, path }) {
  let sessionStorageData = JSON.parse(sessionStorage.getItem('searchData')) || {};
  let { org_page = 1 } = sessionStorageData;
  const queryString = objectToQueryParams(sessionStorageData);
  const [queryParams, setQueryParams] = React.useState(queryString);
  const [tableData, setTableData] = useState({
    columns: portfolio,
    rows: [],
  });
  const [loading, setLoading] = React.useState(true);
  const [page, setCurrentPage] = useState(org_page);
  const [pagination, setPagination] = useState({});
  const { state, dispatch } = React.useContext(Context);
  const [sortParams, setSortParams] = React.useState({
    sortBy: "",
    sortOrder: "",
  });
  const [excelLoader, setExcelLoader] = useState(false)
  const getPortfolio = async (paramsChanged, sortOrder, sortBy) => {
    let response = null;
    setLoading(true);
    if (state.userType === "lender") {
      response = await LenderUgroService.getAdminFilteredPortfolio({
        type: state.userType,
        page: paramsChanged ? 1 : page,
        isOps,
        leadType,
        dataExport: 0,
        sortBy: sortBy ? sortBy : sortParams.sortBy,
        sortOrder: sortOrder ? sortOrder : sortParams.sortOrder,

        queryParams,
      });
    } else {
      response = await LenderUgroService.getFilteredPortfolio({
        type: state.userType,
        isOps,
        leadType,
        page: paramsChanged ? 1 : page,
        dataExport: 0,
        queryParams,
      });
    }
    const data = response.data.data?.cl_leads;
    setTableData({
      ...tableData,
      rows: data?.data || [],
    });

    setCurrentPage(page);
    // Update session storage data if page changed
    let searchDataNew = {...sessionStorageData, org_page: (paramsChanged ? 1 : page)}
    sessionStorage.setItem('searchData', JSON.stringify(searchDataNew))
    setPagination(response?.data?.data.cl_leads.pagination);
    setLoading(false);
  };
  const onSortChange = ({ sortOrder, sortBy }) => {
    setSortParams({ sortBy, sortOrder });
    getPortfolio(false, sortOrder, sortBy);
  };
  const exportLead = async () => {
    let response = null;
    setExcelLoader(true)
    if (state.userType === "admin") {
      response = await LenderUgroService.getAdminFilteredPortfolio({
        type: state.userType,
        page,
        isOps,
        leadType,
        dataExport: 1,
        queryParams,
        responseType: "blob",
      });
      setExcelLoader(false)
    } else {
      response = await LenderUgroService.getFilteredPortfolio({
        type: state.userType,
        isOps,
        leadType,
        page,
        dataExport: 1,
        queryParams,
        sortBy: sortParams.sortBy,
        sortOrder: sortParams.sortOrder,
        responseType: "blob",
      });
      setExcelLoader(false)
    }
    let fileName = ''
    if (leadType === 'pre-sanction') {
      fileName = 'pre-sanction'
    } else if (leadType === 'pre-sanction-approved') {
      fileName = 'pre-sanction-approved'
    } else if (leadType === "pre-sanction-rejected") {
      fileName = 'pre-sanction-rejected'
    } else if (leadType === 'assigned') {
      fileName = 'Final-sanction-approval'
    } else if (leadType === 'rejected') {
      fileName = 'Sanction-rejected'
    } else if (leadType === 'indisbursal-lender-pending') {
      fileName = 'Disbursal-approval'
    }
    downloadFile(response.data, fileName + +new Date());
    setExcelLoader(false)
  };

  React.useEffect(() => {
    getPortfolio();
  }, [page, path]);
  React.useEffect(() => {
    if (!loading) getPortfolio(true);
  }, [queryParams]);
  React.useEffect(() => {
    dispatch({
      type: "selectedColender",
      selectedColender: path,
    });
    localStorage.setItem("selectedColender", path);
  }, [path]);

  return (
    <PortfolioContainer>
      <SearchFilter
        setQueryParams={setQueryParams}
        productTypes={productType}
        productNames={productName}
        lenders={eligibleForLender}
        statusOptions={
          state.userType === "lender"
            ? [
              { label: "Assigned Portfolio", value: 4 },
              { label: "Rejected", value: 5 },
            ]
            : undefined
        }
        subType={state.userType}
        loading={loading}
      ></SearchFilter>
      <div className="d-flex justify-content-end">
        {excelLoader ? <Button classNames='excelDownloadLoader my-3'> <div className="loader"></div></Button> :
          <Button
            type='btn-export'
            classNames='float-right  mt-3 mb-3'
            clickHandler={exportLead}
          >
            	<i class="fa fa-file-excel-o mr-1" aria-hidden="true"></i>
            Export Excel
          </Button>
        }
      </div>
      <div className="post_disbursal_case">
        <Table
          checkbox={false}
          columns={
            state.userType === "lender"
              ? tableData.columns.filter(({ field }) => field !== "lender_name")
              : tableData.columns
          }
          rows={tableData.rows}
          loading={loading}
          onSortChange={onSortChange}
        >
          {Boolean(!tableData.rows.length && !loading) && <TableNoRecords />}
        </Table>
      </div>

      {pagination?.total_pages ? (
        <Pagination
          disabled={loading}
          setPage={setCurrentPage}
          total_pages={pagination.total_pages}
          total={pagination.total}
          showTotal={true}
        />
      ) : (
        ""
      )}
    </PortfolioContainer>
  );
}

export default AssignedPortfolio;

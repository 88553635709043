import React from 'react';
import { Context } from '../../../config/store';
const BusinessDetails = ({ setAffiliateKyc, errors, affiliateKyc, view }) => {
	const onChange = (value, type) => {
		setAffiliateKyc(values => ({
			...values,
			[type]: value
		}));
	};
	const { state } = React.useContext(Context);

	let affiliate_type = parseInt(affiliateKyc.affiliate_type) || 1;
	return (
		<div className='content'>
			<h5>{affiliate_type === 2 ? 'Business Details' : 'Personal Details'}</h5>
			<div className='row'>
				<div className='col-md-4'>
					<div className='form-group'>
						<label>
							Affiliate Type <tt>*</tt>
						</label>
						<select
							disabled={view}
							value={affiliateKyc.affiliate_type}
							onChange={e =>
								setAffiliateKyc(values => ({
									...values,
									affiliate_type: parseInt(e.target.value)
								}))
							}
							className='form-control'
						>
							<option value={1}>Individual</option>
							<option value={2}>Corporate</option>
						</select>
					</div>
				</div>
				<div className='col-md-4'>
					<div className='form-group'>
						<label>
							{affiliate_type === 2 ? 'Company Name' : 'Name'} <tt>*</tt>
						</label>
						<input
							disabled={view}
							value={affiliateKyc.name}
							onChange={e => onChange(e.target.value, 'name')}
							type='email'
							className='form-control'
						/>
						{errors && (
							<p className='mt-2 mb-0 text-danger'>
								{errors?.fields?.name?.[0]}
							</p>
						)}
					</div>
				</div>
				{affiliate_type === 2 ? (
					<div className='col-md-4'>
						<div className='form-group'>
							<label>
								Business Constitution <tt>*</tt>
							</label>

							<select
								disabled={view}
								value={affiliateKyc.biz_const}
								onChange={e => onChange(e.target.value, 'biz_const')}
								className='form-control'
								// value={}
							>
								<option value={null}>Select Constitution</option>
								{(state.config?.constitutions || []).map(({ id, name }) => (
									<option key={id} value={id}>
										{name}
									</option>
								))}
							</select>
							{errors && (
								<p className='mt-2 mb-0 text-danger'>
									{errors?.fields?.biz_const?.[0]}
								</p>
							)}
						</div>
					</div>
				) : null}
				{affiliate_type === 2 ? (
					<div className='col-md-4'>
						<div className='form-group'>
							<label>
								Authorised signatory Name <tt>*</tt>
							</label>
							<input
								disabled={view}
								value={affiliateKyc.auth_sec_name}
								onChange={e => onChange(e.target.value, 'auth_sec_name')}
								type='text'
								className='form-control'
							/>
							{errors && (
								<p className='mt-2 mb-0 text-danger'>
									{errors?.fields?.auth_sec_name?.[0]}
								</p>
							)}
						</div>
					</div>
				) : null}
				<div className='col-md-4'>
					<div className='form-group'>
						<label>
							Mobile no. <tt>*</tt>
						</label>
						<input
							disabled
							value={affiliateKyc.phone}
							onChange={e => onChange(e.target.value, 'phone')}
							type='text'
							className='form-control'
						/>
						{errors && (
							<p className='mt-2 mb-0 text-danger'>
								{errors?.fields?.phone?.[0]}
							</p>
						)}
					</div>
				</div>
				<div className='col-md-4'>
					<div className='form-group'>
						<label>
							Email <tt>*</tt>
						</label>
						<input
							disabled
							type='email'
							value={affiliateKyc.email}
							onChange={e => onChange(e.target.value, 'email')}
							className='form-control'
						/>
						{errors && (
							<p className='mt-2 mb-0 text-danger'>
								{errors?.fields?.email?.[0]}
							</p>
						)}
					</div>
				</div>
				{affiliate_type === 2 ? (
					<div className='col-md-4'>
						<div className='form-group'>
							<label>
								GST Number <tt>*</tt>
							</label>
							<input
								disabled={view}
								type='text'
								value={affiliateKyc.gst_no}
								onChange={e => onChange(e.target.value, 'gst_no')}
								className='form-control'
								style={{ textTransform: 'uppercase' }}
							/>
							{errors && (
								<p className='mt-2 mb-0 text-danger'>
									{errors?.fields?.gst_no?.[0]}
								</p>
							)}
						</div>
					</div>
				) : null}
				<div className='col-md-4'>
					<div className='form-group'>
						<label>
							{affiliate_type === 2 ? 'Business Pan' : 'PAN'} <tt>*</tt>
						</label>
						<input
							disabled={view}
							type='text'
							value={affiliateKyc.pan_no}
							onChange={e => onChange(e.target.value, 'pan_no')}
							className='form-control'
						/>
						{errors && (
							<p className='mt-2 mb-0 text-danger'>
								{errors?.fields?.pan_no?.[0]}
							</p>
						)}
					</div>
				</div>
				{affiliate_type === 2 ? (
					<div className='col-md-4'>
						<div className='form-group'>
							<label>
								{' '}
								Authorised signatory PAN <tt>*</tt>
							</label>
							<input
								disabled={view}
								type='text'
								className='form-control'
								value={affiliateKyc.auth_sec_pan}
								onChange={e => onChange(e.target.value, 'auth_sec_pan')}
							/>
							{errors && (
								<p className='mt-2 mb-0 text-danger'>
									{errors?.fields?.auth_sec_pan?.[0]}
								</p>
							)}
						</div>
					</div>
				) : null}
			</div>
		</div>
	);
};

export default BusinessDetails;

import React from 'react';
import objectToQueryParams from '../../../common/objectToQueryParams';
import Select from '../../../components/Common/Input/Select';
import MasterDataService from '../../../services/master-data';
import { Context } from '../../../config/store';
import { objectsForEach } from '../../../common/objectToArray';
const SearchFilter = ({ setQueryParams }) => {
	const [eligibleLender, setEligibleLender] = React.useState([]);

	const [lafId, setLafId] = React.useState('');

	const [lenders, setLenders] = React.useState([]);
	const [selectedStatus, setSelectedStatus] = React.useState('');

	const { state } = React.useContext(Context);
	const submitQueryParams = () => {
		const searchObject = {
			lender_name: (eligibleLender || []).map((lender) => lender.value),
			laf_id: lafId,
			status_ids: (selectedStatus || []).map((status) => status.value),
		};

		const queryString = objectToQueryParams(searchObject);
		setQueryParams(queryString);
	};
	const statusOptions = [
		{ label: 'Unassigned', value: 1 },
		{ label: 'Additional Info Pending', value: 2 },
		{
			label: 'Doc Pending',
			value: 3,
		},
		{
			label: 'Rejected',
			value: 5,
		},
		{ label: 'Pulled Back', value: 8 },
	];
	const resetQueryParams = () => {
		setEligibleLender('');
		setSelectedStatus('');
		setLafId('');
		setQueryParams('');
	};
	React.useEffect(() => {
		if (state.userType === 'admin') {
			lenderList();
		}
	}, []);
	const lenderList = async () => {
		const response = await MasterDataService.getLenderList(
			state.userType,
			state.userType === 'admin' ? 'co-lender' : ''
		);
		if (response?.data?.status?.code === 1) {
			let responseList = objectsForEach(
				response.data.data.lender_list,
				'STRING_VAL'
			);
			let lenderItems = responseList.map((response) => ({
				label: response.value,
				value: response.id,
			}));
			setLenders(lenderItems);
		}
	};

	return (
		<div className='tab panel overflow-unset'>
			<form>
				<div className='p-3'>
					<div className='row'>
						<div className='col-md-4'>
							<div className='form-group'>
								<label>LAF ID</label>
								<input
									className='form-control'
									value={lafId}
									onChange={(e) => setLafId(e.target.value)}
								/>
							</div>
						</div>
						{state.userType === 'admin' && (
							<div className='col-md-4'>
								<div className='form-group'>
									<Select
										label='Assigned Lender'
										options={lenders}
										onChange={(data) => setEligibleLender(data)}
										value={eligibleLender}
									/>
								</div>
							</div>
						)}

						{statusOptions && (
							<div className='col-md-4'>
								<div className='form-group'>
									<Select
										label='Status'
										options={statusOptions}
										value={selectedStatus}
										onChange={(data) => setSelectedStatus(data)}
									/>
								</div>
							</div>
						)}
					</div>
					<div className='row'>
						<div className='col-md-12'>
							<button
								onClick={submitQueryParams}
								type='button'
								className='btn btn-custom'
							>
								Search
							</button>
							<button
								onClick={resetQueryParams}
								type='button'
								className='btn btn-custom ml-4'
							>
								Reset All
							</button>
						</div>
					</div>
				</div>
			</form>
		</div>
	);
};

export default SearchFilter;

import config from "./config";
import { getCookie, setCookie } from "./cookie";

/**
 * @param {String} email
 * @param  {String} password
 * @param {String} type
 */

export const login = ({ email, password, type, loginType, otp }) => {
  let body;
  if (type === "dsa") {
    body = { user_id: email, password };
  } else if (loginType === "otp") {
    body = { email, password, otp, type: loginType };
  } else if (loginType === "resend") {
    body = { email, password, type: loginType };
  } else {
    body = { email, password };
  }
  return fetch(`${config.baseUrl[type]}/login-with-password`, {
    method: "POST",
    body: JSON.stringify(body),
    headers: { ...config.client, ...config.defaultHeaders },
  });
};

/**
 * Register a affiliate
 * @param { "name": "", "email": "", "mobile_no": "", "pan_no": "", "password": "", "confirm_password": "", "part_type": "" }  body
 */
export const register = (body, type = "register") => {
  return fetch(`${config.baseUrl.affiliate}/${type}`, {
    method: "POST",
    body: JSON.stringify(body),
    headers: {
      ...config.client,
      ...config.defaultHeaders,
      // origin: 'https://backend-ugro.prosofo.com',
      // 'Access-Control-Allow-Origin': '*',
      // Host: 'https://backend-ugro.prosofo.com'
    },
  });
};

/**
 *
 * @param {String} type
 */
export const logout = async ({ type }) => {
  const token = getCookie(btoa("token"));
  const response = await (
    await fetch(`${config.baseUrl[type]}/logout`, {
      headers: { Authorization: `Bearer ${token}`, ...config.defaultHeaders },
    })
  ).json();
  setCookie(btoa("token"), "");
  return response;
};

/**
 * Referesh token
 *
 * @param {String} type
 */
export const refreshToken = ({ type }) => {
  const token = getCookie(btoa("token"));

  return fetch(`${config.baseUrl[type]}/refresh-token`, {
    headers: {
      ...config.defaultHeaders,
      Authorization: `Bearer ${token}`,
    },
  });
};

/**
 *
 * @param {String} mobile_number
 */
export const sendOtp = async ({ mobile_no }) => {
  return await (
    await fetch(`${config.baseUrl.affiliate}/mobile/send-otp`, {
      method: "POST",
      body: JSON.stringify({ mobile_no }),
      headers: { ...config.client, ...config.defaultHeaders },
    })
  ).json();
};

/**
 *
 * @param {String} mobile_no
 * @param {String} otp
 */
export const verifyOtp = async ({ mobile_no, otp }) => {
  return await (
    await fetch(`${config.baseUrl.affiliate}/mobile/verify-otp`, {
      method: "POST",
      body: JSON.stringify({ mobile_no, otp }),
      headers: { ...config.client, ...config.defaultHeaders },
    })
  ).json();
};

/**
 *
 * @param {String} email
 */
export const forgotPassword = async ({ email, type }) => {
  return await (
    await fetch(`${config.baseUrl[type]}/password/forget`, {
      method: "POST",
      body: JSON.stringify({ email }),
      headers: { ...config.client, ...config.defaultHeaders },
    })
  ).json();
};

/**
 * @param {String} password_token
 * @param {String} password
 * @param {String} confirm_password
 */
export const resetPassword = async ({
  password_token,
  password,
  confirm_password,
  type,
}) => {
  return await (
    await fetch(`${config.baseUrl[type]}/password/reset`, {
      method: "POST",
      body: JSON.stringify({ password, password_token, confirm_password }),
      headers: { ...config.client, ...config.defaultHeaders },
    })
  ).json();
};

/**
 *
 * @param {*} body
 */
export const changeAdminPassword = async (body, userType) => {
  const token = getCookie(btoa("token"));
  return await (
    await fetch(`${config.baseUrl[userType]}/password/change-password`, {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        Authorization: `Bearer ${token}`,
        ...config.client,
        ...config.defaultHeaders,
      },
    })
  ).json();
};

/**
 *
 * @param {String} token
 */
export const getAffiliateFromToken = async (token) => {
  return await (
    await fetch(
      `${config.baseUrl.affiliate}/get-affiliate-detail?token=${token}`,
      {
        headers: { ...config.client, ...config.defaultHeaders },
      }
    )
  ).json();
};

/**
 *
 * @param {String} type
 */
export const getLoggedInUser = async (type) => {
  const token = getCookie(btoa("token"));
  return await (
    await fetch(`${config.baseUrl[type]}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        ...config.client,
        ...config.defaultHeaders,
      },
    })
  ).json();
};

import React from 'react';
import Modal from '../../../components/Modal';
import LenderUgroService from '../../../services/lender';
import { Context } from '../../../config/store';
import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router';
import message from '../../../constant/message';
import Button from '../../../components/Common/Button';
import { objectsForEach } from '../../../common/objectToArray';

const SubmitToLender = ({
	modelState,
	detailLenderId,
	detailLenderName,
	toggleModal,
	getLeadDetail
}) => {
	const navigate = useNavigate();
	const { state } = React.useContext(Context);
	const [loading, setLoading] = React.useState(false);
	const leadId = useParams()['id'];

	const submitToLender = async () => {
		setLoading(true);
		try {
			const response = await LenderUgroService.submitToLender(
				state.userType,
				'co-lender',
				leadId,
				detailLenderId
			);
			getLeadDetail();
			const success = response?.data?.messages?.success;
			toast.success(
				success?.form?.[0] || success?.others?.[0] || message.SAVED_SUCCESS
			);
			navigate('/portfolio/pending');
		} catch (err) {
			let error = err?.response?.data?.messages?.error;
			toast.error(error?.form?.[0] || error?.others?.[0] || message.ERROR_FORM);
		}
		setLoading(false);
	};

	return (
		<Modal state={modelState} closeModal={toggleModal}>
			<div>
				<p className='font-weight-bold text-center'>
					Do you really want to submit this application to the{' '}
					{detailLenderName}?
				</p>
				<div className='d-flex align-items-center justify-content-center'>
					<Button
						clickHandler={toggleModal}
						classNames='btn-custom small mr-2'
					>
						No
					</Button>
					<Button clickHandler={submitToLender} classNames='btn-custom small'>
						{loading ? <div className='loader' /> : 'Yes'}
					</Button>
				</div>
			</div>
		</Modal>
	);
};

export default SubmitToLender;

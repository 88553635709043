import { useEffect, useState } from 'react';
import GraphContainer from '../../../../components/Portfolio/GraphContainer';
import { bg } from '../../../../constant/options';
import LenderUgroService from '../../../../services/lender';
import GraphTypeFilter from '../GraphTypeFilter';

function FinallyAssigned({ userType }) {
	const [loading, setLoading] = useState(true);
	const [queryParams, setQueryParams] = useState({});
	const [error, setError] = useState(false);
	const [series, setSeries] = useState([]);
	const [options, setOptions] = useState({
		chart: {
			type: 'pie',

			width: '100%',
		},
		colors: bg.map(({ color }) => color),
		legend: {
			show: true,
			position: 'bottom',
		},
		dataLabels: {
			formatter: function (val, opts) {
				return opts.w.config.series[opts.seriesIndex];
			},
		},
	});
	const getDashboardFinallyAssigned = async () => {
		setLoading(true);
		setError(false);
		try {
			const response =
				await LenderUgroService.dashboardFinallyAssignedPortfolio({
					type: userType,
					params: queryParams,
					subType: userType == 'admin' ? 'co-lender' : '',
				});
			if (response?.data?.status?.code === 1) {
				const categories =
					response?.data?.data?.lender_portfolio_pending?.x?.map(
						(category) => category.name
					);

				setOptions((previous) => {
					return {
						...previous,
						labels: categories,
					};
				});
				let seriesData = response?.data?.data?.lender_portfolio_pending.y?.map(
					(yaxis) => yaxis.count
				);
				setSeries(seriesData);
			}
		} catch (err) {
			setError();
			console.log(err);
		}
		setLoading(false);
	};

	useEffect(() => {
		getDashboardFinallyAssigned();
	}, [queryParams]);
	return (
		<GraphContainer
			filters={<Filters setQueryParams={setQueryParams} />}
			options={options}
			loading={loading}
			title={'Finally Assigned'}
			series={series}
			type={'pie'}
			error={error}
			height={390}
		/>
	);
}

const Filters = ({ setQueryParams }) => {
	const [graphType, setGraphType] = useState();

	useEffect(() => {
		if (graphType) {
			setQueryParams({
				graph_type: graphType?.value || '',
			});
		}
	}, [graphType]);
	return (
		<div className='row mt-2'>
			<div className='col-md-4'>
				<GraphTypeFilter onGraphTypeChange={(value) => setGraphType(value)} />
			</div>
		</div>
	);
};
export default FinallyAssigned;

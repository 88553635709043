import React from 'react';
import './index.css';

/**
 *
 * @param {any} children
 * @param {String} type
 */

const Button = ({ children, type, clickHandler, classNames }) => {
	return (
		<button type='button' className={`btn ${type} ${classNames}`} onClick={clickHandler}>
			{children}
		</button>
	);
};

export default Button;

import { getCookie } from './cookie';
import config from './config';

export const DRFdetailsave = async (DRFdETAIL,leadid ) => {
 
	const token = getCookie(btoa('token'));
	return await (await fetch(`${config.baseUrl.admin}/lender/lead/drf/${leadid}`, {
		method: 'POST',
		body: JSON.stringify(DRFdETAIL),
		headers: {
			Authorization: `Bearer ${token}`,
			...config.defaultHeaders
		}
	})).json();
};
export const DRFdetailGet = async (leadid ) => {
 
	console.log("lead checking")
	const token = getCookie(btoa('token'));
	return await (await fetch(`${config.baseUrl.lender}/lead/drf/${leadid}`, {
		headers: {
			Authorization: `Bearer ${token}`,
			...config.defaultHeaders
		}
	})).json();
};

 
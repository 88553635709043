import React from 'react';
import { getFullDate } from '../../common/date';
import { Link } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
const RoleListingItem = ({
	pageType,
	role_id,
	role_name,
	created_at,
	is_active,
}) => {
	console.log(pageType, "page Type ")
	return (
		<tr>
			<td>RL {role_id}</td>
			<td>{role_name}</td>
			<td>{getFullDate(new Date(created_at))}</td>
			<td>
				{' '}
				<span className={is_active ? 'text-success' : 'text-warning'}>
					{is_active ? 'Active' : 'In active'}
				</span>
			</td>
			{pageType === 'lender' && (
				<td>
					<ReactTooltip id='view'>View</ReactTooltip>
					<ReactTooltip id='edit'>Edit</ReactTooltip>
					<Link to={`/lender/role/edit/${role_id}`}>
						<i className='fa fa-pencil fa-lg mr-2' style={{ color: '#4A4E88' }}
							data-tip
							data-for='edit'>

						</i>
					</Link>
					<Link to={`/lender/role/${role_id}`} href='#'>
						<i className='fa fa-info-circle fa-lg' style={{ color: '#4A4E88' }} data-tip
							data-for='view'>
						</i>
					</Link>
				</td>
			)}
			{pageType === 'admin' && (
				<td>
					<Link to={`/admin/role/edit/${role_id}`}>Edit</Link>/
					<Link to={`/admin/role/${role_id}`} href='#'>
						View
					</Link>
				</td>
			)}
		</tr>
	);
};

export default RoleListingItem;

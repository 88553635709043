import React from 'react';
import UserHeader from '../components/AddUser/UserHeader';
import Actions from '../components/AddUser/Actions';
import { useRouter } from '../hooks/useRouter';
import { getUserParameters, getSingleUser } from '../services/manage-user';
import SelectedBranch from '../components/AddUser/SelectedBranch';
import SelectedProduct from '../components/AddUser/SelectedProduct';
import { objectsForEach } from '../common/objectToArray';
import Select from 'react-select';
import SelectProductLender from '../components/AddUser/SelectProductLender';
import { Link } from 'react-router-dom';
const ViewUser = ({ type: viewType }) => {
	const [type, setType] = React.useState('ADD_USER');
	const [f_name, setFname] = React.useState('');
	const [l_name, setLname] = React.useState('');
	const [mobile_no, setMobNo] = React.useState('');
	const [loading, setLoading] = React.useState(true);
	const [role_id, setRoleId] = React.useState('');
	const [user_id, setUserId] = React.useState('');
	const [parent_id, setParentId] = React.useState('');
	const [business_type, setBusinessType] = React.useState('');
	const [emp_code, setEmployeeCode] = React.useState('');
	const [is_active, setActiveStatus] = React.useState('');
	const [email, setEmail] = React.useState('');
	const [userProducts, setUserProducts] = React.useState([]);
	const [userBranchData, setUserBranchData] = React.useState([]);

	const [errors, setErrors] = React.useState({});
	const router = useRouter();
	const [parameters, setParameters] = React.useState({
		branchList: [],
		product: [],
		parentUserData: [],
		rolesList: {},
		businesstype: [],
	});
	const getParameters = async () => {
		const response = await getUserParameters(viewType);
		if (response?.status?.code === 1) {
			setParameters(response.data);
		}
		return response.data;
	};
	const getData = async () => {
		const params = await getParameters();
		if (router.params.userId) {
			setUserId(router.params.userId);
			await getUser(router.params.userId, params);
			if (router.pathname.indexOf('edit') !== -1) {
				setType('UPDATE_USER');
			} else {
				setType('VIEW_USER');
			}
		}
		setLoading(false);
	};
	React.useEffect(() => {
		getData();
	}, []);

	const getUser = async (user_id, params) => {
		const response = await getSingleUser({ userType: viewType, user_id });
		if (response?.status?.code === 1) {
			const data = response.data;
			const mappedProducts = data?.userProduct?.map((productItem) => ({
				label: productItem?.product_detail
					? productItem?.product_detail?.prod_type
					: productItem.prod_type,
				value: productItem.id ? productItem.id : productItem.prod_id,
			}));
			setUserProducts(
				viewType === 'lender' ? mappedProducts : data.userProduct
			);
			setEditUserVariables(data.userData, params);
			setRoleId(data?.roleData?.role_id);
			setUserBranchData(data.userBranchData);
		}
	};
	const setEditUserVariables = async (data, params) => {
		setFname(data.f_name);
		setLname(data.l_name);
		setMobNo(data.mobile_no);
		setEmail(data.email);
		setParentId({
			label: params.parentUserData[data.parent_id],
			value: data.parent_id,
		});
		setActiveStatus(data.is_active);
		setEmployeeCode(data.emp_code);
		setBusinessType(data.business_type);
	};
	return (
		<>
			<>
				<div className='ml-3'>
					{type !== 'ADD_USER' && (
						<UserHeader userId={user_id} viewType={viewType} />
					)}
					{/* <div className='row'>
						<div className='col-md-12 d-flex align-items-center content'>
							<Link
								to={`/${viewType ? viewType : 'admin'}/manage-users`}
								className='btn btn-close'
							>
								<i className='fa fa-chevron-left' aria-hidden='true'></i> Back
							</Link>
						</div>
					</div>
					<hr className='m-0' style={{width:"97%"}} /> */}
					<div className='content'>
						<h5 className='mt-2'>User Details</h5>
					</div>
					<div className='content'>
						<div className='row '>
							<div className='col-md-4'>
								<div className='form-group'>
									<label>
										{' '}
										First Name<tt>*</tt>
									</label>
									<input
										disabled={type === 'VIEW_USER'}
										onChange={(e) => setFname(e.target.value)}
										value={f_name}
										type='text'
										className='form-control'
										placeholder=' First Name'
									/>
									{errors && (
										<p className='mt-2 mb-0 text-danger'>
											{errors?.fields?.f_name?.[0]}
										</p>
									)}
								</div>
							</div>
							<div className='col-md-4'>
								<div className='form-group'>
									<label>
										{' '}
										Last Name <tt>*</tt>
									</label>
									<input
										disabled={type === 'VIEW_USER'}
										type='text'
										value={l_name}
										onChange={(e) => setLname(e.target.value)}
										className='form-control'
										placeholder=' Last Name'
									/>
									{errors && (
										<p className='mt-2 mb-0 text-danger'>
											{errors?.fields?.l_name?.[0]}
										</p>
									)}
								</div>
							</div>
							<div className='col-md-4'>
								<div className='form-group'>
									<label>
										{' '}
										Email<tt>*</tt>
									</label>
									<input
										disabled={(type === 'VIEW_USER' || (router.pathname.indexOf('edit') != -1))}
										value={email}
										onChange={(e) => setEmail(e.target.value)}
										type='text'
										className='form-control'
										placeholder='example@email.com	'
									/>
									{errors && (
										<p className='mt-2 mb-0 text-danger'>
											{errors?.fields?.email?.[0]}
										</p>
									)}
								</div>
							</div>
							<div className='col-md-4'>
								<div className='form-group'>
									<label>
										{' '}
										Mobile Number<tt>*</tt>
									</label>
									<input
										disabled={type === 'VIEW_USER'}
										value={mobile_no}
										onChange={(e) => setMobNo(e.target.value)}
										type='text'
										className='form-control'
										placeholder=' Mobile Number'
									/>
									{errors && (
										<p className='mt-2 mb-0 text-danger'>
											{errors?.fields?.mobile_no?.[0]}
										</p>
									)}
								</div>
							</div>
							<div className='col-md-4'>
								<div className='form-group'>
									<label>
										Role <tt>*</tt>
									</label>
									<select
										disabled={type === 'VIEW_USER'}
										value={role_id}
										onChange={(e) => setRoleId(e.target.value)}
										className='form-control'
									>
										<option value={null}>Role</option>
										{objectsForEach(parameters.rolesList, 'STRING_VAL').map(
											({ value, id }, index) => (
												<option key={index} value={id}>
													{value}
												</option>
											)
										)}
									</select>
									{errors && (
										<p className='mt-2 mb-0 text-danger'>
											{errors?.fields?.role_id?.[0]}
										</p>
									)}
								</div>
							</div>
							{/* {
								<div className='col-md-4'>
									<div className='form-group'>
										<label>
											Employee Code <tt>*</tt>
										</label>
										<input
											disabled={type === 'VIEW_USER'}
											value={emp_code}
											onChange={(e) => setEmployeeCode(e.target.value)}
											type='text'
											className='form-control'
										/>
										{errors && (
											<p className='mt-2 mb-0 text-danger'>
												{errors?.fields?.emp_code?.[0]}
											</p>
										)}
									</div>
								</div>
							} */}
							{/* {viewType !== 'lender' && (
								<div className='col-md-4'>
									<div className='form-group'>
										<label>Business Type</label>
										<select
											disabled={type === 'VIEW_USER'}
											value={business_type}
											onChange={(e) => setBusinessType(e.target.value)}
											className='form-control'
										>
											<option value={null}>Business Type</option>
											{parameters.businesstype.map(({ name, id }, index) => (
												<option value={id} key={index}>
													{name}
												</option>
											))}
										</select>
										{errors && (
											<p className='mt-2 mb-0 text-danger'>
												{errors?.fields?.business_type?.[0]}
											</p>
										)}
									</div>
								</div>
							)} */}
							{/* {viewType !== 'lender' && (
								<div className='col-md-4'>
									<div className='form-group'>
										<label>Reporting Manager</label>
										<Select
											isDisabled={type === 'VIEW_USER'}
											value={parent_id}
											placeholder='Select Reporting Manager'
											className='border-none'
											onChange={(parent) => setParentId(parent)}
											options={objectsForEach(
												parameters.parentUserData,
												'STRING_VAL'
											).map(({ value, id }) => ({
												value: id,
												label: value,
											}))}
										/>

										{errors && (
											<p className='mt-2 mb-0 text-danger'>
												{errors?.fields?.parent_id?.[0]}
											</p>
										)}
									</div>
								</div>
							)} */}
							<div className='col-md-4'>
								<div className='form-group'>
									<label>
										Status <tt>*</tt>
									</label>
									<select
										disabled={type === 'VIEW_USER'}
										value={is_active}
										onChange={(e) => setActiveStatus(e.target.value)}
										className='form-control'
									>
										<option value={null}>Status</option>
										<option value={0}>In active</option>
										<option value={1}>Active</option>
									</select>
									{errors && (
										<p className='mt-2 mb-0 text-danger'>
											{errors?.fields?.is_active?.[0]}
										</p>
									)}
								</div>
							</div>
							{viewType === 'lender' && (
								<div className='col-md-4'>
									<SelectProductLender
										product={userProducts}
										products={parameters.product}
										setProduct={setUserProducts}
										errors={errors}
									/>
								</div>
							)}
						</div>
					</div>
					{/* <hr className='m-0' />
					{viewType !== 'lender' && (
						<div className='content'>
							<h5>Branch Details</h5>
							{!loading && (
								<SelectedBranch
									viewMode={type === 'VIEW_USER'}
									userBranchData={userBranchData}
									branchList={parameters.branchList}
									setUserBranchData={setUserBranchData}
									errors={errors}
								/>
							)}
						</div>
					)} */}
					{/* <hr className='m-0' />
					<div className='content'>
						<h5>Products</h5>
						{!loading && (
							<SelectedProduct
								viewMode={type === 'VIEW_USER'}
								userProducts={userProducts}
								product={parameters.product}
								setUserProducts={setUserProducts}
								errors={errors}
							/>
						)}
					</div> */}

					<Actions
						setErrors={setErrors}
						type={type}
						f_name={f_name}
						l_name={l_name}
						mobile_no={mobile_no}
						role_id={role_id}
						parent_id={parent_id}
						is_active={is_active}
						email={email}
						user_id={user_id}
						emp_code={emp_code}
						business_type={business_type}
						branch={userBranchData}
						product={userProducts}
						viewType={viewType}
					/>
				</div>
			</>
		</>
	);
};

export default ViewUser;

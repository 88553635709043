import React from 'react';
import Actions from '../components/AddRole/Actions';
import PermissionList from '../components/AddRole/PermissionList';
import { getRolePermission } from '../services/manage-roles';
import { useRouter } from '../hooks/useRouter';
import { Link } from 'react-router-dom';
const ViewRole = ({ type: pageType }) => {
	const [type, setType] = React.useState('ADD_ROLE');
	const [parentPermissions, setParentPermissions] = React.useState([]);
	const [childrenPermissions, setChildrenPermissions] = React.useState([]);
	const [role_name, setRoleName] = React.useState('');
	const [permissions, setPermissions] = React.useState([]);
	const [role_id, setRoleId] = React.useState('');
	const [userPermissions, setUserPermissions] = React.useState([]);
	const router = useRouter();
	const [is_active, setIsActive] = React.useState(1);
	const [error, setErrors] = React.useState({});
	// const [type, setType] = React.useState('');
	const getAllPermission = async (role_id) => {
		const response = await getRolePermission({ type: pageType, role_id });
		if (response?.status?.code === 1) {
			if (role_id) {
				setRoleName(response.data.roleInfo.role_name);
				setIsActive(response.data.roleInfo.is_active);
				setUserPermissions(response.data.assignedPermArr);
			} else {
				const permissionData = (
					response?.data?.getPermissionsData || []
				).filter((permissionTree) => permissionTree.is_display);
				setPermissions(permissionData);
			}
		}
	};

	React.useEffect(() => {
		const role_id = router.params.roleId;

		getAllPermission();
		if (role_id) {
			if (router.pathname.indexOf('edit') !== -1) {
				setType('UPDATE_ROLE');
			} else {
				setType('VIEW_ROLE');
			}
			setRoleId(role_id);
			getAllPermission(role_id);
		}
	}, []);
	return (
		<>
			<div className='col-lg-9'>
				{/* <div className=''>
					<div className='row'>
						<div className='col-md-12 d-flex align-items-center content'>
							<Link
								to={`/${pageType ? pageType : 'admin'}/roles`}
								className='btn btn-close'
							>
								<i className='fa fa-chevron-left' aria-hidden='true'></i> Back
							</Link>

						</div>
					</div>
				</div>
				<hr className='m-0' /> */}
				<div className='content'>
					<h5 className='mt-2'>Role Details</h5>
				</div>
			
				<div className='alignments row' style={{marginLeft:"12px" , marginTop:"4px"}}>
					<div className='col-lg-4'>
						<div className='form-group '>
							<label>
								Role Name<tt>*</tt>
							</label>
							<input
								disabled={type === 'VIEW_ROLE'}
								className='form-bg form-control'
								onChange={(e) => setRoleName(e.target.value)}
								placeholder='Role Name'
								value={role_name}
							/>
							{error && (
								<p className='mt-2 mb-0 text-danger'>
									{error?.fields?.['role_name']?.[0]}
								</p>
							)}
						</div>
					</div>
					<div className='col-md-4'>
						<div className='form-group'>
							<label>
								Status<tt>*</tt>
							</label>
							<select
								disabled={type === 'VIEW_ROLE'}
								className='form-bg form-control'
								placeholder='Active'
								value={is_active}
								onChange={(e) => setIsActive(e.target.value)}
							>
								<option value={1}>Active</option>
								<option value={0}>In Active</option>
							</select>
						</div>
					</div>
				</div>
				<hr className='m-0' />
				<div>
					<div className='content'>
						<h5>Assign Permissions</h5>
					</div>

					<PermissionList
						viewMode={type === 'VIEW_ROLE'}
						userPermissions={userPermissions}
						edit={Boolean(role_id)}
						setParentPermissions={setParentPermissions}
						setChildrenPermissions={setChildrenPermissions}
						parentPermissions={parentPermissions}
						childrenPermissions={childrenPermissions}
						permissions={permissions}
					/>
				</div>
				<Actions
					setErrors={setErrors}
					type={type}
					pageType={pageType}
					role_id={role_id}
					role_name={role_name}
					is_active={is_active}
					parentPermissions={parentPermissions}
					childrenPermissions={childrenPermissions}
				/>
			</div>
		</>
	);
};

export default ViewRole;

import React, { useContext } from 'react';
import {
	affiliateListing,
	updateApproveRejectStatusService,
} from '../services/manage-affiliate';
import Pagination from '../components/Pagination';
import AffiliateListingItem from '../components/ManageAffiliate/AffiliateListingItem';
import SearchFilter from '../components/ManageAffiliate/SearchFilter';
import ChangeAffiliateType from '../components/ManageAffiliate/ChangeAffiliateType';
import Button from '../components/Common/Button';
import message from '../constant/message';
import { toast } from 'react-toastify';
import { useRouter } from '../hooks/useRouter';
import { downloadFile } from '../common/download';
import UploadUserModal from '../components/ManageUser/UploadUserModal';
import { Context } from '../config/store';

const ManageAffiliate = ({ listType = 'affiliate' }) => {
	const [affiliates, setAffiliates] = React.useState([]);
	const [pagination, setPaginationData] = React.useState({});
	const [page, setPage] = React.useState(1);
	const [queryParams, setQueryParams] = React.useState('');
	const [listingType, setListingType] = React.useState('');
	const [modalState, setModalState] = React.useState(false);
	const [loading, setLoading] = React.useState(false);
	const router = useRouter();
	const getAffiliates = async (listingType, exportAffiliateFlag) => {
		const response = await affiliateListing({
			page,
			queryParams,
			listType: listType,
			listingType,
			exportAffiliateFlag,
		});
		if (response?.status?.code === 1) {
			const data = response.data?.resource;
			setAffiliates(data?.data || []);
			setPaginationData(data?.pagination || {});
		} else if (exportAffiliateFlag) {
			downloadFile(response);
			setLoading(false);
		}
	};
	const [affiliateChangeModal, toggleAffiliateChangeModal] =
		React.useState(false);
	const [currentPartId, setCurrentPartId] = React.useState('');
	const [currentPartType, setCurrentPartType] = React.useState('');
	const [allChecked, setAllChecked] = React.useState(false);
	const [userIds, setUserIds] = React.useState([]);
	const [partnerIds, setPartners] = React.useState([]);
	const { state } = useContext(Context);
	React.useEffect(() => {
		getAffiliates(listingType);
	}, [page, queryParams, listingType, listType]);

	/**
	 *
	 */
	const setCheckedStatus = (state, part_id, user_id) => {
		if (state && part_id && user_id) {
			setPartners([...partnerIds, part_id]);
			setUserIds([...userIds, user_id]);
			return;
		} else if (!state && part_id && user_id) {
			const partIds = partnerIds.filter((id) => id !== part_id);
			const uIds = userIds.filter((userId) => userId !== user_id);
			setPartners([...partIds]);
			setUserIds([...uIds]);
			return;
		} else if (state) {
			const partIds = affiliates.map((affiliate) => affiliate.part_id);
			setPartners(partIds);
			const uIds = affiliates.map((affiliate) => affiliate.user_id);
			setUserIds(uIds);
			setAllChecked(true);
			return;
		} else {
			const partIds = [];
			setPartners(partIds);
			const uIds = [];
			setUserIds(uIds);
			setAllChecked(false);
		}
	};
	const affiliateTypeModal = (partId, partType) => {
		setCurrentPartId(partId);
		toggleAffiliateChangeModal(true);
		setCurrentPartType(partType);
	};
	const changeApproveRejectStatus = async ({ status }) => {
		setLoading(true);

		const response = await updateApproveRejectStatusService({
			part_id: partnerIds,
			user_id: userIds,
			status: status,
		});
		if (response?.status?.code === 1) {
			let success = response?.messages?.success;
			toast.success(
				success?.form?.[0] || success?.others?.[0] || message.SAVED_SUCCESS
			);
			getAffiliates(listingType);
		} else {
			let error = response.messages.error;
			// setErrors(error);
			toast.error(error.form?.[0] || error?.others?.[0] || message.ERROR);
		}
		setLoading(false);
	};

	const onChangeQueryParams = (params) => {
		setQueryParams(params);
		setPage(1);
	};
	const exportAffiliate = () => {
		setLoading(true);
		getAffiliates(listingType, true);
	};
	const toggleModal = () => {
		setModalState(!modalState);
	};
	return (
		<>
			{affiliateChangeModal && (
				<ChangeAffiliateType
					partId={currentPartId}
					currentPartType={currentPartType}
					state={affiliateChangeModal}
					reloadTable={getAffiliates}
					toggleModal={toggleAffiliateChangeModal}
				/>
			)}

			<>
				<div className='clearfix' />
				<SearchFilter setQueryParams={onChangeQueryParams} />

				<div className='row justify-content-end align-items-end mt-2'>
					{listType === 'affiliate' && (
						<div className='col-md-2'>
							<select
								onChange={(e) => setListingType(e.target.value)}
								className=' mt-3 form-control'
							>
								<option value=''>Select Type</option>
								<option value='1'>Affiliate</option>
								<option value='2'>DSA</option>
							</select>
						</div>
					)}
					<button
						onClick={toggleModal}
						type='button'
						className='btn btn-custom ml-3'
					>
						{'Add Affiliate'}
					</button>
					{modalState && (
						<UploadUserModal
							type='affiliate'
							userType={state.userType}
							state={modalState}
							toggleModal={toggleModal}
							reloadTable={getAffiliates}
						/>
					)}
					{!Boolean(listType === 'pending-affiliate-dsa-list') && (
						<div className='col-md-2'>
							<button
								onClick={exportAffiliate}
								type='button'
								className='btn btn-custom ml-3'
							>
								{loading ? <div className='loader' /> : 'Export Data'}
							</button>
						</div>
					)}
				</div>

				{Boolean(listType === 'pending-affiliate-dsa-list') &&
					partnerIds.length > 0 && (
						<div className='row no-gutters mt-4 justify-content-end align-items-end'>
							<Button
								disabled={loading}
								type='btn-custom me-2'
								clickHandler={() => changeApproveRejectStatus({ status: 1 })}
							>
								{loading ? <div className='loader' /> : 'Approve'}
							</Button>
							<Button
								disabled={loading}
								type='btn-custom me-2 ml-2'
								clickHandler={() => changeApproveRejectStatus({ status: 0 })}
							>
								{loading ? <div className='loader' /> : 'Reject'}
							</Button>
						</div>
					)}
				<div className='panel '>
					<div className='table-responsive'>
						<table className='table table-striped'>
							<thead>
								<tr>
									{Boolean(
										listType === 'pending-affiliate-dsa-list' &&
											router.pathname.includes('approval')
									) && (
										<th>
											<input
												type='checkbox'
												checked={allChecked}
												id='header-checkbox'
												onChange={(e) => setCheckedStatus(e.target.checked)}
											></input>
										</th>
									)}
									<th>Affiliate ID</th>
									<th>Affiliate Name</th>
									<th>Email</th>
									<th>Mobile Number</th>
									<th>PAN</th>

									<th>Partner Type</th>
									<th>Partner Code</th>
									<th>Onboarding Status</th>
									<th>Status</th>
									<th>Date Added</th>
									<th>Action</th>
								</tr>
							</thead>
							<tbody>
								{affiliates.map((affiliate) => (
									<AffiliateListingItem
										listType={listType}
										userIds={userIds}
										setCheckedStatus={setCheckedStatus}
										affiliateTypeModal={affiliateTypeModal}
										reloadTable={getAffiliates}
										key={affiliate.user_id}
										{...affiliate}
									/>
								))}
							</tbody>
						</table>
					</div>
				</div>
				<Pagination
					setPage={setPage}
					total_pages={pagination.total_pages}
					total={pagination.total}
				/>
			</>
		</>
	);
};

export default ManageAffiliate;

import React from 'react';
import PermissionEach from './PermissionEach';
import { useRouter } from '../../hooks/useRouter';
import treeToList from '../../common/treeToList';
const Permission = ({
	subChilds = [],
	display_name,
	defaultSelected,
	id,
	parentId,
	parentPermissions,
	childrenPermissions,
	setParentPermissions,
	setChildrenPermissions,
	parent_permission_id,
	permissions,
	edit,
	userPermissions
}) => {
	const [selected, setSelected] = React.useState(false);
	const router = useRouter();
	const toggleValue = e => {
		const state = e.currentTarget.checked;

		setSelected(state);

		if (state) {
			const parent = parentPermissions.find(
				parentPermission => parentPermission === parentId
			);

			if (!parent) {
				setParentPermissions(values => [...values, parentId]);
			}

			const checkChildExist = childrenPermissions.find(childPermission => {
				return childPermission === id;
			});

			if (checkChildExist || !parentId) return;

			setChildrenPermissions(values => [
				...new Set([...values]),
				id,
				parent_permission_id
			]);
		} else {
			// const filterIds = childrenPermissions
			// 	.filter(childId => childId !== parent_permission_id)
			// 	.filter(childId => childId !== id);

			setChildrenPermissions(childrenPermission => {
				let filterIds = childrenPermission.filter(childId => childId !== id);
				let permissionObject = treeToList(permissions, parent_permission_id);

				const permissionFlatArray = treeToList(permissionObject.childrens);
				const checkExist = filterIds.some(permission =>
					permissionFlatArray.includes(permission)
				);

				if (!checkExist) {
					filterIds = filterIds.filter(
						childId => childId !== parent_permission_id
					);
				}
				return filterIds;
			});
			const permissionObject = permissions.find(
				permission => permission.id === parentId
			);
			const permissionFlatArray = treeToList(permissionObject.childrens);
			const checkExist = childrenPermissions
				.filter(child => child !== id)
				.filter(child => child !== parent_permission_id)
				.some(permission => permissionFlatArray.includes(permission));
			if (!checkExist) {
				const newPermissions = parentPermissions.filter(
					parentPermission => parentPermission !== parentId
				);
				setParentPermissions(() => [...newPermissions]);
			}
		}
	};

	React.useEffect(() => {
		toggleValue({ currentTarget: { checked: defaultSelected } });
	}, [defaultSelected]);
	React.useEffect(() => {
		if (userPermissions?.length) {
			const isSelected = userPermissions.find(permission => permission === id);

			if (edit && isSelected) {
				toggleValue({ currentTarget: { checked: true } });
			}
		}
	}, [userPermissions]);
	if (subChilds.length) {
		return (
			<PermissionEach
				userPermissions={userPermissions}
				id={id}
				edit={edit}
				parentId={parentId}
				permissions={permissions}
				parent_permission_id={parent_permission_id}
				childrenPermissions={childrenPermissions}
				parentPermissions={parentPermissions}
				setChildrenPermissions={setChildrenPermissions}
				setParentPermissions={setParentPermissions}
				display_name={display_name}
				childrens={subChilds}
				parentDefaultSelected={defaultSelected}
			/>
		);
	}
	return (
		<div className='form-check'>
			<input
				disabled={router.pathname.indexOf('edit') === -1 && edit}
				className='form-check-input gray-bg'
				type='checkbox'
				checked={selected}
				onChange={toggleValue}
			/>
			<label className='opactiy-6 form-check-label font-size-12'>
				{display_name}
			</label>
		</div>
	);
};

export default Permission;

import { useRef, useState } from 'react';
import { toast } from 'react-toastify';
import Modal from '../../../../components/Modal';
import message from '../../../../constant/message';
import LenderUgroService from '../../../../services/lender';

const AddRepaymentDocument = ({ toggleModal, userType, leadId, state }) => {
	const inputRef = useRef(null);

	const [file, setFile] = useState(null);
	const [loading, setLoading] = useState(false);
	const [errors, setErrors] = useState();
	const formSubmitHandler = async (e) => {
		e.preventDefault();
		setLoading(true);
		try {
			let response;
			let formData = new FormData();
			formData.append('file', file);
			formData.append('lead_id', leadId);
			response = await LenderUgroService.addRepaymentDoc(
				userType,
				formData,
				userType === 'admin' ? 'co-lender' : ''
			);

			if (response?.data?.status?.code === 1) {
				toast.success(
					response?.data?.data?.messages?.[0]?.[0] || message.SAVED_SUCCESS
				);
			}
		} catch (err) {
			let error = err?.response?.data?.messages?.error;

			toast.error(error?.form?.[0] || error?.others?.[0] || message.ERROR_FORM);
			setErrors(error);
		}
		setLoading(false);
	};

	return (
		<Modal state={state} closeModal={toggleModal}>
			<form onSubmit={formSubmitHandler}>
				<div class='form-group'>
					<label>Upload File</label>
					<div class='row'>
						<div class='col-12'>
							<input
								ref={inputRef}
								file={file}
								onChange={(e) => setFile(e.target.files[0])}
								type='file'
								className='form-control'
							/>
						</div>
					</div>
				</div>
				<div class='d-flex align-items-center'>
					<button type='submit' class='btn btn-custom' disabled={!file}>
						{loading ? <div className='loader' /> : 'Submit'}
					</button>
					<button
						type='button'
						class='btn btn-outline-custom ml-2'
						data-dismiss='modal'
						onClick={toggleModal}
					>
						Cancel
					</button>
				</div>
			</form>
		</Modal>
	);
};

export default AddRepaymentDocument;

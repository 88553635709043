import React from 'react';
import Accordion from '../../../components/Portfolio/Accordion';
import Table from '../../../components/Common/Table';
import { objectsForEach } from '../../../common/objectToArray';
import { Context } from '../../../config/store';
import { useParams, useNavigate } from 'react-router';
import { toast } from 'react-toastify';
import message from '../../../constant/message';
import {
	uploadPartDoc,
	getPartnerKycDetails,
	savePartnerKycDetails,
	downloadKycDoc,
} from '../../../services/manage-affiliate';
import { Link } from 'react-router-dom';
import Actions from './Actions';
import { createPartnerCode } from '../../../services/onboarding-form-service';
import PromoterDocItem from './PromoterDocItem';
import EntityDocuments from './EntityDocuments';
import OptionGroup from './OptionGroup';
import DateFormatter from '../../../components/Common/DateFormatter';
const DocumentsUpload = () => {
	const documentFileTableHeaders = [
		{
			title: 'File Name',
			field: 'doc_name',
		},
		{
			title: 'Uploaded On',
			field: 'created_at',
			component: DateFormatter,
		},
		{
			title: 'Action',
			field: 'action',
			component: Actions,
		},
	];
	let inputRef = React.useRef(null);
	const { state } = React.useContext(Context);
	const partId = useParams().id;
	const [documentData, setDocumentData] = React.useState({});
	const [promoterId, setPromoterId] = React.useState('');
	const [promoterDocId, setPromoterDocId] = React.useState('');
	const [selectedDoc, setSelectedDoc] = React.useState();
	const [body, setBody] = React.useState();
	const [loading, setLoading] = React.useState(false);
	const [docLoading, setDocLoading] = React.useState(false);
	const [codeLoading, setCodeLoading] = React.useState(false);
	const [file, setFile] = React.useState(null);
	const [docs, setDocs] = React.useState([]);
	const [errors, setErrors] = React.useState({});
	const [showPromoters, setShowPromoters] = React.useState(false);
	const params = useParams();
	const navigate = useNavigate();
	const checkHasPermission = (checkPermission) => {
		// if (state.is_super_admin === 1) return true;
		const permission = state.permission.find(
			(permission) => permission === checkPermission
		);

		if (permission) return true;
		else return false;
	};
	const submitDocuments = async (e) => {
		e.preventDefault();
		setDocLoading(true);
		const requestBody = { info_type: 3, part_docs: docs };
		let response;
		try {
			response = await savePartnerKycDetails(
				state.userType,
				params.id,
				requestBody
			);

			if (response?.data?.status?.code === 1) {
				let success = response?.data?.messages?.success;
				toast.success(
					success?.form?.[0] || success?.others?.[0] || message.SAVED_SUCCESS
				);
				let path = '/onboarding/details/user';
				if (state.userType == 'admin') {
					path += `/${params.id}`;
				}
				navigate(path);
			}
		} catch (err) {
			let error = err?.response?.data?.messages?.error;
			toast.error(error?.form?.[0] || error?.others?.[0] || message.ERROR_FORM);
			setErrors(error);
		}

		setDocLoading(false);
	};
	const uploadFile = async (file, name) => {
		let response;
		if (file) {
			response = await uploadPartDoc(
				file,
				name,
				partId,
				selectedDoc,
				state.userType,
				selectedDoc === 'partner_documents' ? promoterDocId : undefined,
				promoterId
			);
			if (response?.status?.code === 1) {
				const doc = response.data.resource;
				docs.push({
					part_entity_doc_id: selectedDoc,
					file_id: doc.file_id,
					file_type: doc.file_type,
					file_name: doc.file_name,
				});
				setDocs(docs);
				let success = response?.messages?.success;
				toast.success(
					success?.form?.[0] || success?.others?.[0] || message.SAVED_SUCCESS
				);
			}
			return response;
		} else {
			let error = response?.messages?.error;

			toast.error(
				error?.form?.[0] || error?.others?.[0] || message.SELECT_FILE
			);
		}
	};
	const submitUploadDocument = async (e) => {
		e.preventDefault();
		if (!file) {
			return toast.error(message.SELECT_FILE);
		}
		setLoading(true);
		let docFile;
		if (selectedDoc !== 'partner_documents') {
			docFile = (documentData.document || []).find(
				(doc) => doc.partner_entity_doc_id == selectedDoc
			);
		} else {
			docFile = (documentData.promotor_doc_master || []).find(
				(doc) => doc.partner_entity_doc_id == promoterDocId
			);
		}
		const response = await uploadFile(file, docFile.doc_name);
		if (response?.status?.code == 1) {
			inputRef.current.value = '';
			setFile(null);
			getPartnerInfo();
		} else {
			let error = response?.messages?.error;
			toast.error(error?.form?.[0] || error?.others?.[0] || message.ERROR_FORM);
		}
		setLoading(false);
	};
	React.useEffect(() => {
		getPartnerInfo();
	}, []);
	const getPartnerInfo = async () => {
		const response = await getPartnerKycDetails(state.userType, params.id);
		setDocumentData(response.data.data);
		setBody(response.data.data.resource);
		// setDetails
	};
	const sumbitPartCode = async () => {
		const body = { part_id: params.id };
		setCodeLoading(true);
		try {
			const response = await createPartnerCode({ type: state.userType, body });
			if (response?.data?.status?.code == 1) {
				await getPartnerInfo();
				let success = response?.data?.messages?.success;
				toast.success(
					success?.form?.[0] || success?.others?.[0] || message.SAVED_SUCCESS
				);
			}
		} catch (err) {
			let error = err?.response?.messages?.error;
			toast.error(error?.form?.[0] || error?.others?.[0] || message.ERROR_FORM);
		}
		setCodeLoading(false);
	};
	const onDocumentChange = (e) => {
		setSelectedDoc(e.target.value);
		if (e.target.value === 'partner_documents') {
			setShowPromoters(true);
			return;
		} else setShowPromoters(false);
	};
	return (
		<div>
			<div className='row'>
				<div className='col-md-7'>
					<h5>Entity</h5>
					<EntityDocuments
						documentData={documentData}
						documentFileTableHeaders={documentFileTableHeaders}
					/>
					<div className='mt-5'>
						<h5>Promoter Documents</h5>

						{(documentData?.resource?.partner_promoters || []).map(
							(promoter, i) => {
								return (
									<PromoterDocItem
										docLength={
											documentData?.resource?.partner_promoters?.length
										}
										promoter={promoter}
										documentFileTableHeaders={documentFileTableHeaders}
										key={promoter.part_promoter_id}
									/>
								);
							}
						)}
					</div>
				</div>

				<div className='col-md-5'>
					<div className='card border-0'>
						<div className='card-body custom'>
							<h6>
								<small>
									<b>Upload More Documents</b>
								</small>
							</h6>

							<form onSubmit={submitUploadDocument}>
								<div className='form-group'>
									<label>Upload Document</label>
									<select
										value={selectedDoc}
										onChange={onDocumentChange}
										className='form-control custom-form-control'
									>
										<option value={''}>Select</option>
										<OptionGroup documentData={documentData} />
										<optgroup label={'Promoter Documents'}>
											<option value={'partner_documents'}>
												Promoter Documents
											</option>
										</optgroup>
									</select>
								</div>
								{showPromoters && (
									<>
										<div className='form-group'>
											<label>Promoters</label>
											<select
												value={promoterId}
												onChange={(e) => setPromoterId(e.target.value)}
												className='form-control custom-form-control'
											>
												<option value={''}>Select</option>

												{(documentData?.resource?.partner_promoters || []).map(
													({ name, part_promoter_id }, i) => (
														<option value={part_promoter_id}>{name}</option>
													)
												)}
											</select>
										</div>
										<div className='form-group'>
											<label>Document Type</label>
											<select
												value={promoterDocId}
												onChange={(e) => setPromoterDocId(e.target.value)}
												className='form-control custom-form-control'
											>
												<option value={''}>Select</option>

												{(documentData.promotor_doc_master || []).map(
													({ doc_name, partner_entity_doc_id, id }, i) => (
														<option value={partner_entity_doc_id}>
															{doc_name}
														</option>
													)
												)}
											</select>
										</div>
									</>
								)}
								<div className='form-group'>
									<label>Upload Document</label>
									<div className='row'>
										<div className='col-lg-9 col-md-8 col-sm-9 col-8'>
											<div className='form-group mt-3'>
												<input
													disabled={!selectedDoc || loading}
													ref={inputRef}
													file={file}
													onChange={(e) => setFile(e.target.files[0])}
													type='file'
													className='form-control'
												/>
												<small className='text-gray'>
													[max file size 10 mb]
												</small>
											</div>
										</div>
										<div className='col-lg-3 col-md-4 col-sm-3 col-4 d-flex align-items-center justify-content-end'>
											<button
												className='btn btn-custom'
												disabled={!selectedDoc || loading}
											>
												{loading ? <div className='loader' /> : 'Upload'}
											</button>
										</div>
									</div>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
			{body?.nsm_status !== 7 && (
				<div className='mt-4'>
					{(state.userType == 'admin'
						? checkHasPermission('edit-partner-detail')
						: true) && (
						<button
							onClick={submitDocuments}
							disabled={docLoading}
							type='submit'
							className='btn btn-custom'
						>
							{docLoading ? <div className='loader' /> : 'Next'}
						</button>
					)}
					{state.userType == 'admin' &&
						checkHasPermission('partner-code-creation') &&
						body?.nsm_status === 6 && (
							<button
								onClick={sumbitPartCode}
								disabled={codeLoading}
								type='submit'
								className='btn btn-custom ml-2'
							>
								{codeLoading ? <div className='loader' /> : 'Generate Code'}
							</button>
						)}
					{checkHasPermission('edit-partner-detail') && (
						<Link
							to={`/partner/onboarding/status`}
							className='btn btn-close ml-2'
						>
							Cancel
						</Link>
					)}
				</div>
			)}
		</div>
	);
};

export default DocumentsUpload;

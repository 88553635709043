import React from 'react'
import { useParams } from 'react-router';
import LenderUgroService from '../../../services/lender';
import { Context } from '../../../config/store';
import { toast } from 'react-toastify';
import Block from '../ViewPortfolio/Block';

const RepaymentDetails = () => {

  const leadId = useParams().id;
  const { state } = React.useContext(Context);

  const [repaymentDetailsData, setRepaymentDetailsData] = React.useState([]);
  const [loading, setLoading] = React.useState([]);
  const [newRepaymentDetailsData, setNewRepaymentDetailsData] = React.useState([]);
  const [activeDetailKey, setActiveDetailKey] = React.useState('')
  const [detailKeys, setDetailKeys] = React.useState('')
  const [activeDetailValues, setActiveDetailValues] = React.useState([])

  React.useEffect(() => {
    getRepaymentDetails()
  }, [])

  const getRepaymentDetails = async () => {
    setLoading(true);
    try {
      const response = await LenderUgroService.getRepaymentDetails({
        type: state.userType,
        cl_lead_id: leadId
      }
      );
      const data = response?.data?.data?.[0];

      let values = [];
      Object.entries(data)?.map((item) => {
        values.push(item[1])
      })

      setNewRepaymentDetailsData(Object.entries(data))
      setDetailKeys(Object.keys(data))
      setActiveDetailValues(Object.entries(values[0]))
      setActiveDetailKey(Object.keys(data)[0])
      setRepaymentDetailsData(data || [])
      setLoading(false)
    } catch (err) {
      toast.error(err?.response?.data?.messages?.error?.others[0])
      setLoading(false)
    }
  };

  const splitUnderscoreToCaptialise = (word) => {
    return word.replace(/(^|_)(\w)/g, function ($0, $1, $2) {
      return ($1 && ' ') + $2.toUpperCase();
    });
  };

  const handleLedtItemClick = (key) => {
    setActiveDetailKey(key)
    let value = []
    newRepaymentDetailsData?.map((item) => {
      if (item[0] === key) {
        value.push(Object.entries(item[1]))
      }
    })
    setActiveDetailValues(value[0])
  }

  return (
    <div className='row view-container'>
      {loading ?
        <div className='col-sm-12 d-flex justify-content-center'>
          <div className='Tableloader' style={{ margin: "10% auto auto auto" }}></div>
        </div>
        :
        (
          detailKeys.length === 0 ?
            <div className="d-flex justify-content-center align-items-center ml-auto mr-auto" style={{ marginTop: '100px', fontSize: '16px' }}>{'No Details Found'}</div>
            :
            <>
              <div className='col-md-3'>
                <div className='detailsLeftPart'>
                  {(detailKeys || [])?.map((key) => {
                    return (
                      <p
                        className={`detailsLeftItem ${activeDetailKey === key ? 'activeDetail' : ''}`}
                        onClick={() => { handleLedtItemClick(key) }}
                      >
                        <div className="d-flex justify-content-between">
                          {splitUnderscoreToCaptialise(key)}
                          {activeDetailKey === key && <span className='pr-2'><i class="fa fa-angle-right fa-lg" aria-hidden="true"></i></span>}

                        </div>
                      </p>
                    )
                  })}
                </div>
              </div>

              <div className='col-md-9'>
                <div className='detailsRightPart '>
                  <div className='detailsHeader'>
                    {splitUnderscoreToCaptialise(activeDetailKey)}
                  </div>
                  <div className="p-2">
                    <div className="row">
                      {activeDetailValues?.length > 0 ?
                        (activeDetailValues || [])?.map((detail, i) => {
                          return (
                            <div className='col-md-3'>
                              <p className='detailsRightLabel'>{splitUnderscoreToCaptialise(detail[0])}</p>
                              <p className='detailsRightValue'>{detail[1] ? detail[1] : '---'}</p>
                            </div>
                          )
                        })
                        :
                        <div className='NoDetailsFound'>No Details Found</div>
                      }
                    </div>
                  </div>
                </div>
              </div>
            </>
        )
        // Object.entries(repaymentDetailsData)?.length > 0 ?
        //   <>
        //     <div className='col-md-6'>
        //       {Object.entries(repaymentDetailsData)?.map((detail, i) => {
        //         if (i <= 1) {
        //           return (
        //             <Block
        //               key={i}
        //               titleKey={detail[0]}
        //               title={splitUnderscoreToCaptialise(detail[0])}
        //               list={detail[1]}
        //               isPostDisbursalCase={true}
        //             />
        //           );
        //         }
        //       })}
        //     </div>
        //     <div className='col-md-6'>
        //       {Object.entries(repaymentDetailsData)?.map((detail, i) => {
        //         if (i > 1) {
        //           return (
        //             <Block
        //               key={i}
        //               titleKey={detail[0]}
        //               title={splitUnderscoreToCaptialise(detail[0])}
        //               list={detail[1]}
        //               isPostDisbursalCase={true}
        //             />
        //           );
        //         }
        //       })}
        //     </div>
        //   </>
        //   :
        //   <div className='text-center col-12' style={{ fontSize: 16, marginTop: '35vh' }}>No Repayment Details Found</div>
      }

    </div>
  )
}

export default RepaymentDetails
import { useEffect, useState } from 'react';
import { generateArrayOfYears } from '../../../../common/date';
import Select from '../../../../components/Common/Input/Select';
import GraphTypeFilter from '../GraphTypeFilter';
import LenderPending2 from '../LenderPending2';
import LenderPending3 from '../LenderPending3';

export default function LenderLastRow({ userType }) {
	const [options, setOptions] = useState([]);
	const [currentFinancialYear, setFinancialYear] = useState();
	const [graphType, setGraphType] = useState();
	useEffect(() => {
		generateFinancialYearOptions();
	}, []);
	const generateFinancialYearOptions = () => {
		const yearsArray = generateArrayOfYears(20);
		const financialYearOptions = yearsArray.map((year) => ({
			label: `${year - 1}-${year}`,
			value: `${year - 1}-${year}`,
		}));
		setOptions(financialYearOptions);
	};
	return (
		<div className='row mt-3'>
			<div className='col-sm-12'>
				<div className='chart-card h-540'>
					<div class='d-flex align-items-center justify-content-between'>
						<div class='chart-heading text-orange'>Lender Pending</div>
					</div>
					<div className='row no-gutters mt-2'>
						{' '}
						<div className='col-md-3'>
							<Select
								isMulti={false}
								label='Financial Year'
								options={options}
								onChange={(data) => setFinancialYear(data)}
								value={currentFinancialYear}
							/>
						</div>
						<div className='col-md-3 ml-2'>
							<GraphTypeFilter
								onGraphTypeChange={(value) => setGraphType(value)}
							/>
						</div>
					</div>
					<LenderPending3
						financialYear={currentFinancialYear}
						userType={userType}
						graphType={graphType}
					/>
					<LenderPending2
						financialYear={currentFinancialYear}
						userType={userType}
						graphType={graphType}
					/>
				</div>
			</div>
		</div>
	);
}

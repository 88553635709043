import React from 'react';
import { useParams } from 'react-router';
import { Context } from '../../../../config/store';
import Button from '../../../../components/Common/Button';
import LenderUgroService from '../../../../services/lender';
import { getFormattedDate } from '../../../../common/date';
import { toast } from 'react-toastify';
import message from '../../../../constant/message';

export default function PostDemand({getDemandData, dueDate}) {
	const { state } = React.useContext(Context);
	const leadId = useParams().id;

	const [amount, setAmount] = React.useState(0);
	const [loading, setLoading] = React.useState(false);

	const postDemandData = async () => {
		setLoading(true);

		try {
			const body = {
				due_date: getFormattedDate(dueDate, 'dd/MM/yyyy'),
				amount: amount
			};

			const response = await LenderUgroService.postLeadDemand(
				state.userType,
				'co-lender',
				leadId,
				body
			);
			if (response?.data?.status?.code === 1) {
				let success = response?.data?.messages?.success;
				toast.success(
					success?.form?.[0] || success?.others?.[0] || message.SAVED_SUCCESS
				);
			} else {
				let error = response?.data?.messages?.error;
				toast.error(
					error?.form?.[0] || error?.others?.[0] || message.ERROR_FORM
				);
			}
			setLoading(false);
		} catch (err) {
			setLoading(false);
			let error = err?.response?.data?.messages?.error;
			toast.error(error?.form?.[0] || error?.others?.[0] || message.ERROR_FORM);
		}
	};

	return (
		<>
            <div className='mt-4'>
                <h5>Post Demand</h5>
                <form>
                    <div className='p-3'>
                        <div className='row align-items-center'>
                            <div className='col-md-4'>
                                <div className='form-group'>
                                    <label>Amount</label>
                                    <input
                                        value={amount}
                                        onChange={e => setAmount(e.target.value)}
                                        type='number'
                                        className='form-control'
                                    />
                                </div>
                            </div>
                            <div className='col-md-4'>
                                <Button type='btn-custom' clickHandler={postDemandData}>
                                    {loading ? <div className='loader' /> : 'Post Demand'}
                                </Button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
		</>
	);
}

import React from "react";
import { useParams } from "react-router";
import LenderUgroService from "../../../../services/lender";
import { Context } from "../../../../config/store";
import Table from "../../../../components/Common/Table";
import TableNoRecords from "../../../../components/Common/Table/TableNoRecords";
import './styles.css'
import { Borrower, Sourcing, Lending } from './TableColumns'
import { downloadFile } from "../../../../common/download";
import { toast } from "react-toastify";
import FetchRepaymentModal from "./FetchRepatmentModal";

export default function RepaymentSchedule({ selectedLender }) {
  const { state } = React.useContext(Context);
  const leadId = useParams().id;

  const [tableData, setTableData] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [activeTab, setActiveTab] = React.useState('Borrower')
  const [showDropDown, setDropDownStatus] = React.useState(false);
  const [showFetchRpsModal, setShowFetchRpsModal] = React.useState(false);
  const dropdownRef = React.useRef()
  const [rpsConfig, setRpsConfig] = React.useState({})

  React.useLayoutEffect(() => {
    getPaymentList()
  }, [activeTab])

  const handleExportClick = async (e, type, fileName) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await LenderUgroService.downloadRepaymentFile({
        type,
        leadId: leadId,
        lenderId: selectedLender,
        dataExport: 1,
        responseType: 'blob',
      });
      downloadFile(response.data, fileName + new Date().toLocaleString());
      toast.success(response?.data?.messages?.error?.others[0])
      setLoading(false);
    } catch (err) {
      setLoading(false);
      toast.error(err?.response?.data?.messages?.error?.others[0])
    }
    setDropDownStatus(!showDropDown)
  }

  const getPaymentList = async () => {
    setLoading(true);
    try {
      const response = await LenderUgroService.getRePaymentList({
        leadId: leadId,
        lenderId: selectedLender
      });
      const data = response?.data?.data?.repayments;
      const configData = response?.data?.data?.rps_config
      data.shift()
      setTableData(data);
      setRpsConfig(configData)
      setLoading(false);
    } catch (err) {
      setLoading(false);
      toast.error(
        err.response?.data?.messages?.error?.form[0] ||
        err.response?.data?.messages?.error?.others[0]
      );
    }
  };

  React.useEffect(
    () => {
      const listener = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
          setDropDownStatus(false)
          return;
        } else {
          setDropDownStatus(true)
          return;
        }
      };
      document.addEventListener("mousedown", listener);
      document.addEventListener("touchstart", listener);
      return () => {
        document.removeEventListener("mousedown", listener);
        document.removeEventListener("touchstart", listener);
      };
    },
    [dropdownRef]
  );

  return (
    <>
      <div className="repaymentTopContainer">
        <div className="repaymentTabsContainer">
          <div
            className={activeTab === 'Borrower' ? 'tabs active' : 'tabs'}
            onClick={() => { setActiveTab('Borrower') }}
            style={{ cursor: 'pointer' }}
          >
            Customer
          </div>
          <div
            className={activeTab === 'Sourcing' ? 'tabs active' : 'tabs'}
            onClick={() => { setActiveTab('Sourcing') }}
            style={{ cursor: 'pointer' }}
          >
            Ugro
          </div>
          <div
            className={activeTab === 'Lending' ? 'tabs active' : 'tabs'}
            onClick={() => { setActiveTab('Lending') }}
            style={{ cursor: 'pointer' }}
          >
            Lender
          </div>
        </div>
        <div className="repaymentRightContainer">
          <div className="rps_dropdown" >
            <div className={`dropdown user-login ${showDropDown ? "show" : ""}`}>
              <button
                className="btn user-dropdown btn-custom"
                onClick={() => setDropDownStatus(!showDropDown)}
                type="button"
                id="dropdownMenu"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Export
              </button>
              <div
                className={`DropdownLogout dropdown-menu ${showDropDown ? "show" : ""}`}
                aria-labelledby="dropdownMenu"
                ref={dropdownRef}
              >
                <div
                  className="dropdown-item dropdownItem"
                  onClick={(e) => {
                    handleExportClick(e, '1', "All")
                  }}
                  style={{ cursor: 'pointer' }}
                >
                  All
                </div>
                <div
                  className="dropdown-item dropdownItem"
                  onClick={(e) => {
                    handleExportClick(e, '2', "Customer_RPS_")
                  }}
                  style={{ cursor: 'pointer' }}
                >
                  Customer RPS
                </div>
                <div
                  className="dropdown-item dropdownItem"
                  onClick={(e) => {
                    handleExportClick(e, '4', "Ugro_RPS_")
                  }}
                  style={{ cursor: 'pointer' }}
                >
                  Ugro RPS
                </div>
                <div
                  className="dropdown-item dropdownItem"
                  onClick={(e) => {
                    handleExportClick(e, '3', "Lender_RPS_")
                  }}
                  style={{ cursor: 'pointer' }}
                >
                  Lender RPS
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="repayment_table_css">
        <Table
          columns={activeTab === 'Borrower' ? Borrower : activeTab === 'Sourcing' ? Sourcing : Lending}
          checkbox={false}
          rows={tableData}
          loading={loading}
          type="paymentData"
        >
          {Boolean(tableData.length < 1) && <TableNoRecords />}
        </Table>
      </div>

      {/* {totalElements > pageSize && (
				<Pagination
					size={pageSize}
					currentPage={page}
					totalElements={totalElements}
					updatePage={updateTablePage}
				></Pagination>
			)}  */}
      {/* queryInfo.cl_lender_id  &&*/}
    </>
  );
}
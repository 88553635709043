import React from 'react';
const SelectedProduct = ({
	product,
	userProducts,
	setUserProducts,
	viewMode,
	errors,
}) => {
	const [options, setOptions] = React.useState([{}]);
	const addProductId = (selectedIndex, index) => {
		const productParsed = product[selectedIndex - 1];

		if (productParsed) {
			if (!userProducts[index]) {
				userProducts[index] = {};
			}
			userProducts[index].prod_id = productParsed.prod_id;
			userProducts[index].max_limit = productParsed.max_limit;
			userProducts[index].min_limit = productParsed.min_limit;

			setUserProducts([...userProducts]);
		}
	};

	const changeMaxValue = (maxVal, index) => {
		setUserProducts((data) => {
			if (!data[index]) {
				data[index] = {};
			}
			data[index].max_limit = maxVal;
			return [...data];
		});
	};

	/**
	 *
	 * @param {Event} e
	 * @param {Number} index
	 */
	const changeMinValue = (minVal, index) => {
		setUserProducts((data) => {
			if (!data[index]) {
				data[index] = {};
			}
			data[index].min_limit = minVal;
			return [...data];
		});
	};
	React.useEffect(() => {
		if (userProducts.length) initialiseData();
	}, [userProducts]);

	const initialiseData = () => {
		setOptions(userProducts);
	};

	const addOption = () => {
		setOptions([...options, {}]);
		setUserProducts([...userProducts, {}]);
	};
	const deleteOption = (index) => {
		const newOptions = options.filter((option, idx) => idx !== index);
		setOptions([...newOptions]);
		const newProducts = userProducts.filter(
			(productItem, idx) => idx !== index
		);
		setUserProducts(newProducts);
	};
	return (
		<>
			{options.map(({}, index) => (
				<div key={index} className='row align-items-center'>
					<div className='col-md-4'>
						<div className='form-group'>
							<label>Products</label>
							{JSON.stringify(product)}
							<select
								disabled={viewMode}
								value={userProducts?.[index]?.prod_id}
								onChange={(e) => addProductId(e.target.selectedIndex, index)}
								className='form-control'
							>
								<option value={null}>Products</option>
								{product.map(
									({ prod_name, prod_id, max_limit, min_limit }, index) => (
										<option key={index} value={prod_id}>
											{prod_name} (max-{max_limit}) (min-{min_limit})
										</option>
									)
								)}
							</select>
							{errors && (
								<p className='mt-2 mb-0 text-danger'>
									{errors?.fields?.[`product.${index}.prod_id`]?.[0]}
								</p>
							)}
						</div>
					</div>
					<div className='col-md-3'>
						<div className='form-group'>
							<label>Min</label>
							<input
								disabled={viewMode}
								value={userProducts?.[index]?.min_limit}
								onChange={(e) => changeMinValue(e.target.value, index)}
								type='number'
								min={0}
								className='form-control'
								onKeyDown={(event) => {
									var invalidChars = ['-', '+', 'e'];

									if (invalidChars.includes(event.key)) {
										event.preventDefault();
										return;
									}
								}}
								placeholder='Min Value'
							/>
							{errors && (
								<p className='mt-2 mb-0 text-danger'>
									{errors?.fields?.[`product.${index}.min_limit`]?.[0]}
								</p>
							)}
						</div>
					</div>
					<div className='col-md-3'>
						<div className='form-group'>
							<label>Max</label>
							<input
								disabled={viewMode}
								value={userProducts?.[index]?.max_limit}
								onChange={(e) => changeMaxValue(e.target.value, index)}
								type='number'
								min={0}
								onKeyDown={(event) => {
									var invalidChars = ['-', '+', 'e'];
									if (invalidChars.includes(event.key)) {
										event.preventDefault();
										return;
									}
								}}
								className='form-control'
								placeholder='Max Value'
							/>
							{errors && (
								<p className='mt-2 mb-0 text-danger'>
									{errors?.fields?.[`product.${index}.max_limit`]?.[0]}
								</p>
							)}
						</div>
					</div>

					<div className='col-md-2'>
						{index >= 1 && (
							<i
								onClick={() => deleteOption(index)}
								className='fa fa-minus-circle'
								aria-hidden='true'
							></i>
						)}
					</div>
				</div>
			))}
			{!viewMode && (
				<div className='mt-4'>
					<i
						onClick={addOption}
						className='fa fa-plus-circle'
						aria-hidden='true'
					></i>
				</div>
			)}
		</>
	);
};

export default SelectedProduct;

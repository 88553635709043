import React from "react";
import { Context } from "../../../config/store";
import splitCamelCase from "../../../common/splitCamelCase";
const LeadStatus = ({ row, field }) => {
  const lenderToSkip = process.env.REACT_APP_SKIP_LENDER_STATUS
    ? process.env.REACT_APP_SKIP_LENDER_STATUS.split(",")
    : [];
  const { state } = React.useContext(Context);
  return (
    <span>
      {state.userType === "admin" && row.status ? (
        <ul>
          {row.assigned_lenders.map(({ lender, cl_status, cl_lender_id }) => {
            if (
              lenderToSkip.indexOf(String(cl_lender_id) || cl_lender_id) < 0
            ) {
              return lender?.lender_name.includes("Ugro Capital") ? (
                ""
              ) : (
                <li>
                  {lender?.lender_name}-{cl_status?.admin_status}
                </li>
              );
            } else {
              return "";
            }
          })}
        </ul>
      ) : (
        <span>
          {row?.assigned_lenders?.[0]?.cl_lender_id
            ? lenderToSkip.indexOf(
                String(row.assigned_lenders?.[0]?.cl_lender_id) ||
                  row.assigned_lenders?.[0]?.cl_lender_id
              ) < 0
              ? row?.assigned_lenders?.[0]?.cl_status?.lender_status
                ? splitCamelCase(
                    row?.assigned_lenders?.[0]?.cl_status?.lender_status
                  )
                : ""
              : ""
            : ""}
        </span>
      )}
    </span>
  );
};

export default LeadStatus;

import React, { useEffect } from 'react'
import Modal from '../../../../components/Modal'
import { Context } from '../../../../config/store';
import { objectsForEach } from '../../../../common/objectToArray';
import splitUnderscoreToCaptialise from '../../../../common/splitUnderscoreToCaptialise';
import LenderUgroService from '../../../../services/lender';
import { toast } from 'react-toastify';

let modLength = 0;

const AdditionalInfoCTA = ({ modalState, toggleModal, onChange, params, keyName, selectedLender, leadId, body, edit }) => {

    const skeleton = params.find(({ id }) => id == keyName);
    const { title, type, cta_iteration, ...config } = skeleton?.cta_data;
    const [modalDataLength, setModalDataLength] = React.useState(0)

    const getSplittedLabel = (label) => {
        let newLabel = label;
        if (label?.includes('Add/')) {
            newLabel = label.split('Add/')[1]
        }
        return newLabel;
    }

    const getCtaDataArray = (num) => {
        let data = []
        Object.keys(skeleton?.cta_data).map((item) => {
            if (item !== "title" && item !== 'type' && item !== 'cta_iteration') {
                data.push({
                    [item]: ''
                })
            }
        })
        modLength = data?.length;
        let newData = data.reduce((prev, curr) => ({ ...prev, ...curr }), {});
        const finalArray = Array.from({
            length: num
        }, () => (newData))
        return finalArray;
    }

    const { state } = React.useContext(Context);
    const [ctaData, setCtaData] = React.useState(getCtaDataArray(skeleton?.cta_data?.cta_iteration))
    const [modalDataId, setModalDataId] = React.useState(skeleton?.id);
    const [errors, setErrors] = React.useState([])

    useEffect(() => {
        setModalDataLength(modLength)
        body?.financial_details?.[modalDataId] && getAdditionalInformationAbbData();
    }, [modalDataId])

    const getAdditionalInformationAbbData = async () => {
        let response = await LenderUgroService.getAdditionalInfoAbbData({
            type: state.userType,
            add_info_cta_id: body?.financial_details?.[modalDataId]
        });
        response?.data?.data?.addInfoCta?.res_json?.length > 0 && setCtaData(response?.data?.data?.addInfoCta?.res_json)
    }

    return (
        <Modal state={modalState} closeModal={toggleModal} removeBorderSpace={true} style={{ width: 'fit-content', maxWidth: '90%' }}>
            <div className='card border-0'>
                <div className="modal-header-container">
                    <div className="modal-title">{getSplittedLabel(skeleton.label)}</div>
                    <div className="closeModalView" onClick={toggleModal}>
                        <span className="closeModalIcon">x</span>
                    </div>
                </div>

                <div className='px-4 pt-4 pb-3 modalData' style={{ maxHeight: "75vh" }}>
                    <div className="row position-sticky">
                        {objectsForEach(config).map((row, index) =>
                            <div className={`col-md-${12 / modalDataLength}`} style={{ width: "150px", padding: "0px 5px" }}>
                                <label style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center', fontSize: '12px' }}>{row.label} {row.value == '1' && <tt>*</tt>}</label>
                            </div>
                        )}
                    </div>
                    <form className='mb-3 mt-1' style={{ maxHeight: '300px', overflowY: 'scroll', overflowX: 'clip', paddingLeft: '10px', paddingRight: '10px' }}>
                        {ctaData?.map((val, index1) => {
                            return (
                                <div className='row' style={{ flexWrap: 'nowrap', overflowX: 'auto' }}>
                                    {objectsForEach(config).map((row, index2) =>
                                        <div className={`col-md-${12 / modalDataLength}`} key={index2} style={{ width: "150px", padding: "0px 5px" }}>
                                            {row?.rule?.input_type == 'dropdown' ?
                                                <>
                                                    <select className='form-control' name={row.id} value={val?.[row.id]}>
                                                        <option value=''>Select</option>
                                                        {objectsForEach(row.rule.options, 'STRING_VAL').map(({ value, id }) => (
                                                            <option value={value}>{splitUnderscoreToCaptialise(id)}</option>
                                                        ))}
                                                    </select>
                                                    {<p className='text-danger' style={{ fontSize: '10px', paddingTop: '0px' }}>{errors[index1]?.[row.id]}</p>}
                                                </>
                                                :
                                                row?.rule?.input_type == 'month' ?
                                                    <>
                                                        <input
                                                            type='month'
                                                            name={row.id}
                                                            className='form-control'
                                                            value={val?.[row.id]}
                                                            disabled={!edit}
                                                        />
                                                        {<p className='text-danger' style={{ fontSize: '10px', paddingTop: '0px' }}>{errors[index1]?.[row.id]}</p>}
                                                    </>
                                                    :
                                                    <>
                                                        <input
                                                            type={row?.rule?.input_type}
                                                            name={row.id}
                                                            className='form-control'
                                                            value={val?.[row.id]}
                                                            disabled={!edit}
                                                        />
                                                        {<p className='text-danger' style={{ fontSize: '10px', paddingTop: '0px' }}>{errors[index1]?.[row.id]}</p>}
                                                    </>
                                            }
                                        </div>
                                    )}
                                </div>
                            )
                        }
                        )}
                    </form>
                </div>
            </div>
        </Modal>
    )
}

export default AdditionalInfoCTA
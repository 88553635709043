import Accordion from '../../../components/Portfolio/Accordion';
import { objectsForEach } from '../../../common/objectToArray';
import Table from '../../../components/Common/Table';

const EntityDocuments = ({ documentData, documentFileTableHeaders }) => {
	let docs = (documentData?.document || []).reduce(function (r, a) {
		r[a.doc_type] = r[a.doc_type] || [];
		r[a.doc_type].push(a);
		return r;
	}, Object.create(null));
	return (
		<>
			{(objectsForEach(docs) || []).map((documentGroup, parentIndex) => {
				return (
					<div className='mt-4'>
						<h5 className=''>
							<span>{documentGroup.id}</span>
						</h5>
						{(documentGroup.value || []).map((document, i) => (
							<div className='card border-0'>
								<div className='card-body p-0'>
									<div id='accordion' className='custom_accordion'>
										<Accordion
											key={i}
											label={document.id || document.doc_name}
											count={document?.part_document?.length}
											headerId={'heading' + i + document.partner_entity_doc_id}
											collapseId={
												'collapse' + i + document.partner_entity_doc_id
											}
										>
											{document?.part_document &&
											document?.part_document?.length > 0 ? (
												<Table
													checkbox={false}
													columns={documentFileTableHeaders}
													rows={document.part_document}
													type='documents'
												></Table>
											) : (
												'NA'
											)}
										</Accordion>
									</div>
								</div>
							</div>
						))}
					</div>
				);
			})}
		</>
	);
};

export default EntityDocuments;

import React, { useReducer } from 'react';
import splitCamelCase from '../../../common/splitCamelCase';
import splitUnderscoreToCaptialise from '../../../common/splitUnderscoreToCaptialise';
import Error from '../../../components/Error';
import Field from '../../../components/Field';
import FormGroup from '../../../components/FormGroup';
import { initialState, reducer, Context } from '../../../config/sectionContext';
const SectionContent = ({
	onChange,
	errors,
	body,
	apiResponse,
	fields,
	index,
	errorKey,
	bindKey,
	multiple,
	edit,
}) => {
	const [state, dispatch] = useReducer(reducer, initialState);
	return (
		<Context.Provider value={{ state, dispatch }}>
			{fields.map(
				({
					key,
					label,
					type,
					hideField,
					options,
					required,
					component: Component,
				}) =>
					Component ? (
						<Component
							onChange={onChange}
							key={key}
							id={key}
							label={label}
							type={type}
							required={required}
							value={body?.[key]}
							body={body}
							apiResponse={apiResponse}
							index={index}
							multiple={multiple}
							errors={errors}
							bindKey={bindKey}
							disabled={!edit}
							errorKey={errorKey}
						/>
					) : (
						((hideField !== undefined && !hideField(body)) || !hideField) && (
							<div className='col-md-4' key={key}>
								<FormGroup
									required={required}
									label={label}
									type={type}
									onChange={onChange}
									body={body}
									options={options}
									disabled={!edit}
									multiple={multiple}
									index={index}
									bindKey={bindKey}
									errorKey={errorKey}
									id={key}
									errors={errors}
								/>
							</div>
						)
					)
			)}{' '}
		</Context.Provider>
	);
};

export default SectionContent;

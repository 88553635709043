import React from 'react';
const ComisssionDsaTableItem = ({
	lead_id,
	loan_amt,
	convert_to_app_date,
	product_code,
	comm_amt,
	// comm_type,
	base_payout,
	amt_type
}) => {
	return (
		<tr>
			<td>{lead_id}</td>
			<td>{parseFloat(loan_amt).toLocaleString('en-IN')}</td>
			{/* <td>{tenor}</td> */}
			<td>{convert_to_app_date}</td>
			<td>{product_code}</td>
			{/* <td>{comm_type}</td> */}
			<td>
				{base_payout} {amt_type == 'fixed' ? 'INR' : '%'}
			</td>
			{/* <td>{base_payout_amt}</td>
        <td>{tenor_incentive}</td>
        <td>{tenor_incentive_amt}</td> */}
			<td>{parseFloat(comm_amt).toLocaleString('en-IN')} INR</td>
		</tr>
	);
};

export default ComisssionDsaTableItem;

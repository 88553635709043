import React from 'react';
import { Link } from 'react-router-dom';
const UserHeader = ({ userId, viewType }) => {
	return (
		<div className='content'>
			<div className='row'>
				<div className='col-md-12'>
					{/* <Link
						to={`/${viewType ? viewType : 'admin'}/manage-users`}
						className='btn btn-close mb-3'
					>
						<i className='fa fa-chevron-left' aria-hidden='true'></i> Back
					</Link> */}
				</div>
				<div className='col-md-12 d-flex align-items-center'>
					<p className='font-18 mb-0'>
						<b>User ID:</b>{userId}
					</p>
				</div>
			</div>
		</div>
	);
};

export default UserHeader;

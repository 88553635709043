import React from 'react';
import { Link } from 'react-router-dom';
import { getFullDate } from '../../common/date';
import ReactTooltip from 'react-tooltip';
const UserListing = ({
	type,
	created_at,
	is_active,
	email,
	full_name,
	mobile_no,
	role_name,
	f_name,
	user_id,
	branch_name,
	reporting_mgr,
	employee_code,
	rptmgr_emp_code,
	business_type_name,
	toggleModal,
	setSelectedUserId,
	checkHasPermission,
	pageType,
}) => {

	return (
		<tr>
			<td>{user_id}</td>
			<td>{full_name}</td>
			{type === 'affiliate' && (
				<>
					<td>{email}</td>
					<td>{mobile_no}</td>
				</>
			)}
			{type === 'admin' && (
				<>
					<td>{employee_code}</td>
					{/* <td>{reporting_mgr}</td>
					<td>{rptmgr_emp_code}</td>

					<td>{branch_name}</td> */}
					<td>{role_name}</td>
					{/* <td>{business_type_name}</td> */}
				</>
			)}
			<td>{getFullDate(new Date(created_at))}</td>
			<td>
				{' '}
				<span className={is_active ? 'text-success' : 'text-warning'}>
					{is_active ? 'Active' : 'In active'}
				</span>
			</td>
			{type === 'affiliate' && (
				<td>
					<a
						href='#'
						onClick={(e) => {
							e.preventDefault();
							setSelectedUserId(user_id);
							toggleModal(true);
						}}
					>
						Edit
					</a>
				</td>
			)}
			{type === 'admin' && (
				<td>
					{checkHasPermission('update-backend-user') && (
						<Link to={`/admin/manage-users/edit/${user_id}`}>Edit</Link>
					)}
					/
					{checkHasPermission('get-backend-user') && (
						<Link to={`/admin/manage-users/${user_id}`}>View</Link>
					)}
				</td>
			)}
			{pageType === 'lender' && (
				<td>
					<ReactTooltip id='view'>View</ReactTooltip>
					<ReactTooltip id='edit'>Edit</ReactTooltip>
					<Link to={`/lender/manage-users/edit/${user_id}`}>
						<i className='fa fa-pencil fa-lg mr-2' style={{ color: '#4A4E88' }}
							data-tip
							data-for='edit'>
						</i>
					</Link>
					<Link to={`/lender/manage-users/${user_id}`}>
						<i className='fa fa-info-circle fa-lg' style={{ color: '#4A4E88' }}
							data-tip
							data-for='view'
						></i>
					</Link>

				</td>
			)}
		</tr>
	);
};

export default UserListing;

import Router from './router';
import getRoutes from './router/routes';
import { Context, reducer, initialState } from './config/store';
import React, { useReducer, useState } from 'react';
import Sidebar from 'react-sidebar';
import Notifications from './components/Notifications';
import Statemanage from './config/StateContext';
import { logout } from './services/auth';
import { useIdleTimer } from 'react-idle-timer'
import { inactiveTime } from '../src/constant/constants'
import NotFound from './components/NotFound';

function App() {
	const [state, dispatch] = useReducer(reducer, initialState);
	const [Buttonmanage, SetButtonmanage] = React.useState(false)

	const { getRemainingTime } = useIdleTimer({
		crossTab: true,
		timeout: 1000 * 60 * inactiveTime
	})

	React.useEffect(() => {
		const interval = setInterval(async () => {
			if (Math.ceil(getRemainingTime() / 1000) === 0) {
				await logout({ type: state.userType });
				window.location.reload();
			}
		}, 1000)
		return () => {
			clearInterval(interval)
		}
	}, [getRemainingTime])

	return (
		<Statemanage.Provider value={{ Buttonmanage, SetButtonmanage }}>
			<Context.Provider value={{ state, dispatch }}>
				{ (window.top != window.self  || !navigator.onLine) ?
				<NotFound></NotFound>
				:
				<Sidebar
					sidebar={<Notifications />}
					open={state.showNotification}
					pullRight
					onSetOpen={(open) =>
						dispatch({
							type: 'showNotification',
							showNotification: open,
						})
					}
				>
					<Router basePath={'/'} routes={getRoutes(state.userType)} />
				</Sidebar>
				}
			</Context.Provider>
		</Statemanage.Provider>
	);
}

export default App;

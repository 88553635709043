import React from 'react';
import Pagination from '../components/Pagination';
import RoleListingItem from '../components/ManageRole/RoleListingItem';
import Filters from '../components/ManageRole/Filters';
import { roleListing } from '../services/manage-roles';
import './manageRoles.css'
import objectToQueryParams from '../common/objectToQueryParams';
const ManageRoles = ({ type }) => {

	let sessionStorageData = JSON.parse(sessionStorage.getItem('searchData')) || {};
	let { org_page = 1 } = sessionStorageData;
	const queryString = objectToQueryParams(sessionStorageData);

	const [roles, setRoles] = React.useState([]);
	const [queryParams, setQueryParams] = React.useState(queryString);
	const [pagination, setPaginationData] = React.useState({});
	const [page, setPage] = React.useState(org_page);
	const getRoles = async (paramsChanged) => {
	 
		const response = await roleListing({ 
			type, 
			page: paramsChanged ? 1 : page, 
			queryParams 
		});
		if (response?.status?.code === 1) {
			const data = response.data?.resource;
			setRoles(data?.data || []);
			let searchDataNew = { ...sessionStorageData, org_page: (paramsChanged ? 1 : page) }
			sessionStorage.setItem('searchData', JSON.stringify(searchDataNew))
			setPaginationData(data?.pagination || {});
		}
	};
	// React.useEffect(() => {
	// 	getRoles();
	// }, [page, queryParams]);
	React.useEffect(() => {
		getRoles(false);
	}, [page])

	React.useEffect(() => {
		getRoles(true);
	}, [queryParams])

	return (
		<>
			<>
				<Filters setQueryParams={setQueryParams} />
				<div className='manageRoles mt-3'>
					<div className='table-responsive'>
						<table className='table table-striped'>
							<thead>
								<tr>
									<th>Role ID</th>
									<th>Role Name</th>
									<th>Created Date</th>
									<th>Status</th>
									<th>Action</th>
								</tr>
							</thead>
							<tbody>
								{roles.map((role) => (
									<RoleListingItem
										pageType={type}
										key={role.role_id}
										{...role}
									/>
								))}
							</tbody>
						</table>
					</div>
				</div>
				<Pagination
					setPage={setPage}
					total_pages={pagination.total_pages}
					total={pagination.total}
				/>
			</>
		</>
	);
};

export default ManageRoles;

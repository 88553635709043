import React from 'react'

const DisbursalTo = ({ row }) => {

    const getDisbursedTo = {
        "1": "Bank",
        "2": "Customer"
    }
    return (
        <div>{getDisbursedTo?.[row.disbursal_to]}</div>
    )
}

export default DisbursalTo
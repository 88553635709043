import React from 'react';
import { toast } from 'react-toastify';
import Button from '../../../components/Common/Button';
import Modal from '../../../components/Modal';
import message from '../../../constant/message';
import LenderUgroService from '../../../services/lender';

export default function RejectModal({
	showRejectModal,
	leadId,
	userType,
	toggleRejectModal,
	redirectToAssigned,
}) {
	const [rejectComment, setRejectComment] = React.useState('');
	const [rejectingLead, setRejectingLead] = React.useState(false);
	const rejectLead = async () => {
		setRejectingLead(true);
		try {
			const response = await LenderUgroService.rejectLead({
				type: userType,
				requestBody: {
					cl_lead_ids: [`${leadId}`],
					message: rejectComment,
				},
			});
			const success = response?.data?.messages?.success;
			toast.success(
				success?.form?.[0] || success?.others?.[0] || message.SAVED_SUCCESS
			);
			setRejectingLead(false);
			toggleRejectModal(false);
			redirectToAssigned();
		} catch (err) {
			setRejectingLead(false);
			let error = err?.response?.data?.messages?.error;
			toast.error(error?.form?.[0] || error?.others?.[0] || message.ERROR_FORM);
		}
	};
	return (
		<Modal state={showRejectModal} closeModal={toggleRejectModal} style={{ width: '450px' }} removeBorderSpace={true}>
			<div className="modal-header-container">
				<div className="modal-title">Reject Case</div>
				<div className="closeModalView" onClick={toggleRejectModal}>
					<span className="closeModalIcon">x</span>
				</div>
			</div>
			<div className="p-4 modalData">
				<p className='font-weight-bold'>
					Are you sure you want to reject this case?
				</p>
				<div class='form-group mt-2'>
					<label>Reason for rejecting the case</label>
					<textarea
						class='form-control custom-form-control'
						cols='2'
						rows='4'
						value={rejectComment}
						onChange={(e) => setRejectComment(e.target.value)}
					></textarea>
				</div>
				<div className='d-flex align-items-center justify-content-center'>
					<Button clickHandler={rejectLead} classNames='btn-custom'>
						{rejectingLead ? <div className='loader' /> : 'Submit'}
					</Button>
					<Button
						clickHandler={toggleRejectModal}
						classNames='btn-outline-custom ml-2'
					>
						Cancel
					</Button>
				</div>
			</div>
		</Modal>
	);
}

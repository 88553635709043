import React from 'react';
import Modal from '../Modal';
import { toast } from 'react-toastify';
import message from '../../constant/message';
import { bulkUploadTemplate, bulkUploadUser } from '../../services/manage-user';
import { downloadFile } from '../../common/download';
const UploadUserModal = ({
	userType,
	state,
	type,
	toggleModal,
	reloadTable,
}) => {
	let inputRef = React.useRef(null);
	const style = { width: '420px' };
	const [file, setFile] = React.useState(null);
	const [errors, setErrors] = React.useState(null);
	const [loading, setLoading] = React.useState(false);
	const submit = async (e) => {
		setLoading(true);
		e.preventDefault();

		const response = await bulkUploadUser(file, userType, type);
		if (response?.status?.code === 1) {
			toast.success(message.ADD_SUCCESS);
			toggleModal(false);
			inputRef.current.value = '';
			reloadTable();
		} else {
			let error = response.messages.error;
			setErrors(error);
			toast.error(error?.form?.[0] || error?.others?.[0] || message.ERROR);
		}
		setLoading(false);
	};
	const downloadTemplate = async (e) => {
		e.preventDefault();
		const response = await bulkUploadTemplate(userType, type);
		downloadFile(response);
	};

	return (
		<Modal state={state} style={style} closeModal={toggleModal}>
			{/* Modal body */}
			<div>
				<h5 className=' text-orange font-r'>
					{type === 'affiliate' ? 'Add Affiliate' : 'Add Users'}
				</h5>
				<form>
					<div className='panel mb-3 '>
						<div className='head_top border-0 d-flex align-items-center mt-3'>
							<h4 className='font-r'>Select File </h4>
							<a href='' onClick={downloadTemplate} className='ml-4'>
								{' '}
								(Download Template)
							</a>
						</div>
						<div className='p-3'>
							<div className='row'>
								<div className='col-md-4 pr-0'>
									<input
										type='text'
										className='form-control value-text'
										placeholder='Search File'
									/>
									<div> {file?.name}</div>
									<small className='text-gray'>
										[max file size 10 mb/ only .xls file allowed]
									</small>
								</div>
								<div className='col-md-3 d-flex '>
									<div className='custom-file-uploader mt-1'>
										<input
											ref={inputRef}
											file={file}
											onChange={(e) => setFile(e.target.files[0])}
											type='file'
											className='custom-file-input'
										/>

										<button type='button' className='btn btn-custom '>
											Browse
										</button>
									</div>
								</div>
							</div>
						</div>
						<br />
						<br />
						<br />
					</div>

					<button
						disabled={loading}
						onClick={submit}
						type='submit'
						className='btn btn-custom mr-2'
					>
						{loading ? <div className='loader' /> : 'Submit'}
					</button>
					<button
						onClick={toggleModal}
						type='button'
						className='btn btn-close'
						data-dismiss='modal'
					>
						Cancel
					</button>
				</form>
			</div>
		</Modal>
	);
};

export default UploadUserModal;

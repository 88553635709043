import { toast } from 'react-toastify';

export const downloadFile = (response, fileName) => {
	const url = window.URL.createObjectURL(response);
	const link = document.createElement('a');
	link.href = url;
	link.setAttribute('download', fileName || 'sample'); //or any other extension
	document.body.appendChild(link);
	link.click();
	toast.success('Downloaded successfully !');
};

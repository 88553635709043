import React from 'react';

export const initialState = {
	stateId: ''
};

export const reducer = (state, action) => {
	const { stateId, type } = action;

	switch (type) {
		case 'stateId':
			return { ...state, stateId };

		default:
			return state;
	}
};

export const Context = React.createContext(null);

import React from "react";
import { downloadKycDoc } from "../../../services/manage-affiliate";
import { downloadFile } from "../../../common/download";
import { toast } from "react-toastify";
import { Context } from "../../../config/store";
import { viewOnboardingDoc } from "../../../services/onboarding-form-service";
import Modal from "../../../components/Modal";
import message from "../../../constant/message";
import RenderFile from "../RenderFile";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";
import Button from "../../../components/Common/Button";
import LenderUgroService from "../../../services/lender";
import ReactTooltip from "react-tooltip";
const Actions = (props) => {

	const location = useLocation()
	return (
		<div className={`row no-gutters ${props.className}`}>
			{location.pathname.includes('conditional-approve') ? <>
				{props?.row?.file && <>
					<Download {...props} />
					<ViewFile {...props} />
				</>
				}
				<Approve {...props} /></>
				: <>
					{props?.row?.file && <>
						<Download {...props} />
						<ViewFile {...props} />
					</>}
				</>

			}
		</div>
	);
};

export const Download = ({ title, row: { file_id, supporting_file } }) => {
	const location = useLocation()
	const { state } = React.useContext(Context);
	const [loading, setState] = React.useState(false);
	const downloadDoc = async () => {
		setState(true);
		try {
			const response = await downloadKycDoc(
				state.userType,
				file_id ? file_id : supporting_file
			);
			downloadFile(response.data);
		} catch (err) {
			toast.error(message.ERROR);
		}
		setState(false);
	};
	return (
		<>
			<button className='btn btn-custom mx-1'
				onClick={downloadDoc}
				disabled={loading}
				data-for='download-file'
				style={{ width: "30px", height: "30px", display: "flex", justifyContent: "center", alignItems: "center" }} data-tip>
				<i className='fa fa-download'></i>
			</button>
			<ReactTooltip id='download-file'>Download Document</ReactTooltip>
		</>
	);
};

const ViewFile = ({ row: { file_id, doc_type, ...rest } }) => {
	const location = useLocation()
	const { state } = React.useContext(Context);
	const [modalState, setModalState] = React.useState(false);
	const [responseData, setResponseData] = React.useState(null);
	const [fileUrl, setFileUrl] = React.useState("");
	const [loading, setState] = React.useState(false);
	const toggleModal = () => {
		setModalState(!modalState);
		if (!matchMedia) setFileUrl("");
	};
	const viewFile = async () => {
		setState(true);
		try {
			const response = await viewOnboardingDoc({
				type: state.userType,
				fileId: file_id,
			});

			setResponseData(response.data);
			let url = window.URL.createObjectURL(response.data);
			setFileUrl(url);
			toggleModal();
		} catch (err) {
			toast.error(message.ERROR);
		}
		setState(false);
	};
	return (
		<>
			<Modal
				state={modalState}
				closeModal={toggleModal}
				removeBorderSpace={true}
				style={fileUrl ? { width: "70%", height: "40%" } : { height: "80%" }}
			>
				{!fileUrl ? (
					"No file to view"
				) : (
					<RenderFile
						extension={(String(responseData?.type) || "").split("/")?.[1] || responseData?.type}
						fileUrl={fileUrl}
						closeModal={toggleModal}
					/>
				)}
			</Modal>
			<button className='btn btn-custom mx-1'
				onClick={viewFile}
				disabled={loading}
				data-for='view-file'
				style={{ width: "30px", height: "30px", display: "flex", justifyContent: "center", alignItems: "center",cursor:"pointer" }} data-tip>
				<i className='fa fa-eye'>
				</i>
			</button>
			<ReactTooltip id='view-file'>View Document</ReactTooltip>
		</>
	);
};

const Approve = ({ row: { file_id, doc_type, ...rest }, row, reloadTable }) => {
	const { state } = React.useContext(Context);
	const [modalState, setModalState] = React.useState(false);
	const [loading, setState] = React.useState(false);
	const toggleModal = () => {
		setModalState(!modalState);

	};
	const OnloadModal = async () => {
		setState(true);
		try {
			toggleModal();
		} catch (err) {
			toast.error(message.ERROR);
		}
		setState(false);
	};

	const deleteList = async () => {

		try {
			let apiResponse = await LenderUgroService.SanctionConditionDelete({
				type: state.userType,
				subType: state.userType === 'admin' ? 'co-lender' : '',
				pre_cond_id: row?.cl_pre_cond_params_id,
			});
			if (apiResponse?.data?.status?.code === 1) {
				setModalState(!modalState);
				reloadTable()
				toast.success(
					apiResponse.data?.messages?.success?.others[0]
				);
			}
		} catch (err) {
			let error = err?.response?.data?.messages?.error;
			toast.error(error?.form?.[0] || error?.others?.[0] || message.ERROR_FORM);
		}
	}
	return (
		<>
			<Modal
				state={modalState}
				closeModal={toggleModal}>
				<div className='p-1'>
					<p className='font-weight-bold'>
						Are you sure you want to delete this sanction condition?
					</p>
					<div className='d-flex align-items-center '>
						<Button
							clickHandler={deleteList}
							classNames='btn-custom '
						>
							Delete
						</Button>
						<Button
							clickHandler={toggleModal}
							classNames='btn-outline-custom ml-2'
						>
							Cancel
						</Button>
					</div>
				</div>

			</Modal>
			<button className='btn btn-custom mx-1'
				onClick={OnloadModal}
				data-for='delete-file'
				disabled={loading}
				style={{ width: "30px", height: "30px", display: "flex", justifyContent: "center", alignItems: "center" }} data-tip>
				<i className='fa fa-trash'>
				</i>
			</button>
			<ReactTooltip id='delete-file'>Delete Document</ReactTooltip>
		</>
	);
};

export default Actions;

import React from "react";
const BlockRow = ({ label, value }) => {
  return (
    <div className="row">
      <div className="col-6">
        <p>{label==="OBJECT PRI KEY 1"?"LAF ID":label}</p>
      </div>
      {value && (
        <div className="col-6">
          <p>
            <b>
              {value ? (
                typeof value === "object" ? (
                  <i>Unable to render</i>
                ) : (
                  value
                )
              ) : (
                "NA"
              )}
            </b>
          </p>
        </div>
      )}
    </div>
  );
};

export default BlockRow;

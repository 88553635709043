import React from 'react';
import { downloadOfferFile } from '../../services/manage-offer';
import { downloadFile } from '../../common/download';
import { getFullDate } from '../../common/date';
import { toast } from 'react-toastify';
import message from '../../constant/message';
import { Link } from 'react-router-dom';
const OfferListingItem = ({
	file_id,
	created_at,
	no_of_offers_converted,
	no_of_records,
	temp_offer_file_id,
	index,
	part_id,
	page
}) => {
	const [showDropDown, setDropDownStatus] = React.useState(false);
	const downloadOffer = async () => {
		try {
			const response = await downloadOfferFile({ file_id });
			if (response) downloadFile(response);
		} catch (err) {
			let error = JSON.parse(err.message);
			toast.error(error?.form?.[0] || error?.others?.[0] || message.ERROR);
		}
	};
	return (
		<tr>
			<td>{index + 1 + (page - 1) * 10}</td>
			{/* <td>File Name</td> */}
			<td>{no_of_records}</td>
			<td>{no_of_offers_converted}</td>
			<td>{created_at}</td>
			<td>
				<div className={`dropdown ${showDropDown && 'show'}`}>
					<button
						// onBlur={() => setDropDownStatus(false)}
						onClick={() => setDropDownStatus(!showDropDown)}
						className='btn dropdown-toggle ActionBtn'
						type='button'
						data-toggle='dropdown'
						aria-haspopup='true'
						aria-expanded={showDropDown}
					>
						<span className='text-gray'>Action</span>
					</button>
					<div
						className={`dropdown-menu min-width-auto ${showDropDown && 'show'}`}
						aria-labelledby='dropdownMenuButton'
					>
						<Link
							className='dropdown-item'
							to={`/offers/${part_id}/leads/${temp_offer_file_id}`}
						>
							Offer Leads
						</Link>
						<a
							onClick={e => {
								e.preventDefault();
								downloadOffer();
								setDropDownStatus(false);
							}}
							className='dropdown-item'
							href='#'
						>
							Download
						</a>
					</div>
				</div>
			</td>
		</tr>
	);
};

export default OfferListingItem;

import { useState } from 'react';
import { toast } from 'react-toastify';
import Modal from '../../../../components/Modal';
import message from '../../../../constant/message';
import LenderUgroService from '../../../../services/lender';

const AddUTRModal = ({ userType, state, toggleModal, leadId, reloadTable }) => {
	const [loading, setLoading] = useState(false);

	const [LANno, setLANno] = useState('');
	const [customerName, setCustomerName] = useState('');
	const [UTRDate, setUTRDate] = useState('');
	const [utr, setUTR] = useState('');
	const [file, setFile] = useState(null);
	const [UtrDate, SetutrDate] = useState('');
	const [disbursedamount, setDisbursedamount] = useState('');
	const [disburseddate, setdisburseddate] = useState('');
	const [errors, setErrors] = useState();

	const formSubmitHandler = async (e) => {
		e.preventDefault();
		setLoading(true);
		const formData = new FormData();
		LANno && formData.append('lan_no', LANno);
		customerName && formData.append('cust_name', customerName);
		utr && formData.append('utr_no', utr);
		UtrDate && formData.append('utr_date', UtrDate);
		disbursedamount && formData.append('disbursed_amt', disbursedamount);
		disburseddate && formData.append('disbursed_date', disburseddate);
		leadId && formData.append('lead_id', leadId);
		file && formData.append('file', file);
		try {
			let apiResponse;
			apiResponse = await LenderUgroService.addUTRInfo({
				type: userType,
				formData,
				subType: userType === 'admin' ? 'co-lender' : '',
			});
			if (apiResponse?.data?.status?.code === 1) {
				toast.success(
					apiResponse?.data?.data?.messages?.[0]?.[0] || message.SAVED_SUCCESS
				);
				toggleModal();
				reloadTable();
			}
		} catch (err) {
			let error = err?.response?.data?.messages?.error;

			toast.error(error?.form?.[0] || error?.others?.[0] || message.ERROR_FORM);
			setErrors(error);
		}
	};

	return (
		<Modal state={state} closeModal={toggleModal} style={{ width: '450px' }} removeBorderSpace={true}>
			<div className="modal-header-container">
				<div className="modal-title">Add UTR Details</div>
				<div className="closeModalView" onClick={toggleModal}>
					<span className="closeModalIcon">x</span>
				</div>
			</div>
			<div className="p-4 modalData">
				<form onSubmit={formSubmitHandler}>
					<div class='form-group'>
						<label>LAN No.</label>
						<div class='row'>
							<div class='col-12'>
								<input
									class='form-control custom-form-control'
									value={LANno}
									onChange={(e) => setLANno(e.target.value)}
									required
								/>
							</div>
						</div>
					</div>
					<div class='form-group'>
						<label>Customer Name</label>
						<div class='row'>
							<div class='col-12'>
								<input
									class='form-control custom-form-control'
									value={customerName}
									onChange={(e) => setCustomerName(e.target.value)}
									required
								/>
							</div>
						</div>
					</div>
					<div class='form-group'>
						<label>Disbursed Amount</label>
						<div class='row'>
							<div class='col-12'>
								<input
									type='number'
									class='form-control custom-form-control'
									value={disbursedamount}
									onChange={(e) => setDisbursedamount(e.target.value)}
									required
								/>
							</div>
						</div>
					</div>
					<div class='form-group'>
						<label> Disbursed Date</label>
						<div class='row'>
							<div class='col-12'>
								<input
									type='Date'
									className='form-control'
									name='date'
									value={disburseddate}
									onChange={(e) => setdisburseddate(e.target.value)}
									required
								/>
							</div>
						</div>
					</div>
					<div class='form-group'>
						<label>UTR No.</label>
						<div class='row'>
							<div class='col-12'>
								<input
									class='form-control custom-form-control'
									value={utr}
									onChange={(e) => setUTR(e.target.value)}
									required
								/>
							</div>
						</div>
					</div>

					<div class='form-group'>
						<label> UTR Date</label>
						<div class='row'>
							<div class='col-12'>
								<input
									type='Date'
									className='form-control'
									name='date'
									value={UtrDate}
									onChange={(e) => SetutrDate(e.target.value)}
									required
								/>
							</div>
						</div>
					</div>
					{userType == 'lender' && (
						<div class='form-group'>
							<label>Document Upload</label>
							<div class='row'>
								<div class='col-12'>
									<input
										type='file'
										className='form-control'
										name='date'
										onChange={(e) => setFile(e.target.files[0])}
									/>
								</div>
								{errors && (
									<p className='mt-2 mb-0 text-danger'>{errors.fields.file[0]}</p>
								)}
							</div>
						</div>
					)}
					<div class='d-flex align-items-center justify-content-center'>
						<button type='submit' class='btn btn-custom' disabled={loading}>
							{loading ? <div className='loader' /> : 'OK'}
						</button>
						<button
							type='button'
							class='btn btn-outline-custom ml-2'
							data-dismiss='modal'
							onClick={toggleModal}
						>
							Cancel
						</button>
					</div>
				</form>
			</div>
		</Modal>
	);
};

export default AddUTRModal;

import React from 'react';
const SelectedBranch = ({
	branchList,

	userBranchData,
	setUserBranchData,
	viewMode,
	errors
}) => {
	const [options, setOptions] = React.useState([{}]);
	const changeExcludeZipCode = (e, index) => {
		setUserBranchData(data => {
			if (!data[index]) {
				data[index] = {};
			}
			data[index].excluded_zipcode = e.target.value;
			return [...data];
		});
	};
	const [pincodes, setPincodes] = React.useState([]);
	const changeBranchId = (e, index) => {
		if (!userBranchData[index]) {
			userBranchData[index] = {};
		}
		userBranchData[index].branch_id = e.target.value;

		getPincodes(e.target.value, index);
		setUserBranchData(userBranchData);
	};

	const deleteOption = index => {
		const newOptions = options.filter((option, idx) => idx !== index);
		setOptions([...newOptions]);
		const newBranchData = userBranchData.filter(
			(branchData, idx) => idx !== index
		);
		setUserBranchData(newBranchData);
	};
	const getPincodes = (branchId, index) => {
		const pincodeList = branchList
			.find(branch => branch.branch_id === branchId)
			.branch_zipcode.map(zipcode => zipcode.branch_zipcode)
			.join(', ');
		pincodes[index] = pincodeList;
		setPincodes([...pincodes]);
	};
	const addOption = index => {
		setOptions([...options, {}]);
		setUserBranchData([...userBranchData, {}]);
	};
	React.useEffect(() => {
		if (userBranchData.length) initialiseData();
	}, []);

	const initialiseData = () => {
		setOptions(userBranchData);

		userBranchData.map(({ branch_id }, index) => getPincodes(branch_id, index));
	};
	return (
		<>
			{options.map(({}, index) => (
				<div className='row align-items-center'>
					<div className='col-md-4'>
						<div className='form-group'>
							<label>Branches</label>
							<select
								disabled={viewMode}
								value={userBranchData?.[index]?.branch_id}
								onChange={e => changeBranchId(e, index)}
								className='form-control'
							>
								<option value={null}>Branches (Multiple select)</option>
								{branchList.map(({ branch_name, branch_id }, index) => (
									<option key={index} value={branch_id}>
										{branch_name}
									</option>
								))}
							</select>
							{errors && (
								<p className='mt-2 mb-0 text-danger'>
									{errors?.fields?.[`branch.${index}.branch_id`]?.[0]}
								</p>
							)}
						</div>
					</div>
					<div className='col-md-4'>
						<div className='form-group'>
							<label>Pins</label>
							<textarea
								disabled
								value={pincodes[index]}
								type='text'
								className='form-control'
							/>
						</div>
					</div>
					<div className='col-md-3'>
						<div className='form-group'>
							<label>Exclude Pins</label>
							<input
								disabled={viewMode}
								value={userBranchData?.[index]?.excluded_zipcode}
								onChange={e => changeExcludeZipCode(e, index)}
								type='text'
								className='form-control'
								placeholder='Exclude Pins'
							/>
							{errors && (
								<p className='mt-2 mb-0 text-danger'>
									{errors?.fields?.[`branch.${index}.excluded_zipcode`]?.[0]}
								</p>
							)}
						</div>
					</div>

					<div className='col-md-1'>
						{index >= 1 && (
							<i
								onClick={() => deleteOption(index)}
								className='fa fa-minus-circle'
								aria-hidden='true'
							></i>
						)}
					</div>
				</div>
			))}
			{!viewMode && (
				<div className='mt-4'>
					<i
						onClick={addOption}
						className='fa fa-plus-circle'
						aria-hidden='true'
					></i>
				</div>
			)}
		</>
	);
};

export default SelectedBranch;

import { useState, useEffect } from 'react';
import Select from '../../../components/Common/Input/Select';
const GraphTypeFilter = ({ onGraphTypeChange }) => {
	const [graphType, setGraphType] = useState({ label: 'Leads', value: 1 });
	const [options] = useState([
		{ label: 'Leads', value: 1 },
		{ label: 'Amount', value: 2 },
	]);
	useEffect(() => {
		onGraphTypeChange(graphType);
	}, [graphType]);

	return (
		<Select
			isMulti={false}
			label='Graph Type'
			options={options}
			onChange={(data) => setGraphType(data)}
			value={graphType}
		/>
	);
};

export default GraphTypeFilter;

import React, { useContext, useState, useEffect } from 'react';
import { useParams, useLocation } from 'react-router';
import LenderUgroService from '../../../../services/lender';
import { Context } from '../../../../config/store';
import Table from '../../../../components/Common/Table';
import Pagination from '../../../../components/Pagination';
import TableNoRecords from '../../../../components/Common/Table/TableNoRecords';
import AddQueryModal from '../../../../components/Portfolio/AddQueryModal';
import { getQueries } from '../../../../services/onboarding-form-service';
import { getColumns } from './tableHelpers';
import ReplyQuery from './ReplyQuery';
import Statemanage from '../../../../config/StateContext';
import './styles.css'
export default function QueryList({ type, selectedLender }) {
	const { state } = React.useContext(Context);
	const leadId = useParams().id;
	const queryId = useParams().queryId;
	const location = useLocation();
	const [tableData, setTableData] = React.useState([]);
	const [page, setCurrentPage] = React.useState(1);
	const [pagination, setPagination] = React.useState({});
	const [loading, setLoading] = React.useState(true);
	const [click, setClick] = React.useState(false);
	const [AllReplyData, SetReplyData] = React.useState({});

	const [parentQueryId, setParentQueryId] = React.useState({
		queryId: null,
		leadId: null,
	});
	const { Buttonmanage, SetButtonmanage } = useContext(Statemanage);
	SetButtonmanage(click);

	const getQueryList = async () => {
		setLoading(true);
		let response;

		if (type === 'onboarding-form') {
			response = await getQueries(
				state.userType,
				state.userType === 'affiliate' ? '' : 'affiliate',
				leadId
			);
		} else {
			response = await LenderUgroService.getQueryDetails({
				type: state.userType,
				isChild: queryId,
				params: {
					cl_lead_id: leadId,
					page: page,
					lender_ids: [selectedLender],
				},
			});
		}
		let data =
			response.data.data?.[
				type === 'onboarding-form' ? 'partner_query' : 'cl_lender_query'
			];
		if (!queryId) {
			data = data?.data;
		}

		data.forEach((data) => {
			if (data.query_status === 1) {
				data.query_status = 'open';
			} else if (data.query_status === 2) {
				data.query_status = 'resolved';
			} else if (data.query_status === 3) {
				data.query_status = 'closed';
			} else if (data.query_status === 4) {
				data.query_status = 'Re-Opened';
			}
		});
		data.forEach((data) => {
			if (data.priority === 1) {
				data.priority = 'Low';
			} else if (data.priority === 2) {
				data.priority = 'Medium';
			} else if (data.priority === 3) {
				data.priority = 'High';
			}
		});
		setTableData(data);
		setLoading(false);
		setCurrentPage(page);
		setPagination(response?.data?.data?.cl_lender_query?.pagination);
	};

	const [modalState, setModalState] = React.useState(false);
 
	const toggleModal = (makeQueryCall) => {
		if (makeQueryCall) {
			getQueryList();
		}
		setModalState(!modalState);
	};

	const checkHasPermission = (checkPermission) => {
		if (state.is_super_admin === 1) return true;
		const permission = state.permission.find(
			(permission) => permission === checkPermission
		);

		if (permission) return true;
		else return false;
	};
	React.useEffect(() => {
		getQueryList();
	}, [page, selectedLender, location.pathname]);

	const changePage = (a, b) => {
		// console.log(a,b)
		setParentQueryId({
			queryId: a,
			leadId: b,
		});
		setClick(!click);
	};

	return (
		<>
			<div className='row no-gutters justify-content-between align-items-center mt-2'>
				<div></div>
				<div className='mb-2'>
					{(state.userType === 'lender'
						? checkHasPermission('add-new-query')
						: true) && click == true ? (
						''
					) : (
						<button className='btn btn-custom' onClick={toggleModal}>
							Add New Query
						</button>
					)}
					{modalState && (
						<AddQueryModal
							userType={state.userType}
							state={modalState}
							toggleModal={toggleModal}
							leadId={leadId}
							lenderId={selectedLender}
							globalState={state}
							type={type}
							parentQueryId={queryId}
						/>
					)}
				</div>
			</div>
			{click ? (
				<div className='custom_table_css_query_Reply'>
				<ReplyQuery
					replyQuery={changePage}
					type={type}
					parentId={parentQueryId}
					leadId={leadId}
					userType={state.userType}
					state={modalState}
					toggleModal={toggleModal}
					lenderId={selectedLender}
					globalState={state}
					parentQueryId={queryId}
					getQueryList={getQueryList}
				/>
				</div>
			) : (
				<div className='custom_table_css_query'>
				<Table
					checkbox={false}
					columns={getColumns(type, state)}
					rows={tableData}
					replyQuery={changePage}
					click={click}
					loading={loading}
					type='querydocuments'
					tableExtraClass={''}
					reloadTable={getQueryList}
				>
					{Boolean(!tableData.length && !loading) && <TableNoRecords />}
				</Table>
				</div>
			)}

			{pagination?.total_pages ? (
				<Pagination
					setPage={setCurrentPage}
					total_pages={pagination.total_pages}
					total={pagination.total}
					showTotal={true}
				/>
			) : (
				''
			)}
			{/* queryInfo.cl_lender_id  &&*/}
		</>
	);
}

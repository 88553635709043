import React from 'react';
import { getApplication } from '../services/manage-applications';
import { useRouter } from '../hooks/useRouter';
import { toast } from 'react-toastify';
import message from '../constant/message';
import { objectsForEach } from '../common/objectToArray';
import Section from '../components/ViewApplication/Section';
import splitUnderscoreToCaptialise from '../common/splitUnderscoreToCaptialise';
const ViewApplication = () => {
	const [applicationData, setApplicationData] = React.useState({});
	const router = useRouter();
	React.useEffect(() => {
		getApplicationData();
	}, []);

	const getApplicationData = async () => {
		const id = router.params.id;
		const response = await getApplication({ id });
		if (response?.status?.code === 1) {
			setApplicationData(response?.data?.resource || []);
		} else {
			let error = response?.messages?.error;
			toast.error(error?.form?.[0] || error?.others?.[0] || message.ERROR_FORM);
			// setErrors(error);
		}
	};
	return (
		<>
			{objectsForEach(applicationData || []).map(({ id, ...rest }) => (
				<Section
					key={id}
					title={splitUnderscoreToCaptialise(id)}
					params={rest}
				/>
			))}
		</>
	);
};

export default ViewApplication;

export const productType = [{ label: 'Secured', value: 1 }, { label: 'Unsecured', value: 2 }];
export const eligibleForLender = [
	{ label: 'SBI' },
	{ label: 'IDBI' },
	{ label: 'CBI' },
	{ label: 'BOB' }
];
export const productName = [
	{ label: 'SCF-SCF Borrower Onboarding' },
	{ label: 'SL-CIHL Program Secured' },
	{ label: 'SL-ECL Secured' },
	{ label: 'SL-Low LTV Secured' },
	{ label: 'SL-Machinery and Equipment Finanace-GST' },
	{ label: 'SL-Normal Income Program Secured' },
	{ label: 'SL-Pratham-Secured' },
	{ label: 'SL-Sanjeevani Program-Secured' },
	{ label: 'SL-ABB Surrogate Bank Secured' },
	{ label: 'SL-Turnover Surrogate GST Secured' },
	{ label: 'SL-Turonver Surrogate PNL Secured' },
	{ label: 'SL-Saksham Program Secured' },
	{ label: 'SL-SaathiPlus Secured-ABB' },
	{ label: 'SL-SaathiPlus Secured-AI' },
	{ label: 'SL-SaathiPlus Secured-NWA' },
	{ label: 'SL-SaathiPrime Secured-ABB' },
	{ label: 'SL-SaathiPrime Secured-AI' },
	{ label: 'SL-SaathiPrime Secured-NIP' },
	{ label: 'USL-ECL Unsecured' },
	{ label: 'USL-Normal Income Program Unsecured' },
	{ label: 'USL-Sanjeevani Program- Unsecured' },
	{ label: 'USL-ABB Surrogate Bank UnSecured' },
	{ label: 'USL- Turonver Surrogate GST UnSecured' },
	{ label: 'USL-Turonver Surrogate PNL UnSecured' }
];

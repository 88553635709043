export default {
	REGISTER_SUCCESS: 'Register Success',
	LOGIN_SUCCESS: 'Login success !',
	ERROR: 'Something went wrong !',
	ERROR_FORM: 'There was an error submitting the form !',
	ADD_SUCCESS: 'Added successfully !',
	SAVED_SUCCESS: 'Saved successfully !',
	UPDATE_SUCCESS: 'Updated successfully !',
	SELECT_FILE: 'Please Select a file',
	UPLOAD_SUCCESS: 'Upload successfully !',
	OTP_VERIFIED: 'Otp verified !',
	OTP_SENT: 'OTP has been successfully sent to your mobile number.',
	NOT_VERIFIED: 'User not verified !',
	ADD_ROLE: {
		INVALID_ROLE_NAME: 'Please enter role name !'
	},
	PLEASE_ENTER: 'Please Enter'
};

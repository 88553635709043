import React from 'react';
import Modal from '../Modal';
import { assignLead, leadAssignUser } from '../../services/manage-leads';
import { toast } from 'react-toastify';
import message from '../../constant/message';
import Select from 'react-select';
const AssignLead = ({
	toggleModal,
	state,
	leadId,
	setSelectedLeadId,
	setSelectAll,
	reloadTable
}) => {
	const style = { width: '300px', overflow: 'visible' };
	const [errors, setErrors] = React.useState(null);
	const [loading, setLoading] = React.useState(false);
	const [selectedUser, setSelectedUser] = React.useState(null);
	const [products, setProducts] = React.useState([]);
	const [selectedProduct, setSelectedProduct] = React.useState(null);
	const [users, setUsers] = React.useState([]);
	React.useEffect(() => {
		getUsers();
	}, []);
	const getUsers = async () => {
		const response = await leadAssignUser();

		if (response?.status?.code === 1) {
			setUsers(response.data?.backendUser || []);
		}
	};
	const selectUser = selectedOption => {
		setSelectedUser(selectedOption);
		const currentUser = users.find(
			user => user.user_id === selectedOption.value
		);
		if (currentUser) {
			setProducts(currentUser.user_product);
		}
	};
	const submit = async () => {
		setLoading(true);
		const response = await assignLead({
			user_id: selectedUser.value,
			lead_id: leadId,
			product_id: selectedProduct
		});
		if (response?.status?.code === 1) {
			let success = response?.messages?.success;
			toast.success(
				success?.form?.[0] || success?.others?.[0] || message.UPDATE_SUCCESS
			);
			toggleModal(false);
			setSelectedLeadId([]);
			reloadTable();
			setSelectAll(false);
		} else {
			setErrors(response.messages.error);
			toast.error(response.messages?.error?.form?.[0] || message.ERROR);
		}
		setLoading(false);
	};
	return (
		<Modal state={state} style={style} closeModal={() => toggleModal(false)}>
			<div className='modal-content gray-bg'>
				{/* Modal body */}
				<div className='modal-body'>
					<h5 className=' text-orange font-r'>Assign Lead</h5>
					<form>
						<div className='form-group'>
							<label>User</label>
							<Select
								className='border-none'
								value={selectedUser}
								options={users.map(({ user_id, f_name, l_name, emp_code }) => ({
									label: `${f_name} ${l_name || ''} - ${emp_code || ''}`,
									value: user_id
								}))}
								onChange={selectUser}
							/>
							{/* <select
								value={selectedUser}
								onChange={selectUser}

								className='form-control'
							>
								<option value={null}>Select User</option>
								{users.map(({ user_id, f_name, l_name, emp_code }) => (
									<option value={user_id}>
										
									</option>
								))}
							</select> */}
							{errors && (
								<p className='mt-2 mb-0 text-danger'>
									{errors?.fields?.user_id?.[0]}
								</p>
							)}
						</div>
						<div className='form-group'>
							<label>Select Product</label>
							<select
								disabled={!selectedUser}
								value={selectedProduct}
								onChange={e => setSelectedProduct(e.target.value)}
								className='form-control'
							>
								<option value={null}>Select Product</option>
								{products.map(({ product_detail, prod_id }) => (
									<option value={prod_id}>{product_detail?.prod_name}</option>
								))}
							</select>
							{errors && (
								<p className='mt-2 mb-0 text-danger'>
									{errors?.fields?.product_id?.[0]}
								</p>
							)}
						</div>
						<button
							disabled={loading}
							onClick={submit}
							type='button'
							className='btn btn-custom mr-2'
						>
							{loading ? <div className='loader' /> : 'Submit'}
						</button>
						<button
							onClick={() => toggleModal(false)}
							type='button'
							className='btn btn-close'
							data-dismiss='modal'
						>
							Cancel
						</button>
					</form>
				</div>
			</div>
		</Modal>
	);
};

export default AssignLead;

import React from 'react';
import { getLeadHistory } from '../../services/manage-leads';
import Modal from '../Modal';
import { getFullDate } from '../../common/date';
const LeadHistory = ({ state, toggleModal, type, leadId }) => {
	const style = { width: '300px' };

	const [history, setHistory] = React.useState([]);
	const leadHistory = async () => {
		const response = await getLeadHistory({ type, leadId });
		setHistory(response?.data?.lead_history?.data || []);
	};
	React.useEffect(() => {
		leadHistory();
	}, []);
	return (
		<Modal state={state} style={style} closeModal={() => toggleModal(false)}>
			<div className='modal-content gray-bg'>
				{/* Modal body */}
				<div className='modal-body'>
					<ul className='listing mb-0'>
						{history.map(
							(
								{
									updated_at,
									lead_id,
									status_name,
									status_child_name,
									followup_datetime,
									comment
								},
								index
							) => (
								<li key={index}>
									<span className='time'>
										{getFullDate(new Date(updated_at))}
									</span>{' '}
									- LD00{lead_id} loan application is{' '}
									<span className='text-success'>{status_name} </span>
									{status_child_name && (
										<strong className='font-bold font-size-12'>
											- {status_child_name}
										</strong>
									)}
									<span className='d-block'>{comment}</span>{' '}
									{followup_datetime && '-'} {followup_datetime}
								</li>
							)
						)}
						{!history.length && (
							<li>
								<span className='time'>No status update</span>
							</li>
						)}

						{/* <li>
							<span className='time'>4:26 pm</span> - LD001, LD002 and LD004
							were <span className='text-primary'>contacted</span>
						</li>
						<li>
							<span className='time'>4:15 pm</span> - LD011 loan application is{' '}
							<span className='text-danger'>rejected</span>
						</li>
						<li>
							<span className='time'>4:04 pm</span> - LD012 loan application is{' '}
							<span className='text-warning'>on hold</span>
						</li> */}
					</ul>
				</div>
			</div>
		</Modal>
	);
};

export default LeadHistory;

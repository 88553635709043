import React from 'react';
import { useRouter } from '../hooks/useRouter';
import { resetPassword } from '../services/auth';
import { toast } from 'react-toastify';
import message from '../constant/message';
import { Context } from '../config/store';
import ReactTooltip from 'react-tooltip';
import PasswordPolicy from '../components/PasswordPolicy';
const ResetPassword = () => {
	const router = useRouter();
	const [password, setPassword] = React.useState('');
	const [confirm_password, setConfirmPassword] = React.useState('');
	const password_token = router.query.token;
	const [errors, setErrors] = React.useState(null);
	const { state } = React.useContext(Context);
	const [loader, setStatus] = React.useState(false);
	const [showPassword, setShowPassword] = React.useState(false);
	const [showPasswordtwo, setShowPasswordtwo] = React.useState(false);

	const submitReset = async (e) => {
		setStatus(true);
		e.preventDefault();
		const response = await resetPassword({
			password,
			confirm_password,
			password_token,
			type: state.userType,
		});
		if (response?.status?.code === 1) {
			toast.success('Password reset successfully !');
			router.navigate(`/${state.userType}/login`);
		} else {
			let error = response.messages.error;
			toast.error(error?.form?.[0] || error?.others?.[0] || message.ERROR_FORM);
			setErrors(error);
		}
		setStatus(false);
	};
	return (
		<section className='reset-password-container'>
			<div className='reset-user-bg'>
				<div className='logo text-center mb-3'>
					<img src='/images/logo.png' width={230} />
				</div>
				<form className='mt-5'>
					<div className='form-group'>
						<label style={{ fontSize: '18px', display: 'flex', justifyContent: 'start' }}>New Password <span style={{ fontSize: '10px', marginLeft: '5px',marginTop: '5px' }}><PasswordPolicy password={password} /></span></label>
						<div className='inputWithIcon'>
							<input
								value={password}
								type={showPassword ? 'text' : 'password'}
								onChange={(e) => setPassword(e.target.value)}
								className='form-control'
								placeholder='**********'
								style={{ height: '40px' }}
							/>
							<i onClick={() => setShowPassword(!showPassword)} className={`fa ${!showPassword ? "fa-eye-slash" : "fa-eye"}`}></i>

							{errors && (
								<p className='mt-2 mb-0 text-danger'>
									{errors?.fields?.password?.[0]}
								</p>
							)}
						</div>
						<div className='clearfix' />
					</div>
					<div className='form-group'>
						<div className='form-group'>
							<label style={{ fontSize: '18px', display: 'flex', justifyContent: 'start' }}>Confirm Password </label>
							<div className='inputWithIcon'>
								<input
									value={confirm_password}
									type={showPasswordtwo ? 'text' : 'password'}
									onChange={(e) => setConfirmPassword(e.target.value)}
									className='form-control'
									placeholder='**********'
									style={{ height: '40px' }}
								/>
								<i onClick={() => setShowPasswordtwo(!showPasswordtwo)} className={`fa ${!showPasswordtwo ? "fa-eye-slash" : "fa-eye"}`}></i>
							</div>
							{errors && (
								<p className='mt-2 mb-0 text-danger'>
									{errors?.fields?.password?.[0]}
								</p>
							)}
							<div className='clearfix' />
						</div>
						<div className='clearfix' />
					</div>
					<div className='d-flex justify-content-center align-items-center mt-3'>
						<div className='form-group'>
							<button
								disabled={loader}
								onClick={submitReset}
								className='btn btn-blueButton hoverStyle'
								style={{ height: '30px', width: '100px',borderRadius: '5px' }}
							>
								{loader ? <div className='loader' /> : 'Reset Password'}
							</button>
						</div>
					</div>
				</form>
			</div>
		</section>
	);
};

export default ResetPassword;

import React, { useEffect, useState } from 'react';
import { objectsForEach } from '../../../../common/objectToArray';
import Select from '../../../../components/Common/Input/Select';
import GraphContainer from '../../../../components/Portfolio/GraphContainer';
import { bg } from '../../../../constant/options';
import LenderUgroService from '../../../../services/lender';
import MasterDataService from '../../../../services/master-data';
import GraphTypeFilter from '../GraphTypeFilter';

function LenderPending({ userType }) {
	const [loading, setLoading] = useState(true);
	const [queryParams, setQueryParams] = useState({});
	const [error, setError] = useState(false);
	const [series, setSeries] = useState([]);
	const [options, setOptions] = useState({
		chart: {
			type: 'bar',
			height: 430,
		},
		plotOptions: {
			bar: {
				dataLabels: {
					position: 'top',
				},
			},
		},
		dataLabels: {
			enabled: true,
			offsetY: -20,
			style: {
				fontSize: '12px',
				colors: ['#5A5B79'],
			},
		},
		stroke: {
			show: true,
			width: 1,
			colors: ['#fff'],
		},
		tooltip: {
			shared: true,
			intersect: false,
		},
		colors: [bg[2].color],
	});
	const getDashboardLenderPending = async () => {
		setLoading(true);
		setError(false);
		try {
			const response = await LenderUgroService.dashboardLenderPending({
				type: userType,
				params: queryParams,
				subType: userType == 'admin' ? 'co-lender' : '',
			});
			if (response?.data?.status?.code === 1) {
				const categories = response?.data?.data?.labels;

				setOptions((previous) => {
					return {
						...previous,
						xaxis: {
							labels: {
								rotate: 0,
							},
							categories: categories,
						},
					};
				});
				let seriesData = [
					{
						name: 'No. of leads pending',
						data: response?.data?.data?.series,
					},
				];
				setSeries(seriesData);
			}
		} catch (err) {
			setError();
			console.log(err);
		}
		setLoading(false);
	};

	useEffect(() => {
		getDashboardLenderPending();
	}, [queryParams]);
	return (
		<GraphContainer
			filters={<Filters userType={userType} setQueryParams={setQueryParams} />}
			options={options}
			loading={loading}
			title={'Lender Pending'}
			series={series}
			type={'bar'}
			error={error}
			height={200}
		/>
	);
}
const Filters = ({ userType, setQueryParams }) => {
	const [productNames, setProducts] = useState([]);
	const [productName, setProductName] = useState([]);
	const [leadType, setLeadType] = useState([]);
	const [lenders, setLenders] = useState([]);
	const [currentLender, setCurrentLender] = useState([]);
	const [graphType, setGraphType] = useState();
	const productList = async () => {
		const response = await MasterDataService.getProductTypeList(
			userType,
			userType === 'admin' ? 'co-lender' : ''
		);
		if (response?.data?.status?.code === 1) {
			let responseList = objectsForEach(
				response.data.data.product_category,
				'STRING_VAL'
			);
			let products = responseList.map((response) => ({
				label: response.value,
				value: response.id,
			}));
			setProducts(products);
		}
	};
	const lenderList = async () => {
		const response = await MasterDataService.getLenderList(
			userType,
			userType === 'admin' ? 'co-lender' : ''
		);
		if (response?.data?.status?.code === 1) {
			let responseList = objectsForEach(
				response.data.data.lender_list,
				'STRING_VAL'
			);
			let lenderItems = responseList.map((response) => ({
				label: response.value,
				value: response.id,
			}));
			setLenders(lenderItems);
		}
	};
	useEffect(() => {
		lenderList();
		productList();
	}, []);
	useEffect(() => {
		if (productName || currentLender || leadType || graphType) {
			setQueryParams({
				lead_type: leadType?.value || '',
				prod_cat: productName.map((productName) => productName.value) || '',
				lender_id:
					currentLender.map((currentLender) => currentLender.value) || '',
				graph_type: graphType?.value || '',
			});
		}
	}, [productName, currentLender, leadType, graphType]);
	return (
		<div className='row mt-2'>
			<div className='col-md-3'>
				<Select
					label='Program Name'
					options={productNames}
					onChange={(data) => setProductName(data)}
					value={productName}
				/>
			</div>
			<div className='col-md-3 px-0'>
				<Select
					label='Lenders'
					options={lenders}
					onChange={(data) => setCurrentLender(data)}
					value={currentLender}
				/>
			</div>
			<div className='col-md-3'>
				<Select
					isMulti={false}
					label='Co-lending Type'
					options={[
						{ label: 'Option 1', value: 1 },
						// { label: 'Option 1 PS', value: 3 },
						{ label: 'Option 2', value: 2 },
					]}
					value={leadType}
					onChange={(data) => setLeadType(data)}
				/>
			</div>
			<div className='col-md-3 px-0'>
				<GraphTypeFilter onGraphTypeChange={(value) => setGraphType(value)} />
			</div>
		</div>
	);
};
export default LenderPending;

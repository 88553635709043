import React from 'react';
const SectionItem = ({ label, value }) => {
	return (
		<div className='col-md-4'>
			<div className='form-group'>
				<label>{label}</label>
				<input className='form-control' disabled={true} value={value} />
			</div>
		</div>
	);
};

export default SectionItem;

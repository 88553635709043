import React from "react";
import { Context } from "../../../../config/store";
import LenderUgroService from "../../../../services/lender";
import MasterDataService from "../../../../services/master-data";
import { objectsForEach } from "../../../../common/objectToArray";
import ReactApexChart from "react-apexcharts";

function LenderPieChart ({productTypes}) {
    const {state} = React.useContext(Context);
    const [productType, setProductType] = React.useState("");
    const [month, setMonth] = React.useState("");
    const [year, setYear] = React.useState("");
    const [pieSeries, setPieSeries] = React.useState([]);
    const [pieData, setPieData] = React.useState({
        chart: {
            width: "100%",
            type: "pie"
        } 
    });
    const months = [
        { label: 'Jan', value: '01' },
        { label: 'Feb', value: '02' },
        { label: 'Mar', value: '03' },
        { label: 'Apr', value: '04' },
        { label: 'May', value: '05' },
        { label: 'Jun', value: '06' },        
        { label: 'Jul', value: '07' },        
        { label: 'Aug', value: '08' },        
        { label: 'Sep', value: '09' },        
        { label: 'Oct', value: '10' },        
        { label: 'Nov', value: '11' },        
        { label: 'Dec', value: '12' }
    ];

    const years = [];
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState(false);

    const numDifferentiation = (value) => {
        var val = Math.abs(value)
        if (val >= 10000000) {
          val = (val / 10000000).toFixed(2) + ' Cr';
        } else if (val >= 100000) {
          val = (val / 100000).toFixed(2) + ' Lac';
        }
        return val;
    }

    const getDashboardLenderPortfolio = async () => {
        setLoading(true);
        setError(false);
        const response = await LenderUgroService.getDashboardLenderPortfolio(productType, '01/'+month+'/'+year);
        if (response?.data?.status?.code === 1) {
            let responseData = response.data.data.lender_portfolio;
            let amounts = responseData.reduce((a, b) => {
                a.push(b.amount);
                return a;
            }, []);

            let names = responseData.reduce((a, b) => {
                a.push(b.name);
                return a;
            }, []);
            setPieSeries(amounts);
            setPieData((previous) => {
                return {
                    ...previous,
                    labels: names
                }
            });
            setLoading(false);
        } else {
            setError(true);
            setLoading(false);
        }
    }

    const today = new Date();
    for (let i = today.getFullYear(); i >= 2000; i--) {
        years.push({ label: i, value: i});
    }

    React.useEffect(() => {
        if (productTypes.length > 0) {
            setProductType(productTypes[0].value);
        }
       
        var current_month = today.getMonth() + 1;
        if (current_month < 10) current_month = '0'+current_month;
        setMonth(current_month);
        setYear(today.getFullYear());
    }, [productTypes]);

    React.useEffect(() => {
        if (productType && month && year) {
            getDashboardLenderPortfolio();
        }
    }, [productType, month, year]);

    return (
        <div className="lender-portfolio-pie">
            <div>
                <div className="chart-heading">
                    Portfolios/Lender
                </div>
                <div className="d-flex align-items-center">
                    <select
                        value={productType}
                        onChange={e => setProductType(e.target.value)}
                        className='form-control'
                        placeholder=''
                    >
                        {(productTypes || []).map(type => (
                            <option key={type.value} value={type.value}>
                                {type.label}
                            </option>
                        ))}
                    </select>
                    <select
                        value={month}
                        onChange={e => setMonth(e.target.value)}
                        className='form-control'
                        placeholder=''
                    >
                        {(months || []).map(month => (
                            <option key={month.value} value={month.value}>
                                {month.label}
                            </option>
                        ))}
                    </select>
                    <select
                        value={year}
                        onChange={e => setYear(e.target.value)}
                        className='form-control'
                        placeholder=''
                    >
                        {(years || []).map((y) => (
                            <option key={y.value} value={y.value}>
                                {y.label}
                            </option>
                        ))}
                    </select>
                </div>
            </div>
            <div className="chart-container text-center mt-4">
                {loading && <div className='loader' />}
                {(error || pieSeries.length === 0) && !loading && <div className="text-center">
                        {error ? "Failed to load chart data" : "No data found"}
                    </div>
                }
                {!loading && pieSeries.length > 0 && <ReactApexChart options={pieData} series={pieSeries} type="pie"/>} 
            </div>
        </div>
    )
}

export default LenderPieChart;

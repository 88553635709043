import React from "react";
import { Context } from "../../../../config/store";
import LenderUgroService from "../../../../services/lender";
import ReactApexChart from "react-apexcharts";

function LenderBar ({barType, productTypes}) {
    const {state} = React.useContext(Context);
    // default to  Partham Secured
    const parthamSecured = productTypes.find(item => item.label === "Partham Secured");
    const [productType, setProductType] = React.useState(parthamSecured ? parthamSecured.value : "5");
    const [barSeries, setBarSeries] = React.useState([]);
    const [barData, setBarData] = React.useState({
        chart: {
            type: 'bar',
            height: 430
        },
        plotOptions: {
            bar: {
                dataLabels: {
                    position: 'top',
                },
            }
        },
        dataLabels: {
            enabled: true,
            offsetY: -20,
            style: {
                fontSize: '12px',
                colors: ['#5A5B79']
            }
        },
        stroke: {
            show: true,
            width: 1,
            colors: ['#fff']
        },
        tooltip: {
            shared: true,
            intersect: false
        },
        colors: [
            '#CF5542',
            '#63C75B'
        ]
    });

    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState(false);

    const numDifferentiation = (value) => {
        var val = Math.abs(value)
        return (val / 10000000).toFixed(2);
    }

    const getDashboardLenderPortfolio = async () => {
        setLoading(true);
        setError(false);
        let response = null;
        if (barType === 'approved') {
            response = await LenderUgroService.getDashboardLenderBar(
                state.userType,
                state.userType === 'admin' ? 'co-lender' : '',
                barType,
                productType
            );
        } else {
            response = await LenderUgroService.getDashboardLenderBar(
                state.userType,
                state.userType === 'admin' ? 'co-lender' : '',
                barType,
                productType
            );
        }
        if (response?.data?.status?.code === 1) {
            let responseData = response.data.data[barType === 'approved' ? 'lender_portfolio_approved' : 'lender_portfolio_pending'];
            let series = responseData.reduce((a, b) => {
                if (a[0]) {
                    a[0].data.push(b.count);
                } else {
                    a[0] = {
                        name: "Count",
                        data: [b.count]
                    };
                }

                if (a[1]) {
                    a[1].data.push(numDifferentiation(b.amount));
                } else {
                    a[1] = {
                        name: "Amount (Crores)",
                        data: [numDifferentiation(b.amount)]
                    };
                }

                return a;
            }, []);


            let categories = responseData.reduce((a, b) => {
                a.push(b.name);
                return a;
            }, []);
            
            setBarSeries(series);
            setBarData((previous) => {
                return {
                    ...previous,
                    xaxis: {
                        categories: categories
                    }
                }
            });
            setLoading(false);
        } else {
            setError(true);
            setLoading(false);
        }
    }

    React.useEffect(() => {
        if (productType) {
            getDashboardLenderPortfolio();
        }
    }, [productType]);

    return (
        <div className="lender-portfolio-pie">
            <div className="d-flex align-items-center justify-content-between">
                <div className="chart-heading">
                    {barType === 'approved' ? 'Portfolio - Lender Approved' : 'Portfolio - Pending Lender Approval'}
                </div>
                <div className="d-flex align-items-center">
                <   select
                        value={productType}
                        onChange={e => setProductType(e.target.value)}
                        className='form-control'
                        placeholder=''
                    >
                        {(productTypes || []).map(type => (
                            <option key={type.value} value={type.value}>
                                {type.label}
                            </option>
                        ))}
                    </select>
                </div>
            </div>
            <div className="chart-container text-center mt-4">
                {loading && <div className='loader' />}
                {(error || barSeries.length === 0) && !loading && <div className="text-center">
                        {error ? "Failed to load chart data" : "No data found"}
                    </div>
                }
                {!loading && barSeries.length > 0 && <ReactApexChart options={barData} series={barSeries} type="bar"/>} 
            </div>
        </div>
    )
}

export default LenderBar;
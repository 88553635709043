import React, { useContext, useState } from 'react';
import { Context } from '../../../../config/store';
import LenderUgroService from '../../../../services/lender';
import { downloadFile } from '../../../../common/download';
import { toast } from 'react-toastify';
import message from '../../../../constant/message';
import { Download as DownloadDownload } from '../../../../pages/OnboardingForm/DocumentsUpload/Actions';
import { Link, useParams } from 'react-router-dom';
import Statemanage from '../../../../config/StateContext';
import AddQueryModal from '../../../../components/Portfolio/AddQueryModal';

export const Download = ({
	title,
	row: { lender_query_id, supporting_file },
}) => {
	const { state } = React.useContext(Context);
	const [loading, setLoading] = React.useState(false);
	const downloadDoc = async () => {
		setLoading(true);
		try {
			const response = await LenderUgroService.downloadAttachmentFile(
				state.userType,
				state.userType === 'admin' ? 'co-lender' : '',
				lender_query_id
			);
			downloadFile(response.data, `query_${lender_query_id}`);
		} catch (err) {
			toast.error(message.ERROR);
		}
		setLoading(false);
	};
	return supporting_file ? (
		<span className='link btn' onClick={downloadDoc}>
			{loading ? <div className='loader' /> : title ? title : 'Download'}
		</span>
	) : (
		<span className='mx-4'></span>
	);
};

export const getColumns = (type, state) => {
	const lenderColumns = [
		{ title: 'Catogery', field: 'query_type_name' },
		// { title: 'Initiator', field: 'initiator_name' },
		{ title: 'Created by', field: 'created_by_name' },
		// { title: 'Created For', field: 'child_user_name' },
		// { title: 'Responder', field: 'responder_name' },
		{ title: 'Lender Name', field: 'lender_name' },
		...(state.userType === 'admin'
			? [{ title: 'Lender Name', field: 'lender_name' }]
			: []),
		{ title: 'Comment', field: 'query_txt', component: QueryComments },
		{ title: 'Status', field: 'query_status' },
		{ title: 'Priority', field: 'priority' },

		{ title: 'Date', field: 'created_at' },
		{ title: 'Actions', field: 'action', component: LenderActions },
	];
	const partnerColumns = [
		{ title: 'Query Type', field: 'query_type_name' },
		{ title: 'Requested User', field: 'requested_by' },
		{ title: 'Comment', field: 'query_txt' },
		{ title: 'Date', field: 'created_at' },
		{ title: 'Attachment', field: 'action', component: PartnerActions },
	];
	if (type === 'onboarding-form') return partnerColumns;
	else return lenderColumns;
};

const QueryComments = (props) => {
	// const myRef = React.useRef(null);
	// React.useEffect(() => {
	// 	myRef.current.innerHTML = props?.row?.query_txt;
	// }, [myRef])
	return <div className="htmlContent" dangerouslySetInnerHTML={{__html: props?.row?.query_txt}} />;
}

const PartnerActions = (props) => {
	return (
		<>
			{/* <ViewFile /> */}
			<DownloadDownload {...props} />
		</>
	);
};

const LenderActions = (props) => {
	const { Buttonmanage } = useContext(Statemanage);
	const { state } = useContext(Context);
	const leadId = useParams().id;
	const [modalState, setModalState] = useState(false);

	const toggleModal = () => {
		props.replyQuery(queryId, leadId);
	};

	const queryId = props.row.lender_query_id;
	const reopenCase = async () => {
		try {
			let response;
			response = await LenderUgroService.changeQueryStatus({
				type: state.userType,
				subType: state.userType == 'admin' ? 'co-lender' : '',
				queryId,
			});
			if (response?.data?.status?.code === 1) {
				const successMessage = response?.data?.messages?.success;
				toast.success(
					successMessage?.form?.[0] ||
					successMessage?.others?.[0] ||
					message.SAVED_SUCCESS
				);
			}
			if (props.reloadTable) props.reloadTable();
		} catch (err) {
			console.log(err);
			let error = err?.response?.data?.messages?.error;
			toast.error(error?.form?.[0] || error?.others?.[0] || message.ERROR_FORM);
		}
	};
	console.log(props.row)
	return (
		<>
			{props.row.query_status !== 'closed' && !Buttonmanage && (
				<button className='btn btn-custom sm' style={{ width: "30px", height: "30px", display: "flex", justifyContent: "center", alignItems: "center" }} onClick={toggleModal}>
					<i className='fa fa-comment'></i>
				</button>
			)}

			{props.row?.created_by === state?.user?.user_id ? <> {props.row.query_status === 'closed' && !Buttonmanage && (
				<button className='btn btn-custom mr-2' style={{ fontSize: "10px", padding: "0px 2px",height:"30px" }} onClick={reopenCase}>
					Re-Open
				</button>
			)}</> : ""}


			{modalState && (
				<AddQueryModal
					toggleModal={toggleModal}
					state={modalState}
					leadId={leadId}
					parentQueryId={queryId}
					userType={state.userType}
					viewQuery
					globalState={state}
				/>
			)}

			<Download
				title={
					<button className='btn btn-custom font-18' style={{ width: "30px",height:"30px", padding: "0px", margin: "0% 0 0 0 " }} data-tip>
						<i className='fa fa-download'></i>
					</button>
				}
				{...props}
			/>
		</>
	);
};

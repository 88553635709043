const TableNoRecords = () => {
	return (
		<tr>
			<td colSpan='20'>
				{' '}
				<div class='d-flex justify-content-center'>No Records</div>
			</td>
		</tr>
	);
};

export default TableNoRecords;

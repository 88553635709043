import React from 'react';
import VolumeLinkedIncentiveItem from './VolumeLinkedIncentiveItem';
import VolumeLinkedDsaItem from './VolumeLinkedDsaItem';
const VolumeLinkedIncentive = ({ vli_commission = [], userType }) => {
	return (
		<div className='panel'>
			<div className='head_top'>
				<h4>Volume Linked Incentive</h4>
			</div>
			<div className='table-responsive '>
				<table className='table table-striped mb-0'>
					<thead>
						<tr>
							<th>Product</th>
							<th>{userType === 'dsa' ? 'Loan Type' : 'Total Volume'}</th>
							{userType === 'dsa' && <th>Volume Amount</th>}
							<th>Base Payout</th>
							{/* <th>VLI%</th> */}

							<th>{userType === 'dsa' ? 'Comission Amount' : 'Amount'}</th>
						</tr>
					</thead>
					<tbody>
						{vli_commission.map(({ vli_comm_id, ...rest }) =>
							userType === 'dsa' ? (
								<VolumeLinkedDsaItem
									key={vli_comm_id}
									vli_comm_id={vli_comm_id}
									{...rest}
								/>
							) : (
								<VolumeLinkedIncentiveItem
									key={vli_comm_id}
									vli_comm_id={vli_comm_id}
									{...rest}
								/>
							)
						)}
					</tbody>
				</table>
			</div>
		</div>
	);
};

export default VolumeLinkedIncentive;

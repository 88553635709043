import React from 'react';
const Select = ({ onChange, value, options, disabled }) => {
	return (
		<select
			value={value}
			onChange={onChange}
			disabled={disabled}
			className='form-control'
		>
			<option value={''}>Select</option>
			{options.map(({ value, label }) => (
				<option key={value} value={value}>
					{label}
				</option>
			))}
		</select>
	);
};

export default Select;

import React from 'react';
import { sharedLeadsStatus, getProducts } from '../../services/dashboard';
import objectToQueryParams from '../../common/objectToQueryParams';
import dynamicSum from '../../common/dynamicSum';
import Select from 'react-select';
import { objectsForEach } from '../../common/objectToArray';
import { bg, sharedLeadStatus } from '../../constant/options';
import ReactApexChart from 'react-apexcharts';
const SharedLeadStatus = ({
	type,
	businessType,
	userTypes,
	loanType,
	productType,
	campaignCode,
	leadType,
	sourceId,
	stateId,
	cityId
}) => {
	const [from, setFrom] = React.useState('');
	const [to, setTo] = React.useState('');
	const [data, setData] = React.useState([]);
	const [products, setProducts] = React.useState([]);
	const [productTypeDsa, setProductTypeDsa] = React.useState();
	const [options, setOptions] = React.useState(sharedLeadStatus);
	const [dataList, setDataList] = React.useState([]);
	const loadProducts = async () => {
		const response = await getProducts({ type });
		const productsResponse = objectsForEach(
			response.data.products,
			'STRING_VAL'
		).map(product => ({ label: product.value, value: product.id }));

		setProducts(productsResponse);
	};
	const loadSharedStatusLeads = async () => {
		const queryParams = objectToQueryParams({
			from,
			to,
			business_type: (businessType || []).map(
				business_type => business_type.value
			),
			user_ids: (userTypes || []).map(user_id => user_id.value),
			loan_type: (loanType || []).map(loan_type => loan_type.value),
			product_type: ((type === 'dsa' ? productTypeDsa : productType) || []).map(
				product_type => product_type.value
			),
			campaign_code: (campaignCode || []).map(camp_code => camp_code.value),
			lead_type: leadType?.value || '',
			source_id: sourceId?.value || '',
			state_id: stateId,
			city_id: cityId
		});
		const response = await sharedLeadsStatus({ type, queryParams });

		if (response?.status?.code === 1) {
			const statuses = response?.data?.status_shared_leads?.status;
			let sum = dynamicSum({ data: statuses, sumKey: 'count' });
			let dataItems = (statuses || []).map(({ count, label }) => count);
			let dataList = (statuses || []).map(({ count, label }) => ({
				count: count,
				label: label
			}));
			let labels = (statuses || []).map(({ label }) => label);
			let tempOptions = {
				...sharedLeadStatus,
				xaxis: {
					// labels: { rotate: 0, style: { fontSize: 10 } },

					categories: labels
				}
			};
			setOptions(tempOptions);
			setDataList(dataList);
			setData([{ data: dataItems }]);
		}
	};
	React.useEffect(() => {
		loadSharedStatusLeads();
		loadProducts();
	}, [
		from,
		to,
		type,
		businessType,
		userTypes,
		loanType,
		productType,
		campaignCode,
		productTypeDsa,
		leadType,
		sourceId,
		stateId,
		cityId
	]);
	return (
		<div className='panel'>
			<div className='head_top'>
				<div className='row'>
					<div className='col-4 d-flex align-items-center'>
						<h4>
							Status of Shared {type === 'dsa' ? 'Applications' : 'Leads'}
						</h4>
					</div>

					<div className='col-8 d-flex justify-content-end align-items-center'>
						{type === 'dsa' && (
							<div className='w-240p mr-2'>
								<label>Product Type</label>{' '}
								<Select
									isMulti
									className='border-none'
									value={productTypeDsa}
									options={products}
									onChange={product => setProductTypeDsa(product)}
								/>
							</div>
						)}
						<input
							onChange={e => setFrom(e.target.value)}
							type='date'
							className='form-control  custom-textfield'
							placeholder='From'
						/>
						<input
							onChange={e => setTo(e.target.value)}
							min={from}
							type='date'
							className='form-control custom-textfield ml-3'
							placeholder='To'
						/>
					</div>
				</div>
			</div>
			<ReactApexChart options={options} series={data} type='bar' height={490} />

			<ul className='chart-listing text-center'>
				{dataList.map(({ count, label }, index) => (
					<li key={label}>
						<div className={`bg ${bg?.[index]?.bg}`} />
						<div className='data'>
							{count}
							<br />
							<span>{label}</span>
						</div>
					</li>
				))}
			</ul>
		</div>
	);
};

export default SharedLeadStatus;

import React from 'react';
import { Context } from '../../../config/store';
import { Context as SectionContext } from '../../../config/sectionContext';
import Select from '.';
const State = ({ onChange, value, disabled }) => {
	const { state } = React.useContext(Context);
	const { dispatch } = React.useContext(SectionContext);
	const [states, setStates] = React.useState([]);
	React.useEffect(() => {
		const stateList = state.config.states.map(stateItem => ({
			value: stateItem.id,
			label: stateItem.name
		}));
		setStates(stateList);
	}, [state.config.states]);
	React.useEffect(() => {
		dispatch({
			type: 'stateId',
			stateId: value
		});
	}, [value]);
	return (
		<Select
			disabled={disabled}
			onChange={onChange}
			value={value}
			options={states}
		/>
	);
};

export default State;

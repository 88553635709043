import React, { useState } from 'react';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import FileViewHelper from '../../common/FileViewHelper';

export default function RenderFile({ fileUrl, extension, closeModal }) {
	const [numPages, setNumPages] = useState(null);
	const [isPdf, setPdfStatus] = React.useState(true);
	function onDocumentLoadSuccess({ numPages }) {
		setNumPages(numPages);
	}
	function onLoadError(err) {
		setPdfStatus(false);
	}

	function onPassword(callback, reason) {
		function callbackProxy(password) {
			if (password === null) {
				closeModal()
			}
			callback(password);
		}
		switch (reason) {
			case 1: {
				const password = prompt('Enter the password to open this PDF file.');
				callbackProxy(password);
				break;
			}
			case 2: {
				const password = prompt('Invalid password. Please try again.');
				callbackProxy(password);
				break;
			}
			default:
		}
	}

	return (
		<div className='h-100'>
			<div className="modal-header-container">
				<div className="modal-title">View Document</div>
				<div className="closeModalView" onClick={closeModal}>
					<span className="closeModalIcon">x</span>
				</div>
			</div>
			{!isPdf && FileViewHelper(extension, fileUrl)}
			{isPdf && (
				<Document
					loading={'Loading...'}
					file={fileUrl}
					onLoadSuccess={onDocumentLoadSuccess}
					onLoadError={onLoadError}
					onPassword={onPassword}
					className='document-scroll'
				>
					{numPages &&
						Array.apply(null, Array(numPages)).map((number, index) => (
							<>
								<Page pageNumber={index + 1} scale={1.4} className={'document-align'}/>{' '}
								<p style={{textAlign: 'center'}}>
									Page {index + 1} of {numPages}
								</p>
							</>
						))
					}
				</Document>
			)}
		</div>
	);
}

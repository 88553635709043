import React from 'react';
const LeadDetails = () => {
	return (
		<>
			<div className='col-md-8 pl-md-5'>
				<div className='heading mb-4 d-flex align-items-center'>
					<h3> Details </h3>
					<span className='badge badge-primary ml-2'>Contacted</span>
				</div>
				<div className='panel'>
					<h5 className='mb-3'>Lead Details</h5>
					<div className='row'>
						<div className='col-md-4'>
							<p>
								<b>Assigned to </b>: <span>Ashish Tiwari </span>
							</p>
						</div>
						<div className='col-md-8'>
							<a href='#' data-toggle='modal' data-target='#myModal'>
								<small>
									<i>
										<u>contact details</u>
									</i>
								</small>
							</a>
						</div>
					</div>
					<div className='row'>
						<div className='col-md-4'>
							<p>
								<b>Lead Name </b>: <span>Nikhil Batra </span>
							</p>
						</div>
						<div className='col-md-4'>
							<p>
								<b>E-mail </b>: <span>nikhil@gmail.com </span>
							</p>
						</div>
						<div className='col-md-4 '>
							<p>
								<b>Mobile Number </b>: <span>+91-8578965458 </span>
							</p>
						</div>
						<div className='col-md-4'>
							<p>
								<b>Gender </b>: <span>Male </span>
							</p>
						</div>
						<div className='col-md-4'>
							<p>
								<b>Date of birth </b>: <span>15/01/1982 </span>
							</p>
						</div>
						<div className='col-md-4'>
							<p>
								<b>PAN </b>: <span>ABGLC8988B</span>
							</p>
						</div>
						<div className='col-md-4'>
							<p>
								<b>Networth </b>: <span>25Cr</span>
							</p>
						</div>
					</div>
				</div>
				<div className='panel'>
					<h5 className='mb-3'>Loan Details</h5>
					<div className='row'>
						<div className='col-md-4 '>
							<p>
								<b>Secured/ Unsecured </b>: <span>Secured </span>
							</p>
						</div>
						<div className='col-md-4'>
							<p>
								<b>Ownerships</b>: <span>Dfghfd </span>
							</p>
						</div>
					</div>
				</div>
				<div className='panel '>
					<h5 className='mb-3'>Address Details</h5>
					<div className='row'>
						<div className='col-md-4'>
							<p>
								<b>Address</b> : <span>25/8, 1st Floor, Shakti Nagar</span>
							</p>
							<div className='clearfix' />
						</div>
						<div className='col-md-4'>
							<p>
								<b>City</b> : <span>Delhi</span>
							</p>
						</div>
						<div className='col-md-4'>
							<p>
								<b>State</b> : <span>Delhi</span>
							</p>
						</div>
						<div className='col-md-4'>
							<p>
								<b>Pincode</b> : <span>110030</span>
							</p>
						</div>
					</div>
				</div>
				<a
					href='manage-leads.html'
					className='btn btn-outline-orange pl-5 pr-5 ml-2'
				>
					BACK
				</a>
			</div>
		</>
	);
};

export default LeadDetails;

import splitCamelCase from '../../common/splitCamelCase';
import splitUnderscoreToCaptialise from '../../common/splitUnderscoreToCaptialise';
import Error from '../Error';
import Field from '../Field';

function FormGroup({
	required,
	label,
	type,
	onChange,
	body,
	options,
	disabled,
	multiple,
	index,
	bindKey,
	errorKey,
	errors,
	id,
}) {
	return (
		<div className='form-group'>
			<label>
				{label ? label : splitCamelCase(splitUnderscoreToCaptialise(id))}
				{required && <tt>*</tt>}
			</label>
			<Field
				type={type}
				onChange={(e) =>
					onChange(
						type === 'file' ? e.target.files[0] : e.target.value,
						id,
						index,
						multiple
					)
				}
				id={id}
				body={body}
				value={type === 'file' ? undefined : body?.[id]}
				options={options}
				disabled={disabled}
			/>
			{errors && (
				<Error
					errorKey={errorKey}
					bindKey={bindKey}
					index={index}
					errors={errors}
					multiple={multiple}
					id={id}
				/>
			)}
		</div>
	);
}

export default FormGroup;

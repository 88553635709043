import React from 'react';

const ComissionTableItem = ({
	lead_id,
	loan_amt,
	tenor,
	convert_to_app_date,
	product_name,
	base_payout,
	base_payout_amt,
	tenor_incentive,
	tenor_incentive_amt,
	tot_amt,
	loan_type_name,
	amt_type,
	comm_type
}) => {
	return (
		<tr>
			<td>{lead_id}</td>
			<td>{parseFloat(loan_amt).toLocaleString('en-IN')}</td>
			{/* <td>{tenor}</td> */}
			<td>{convert_to_app_date}</td>
			<td>
				{product_name} {loan_type_name}
			</td>
			<td>{comm_type}</td>
			<td>
				{base_payout} {amt_type == 'fixed' ? 'INR' : '%'}
			</td>
			{/* <td>{base_payout_amt}</td>
			<td>{tenor_incentive}</td>
			<td>{tenor_incentive_amt}</td> */}
			<td>{parseFloat(tot_amt).toLocaleString('en-IN')} INR</td>
		</tr>
	);
};

export default ComissionTableItem;

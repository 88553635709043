import React from 'react';

export const initialState = {
	userType: 'lender',
	is_super_admin: 0,
	permission: [],
	config: [],
	user: {},
	showNotification: false,
	leadTools: [],
	dsaUser: false,
	userTypeCode: 2,
	selectedColender: localStorage.getItem('selectedColender'),
	sidebarOpen: true,
};

export const reducer = (state, action) => {
	const {
		type,
		userType,
		config,
		user,
		permission,
		is_super_admin,
		showNotification,
		leadTools,
		dsaUser,
		userTypeCode,
		selectedColender,
		sidebarOpen
	} = action;

	switch (type) {
		case 'userType':
			return { ...state, userType };
		case 'config':
			return { ...state, config };
		case 'leadTools':
			return { ...state, leadTools };
		case 'permission':
			return { ...state, permission };
		case 'is_super_admin':
			return { ...state, is_super_admin };
		case 'user':
			return { ...state, user };
		case 'showNotification':
			return {
				...state,
				showNotification,
			};
		case 'dsaUser':
			return {
				...state,
				dsaUser,
			};
		case 'userTypeCode':
			return {
				...state,
				userTypeCode,
			};
		case 'selectedColender':
			return {
				...state,
				selectedColender,
			};
		case 'sidebarOpen':
			return {
				...state,
				sidebarOpen,
			};
		default:
			return state;
	}
};

export const Context = React.createContext(null);

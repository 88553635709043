import React from 'react';
import { saveComissionConfig } from '../../services/comission';
import { toast } from 'react-toastify';
import message from '../../constant/message';

const Actions = ({ body, router, setError }) => {
	const [loading, setLoading] = React.useState(false);

	const saveConfig = async () => {
		setLoading(true);
		const part_id = router.params.partId;
		const response = await saveComissionConfig(body, part_id);
		if (response?.status?.code == 1) {
			toast.success(message.SAVED_SUCCESS);
		} else {
			let error = response.messages.error;

			toast.error(error?.form?.[0] || error?.others?.[0] || message.ERROR_FORM);
			setError(error);
		}
		setLoading(false);
	};
	return (
		<div className='content'>
			<button
				type='button'
				disabled={loading}
				onClick={saveConfig}
				className='btn btn-custom'
			>
				{loading ? <div className='loader' /> : 'Save'}
			</button>

			<button
				onClick={() => router.history.goBack()}
				type='button'
				className='btn btn-close ml-2'
			>
				Cancel
			</button>
		</div>
	);
};

export default Actions;

import Actions from "./Action"

export const Borrower = [
	{ title: 'Installment No.', field: 'srno' },
	{ title: 'Due Date', field: 'due_date' },
	{ title: 'Outstanding Principle', field: 'opening_principal' },
	{ title: 'Principal', field: 'princomp' },
	{ title: 'Interest', field: 'intcomp' },
	{ title: 'Total EMI Amount', field: 'instlamt' },
	{ title: 'Closing Principle', field: 'closing_principal' },
	{ title: 'ROI', field: 'roi' },
	// { title: 'Paid Date', field: 'due_date' },
	// { title: 'Status', field: 'laf_id', component: Actions },
]

export const Sourcing = [
	{ title: 'Installment No.', field: 'srno' },
	{ title: 'Due Date', field: 'due_date' },
	{ title: 'Outstanding Principle', field: 'ugro_opening_principal' },
	{ title: 'Principal', field: 'ugro_princomp' },
	{ title: 'Interest', field: 'ugro_intcomp' },
	{ title: 'Total EMI Amount', field: 'ugro_instlamt' },
	{ title: 'Closing Principle', field: 'ugro_closing_principal' },
	// { title: 'Paid Date', field: 'due_date' },
	// { title: 'Status', field: 'laf_id', component: Actions },
]

export const Lending = [
	{ title: 'Installment No.', field: 'srno' },
	{ title: 'Due Date', field: 'due_date' },
	{ title: 'Outstanding Principle', field: 'lender_opening_principal' },
	{ title: 'Principal', field: 'lender_princomp' },
	{ title: 'Interest', field: 'lender_intcomp' },
	{ title: 'Total EMI Amount', field: 'lender_instlamt' },
	{ title: 'Closing Principle', field: 'lender_closing_principal' },
	{ title: 'ROI', field: 'lender_roi' },
	// { title: 'Paid Date', field: 'due_date' },
	// { title: 'Status', field: 'laf_id', component: Actions },
]
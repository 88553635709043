import React from 'react';
import ReactTooltip from 'react-tooltip';
const PasswordPolicy = ({ password }) => {
	const cases = [
		{ text: '1 uppercase', match: /[A-Z]/ },
		{ text: '1 lowercase', match: /[a-z]/ },
		{ text: '1 number', match: /[0-9]/ },
		{
			text: '1 special character',
			match: /\W|_/g
		},
		{
			text: 'Minimum 8 characters.',
			match: /^.{8,35}$/
		}
	];
	return (
		<>
			{' '}
			<ReactTooltip id='password-policy'>
				<div>
					Password must include -
					{cases.map(({ text, match }) => (
						<div key={text}>
							{String(password).match(match) && (
								<i className='fa fa-check-circle' aria-hidden='true'>
									{' '}
								</i>
							)}{' '}
							{text}
						</div>
					))}
				</div>
			</ReactTooltip>
			<div className='input-group-addon'>
				<i
					data-tip
					data-for='password-policy'
					className='fa fa-info-circle'
					aria-hidden='true'
				></i>
			</div>{' '}
		</>
	);
};

export default PasswordPolicy;

import { getCookie } from './cookie';
import config from './config';

export const getApplications = async ({ queryParams, exportLeads }) => {
	const token = getCookie(btoa('token'));
	const response = await fetch(
		`${config.baseUrl.dsa}/lead/list?${queryParams}&export=${
			exportLeads ? 1 : 0
		}`,

		{
			headers: {
				Authorization: `Bearer ${token}`,
				...config.defaultHeaders
			}
		}
	);
	if (exportLeads) {
		return response.blob();
	} else {
		return response.json();
	}
};

export const getApplication = async ({ id }) => {
	const token = getCookie(btoa('token'));
	return await (await fetch(`${config.baseUrl.dsa}/lead/${id}`, {
		headers: {
			Authorization: `Bearer ${token}`,
			...config.defaultHeaders
		}
	})).json();
};

import React from 'react';
import objectToQueryParams from '../../common/objectToQueryParams';
const SearchFilter = ({
	setQueryParams,
	branches,
	products,
	assignedTo,
	statuses
}) => {
	const [CONTACT_PERSON_NAME, setCONTACT_PERSON_NAME] = React.useState('');
	const [LAF_ID, setLAF_ID] = React.useState('');
	const [BRANCH, setBRANCH] = React.useState('');
	const [LOAN_TYPE, setLOAN_TYPE] = React.useState('');
	const [PRODUCT_CODE, setPRODUCT] = React.useState('');
	const [LAF_END_DATE, setLAF_END_DATE] = React.useState('');
	const [ADDRESS, setADDRESS] = React.useState('');
	const [CURRENT_LOS_QUEUE, setCURRENT_LOS_QUEUE] = React.useState('');
	const [LAF_START_DATE, setLAF_START_DATE] = React.useState('');
	const [BDM_NAME, setBDM_NAME] = React.useState('');

	const submitQueryParams = () => {
		const searchObject = {
			LAF_ID: LAF_ID,
			BRANCH: BRANCH,
			PRODUCT_CODE: PRODUCT_CODE,
			BDM_NAME: BDM_NAME,
			LAF_END_DATE: LAF_END_DATE,
			LAF_START_DATE: LAF_START_DATE,
			CURRENT_LOS_QUEUE: CURRENT_LOS_QUEUE,
			ADDRESS: ADDRESS,
			CONTACT_PERSON_NAME: CONTACT_PERSON_NAME
		};
		const queryString = objectToQueryParams(searchObject);
		setQueryParams(queryString);
	};
	const resetQueryParams = () => {
		setLAF_ID('');
		setBRANCH('');
		setLAF_END_DATE('');
		setLAF_START_DATE('');
		setCURRENT_LOS_QUEUE('');
		setADDRESS('');
		setCONTACT_PERSON_NAME('');
		// setLOAN_TYPE('');
		setPRODUCT('');
		setBDM_NAME('');
		setQueryParams('');
	};
	return (
		<div className='tab panel'>
			<input type='checkbox' id='accordion'></input>
			<label for='accordion' className='w-100 head_top tab-label'>
				<h4>Search Filter</h4>
			</label>
			<form className='tab-content'>
				<div className='p-3'>
					<div className='row'>
						<div className='col-md-4'>
							<div className='form-group'>
								<label>Customer Name</label>
								<input
									value={CONTACT_PERSON_NAME}
									onChange={e => setCONTACT_PERSON_NAME(e.target.value)}
									type='text'
									className='form-control'
									placeholder='Customer Name'
								/>
							</div>
						</div>
						<div className='col-md-4'>
							<div className='form-group'>
								<label>Lead ID</label>
								<input
									value={LAF_ID}
									onChange={e => setLAF_ID(e.target.value)}
									type='text'
									className='form-control'
									placeholder='Lead ID'
								/>
							</div>
						</div>
						<div className='col-md-4'>
							<div className='form-group'>
								<label>Location</label>
								<input
									value={ADDRESS}
									onChange={e => setADDRESS(e.target.value)}
									type='text'
									className='form-control'
									placeholder='Location'
								/>
							</div>
						</div>
						<div className='col-md-4'>
							<div className='form-group'>
								<label>Branch</label>
								<select
									value={BRANCH}
									onChange={e => setBRANCH(e.target.value)}
									className='form-control'
									placeholder='Status'
								>
									<option value={''}>Select Branch</option>
									{(branches || []).map(branch => (
										<option key={branch} value={branch}>
											{branch}
										</option>
									))}
								</select>
								{/* <input
									value={BRANCH}
									onChange={e => setBRANCH(e.target.value)}
									type='text'
									className='form-control'
									placeholder='BRANCH'
								/> */}
							</div>
						</div>
						<div className='col-md-4'>
							<div className='form-group'>
								<label>Start Date</label>
								<input
									value={LAF_START_DATE}
									onChange={e => setLAF_START_DATE(e.target.value)}
									type='date'
									className='form-control'
								/>
							</div>
						</div>
						<div className='col-md-4'>
							<div className='form-group'>
								<label>End Date</label>
								<input
									value={LAF_END_DATE}
									onChange={e => setLAF_END_DATE(e.target.value)}
									type='date'
									className='form-control'
								/>
							</div>
						</div>

						{/* <div className='col-md-4'>
							<div className='form-group'>
								<label>LOAN TYPE</label>
								<input
									value={LOAN_TYPE}
									onChange={e => setLOAN_TYPE(e.target.value)}
									type='text'
									className='form-control'
									placeholder='LOAN TYPE'
								/>
							</div>
						</div> */}
						<div className='col-md-4'>
							<div className='form-group'>
								<label>Product</label>
								<select
									value={PRODUCT_CODE}
									onChange={e => setPRODUCT(e.target.value)}
									className='form-control'
									placeholder='Status'
								>
									<option value={''}>Select Product</option>
									{(products || []).map(({ value, label }) => (
										<option key={value} value={value}>
											{label}
										</option>
									))}
								</select>
								{/* <input
									value={PRODUCT}
									onChange={e => setPRODUCT(e.target.value)}
									type='text'
									className='form-control'
									placeholder='Product'
								/> */}
							</div>
						</div>
						<div className='col-md-4'>
							<div className='form-group'>
								<label>Status</label>
								<select
									value={CURRENT_LOS_QUEUE}
									onChange={e => setCURRENT_LOS_QUEUE(e.target.value)}
									className='form-control'
									placeholder='Status'
								>
									<option value={''}>Select Status</option>
									{(statuses || []).map(status => (
										<option key={status} value={status}>
											{status}
										</option>
									))}
								</select>
							</div>
						</div>
						<div className='col-md-4'>
							<div className='form-group'>
								<label>Assigned to</label>
								<select
									value={BDM_NAME}
									onChange={e => setBDM_NAME(e.target.value)}
									className='form-control'
									placeholder='Assigned to'
								>
									<option>Assigned to</option>
									{(assignedTo || []).map(assginedUser => (
										<option key={assginedUser} value={assginedUser}>
											{assginedUser}
										</option>
									))}
								</select>
								{/* <input
									value={BDM_NAME}
									onChange={e => setBDM_NAME(e.target.value)}
									type='text'
									className='form-control'
									placeholder='Assigned to'
								/> */}
							</div>
						</div>
					</div>
					<div className='row'>
						<div className='col-md-12'>
							<button
								onClick={submitQueryParams}
								type='button'
								className='btn btn-custom'
							>
								Search
							</button>
							<button
								onClick={resetQueryParams}
								type='button'
								className='btn btn-custom ml-4'
							>
								Reset All
							</button>
						</div>
					</div>
				</div>
			</form>
		</div>
	);
};

export default SearchFilter;

const arrayToObject = (arr, key, valueKey, dynamic) => {
	const res = arr.reduce(
		(acc, curr) => (
			(acc[key ? curr[key] : curr.id] = dynamic
				? dynamic(curr, acc)
				: valueKey
				? curr[valueKey]
				: curr),
			acc
		),
		{}
	);
	return res;
};
export default arrayToObject;

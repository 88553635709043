import { useEffect, useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import { getCurrentMonth, getCurrentYear } from '../../../../common/date';
import { objectsForEach } from '../../../../common/objectToArray';
import Select from '../../../../components/Common/Input/Select';
import GraphContainer from '../../../../components/Portfolio/GraphContainer';
import { bg } from '../../../../constant/options';
import LenderUgroService from '../../../../services/lender';
import MasterDataService from '../../../../services/master-data';
import { numDifferentiation } from '../TotalPortfolio';

function LenderPortfolio({ userType }) {
	const [loading, setLoading] = useState(true);
	const [queryParams, setQueryParams] = useState({});
	const [assignedData, setAssignedData] = useState({});
	const [error, setError] = useState(false);
	const [series, setSeries] = useState([]);
	const [options, setOptions] = useState({
		chart: {
			type: 'bar',
			height: 430,
		},
		plotOptions: {
			bar: {
				dataLabels: {
					position: 'top',
				},
			},
		},
		dataLabels: {
			enabled: true,
			offsetY: -20,
			style: {
				fontSize: '12px',

				colors: ['#5A5B79'],
			},
		},
		stroke: {
			show: true,
			width: 1,
			colors: ['#fff'],
		},
		tooltip: {
			shared: true,
			intersect: false,
		},
		colors: bg.map(({ color }) => color),
	});
	const getDashboardLenderPortfolio = async () => {
		setLoading(true);
		setError(false);
		try {
			const response = await LenderUgroService.dashboardTotalAssignedPortfolio({
				type: userType,
				params: queryParams,
				subType: userType == 'admin' ? 'co-lender' : '',
			});
			if (response?.data?.status?.code === 1) {
				const lenderPortfolioData = response?.data?.data?.lender_portfolio;
				setAssignedData({
					count: lenderPortfolioData.assigned_lead_count,
					amount: lenderPortfolioData.assigned_lead_amount,
				});
				const categories = (lenderPortfolioData?.lender_wise_data?.x || []).map(
					(category) => category.name
				);
				setOptions((previous) => {
					return {
						...previous,
						xaxis: {
							categories: categories,
							labels: {
								show: true,
								style: {
									fontSize: '10px',
								},
								rotate: 0,
							},
						},
					};
				});
				let seriesData = [
					{
						name: 'No. of cases',
						data: (
							response?.data?.data?.lender_portfolio?.lender_wise_data?.y || []
						).map((yaxis) => yaxis.count),
					},
					// {
					// 	name: 'Amount assigned',
					// 	data: (
					// 		response?.data?.data?.lender_portfolio?.lender_wise_data?.y || []
					// 	).map((yaxis) => yaxis.amount),
					// },
				];
				setSeries(seriesData);
			}
		} catch (err) {
			setError();
			console.log(err);
		}
		setLoading(false);
	};

	useEffect(() => {
		getDashboardLenderPortfolio();
	}, [queryParams]);
	return (
		<div>
			{' '}
			<GraphContainer
				filters={
					<Filters userType={userType} setQueryParams={setQueryParams} />
				}
				options={options}
				loading={loading}
				title={'Lender Portfolio'}
				series={series}
				type={'bar'}
				error={error}
				height={180}
			>
				{' '}
				{Object.keys(assignedData).length > 0 && (
					<div className='chart-container my-2 py-2'>
						<>
							<div className='row no-gutters totalAssignedCounts'>
								<div className='col-6'>
									<span className='d-block mb-2 text-center'>
										Number of case assigned{' '}
									</span>
									<h1 className='text-center'>#{assignedData.count}</h1>
								</div>
								<div className='col-6'>
									<span className='d-block mb-2 text-center'>
										Amount assigned
									</span>
									<h1 className='text-center crores'>
										{numDifferentiation(assignedData.amount)}{' '}
										<span>Crores</span>
									</h1>
								</div>
							</div>
						</>
					</div>
				)}
			</GraphContainer>
		</div>
	);
}
const Filters = ({ userType, setQueryParams }) => {
	const [productNames, setProducts] = useState([]);
	const [startDate, setStartDate] = useState(new Date());
	const [productName, setProductName] = useState([]);
	const [lenders, setLenders] = useState([]);
	const [currentLender, setCurrentLender] = useState([]);
	const productList = async () => {
		const response = await MasterDataService.getProductTypeList(
			userType,
			userType === 'admin' ? 'co-lender' : ''
		);
		if (response?.data?.status?.code === 1) {
			let responseList = objectsForEach(
				response.data.data.product_category,
				'STRING_VAL'
			);
			let products = responseList.map((response) => ({
				label: response.value,
				value: response.id,
			}));
			setProducts(products);
		}
	};
	const lenderList = async () => {
		const response = await MasterDataService.getLenderList(
			userType,
			userType === 'admin' ? 'co-lender' : ''
		);
		if (response?.data?.status?.code === 1) {
			let responseList = objectsForEach(
				response.data.data.lender_list,
				'STRING_VAL'
			);
			let lenderItems = responseList.map((response) => ({
				label: response.value,
				value: response.id,
			}));
			setLenders(lenderItems);
		}
	};
	useEffect(() => {
		if (productName || startDate || currentLender) {
			const formattedDate = `${getCurrentMonth(startDate) + 1}/${getCurrentYear(
				startDate
			)}`;
			setQueryParams({
				prod_cat: productName.map((productName) => productName.value) || '',
				lender_id:
					currentLender.map((currentLender) => currentLender.value) || '',
				date: formattedDate || '',
			});
		}
	}, [productName, startDate, currentLender]);
	useEffect(() => {
		productList();
		lenderList();
	}, []);
	return (
		<div className='row align-items-end mt-2'>
			<div className='col-md-4'>
				<Select
					label='Program Name'
					options={productNames}
					onChange={(data) => setProductName(data)}
				/>
			</div>
			<div className='col-md-4 px-0'>
				<label>Month</label>
				<div className='form-group'>
					<ReactDatePicker
						className='form-control'
						selected={startDate}
						onChange={(date) => setStartDate(date)}
						dateFormat='MM/yyyy'
						showMonthYearPicker
						maxDate={new Date()}
						value={startDate}
					/>
				</div>
			</div>
			<div className='col-md-4'>
				<Select
					label='Lenders'
					options={lenders}
					onChange={(data) => setCurrentLender(data)}
					value={currentLender}
				/>
			</div>
		</div>
	);
};
export default LenderPortfolio;

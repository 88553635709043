import React from 'react';
import { Link } from 'react-router-dom';
import DdupeTable from './DdupeTable';
const EditLeadHeader = ({
	leadId,
	userType,
	ddupeData,
	body,
	toggleOfferModal,
	checkHasPermission
}) => {
	return (
		<div className='content'>
			<div className='row'>
				<div className='col-md-12'>
					<Link to='/manage-leads' className='btn btn-close mb-3'>
						<i className='fa fa-chevron-left' aria-hidden='true'></i> Back
					</Link>
				</div>
				<div className='col-md-10 d-flex align-items-center'>
					<div className='font-18 mb-0'>
						<b>Lead ID :</b> {leadId}{' '}
						{checkHasPermission('get-dedupe-leads') && ddupeData?.length ? (
							<DdupeTable userType={userType} ddupeData={ddupeData} />
						) : null}
					</div>

					<button className='btn btn-success ml-2'>
						{' '}
						{body?.per_info?.status}{' '}
					</button>
				</div>
				{checkHasPermission('get-lead-offer') && (
					<div className='col-md-2 d-flex justify-content-end'>
						<a
							onClick={e => {
								e.preventDefault();
								toggleOfferModal(true);
							}}
							className='link'
						>
							View Offer
						</a>
					</div>
				)}
			</div>
		</div>
	);
};

export default EditLeadHeader;

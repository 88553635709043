import React from 'react';
import { bulkUploadTemplate, bulkUpload } from '../../services/manage-leads';
import { downloadFile } from '../../common/download';
import { toast } from 'react-toastify';
import message from '../../constant/message';
import { useRouter } from '../../hooks/useRouter';
const BulkUpload = ({ activeTab, userType, partId }) => {
	let inputRef = React.useRef(null);
	const [file, setFile] = React.useState(null);
	const [loading, setLoading] = React.useState(false);
	const router = useRouter();
	/**
	 * @param {*} e
	 */
	const downloadTemplate = async e => {
		e.preventDefault();
		const response = await bulkUploadTemplate(userType, partId);
		downloadFile(response);
	};

	/**
	 *
	 */
	const uploadFile = async e => {
		e.preventDefault();
		if (!file) {
			return toast.error(message.SELECT_FILE);
		}
		setLoading(true);
		const response = await bulkUpload(file, userType, partId);
		if (response?.status?.code === 1) {
			let success = response.messages.success;
			toast.success(
				success?.form?.[0] || success?.others?.[0] || message.UPLOAD_SUCCESS
			);
			inputRef.current.value = '';
			setFile(null);
		} else {
			let error = response.messages.error;
			toast.error(error?.form?.[0] || error?.others?.[0] || message.ERROR_FORM);
		}
		setLoading(false);
	};

	return (
		<div className={`tab-pane ${activeTab ? 'active' : 'fade'}`} id='menu1'>
			<div className='panel mb-3 '>
				<div className='head_top border-0 d-flex align-items-center mt-3'>
					<h4 className='font-r'>Select File </h4>
					<a href='' onClick={downloadTemplate} className='ml-4'>
						{' '}
						(Download Template)
					</a>
				</div>
				<div className='p-3'>
					<div className='row'>
						<div className='col-md-4 pr-0'>
							<input
								type='text'
								className='form-control value-text'
								placeholder='Search File'
							/>
							<div> {file?.name}</div>
							<small className='text-gray'>
								[max file size 10 mb/ only .xls file allowed]
							</small>
						</div>
						<div className='col-md-3 d-flex '>
							<div className='custom-file-uploader mt-1'>
								<input
									ref={inputRef}
									file={file}
									onChange={e => setFile(e.target.files[0])}
									type='file'
									className='custom-file-input'
								/>

								<button type='button' className='btn btn-custom '>
									Browse
								</button>
							</div>
						</div>
					</div>
				</div>
				<br />
				<br />
				<br />
			</div>
			<button
				disabled={loading}
				onClick={uploadFile}
				type='button'
				className='btn btn-custom'
			>
				{loading ? <div className='loader' /> : 'Submit'}
			</button>
			<button
				onClick={e => {
					e.preventDefault();
					router.navigate('/manage-leads');
				}}
				type='button'
				className='btn btn-close ml-2'
			>
				Cancel
			</button>
		</div>
	);
};

export default BulkUpload;

import React from 'react';
import { toast } from 'react-toastify';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { objectsForEach } from '../../common/objectToArray';
import Section from '../../Views/Portfolio/UnassignedPortfolio/Section';
import { partnerDetailsFields } from '../../constant/constants';
import message from '../../constant/message';
import {
	getPartnerKycDetails,
	savePartnerKycDetails,
	updateApproveRejectStatusService,
} from '../../services/manage-affiliate';
import { Context } from '../../config/store';
import Modal from '../../components/Modal';
import Button from '../../components/Common/Button';
import {
	changePartnerType,
	getPartnerPayoutGrid,
} from '../../services/onboarding-form-service';
import { downloadFile } from '../../common/download';
const Details = () => {
	const { state } = React.useContext(Context);
	const [loading, setLoading] = React.useState(false);
	const [partType, setPartType] = React.useState('');
	const [errors, setErrors] = React.useState({});
	const [body, setBody] = React.useState({ promoter_detail: [{}] });
	const [userId, setUserId] = React.useState('');
	const [showConfirmationModal, setConfirmationModal] = React.useState(false);
	const [tempPartType, setTempPartType] = React.useState('');
	const params = useParams();
	const navigate = useNavigate();
	React.useLayoutEffect(() => {
		getPartnerInfo();
	}, []);

	const getPartnerInfo = async () => {
		const response = await getPartnerKycDetails(state.userType, params.id);
		setUserId(response.data.data.partner_user_id);
		setBody(
			{
				...response.data.data.resource,
				promoter_detail: response.data.data.resource.partner_promoters.length
					? response.data.data.resource.partner_promoters
					: [{}],
			} || {
				promoter_detail: [{}],
			}
		);
		setPartType(response.data.data.resource.part_type_view);
		// setDetails
	};

	const savePartnerDetails = async (e, cofirmation) => {
		e.preventDefault();
		if (
			body.part_type_view !== partType &&
			!cofirmation &&
			checkHasPermission('nsm-partner-listing')
		) {
			setConfirmationModal(true);
			return;
		}
		setLoading(true);
		const requestBody = {
			info_type: 1,
			partner_detail: { ...body, part_type_view: partType },
		};
		let response;
		try {
			response = await savePartnerKycDetails(
				state.userType,
				params.id,
				requestBody
			);

			if (response?.data?.status?.code === 1) {
				toast.success(
					response?.data?.data?.messages?.[0]?.[0] || message.SAVED_SUCCESS
				);
				let path = '/onboarding/details/bank';
				if (state.userType === 'admin') {
					path += `/${params.id}`;
				}
				navigate(path);
			}
		} catch (err) {
			let error = err?.response?.data?.messages?.error;

			toast.error(error?.form?.[0] || error?.others?.[0] || message.ERROR_FORM);
			setErrors(error);
		}

		setLoading(false);
	};
	const onChange = (value, key, index, multiple) => {
		setBody((partnerDetails) => {
			if (multiple) {
				partnerDetails['promoter_detail'][index][key] = value;
			} else {
				partnerDetails[key] = value;
			}
			return {
				...partnerDetails,
			};
		});
	};
	const toggleModal = () => {
		setConfirmationModal(!showConfirmationModal);
	};
	const addNewSection = (index, bindKey) => {
		body[bindKey].push({});
		setBody({ ...body });
	};
	const removeSection = (index, bindKey) => {
		body[bindKey].splice(index, 1);
		setBody({ ...body });
	};
	const changeApproveRejectStatus = async ({ status }) => {
		setLoading(true);

		const response = await updateApproveRejectStatusService({
			part_id: [body.part_id],
			user_id: [userId],
			status: status,
		});
		if (response?.status?.code === 1) {
			let success = response?.messages?.success;
			toast.success(
				success?.form?.[0] || success?.others?.[0] || message.SAVED_SUCCESS
			);
			await getPartnerInfo();
		} else {
			let error = response.messages.error;
			setErrors(error);
			toast.error(error.form?.[0] || error?.others?.[0] || message.ERROR);
		}

		setLoading(false);
	};
	const checkHasPermission = (checkPermission) => {
		// if (state.is_super_admin === 1) return true;
		const permission = state.permission.find(
			(permission) => permission === checkPermission
		);

		if (permission) return true;
		else return false;
	};
	const onPartChange = (partId) => {
		if (body?.nsm_status >= 4) return;
		setTempPartType(partId);
		toggleModal();
	};
	const changePartType = async () => {
		try {
			let partBody = { part_id: body.part_id, part_type_view: tempPartType };
			let response = await changePartnerType({
				type: state.userType,
				body: partBody,
			});
			const success = response?.data?.messages?.success;
			toast.success(
				success?.form?.[0] || success?.others?.[0] || message.SAVED_SUCCESS
			);
			setPartType(tempPartType);
			toggleModal();
		} catch (err) {
			let error = err?.response?.data?.messages?.error;
			toast.error(error?.form?.[0] || error?.others?.[0] || message.ERROR_FORM);
		}
	};

	const downloadPayoutGrid = async (e) => {
		e.preventDefault();
		try {
			let body = { part_type_view: partType };
			let response = await getPartnerPayoutGrid({
				type: state.userType,
				body,
			});
			downloadFile(response?.data);
		} catch (err) {
			console.log(err);
			let error = err?.response?.data?.messages?.error;
			toast.error(error?.form?.[0] || error?.others?.[0] || message.ERROR_FORM);
		}
	};
	const canEditPartnerDetails = () =>
		state.userType === 'admin'
			? body.nsm_status !== 7 && checkHasPermission('edit-partner-detail')
			: body.nsm_status < 2;

	return (
		<>
			<Modal state={showConfirmationModal} closeModal={toggleModal}>
				<div className='p-4'>
					<p className='font-weight-bold'>
						Are you sure you want to change partner type?
					</p>
					<div className='d-flex align-items-center justify-content-center'>
						<Button
							clickHandler={toggleModal}
							classNames='btn-outline-custom small text-orange mr-2'
						>
							No
						</Button>
						<Button
							clickHandler={changePartType}
							disabled={loading}
							classNames='btn-custom small'
						>
							{loading ? <div className='loader' /> : 'Yes'}
						</Button>
					</div>
				</div>
			</Modal>
			<form onSubmit={savePartnerDetails}>
				{checkHasPermission('nsm-partner-listing') && (
					<div className='form-group pt-2'>
						{' '}
						<h5>Partner Type</h5>
						<div className='row no-gutters'>
							<div
								className='custom-control custom-radio'
								onClick={(e) => onPartChange(1)}
							>
								<input
									type='radio'
									className='custom-control-input'
									name='partType'
									checked={partType == 1}
								/>
								<label className='custom-control-label mt-1'>Retail DSA</label>
							</div>
							<div
								className='custom-control custom-radio ml-2'
								onClick={(e) => onPartChange(2)}
							>
								<input
									type='radio'
									className='custom-control-input'
									name='partType'
									checked={partType == 2}
								/>
								<label className='custom-control-label mt-1'>
									Corporate DSA
								</label>
							</div>
							<div
								className='custom-control custom-radio ml-2'
								onClick={(e) => onPartChange(3)}
							>
								<input
									type='radio'
									className='custom-control-input'
									name='partType'
									checked={partType == 3}
								/>
								<label className='custom-control-label mt-1'>Connector</label>
							</div>
							<div
								className='custom-control custom-radio ml-2'
								onClick={(e) => onPartChange(4)}
							>
								<input
									type='radio'
									className='custom-control-input'
									name='partType'
									checked={partType == 4}
								/>
								<label className='custom-control-label mt-1'>Vendor</label>
							</div>
						</div>
						{errors && (
							<p className='mt-2 mb-0 text-danger'>
								{errors?.fields?.part_type_view?.[0]}
							</p>
						)}
					</div>
				)}
				{checkHasPermission('partner-payout-grid') &&
					(partType == 1 || partType == 2) && (
						<div className='mb-2'>
							<Link onClick={downloadPayoutGrid} to={'#'}>
								<div>Download Payout Grid</div>
							</Link>
						</div>
					)}
				{objectsForEach(partnerDetailsFields).map(
					({ id, value, multiple, key, showField, ...rest }, index) => (
						<Section
							key={id + index}
							fields={multiple ? rest.fields : value}
							id={id}
							bindKey={key}
							onChange={onChange}
							errors={errors}
							addNewSection={addNewSection}
							removeSection={removeSection}
							multiple={multiple}
							errorKey={'partner_detail'}
							body={body}
							edit={canEditPartnerDetails()}
						/>
					)
				)}

				<div>
					{state.userType === 'admin' &&
						(body.nsm_status < 3 || body.nsm_status === 5) &&
						!checkHasPermission('nsm_partner_approve_reject') &&
						checkHasPermission('verify-partner-kyc') && (
							<Link
								to={`/onboarding/documentation/bureau/${params.id}`}
								className='btn btn-custom'
							>
								{'Verify KYC'}
							</Link>
						)}

					{canEditPartnerDetails() && (
						<button
							disabled={loading}
							type='submit'
							className='btn btn-custom ml-2'
						>
							{loading ? <div className='loader' /> : 'Next'}
						</button>
					)}

					{checkHasPermission('nsm_partner_approve_reject') && (
						<>
							{body?.nsm_status === 3 && (
								<button
									disabled={loading}
									onClick={() => changeApproveRejectStatus({ status: 1 })}
									className='btn btn-custom'
								>
									{loading ? <div className='loader' /> : 'Approve'}
								</button>
							)}

							{(body?.nsm_status === 3 ||
								body?.nsm_status === 4 ||
								body?.nsm_status === 6) && (
								<Link
									onClick={(e) => {
										e.preventDefault();
										changeApproveRejectStatus({ status: 0 });
									}}
									to={'#'}
									className='btn btn-close ml-2'
								>
									{loading ? <div className='loader' /> : 'Reject'}
								</Link>
							)}
						</>
					)}
					{canEditPartnerDetails() && (
						<Link
							to={'/partner/onboarding/status'}
							className='btn btn-close ml-2'
						>
							Cancel
						</Link>
					)}
				</div>
			</form>
		</>
	);
};

export default Details;

import React, { useState } from 'react';
import { Context } from '../config/store';
import { changeAdminPassword, logout } from '../services/auth';
import { toast } from 'react-toastify';
import message from '../constant/message';
import { useRouter } from '../hooks/useRouter';
import ReactTooltip from 'react-tooltip';
import PasswordPolicy from '../components/PasswordPolicy';
const ChangePassword = () => {
	const [current_password, setCurrentPassword] = React.useState('');
	const [password, setPassword] = React.useState('');
	const [confirm_password, setConfirmPassword] = React.useState('');
	const [errors, setErrors] = React.useState(null);
	const [loader, setStatus] = React.useState(false);
	const { state } = React.useContext(Context);
	const [curruentpass, setCurrentpass] = useState(false)
	const [newpass, setNewpass] = useState(false)
	const [ConfirmPass, setConfirmpass] = useState(false)
	const router = useRouter();
	const submitChangePassword = async (e) => {
		setStatus(true);
		setErrors(null);
		e.preventDefault();
		const response = await changeAdminPassword(
			{
				password,
				current_password,
				confirm_password,
				user_id: state?.user?.user_id,
				email: state?.user?.email,
			},
			state.userType
		);
		if (response?.status?.code === 1) {
			toast.success('Password changed successfully !');
			logoutUser();
		} else {
			let error = response.messages.error;
			toast.error(error?.form?.[0] || error?.others?.[0] || message.ERROR_FORM);
			setErrors(error);
		}
		setStatus(false);
	};

	const logoutUser = async () => {
		await logout({ type: state.userType });
		router.navigate(`/${state.userType}/login`);
	};
	return (
		<>
			<>

				<section className='my-5 pt-5 mx-auto' style={{ marginLeft: "auto" }}>
					<div className='container'>
						<div className='row mt-2'>
							<div className='col-md-6 ml-auto mr-auto'>
								<div className='col-12 mx-auto'>
									{/* <div className='logo text-center mb-3'>
										<img src='../images/logo.png' width={130} />
									</div> */}
									<h4 className='text-center mb-3 mt-3' style={{ color: "#4A4E88" }}>
										Change Password
									</h4>
									<div className=''>
										<form className='row justify-content-center'>
											<div className='col-md-12'>
												<div className='form-group'>
													<label style={{ fontSize: '18px'}}>Current Password</label>
													<div className='inputWithIcon'>
														<input
															value={current_password}
															onChange={(e) => setCurrentPassword(e.target.value)}
															type={curruentpass ? 'text' : 'password'}
															className='form-control'
															placeholder='**********'
															style={{ height: "40px" }}
														/>
														<div className='input-group-addon' style={{ cursor: 'pointer' }} onClick={()=>setCurrentpass(!curruentpass)}>
															{curruentpass ? (
																<i className='fa fa-eye' aria-hidden='true'></i>
															) : (
																<i className='fa fa-eye-slash' aria-hidden='true'></i>
															)}
														</div>
													</div>
													{errors && (
														<p className='mt-2 mb-0 text-danger'>
															{errors?.fields?.current_password?.[0]}
														</p>
													)}
												</div>
												<div className='form-group'>
												<label style={{ fontSize: '18px', display: 'flex', justifyContent: 'start' }}>New Password <span style={{ fontSize: '10px', marginLeft: '5px',marginTop: '5px' }}><PasswordPolicy password={password} /></span></label>
													<div className='inputWithIcon'>
														<input
															value={password}
															onChange={(e) => setPassword(e.target.value)}
															type={newpass ? 'text' : 'password'}
															className='form-control'
															placeholder='**********'
															style={{ height: "40px" }}
														/>
														{/* <PasswordPolicy password={password} style={{ cursor: 'pointer' }}/> */}
														<div className='input-group-addon' style={{ cursor: "pointer" }} onClick={() => setNewpass(!newpass)}>
															{newpass ? (
																<i className='fa fa-eye' aria-hidden='true'></i>
															) : (
																<i className='fa fa-eye-slash' aria-hidden='true'></i>
															)}
														</div>
													</div>

													{errors && (
														<p className='mt-2 mb-0 text-danger'>
															{errors?.fields?.password?.[0]}
														</p>
													)}
												</div>
												<div className='form-group'>
													<label style={{ fontSize: '18px'}}>Confirm Password</label>
													<div className='inputWithIcon'>
														<input
															value={confirm_password}
															onChange={(e) => setConfirmPassword(e.target.value)}
															// type='password'
															type={ConfirmPass ? 'text' : 'password'}
															className='form-control'
															placeholder='**********'
															style={{ height: "40px" }}
														/>
														<div className='input-group-addon' style={{ cursor: "pointer" }} onClick={() => setConfirmpass(!ConfirmPass)}>
															{ConfirmPass ? (
																<i className='fa fa-eye' aria-hidden='true'></i>
															) : (
																<i className='fa fa-eye-slash' aria-hidden='true'></i>
															)}
														</div>
													</div>
													{console.log(ConfirmPass)}
													{errors && (
														<p className='mt-2 mb-0 text-danger'>
															{errors?.fields?.confirm_password?.[0]}
														</p>
													)}
												</div>
												<div className='form-group mt-4 mb-4' style={{width:"25%",margin:"0 auto"}}>
													<button
														disabled={loader}
														onClick={submitChangePassword}
														className='btn btn-orange w-100 text-center'
														style={{ background: "#4A4E88" }}
													>
														{loader ? <div className='loader' /> : 'Change Password'}
													</button>
												</div>
											</div>
										</form>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section >

			</>
		</>
	);
};

export default ChangePassword;

import { useContext, useLayoutEffect, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Context } from '../../../../config/store';

import LenderUgroService from '../../../../services/lender';
import { Download } from './tableHelpers';
const ViewQuery = ({ queryId, addQuery }) => {
	const { state } = useContext(Context);
	 
	const [loading, setLoading] = useState(true);
	const [tableData, setTableData] = useState([]);

	const getQueryList = async () => {
		setLoading(true);
		const response = await LenderUgroService.getQueryDetails({
			type: state.userType,
			isChild: queryId,
		});
		const data = response.data.data?.['cl_lender_query'];
		setTableData(data);
		setLoading(false);
	};
	useLayoutEffect(() => {
		getQueryList();
	}, []);

	return (

		<div className=''>
		 
			<h6 className='text-orange'>Replies</h6>

			<div className='query-reply-container'>
				{tableData.map(
					({

						query_type_name,
						supporting_file,
						lender_query_id,
						query_txt,
					}) => (
						<div className='query-item row no-gutters justify-content-between align-items-center'>
							<div>
								<div>Query Title: {query_type_name}</div>
								<div>Remarks: {query_txt}</div>
							</div>
							<div>
								<Download
									title={<i class='fa fa-paperclip' aria-hidden='true'></i>}
									row={{ supporting_file, lender_query_id }}
								/>{' '}
							</div>
						</div>
					)
				)}
			</div>
			<h6 className='text-orange'>Add new reply</h6>
		</div>
	);
};

export default ViewQuery;

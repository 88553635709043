import React from 'react';
import Modal from '../../../../components/Common/Modal';
import LenderUgroService from '../../../../services/lender';
import { toast } from 'react-toastify';

const FetchRepaymentModal = ({ modelState, closeModal, cl_lead_id, reloadTable, selectedLender, lenderShareData, lenderRoiData }) => {
  const [lenderShare, setLenderShare] = React.useState(lenderShareData);
  const [lenderRoi, setLenderRoi] = React.useState(lenderRoiData);
  const [includeInsurance, setIncludeInsurance] = React.useState('')
  const date = new Date();
  const tempDate = date.toLocaleDateString('en-CA');
  const [dueDate, setDueDate] = React.useState(tempDate)
  const [loading, setLoading] = React.useState(false)
  const [errors, setErrors] = React.useState({});

  const formSubmitHandler = async (e) => {
    e.preventDefault();
    let errflag = false;
    let err = {};
    if (lenderShare === '') {
      err.lender_share = ["Please enter Lender share (between 1 to 100)"]
      setErrors(err)
      errflag = true;
    }
    if (1 < lenderShare && lenderShare > 100) {
      err.lender_share = ["Lender share should be between 1 to 100"]
      setErrors(err)
      errflag = true;
    }
    if (lenderRoi === '') {
      err.lander_roi = ["Please enter Lender ROI (between 0 to 100)"]
      setErrors(err)
      errflag = true;
    }
    if (0 < lenderRoi && lenderRoi > 100) {
      err.lander_roi = ["Lender ROI should be between 0 to 100"]
      setErrors(err)
      errflag = true;
    }
    if (includeInsurance === '') {
      err.include_insurance = ["Please Select Insurance option"]
      setErrors(err)
      errflag = true;
    }
    if (dueDate === '') {
      err.due_date = ["Please Select Due Date"]
      setErrors(err)
      errflag = true;
    }
    if (!errflag) {
      setLoading(true)
      try {
        const response = await LenderUgroService.fetchRePaymentList({
          "cl_lead_id": cl_lead_id,
          'lender_share': lenderShare,
          'lender_roi': lenderRoi,
          'include_insurance': includeInsurance,
          'due_date': dueDate,
          "cl_lender_id": selectedLender
        });
        if (response?.data?.status?.code === 1) {
          toast.success(response?.data?.messages?.success?.others[0] || "Repayment data fetched successfully")
          closeModal()
          reloadTable()
          setLoading(false)
        }
      } catch (err) {
        setLoading(false);
        setErrors(err.response?.data?.messages?.error?.fields)
        toast.error(
          err.response?.data?.messages?.error?.form[0] ||
          err.response?.data?.messages?.error?.others[0] ||
          "Failed to load payment"
        );
      }
    }
  };

  return (
    <Modal
      style={{
        minWidth: "20%",
        padding: "0px",
        width: '450px'
      }}
      state={modelState}
      closeModal={closeModal}
      removeBorderSpace={true}
    >
      {' '}
      <form onSubmit={formSubmitHandler}>
        <div className="modal-header-container">
          <div className="modal-title">{'Repayment Schedule Details'}</div>
          <div className="closeModalView" onClick={closeModal}>
            <span className="closeModalIcon">x</span>
          </div>
        </div>
        <div className="p-4 modalData">
          <div class='form-group'>
            <label>Lender Share (%) <span className="text-danger">*</span></label>
            <div class='row'>
              <div class='col-12'>
                <input
                  class='form-control custom-form-control'
                  type='number'
                  value={lenderShare}
                  onChange={(e) => {
                    setLenderShare(e.target.value)
                  }}
                />
              </div>
            </div>
            {errors?.lender_share?.length > 0 &&
              <div>
                <p className='text-danger'>{errors?.lender_share[0]}</p>
              </div>
            }
          </div>
          <div class='form-group'>
            <label>Lender ROI (%) <span className="text-danger">*</span></label>
            <div class='row'>
              <div class='col-12'>
                <input
                  class='form-control custom-form-control'
                  type='number'
                  value={lenderRoi}
                  onChange={(e) => setLenderRoi(e.target.value)}
                />
              </div>
            </div>
            {errors?.lander_roi?.length > 0 &&
              <div>
                <p className='text-danger'>{errors?.lander_roi[0]}</p>
              </div>
            }
          </div>
          <div class='form-group'>
            <label>Include Insurance <span className="text-danger">*</span></label>
            <div class='row col-4 mb-3 mt-2 p-0'>
              <div class='col-6 d-flex align-items-center'>
                <input
                  type='radio'
                  id="Yes"
                  value={1}
                  onChange={(e) => setIncludeInsurance(e.target.value)}
                  name='Insurance'
                />
                <label className='ml-2 mb-0' for="Yes">
                  Yes
                </label>
              </div>
              <div class='col-6 d-flex align-items-center'>
                <input
                  type='radio'
                  name='Insurance'
                  id="No"
                  value={0}
                  onChange={(e) => setIncludeInsurance(e.target.value)}
                />
                <label className='ml-2 mb-0' for="No">
                  No
                </label>
              </div>
            </div>
            {errors?.include_insurance?.length > 0 &&
              <div>
                <p className='text-danger'>{errors?.include_insurance[0]}</p>
              </div>
            }
          </div>
          <div class='form-group'>
            <label> Due Date <span className="text-danger">*</span></label>
            <div class='row'>
              <div class='col-12'>
                <input
                  type='Date'
                  id='myDate'
                  className='form-control position-relative'
                  name='date'
                  value={dueDate}
                  onChange={(e) => {
                    if (e.target.validity.valid) {
                      let err = {
                        due_date: []
                      }
                      setErrors(err)
                      setDueDate(e.target.value)
                    } else {
                      let err = {
                        due_date: ["Due date is not valid"]
                      }
                      setErrors(err)
                      setDueDate('')
                    }
                  }}
                />
              </div>
              {errors?.due_date?.length > 0 &&
                <div className='ml-3'>
                  <p className='text-danger'>{errors?.due_date[0]}</p>
                </div>
              }
            </div>
          </div>
          <div class='d-flex align-items-center justify-content-center mt-2'>
            <button type='submit' class='btn btn-custom'>
              {loading ? <div className='loader' /> : 'OK'}
            </button>
            <button
              type='button'
              class='btn btn-outline-custom ml-2'
              data-dismiss='modal'
              onClick={closeModal}
            >
              Cancel
            </button>
          </div>
        </div>
      </form>
    </Modal>
  );
};

export default FetchRepaymentModal;


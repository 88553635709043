import React from 'react';
import { objectsForEach } from '../../common/objectToArray';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Section from '../../Views/Portfolio/UnassignedPortfolio/Section';
import { toast } from 'react-toastify';
import message from '../../constant/message';
import { bankDetailsFields } from '../../constant/constants';
import {
	getPartnerKycDetails,
	savePartnerKycDetails,
} from '../../services/manage-affiliate';
import { Context } from '../../config/store';
const BankDetails = () => {
	const { state } = React.useContext(Context);
	const [loading, setLoading] = React.useState(false);
	const [errors, setErrors] = React.useState({});
	const [body, setBody] = React.useState({});
	const [responseData, setResponseData] = React.useState(null);
	const params = useParams();
	const navigate = useNavigate();
	React.useEffect(() => {
		getPartnerInfo();
	}, []);
	const getPartnerInfo = async () => {
		const response = await getPartnerKycDetails(state.userType, params.id);
		setResponseData(response.data.data);
		setBody(response.data.data.resource.bank_info);
		// setDetails
	};
	const saveBankDetails = async (e) => {
		e.preventDefault();
		setLoading(true);
		const requestBody = { info_type: 2, ...body };
		let response;
		var form_data = new FormData();

		for (var key in requestBody) {
			form_data.append(key, requestBody[key]);
		}
		try {
			response = await savePartnerKycDetails(
				state.userType,
				params.id,
				form_data,
				2
			);

			if (response?.data?.status?.code === 1) {
				toast.success(
					response?.data?.data?.messages?.[0]?.[0] || message.SAVED_SUCCESS
				);
				let path = '/onboarding/details/documents';
				if (state.userType === 'admin') {
					path += `/${params.id}`;
				}
				navigate(path);
			}
		} catch (err) {
			let error = err?.response?.data?.messages?.error;

			toast.error(error?.form?.[0] || error?.others?.[0] || message.ERROR_FORM);
			setErrors(error);
		}

		setLoading(false);
	};
	const checkHasPermission = (checkPermission) => {
		// if (state.is_super_admin === 1) return true;
		const permission = state.permission.find(
			(permission) => permission === checkPermission
		);

		if (permission) return true;
		else return false;
	};
	const onChange = (value, key) =>
		setBody((partnerDetails) => ({ ...partnerDetails, [key]: value }));
	const canEditBankDetails = () =>
		state.userType === 'admin'
			? responseData?.resource?.nsm_status !== 7 &&
			  checkHasPermission('edit-partner-detail')
			: responseData?.resource?.nsm_status < 2;

	return (
		<form onSubmit={saveBankDetails}>
			{objectsForEach(bankDetailsFields).map(({ id, value }) => (
				<Section
					key={id}
					fields={value}
					id={id}
					onChange={onChange}
					errors={errors}
					body={body}
					errorKey={''}
					edit={canEditBankDetails()}
				/>
			))}

			{canEditBankDetails() && (
				<button disabled={loading} type='submit' className='btn btn-custom'>
					{loading ? <div className='loader' /> : 'Next'}
				</button>
			)}
			{/* <Link to={'#'} className='btn btn-close ml-2'>
				Cancel
			</Link> */}
		</form>
	);
};

export default BankDetails;

import React from 'react';
import Modal from '../Modal';
import {
	registerAffiliate,
	changeAffiliateType
} from '../../services/manage-affiliate';
import { toast } from 'react-toastify';
import message from '../../constant/message';
const ChangeAffiliateType = ({
	state,
	toggleModal,
	reloadTable,
	partId,
	currentPartType
}) => {
	const style = { width: '300px' };
	const [affiliateType, setAffiliateType] = React.useState(
		currentPartType || 1
	);

	const [errors, setErrors] = React.useState(null);
	const [loading, setLoading] = React.useState(false);
	const submit = async e => {
		setLoading(true);
		e.preventDefault();
		const body = {
			part_id: partId,
			part_type: affiliateType
		};
		const response = await changeAffiliateType(body);
		if (response?.status?.code === 1) {
			let success = response?.messages?.success;
			toast.success(
				success?.form?.[0] || success?.others?.[0] || message.ADD_SUCCESS
			);
			toggleModal(false);
			reloadTable();
		} else {
			let error = response.messages.error;
			setErrors(error);
			toast.error(error?.form?.[0] || error?.others?.[0] || message.ERROR);
		}
		setLoading(false);
	};

	return (
		<Modal state={state} style={style} closeModal={() => toggleModal(false)}>
			<div className='modal-content gray-bg'>
				{/* Modal body */}
				<div className='modal-body'>
					<h5 className=' text-orange font-r'>Change Affiliate Type</h5>
					<form>
						<div className='form-group'>
							<label>
								Affiliate Type<tt>*</tt>
							</label>
							<select
								value={affiliateType}
								onChange={e => setAffiliateType(e.target.value)}
								className='form-control'
							>
								<option value={1}>Affiliate</option>
								<option value={2}>DSA</option>
								<option value={3}>Affiliate & DSA</option>
							</select>
						</div>

						<button
							disabled={loading}
							onClick={submit}
							type='submit'
							className='btn btn-custom mr-2'
						>
							{loading ? <div className='loader' /> : 'Submit'}
						</button>
						<button
							onClick={() => toggleModal(false)}
							type='button'
							className='btn btn-close'
							data-dismiss='modal'
						>
							Cancel
						</button>
					</form>
				</div>
			</div>
		</Modal>
	);
};

export default ChangeAffiliateType;

import '../../index.css';
function PortfolioContainer(props) {
	return (
		<>
			{/* <Tabs></Tabs> */}
			<div className='p-1' id='datepickerportal'>
				{props.children}
			</div>
		</>
	);
}

export default PortfolioContainer;

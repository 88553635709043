import React from 'react';
import Select from './Select';
import State from './Select/State';
import BusinessConstitions from './Select/BusinessConstitutions';
import City from './Select/City';
import LegalDispute from './LegalDispute';
import Cheque from './Cheque';
const Field = ({ type, id, value, onChange, options, disabled, body }) => {
	const getStyles = (id) => {
		switch (id) {
			case 'pan_no':
			case 'comp_pan':
			case 'gst_no':
			case 'org_pan':
			case 'pan_number':
			case 'ifsc':
				return { textTransform: 'uppercase' };
		}
	};
	switch (type) {
		case 'select':
			return (
				<Select
					value={value}
					disabled={disabled}
					onChange={onChange}
					options={options}
				/>
			);
		case 'state':
			return <State value={value} disabled={disabled} onChange={onChange} />;
		case 'city':
			return <City value={value} disabled={disabled} onChange={onChange} />;
		case 'businessconstitutions':
			return (
				<BusinessConstitions
					value={value}
					disabled={disabled}
					onChange={onChange}
				/>
			);
		case 'legaldispute':
			return (
				<LegalDispute value={value} disabled={disabled} onChange={onChange} />
			);
		case 'cheque':
			return (
				<Cheque
					body={body}
					value={value}
					disabled={disabled}
					onChange={onChange}
				/>
			);
		default:
			return (
				<input
					value={value}
					onChange={onChange}
					type={type ? type : 'text'}
					className='form-control'
					style={getStyles(id)}
					disabled={disabled}
				/>
			);
	}
};

export default Field;

import React from 'react';
import { setAffiliateConfig } from '../../../services/manage-affiliate';
import { toast } from 'react-toastify';
import message from '../../../constant/message';
import { Link } from 'react-router-dom';
const Actions = ({ config, part_id, leadOptions }) => {
	const [loading, setLoading] = React.useState(false);
	const submit = async () => {
		setLoading(true);
		const response = await setAffiliateConfig({
			part_id,
			aff_lead_config: config,
			is_active: 1,
			lead_tools: leadOptions
		});

		if (response?.status?.code === 1) {
			toast.success(message.SAVED_SUCCESS);
		} else {
			let error = response.messages.error;
			toast.error(error?.form?.[0] || error?.others?.[0] || message.ERROR_FORM);
		}
		setLoading(false);
	};
	return (
		<div className='content'>
			<div className='mb-4'>
				{/* <div className='theme-checkbox'>
					<input type='checkbox' />
					<label>
						I agree with U Gro Capital{' '}
						<span className='link'> Terms & Conditions.</span>
					</label>
				</div> */}
			</div>
			{/* <button type='submit' className='btn btn-close '>
				Back
			</button> */}
			<button
				disabled={loading}
				onClick={submit}
				type='submit'
				className='btn btn-custom ml-2'
			>
				{loading ? <div className='loader' /> : 'Submit'}
			</button>
			<Link to='/manage-affiliate' type='submit' className='btn btn-close ml-2'>
				Cancel
			</Link>
			{/* <button type='submit' className='btn btn-close ml-2'>
				Next
			</button> */}
		</div>
	);
};

export default Actions;

import Select from '../Common/Input/Select';

const SelectProductLender = ({ products, product, setProduct }) => {
	const productOptions = products.map((productItem) => ({
		label: productItem.prod_type,
		value: productItem.id,
	}));
	return (
		<Select
			isMulti
			label={
				<span>
					Products <tt>*</tt>
				</span>
			}
			options={productOptions}
			onChange={(data) => setProduct(data)}
			value={product}
		/>
	);
};

export default SelectProductLender;

import React from 'react';
import { Link } from 'react-router-dom';
const RegisterThankingNote = () => {
	return (
		<section className='user-section '>
			<div className='container'>
				<div className='row'>
					<div className='col-md-6 ml-auto mr-auto'>
						<div className='user-bg col-10 mx-auto'>
							<div className='logo text-center mb-5'>
								<img src='/images/logo.png' width={190} />
							</div>
							<div className='clearfix' />
							<h4 className='text-center mb-5'>Thank you for registration.</h4>
							<p className='text-center text-gray mb-5 btn-font'>
								<b>
									Please login to your account to complete onboarding process.
								</b>
							</p>
							<form>
								<div className='form-group'>
									<Link to='/affiliate/login' className='btn btn-orange w-100'>
										Login
									</Link>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default RegisterThankingNote;

import React from 'react';
import Pagination from '../components/Pagination';
import { useRouter } from '../hooks/useRouter';
import { getLeadApiDetails } from '../services/manage-leads';
import { Link } from 'react-router-dom';
import config from '../services/config';
import { Context } from '../config/store';
import { toast } from 'react-toastify';
import message from '../constant/message';
const ApiDetails = ({ type }) => {
	const [part_id, setPartId] = React.useState('');
	const [request, setRequest] = React.useState({});
	const [api, setApiParams] = React.useState({});
	const { state } = React.useContext(Context);
	const router = useRouter();
	React.useEffect(() => {
		const partId = router?.params?.partId || '';
		if (type || partId) {
			setPartId(partId);
			getApiDetails(partId);
		}
	}, []);
	const getApiDetails = async partId => {
		const response = await getLeadApiDetails(partId, type || state.userType);
		if (response?.status?.code === 1) {
			setRequest(response?.data?.resource?.api_request || {});
			setApiParams(response?.data?.resource?.part_api || {});
		} else {
			let error = response?.messages?.error;
			toast.error(error?.form?.[0] || error?.others?.[0] || message.ERROR_FORM);
		}
	};
	return (
		<>
			<>
				<div className='p-3'>
					<div className='content'>
						{type !== 'affiliate' && (
							<div className='row'>
								<div className='col-md-12'>
									<Link to={`/manage-affiliate`} className='btn btn-close mb-3'>
										<i className='fa fa-chevron-left' aria-hidden='true' /> Back
									</Link>
								</div>
							</div>
						)}
					</div>
					<hr className='m-0' />
					<div className='content'>
						<h5>API Details</h5>
						<div className='col-md-4'>
							<div className='form-group'>
								<label> API URL</label>
								<input
									type='text'
									className='form-control'
									value={`${config.baseUrl.affiliate}/add-lead-via-api`}
									disabled
								/>
							</div>
						</div>
						{/* <div className='col-md-4'>
							<div className='form-group'>
								<label>IP</label>
								<input
									type='text'
									className='form-control'
									value={api?.part_allow_ip}
									disabled
								/>
							</div>
						</div> */}
						<div className='col-md-4'>
							<div className='form-group'>
								<label>API KEY</label>
								<input
									type='email'
									className='form-control'
									value={api.part_key}
									disabled
								/>
							</div>
						</div>
						<div className='col-md-4'>
							<div className='form-group'>
								<label>API Secret</label>
								<input
									type='text'
									className='form-control'
									value={api.part_secret_key}
									disabled
								/>
							</div>
						</div>
						<div className='col-md-4'>
							<div className='form-group'>
								<label>Header</label>
								<textarea
									type='text'
									className='form-control'
									value={`" { "Content-Type" : "application/json", "Accept" : "application/json", "x-part-key" : "<API_KEY>" } "`}
									disabled
								/>
							</div>
						</div>
					</div>
					<hr className='m-0' />
					<div className='content'>
						<h5>Request</h5>
						<div className='col-md-12'>
							<div className='card'>
								<div className='card-header bg-header'>
									<ul className='nav nav-tabs custom-tabs response-tab border-none'>
										<li className='nav-item'>
											<a
												className='nav-link active'
												data-toggle='tab'
												href='#response'
											>
												Body
											</a>
										</li>
										{/* <li className='nav-item'>
											<a className='nav-link' data-toggle='tab' href='#header'>
												Header
											</a>
										</li> */}
									</ul>
								</div>
								<pre className='card-body scroll-card active' id='response'>
									{JSON.stringify(request, null, 2)}
								</pre>
							</div>
						</div>
					</div>
					<hr className='m-0' />
					<div className='content'>
						<h5>Response</h5>
						<div className='col-md-12'>
							<div className='card'>
								<div className='card-header bg-header'>
									<ul className='nav nav-tabs custom-tabs request-tab border-none'>
										<li className='nav-item'>
											<a
												className='nav-link active'
												data-toggle='tab'
												href='#response'
											>
												Body
											</a>
										</li>
										{/* <li className='nav-item'>
											<a className='nav-link' data-toggle='tab' href='#header'>
												Header
											</a>
										</li> */}
									</ul>
								</div>
								<pre className='card-body scroll-card active' id='response'>
									{JSON.stringify(request, undefined, 2)}
								</pre>
							</div>
						</div>
					</div>
					<hr className='m-0' />
					{/* <div className='panel m-4'>
						<div className='table-responsive '>
							<table className='table table-striped'>
								<thead>
									<tr>
										<th width='20%'>Error Code</th>
										<th width='80%'>Error Description</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td width='20%'>AF001</td>
										<td width='80%'>Arun Singh</td>
									</tr>
									<tr>
										<td width='20%'>AF001</td>
										<td width='80%'>Arun Singh</td>
									</tr>
									<tr>
										<td width='20%'>AF001</td>
										<td width='80%'>Arun Singh</td>
									</tr>
									<tr>
										<td width='20%'>AF001</td>
										<td width='80%'>Arun Singh</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div> */}
					{/* <Pagination /> */}
				</div>
			</>
		</>
	);
};

export default ApiDetails;

import React from 'react';
import { getPartNotifications } from '../../services/notifications';
import { Context } from '../../config/store';
import useInterval from '../../hooks/useInterval';
const Notifications = () => {
	const [notifications, setNotifications] = React.useState([]);
	const { state } = React.useContext(Context);
	React.useEffect(() => {
		if (state.user.resource) {
			getNotifications();
		}
	}, [state.user]);
	useInterval(() => {
		// Your custom logic here
		getNotifications();
	}, 60000);
	const getNotifications = async () => {
		// state.
		const response = await getPartNotifications();
		 
		if (response?.status?.code === 1) {
			setNotifications(response?.data?.lead_notifications || []);
		}
	};
	return (
		<div className=' offcanvas-collapse open'>
			<h4 className='text-center mt-3'>Notifications</h4>
			{notifications.map((notification, index) => (
				<div className={`notiOne ${index && 'mt-3'}`}>
					<div className='d-flex'>
						<div className='col-1'>
							<i className='fa fa-info-circle text-info'></i>
						</div>
						<div className='col-10'>
							<p className='mb-1'>
								{notification?.lead_name} status is {notification?.status}{' '}
							</p>
							<span> updated {notification?.status_updatetime_hr}</span>
						</div>
						<div className='col-1'>
							<i className='fa fa-ellipsis-v'></i>
						</div>
					</div>
				</div>
			))}
		</div>
	);
};

export default Notifications;

/**
 * Split a Hyphen case string to Captialalise
 * @param {string} word
 *
 * @input -> 'xift-is-powerful
 * @output -> xift Is Powerful
 */
export default function splitHyphenToCaptialise(word) {
	return word.replace(/(^|-)(\w)/g, function($0, $1, $2) {
		return ($1 && ' ') + $2.toUpperCase();
	});
}

import React from 'react';
import Modal from '../../Modal';
import LenderUgroService from '../../../services/lender';
import MasterDataService from '../../../services/master-data';
import { Context } from '../../../config/store';
import { toast } from 'react-toastify';
import message from '../../../constant/message';
import { objectsForEach } from '../../../common/objectToArray';
import splitUnderscoreToCaptialise from '../../../common/splitUnderscoreToCaptialise';

const AssignLenderLeadModal = ({
	modelState,
	toggleModal,
	reloadTable,
	selectedIds,
	type,
	bulkAssign,
	leadType,
}) => {
	const { state } = React.useContext(Context);
	const [comment, setComment] = React.useState('');
	const [errors, setErrors] = React.useState({});
	const [colenders, setColenders] = React.useState([]);
	const [lenderId, setLenderId] = React.useState('');
	const [leadAssignType, changeLeadAssignType] = React.useState(false);
	React.useEffect(() => {
		getAssignLenders();
	}, []);
	const getAssignLenders = async () => {
		let response;
		if (bulkAssign) {
			response = await MasterDataService.getLenderList(
				state.userType,
				state.userType === 'admin' ? 'co-lender' : ''
			);
		} else {
			response = await LenderUgroService.getCoLenders({
				type: state.userType,
				leadId: selectedIds[0],
			});
		}

		if (response?.data?.status?.code === 1) {
			if (bulkAssign) {
				setColenders(
					objectsForEach(response?.data?.data?.lender_list, 'STRING_VAL').map(
						({ id, value }) => ({
							cl_lender_id: id,
							lender: { lender_name: value },
						})
					)
				);
			} else {
				setColenders(response?.data?.data?.coLenders);
			}
		}
	};
	const assignLender = async () => {
		try {
			let response;
			if (bulkAssign) {
				response = await LenderUgroService.bulkLeadSubmit({
					leadIds: selectedIds.map((lead) => lead.cl_lead_id),
					lenderId,
					comment,
				});
			} else {
				response = await LenderUgroService.assignLender({
					type: state.userType,
					requestBody: {
						lender_id: lenderId,
						...(leadType === 2 && {
							lead_assign_as_type: leadAssignType ? 1 : 2,
						}),
						cl_lead_id:
							type === 'details' ? selectedIds : formatLafIds('cl_lead_id'),
						comment: comment,
					},
				});
			}
			if (response?.data?.status?.code === 1) {
				let success = objectsForEach(
					response?.data?.data?.result,
					'STRING_VAL'
				);
				success = success.filter(({ value }) => value);
				if (success.length) {
					toast.success(
						<ol>
							{success.map(({ id, value }) => (
								<li>
									{splitUnderscoreToCaptialise(id)} - {value}
								</li>
							))}
						</ol>
					);
				} else {
					const successMessage = response?.data?.messages?.success;
					toast.success(
						successMessage?.form?.[0] ||
							successMessage?.others?.[0] ||
							message.SAVED_SUCCESS
					);
				}
				if (reloadTable) reloadTable(selectedIds[0]);
				toggleModal();
			}
		} catch (err) {
			let error = err?.response?.data?.messages?.error;
			toast.error(error?.form?.[0] || error?.others?.[0] || message.ERROR_FORM);
			setErrors(error);
		}
	};

	const formatLafIds = (key) => {
		return selectedIds.map((id) => id[key]);
	};

	const formSubmitHandler = (e) => {
		e.preventDefault();

		assignLender(bulkAssign);
	};

	return (
		<Modal state={modelState} closeModal={toggleModal}>
			{' '}
			<form onSubmit={formSubmitHandler}>
				<div className='form-group mb-0'>
					{Boolean(bulkAssign) && (
						<div className='row'>
							<div className='col-12'>
								<label>
									Total {selectedIds.length} Accounts have been selected :{' '}
								</label>
							</div>
							<div className='col-12'>
								<label>LAF ID : {formatLafIds('laf_id').join(', ')}</label>
							</div>
						</div>
					)}
				</div>

				{leadType === 2 && (
					<div className='d-flex flex-row justify-content-between'>
						<div>
							<input
								checked={leadAssignType}
								onChange={(e) => changeLeadAssignType(e.target.checked)}
								type='checkbox'
							/>
							<label className='ml-2'>Convert to Option 1</label>
						</div>
					</div>
				)}
				<h6 className='mb-2 mt-2'>
					<b>
						<small>Assign to</small>
					</b>
				</h6>
				<div className='form-group mb-0'>
					<div className='custom_control d-flex flex-wrap'>
						<select
							name='entity-type'
							onChange={(e) => setLenderId(e.target.value)}
						>
							<option value={''}>Select</option>
							{(colenders || []).map(({ lender, cl_lender_id }) => (
								<option
									className='custom_radio_control mr-4'
									value={cl_lender_id}
									key={cl_lender_id}
								>
									{lender.lender_name}
								</option>
							))}
						</select>
					</div>
					{errors && (
						<p className='mt-2 mb-0 text-danger'>
							{errors?.fields?.lender_id?.[0]}
						</p>
					)}
				</div>

				<div className='form-group'>
					<label>Remark</label>
					 
					<textarea
						className='form-control custom-form-control'
						cols={4}
						rows={4}
						value={comment}
						onChange={(e) => setComment(e.target.value)}
						defaultValue={''}
					/>
					{errors && (
						<p className='mt-2 mb-0 text-danger'>
							{errors?.fields?.comment?.[0]}
						</p>
					)}
				</div>
				<div className='d-flex'>
					<button type='submit' className='btn btn-custom'>
						OK
					</button>
					<button
						type='button'
						className='btn btn-outline-custom ml-2'
						data-dismiss='modal'
						onClick={toggleModal}
					>
						Cancel
					</button>
				</div>
			</form>
		</Modal>
	);
};

export default AssignLenderLeadModal;


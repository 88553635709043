import React from 'react';
import ComissionTable from '../components/ComissionReport/ComissionTable';
import VolumeLinkedIncentive from '../components/ComissionReport/VolumeLinkedIncentive';
import OtherIncentive from '../components/ComissionReport/OtherIncentive';
import { getComissionReport } from '../services/comission';
import dynamicSum from '../common/dynamicSum';
import { objectsForEach } from '../common/objectToArray';
import { toast } from 'react-toastify';
import message from '../constant/message';
import Header from '../components/ComissionReport/Header';
import { Context } from '../config/store';

const ComissionReport = ({ type }) => {
	const [commissionReport, setComissionReport] = React.useState(null);
	const [otherIncentive, setOtherIncentive] = React.useState([]);
	const [error, setError] = React.useState();
	const { state } = React.useContext(Context);
	const generateReport = async ({ date, partner }) => {
		setError(null);
		let body;
		if (type === 'dsa') {
			body = { comm_for: date };
		} else {
			body = { comm_for: date, part_id: partner };
		}

		const response = await getComissionReport({
			body,
			userType: type || state.userType
		});

		if (response?.status === 1) {
			const report = response?.data?.resource;
			setComissionReport(report || {});
			const others = getOtherIncentives(report);
			setOtherIncentive(others);
		} else {
			let error = response?.messages?.error;
			setError(error);
			toast.error(
				error?.form?.[0] ||
					error?.others?.[0] ||
					response?.message ||
					message.ERROR
			);
		}
	};
	const getOtherIncentives = report => {
		const lead_commission = report?.lead_commission || [];
		const res = lead_commission.reduce(
			(acc, curr) => (
				(acc[`${curr.product_name} ${curr?.loan_type_name || ''}`] = dynamicSum(
					{
						data: lead_commission.filter(
							lead => lead.lead_comm_id === curr.lead_comm_id
						),
						sumKey: 'tot_amt'
					}
				)),
				acc
			),
			{}
		);
		return objectsForEach(res, 'STRING_VAL');
	};

	return (
		<>
			<>
				<div className='p-3'>
					<Header
						error={error}
						userType={type || state.userType}
						generateReport={generateReport}
					/>
					{commissionReport && (
						<>
							<VolumeLinkedIncentive
								vli_commission={commissionReport?.vli_commission}
								userType={type}
							/>
							{/* <OtherIncentive incentives={otherIncentive} /> */}

							<ComissionTable
								reports={commissionReport?.lead_commission}
								userType={type}
							/>
							<div className='panel mt-2'>
								<div className='row no-gutters align-items-center'>
									<div className='col-md-3'>
										<div className='head_top border-none'>
											<h4>Grand Total</h4>
										</div>
									</div>
									<div className='col-md-3'></div>
									<div className='col-md-3'></div>
									<div className='col-md-3'>
										<span className='bold ml-3'>
											{type === 'dsa'
												? parseFloat(
														commissionReport.tot_lead_comm_amt +
															commissionReport.tot_vli_comm_amt
												  ).toLocaleString('en-IN')
												: parseFloat(commissionReport?.comm_amt).toLocaleString(
														'en-IN'
												  )}{' '}
											INR
										</span>
									</div>
								</div>
							</div>
						</>
					)}
				</div>
			</>
		</>
	);
};

export default ComissionReport;

import React from 'react';
const VolumeLinkedItem = ({
	products,
	product_id,
	min_value,
	max_value,
	base_payout,
	showAddNew,
	setBody,
	index,
	body,
	error
}) => {
	const addNewItem = e => {
		e.preventDefault();
		let newBody = JSON.parse(JSON.stringify(body));
		if (!index) {
			newBody.vli[index] = {};
		}
		newBody.vli[index + 1] = {};
		setBody({ ...newBody });
	};
	const deleteItem = e => {
		e.preventDefault();

		const newItems = body.vli.filter((option, idx) => idx !== index);

		setBody({ ...body, vli: newItems });
	};
	const onChangeText = (e, key) => {
		let newVli = body.vli[index] || {};
		newVli[key] = e.target.value;
		let assignVli = body.vli;
		newVli.amt_type = 'percent';
		assignVli[index] = newVli;
		setBody(values => ({ ...values, vli: assignVli }));
	};
	return (
		<div className='mt-3'>
			<div className='row'>
				<div className='col-md-3'>
					<div className='form-group'>
						<label>
							Select Product <tt>*</tt>
						</label>
						<select
							onChange={e => onChangeText(e, 'product_id')}
							value={body?.vli?.[index]?.product_id}
							className='form-control'
						>
							<option value={null}>Select Product</option>
							{products.map(({ prod_name, prod_id }) => (
								<option value={prod_id} key={prod_name}>
									{prod_name}
								</option>
							))}
						</select>
						{error && (
							<p className='mt-2 mb-0 text-danger'>
								{error?.fields?.[`vli.${index}.product_id`]?.[0]}
							</p>
						)}
					</div>
				</div>
				<div className='col-md-3'>
					<div className='form-group'>
						<label>
							Min Volume <tt>*</tt>
						</label>
						<input
							value={min_value}
							onChange={e => onChangeText(e, 'min_value')}
							type='number'
							className='form-control'
							placeholder='Min Volume'
						/>
						{error && (
							<p className='mt-2 mb-0 text-danger'>
								{error?.fields?.[`vli.${index}.min_value`]?.[0]}
							</p>
						)}
					</div>
				</div>
				<div className='col-md-3'>
					<div className='form-group'>
						<label>
							Max Volume <tt>*</tt>
						</label>
						<input
							value={max_value}
							onChange={e => onChangeText(e, 'max_value')}
							type='number'
							className='form-control'
							placeholder='Max Volume'
						/>
						{error && (
							<p className='mt-2 mb-0 text-danger'>
								{error?.fields?.[`vli.${index}.max_value`]?.[0]}
							</p>
						)}
					</div>
				</div>
				<div className='col-md-2'>
					<div className='form-group'>
						<label>
							{' '}
							Percentage (%) <tt>*</tt>
						</label>
						<input
							value={base_payout}
							onChange={e => onChangeText(e, 'base_payout')}
							type='number'
							className='form-control'
						/>
						{error && (
							<p className='mt-2 mb-0 text-danger'>
								{error?.fields?.[`vli.${index}.base_payout`]?.[0]}
							</p>
						)}
					</div>
				</div>
				{showAddNew ? (
					<div className='col-md-1 m-auto'>
						<a href='#' onClick={addNewItem} className='ml-2'>
							<i
								className='fa fa-plus-circle fa-2x text-gray'
								aria-hidden='true'
							/>
						</a>
					</div>
				) : (
					index >= 1 && (
						<div className='col-md-1 m-auto'>
							<a href='#' onClick={deleteItem} className='ml-2'>
								<i
									className='fa fa-minus-circle fa-2x text-gray'
									aria-hidden='true'
								/>
							</a>
						</div>
					)
				)}
			</div>
		</div>
	);
};

export default VolumeLinkedItem;

import React, { useEffect } from 'react';
import { toast } from 'react-toastify';
import FormGroup from '../../../components/FormGroup';
import Modal from '../../../components/Modal';
import message from '../../../constant/message';
import { savePartnerKycDetails } from '../../../services/manage-affiliate';
const RequiredUserModal = ({
	state,
	body,
	responseData,
	toggleModal,
	requiredUserDetails,
	partId,
	userType,
	reloadTable,
	editIndex,
}) => {
	const [errors, setErrors] = React.useState({});
	const [loading, setLoading] = React.useState(false);
	const [formBody, setFormBody] = React.useState({});

	const onChange = (value, key) => {
		setFormBody((userInfo) => {
			userInfo[key] = value;

			return { ...userInfo };
		});
	};
	const submitRequiredUserDetails = async (e) => {
		e.preventDefault();
		setLoading(true);

		let partner_user_body;
		if (typeof editIndex === 'number') {
			body[editIndex] = formBody;
			partner_user_body = body;
		} else {
			partner_user_body = [...body, formBody];
		}
		const requestBody = {
			info_type: 4,
			partner_user: partner_user_body,
			// consent_one: consentOne ? '1' : '',
			// consent_two: consentTwo ? '1' : '',
		};
		let response;
		try {
			response = await savePartnerKycDetails(userType, partId, requestBody);

			if (response?.data?.status?.code === 1) {
				let success = response?.data?.messages?.success;
				toast.success(
					success?.form?.[0] || success?.others?.[0] || message.SAVED_SUCCESS
				);
				toggleModal();
				reloadTable();
				// navigate(
				// 	userType === 'admin'
				// 		? '/partner/onboarding/status'
				// 		: `/onboarding/thanks`
				// );
			}
		} catch (err) {
			let error = err?.response?.data?.messages?.error;
			console.log(error);
			toast.error(error?.form?.[0] || error?.others?.[0] || message.ERROR_FORM);
			setErrors(error);
		}

		setLoading(false);
	};
	useEffect(() => {
		setFormBody({ ...body[editIndex] });
	}, [editIndex]);
	return (
		<Modal state={state} closeModal={toggleModal}>
			<form onSubmit={submitRequiredUserDetails}>
				{requiredUserDetails.map(
					({ field, id, title, type, required, options }, index) => (
						<div>
							<FormGroup
								key={id ? id : field}
								id={id ? id : field}
								onChange={onChange}
								errors={errors}
								multiple={true}
								errorKey={'partner_user'}
								body={formBody}
								apiResponse={responseData}
								label={title ? title : id ? id : field}
								required={required}
								options={options}
								type={type}
								index={typeof editIndex === 'number' ? editIndex : body.length}
							/>
						</div>
					)
				)}
				<button
					disabled={loading}
					type='submit'
					className='btn btn-custom mt-2 ml-2'
				>
					{loading ? <div className='loader' /> : 'Save'}
				</button>
			</form>
		</Modal>
	);
};

export default RequiredUserModal;

const TableLoading = () => {
	return (
		<div className='table-overlay-loader'>
			<div className='d-flex justify-content-center align-items-center w-100 h-100 bg'>
				<div className='Tableloader' />
			</div>
		</div>
	);
};

export default TableLoading;

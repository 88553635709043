import { getCookie } from './cookie';
import config from './config';

export const getUserList = async ({ page, queryParams, exportList }) => {
	const token = getCookie(btoa('token'));
	const response = await fetch(
		`${config.baseUrl.admin}/backend-user?page=${page}&${queryParams}&export=${
			exportList ? 1 : 0
		}`,
		{
			headers: {
				Authorization: `Bearer ${token}`,
				...config.defaultHeaders,
			},
		}
	);
	if (exportList) {
		return await response.blob();
	} else {
		return await response.json();
	}
};
/**
 *
 * @param {*} param0
 */
export const getAffiliateUserList = async ({
	page,
	queryParams,
	exportList,
}) => {
	const token = getCookie(btoa('token'));
	const response = await fetch(
		`${
			config.baseUrl.affiliate
		}/acl/affiliates-user?page=${page}&${queryParams}&export=${
			exportList ? 1 : 0
		}`,
		{
			headers: {
				Authorization: `Bearer ${token}`,
				...config.defaultHeaders,
			},
		}
	);
	if (exportList) {
		return await response.blob();
	} else {
		return await response.json();
	}
};

/**
 *
 * @param {*} body
 */
export const saveAffiliateUser = async (body) => {
	const token = getCookie(btoa('token'));
	return await (
		await fetch(`${config.baseUrl.affiliate}/acl/save-affiliates-user`, {
			method: 'POST',
			body: JSON.stringify(body),
			headers: {
				Authorization: `Bearer ${token}`,
				...config.defaultHeaders,
			},
		})
	).json();
};

/**
 *
 * @param {String} user_id
 */
export const getAffiliateUser = async (user_id) => {
	const token = getCookie(btoa('token'));
	return await (
		await fetch(
			`${config.baseUrl.affiliate}/acl/add-affiliate-user?user_id=${user_id}`,
			{
				headers: {
					Authorization: `Bearer ${token}`,
					...config.defaultHeaders,
				},
			}
		)
	).json();
};

/**
 *
 * @param {{ "f_name": "", "l_name": "", "email": "", "mobile_no": "","parent_id": "","branch_id": [],"excluded_zipcode": [],"state_id": "","city_id": "","role_id":"","prod_id":[],"max_limit":[], "min_limit":[],"is_active": ""}} body
 */

export const saveUser = async (body, viewType = 'admin') => {
	const token = getCookie(btoa('token'));
	return await (
		await fetch(`${config.baseUrl[viewType]}/save-backend-user`, {
			method: 'POST',
			body: JSON.stringify(body),
			headers: {
				Authorization: `Bearer ${token}`,
				...config.defaultHeaders,
			},
		})
	).json();
};

export const getSingleUser = async ({ userType, user_id }) => {
	const token = getCookie(btoa('token'));
	return await (
		await fetch(
			`${config.baseUrl[userType]}/get-backend-user?user_id=${user_id}`,
			{
				headers: {
					Authorization: `Bearer ${token}`,
					...config.defaultHeaders,
				},
			}
		)
	).json();
};
/**
 *
 * @param {{ user_id:"","f_name": "", "l_name": "", "email": "", "mobile_no": "","parent_id": "","branch_id": [],"excluded_zipcode": [],"state_id": "","city_id": "","role_id":"","prod_id":[],"max_limit":[], "min_limit":[],"is_active": ""}} body
 */
export const updateUser = async (body, viewType = 'admin') => {
	const token = getCookie(btoa('token'));
	return await (
		await fetch(`${config.baseUrl[viewType]}/update-backend-user`, {
			method: 'POST',
			body: JSON.stringify(body),
			headers: {
				Authorization: `Bearer ${token}`,
				...config.defaultHeaders,
			},
		})
	).json();
};

export const getUserParameters = async (type = 'admin') => {
	const token = getCookie(btoa('token'));
	return await (
		await fetch(`${config.baseUrl[type]}/add-backend-user?user_id=1`, {
			headers: {
				Authorization: `Bearer ${token}`,
				...config.defaultHeaders,
			},
		})
	).json();
};

export const bulkUploadTemplate = async (type, downloadType) => {
	const token = getCookie(btoa('token'));

	let url = `${config.baseUrl[type]}`;
	if (downloadType === 'affiliate') {
		url += '/affiliate/download-dsa-template';
	} else {
		url += '/download-backend-user-template';
	}
	return await (
		await fetch(url, {
			headers: {
				Authorization: `Bearer ${token}`,
				...config.defaultHeaders,
			},
		})
	).blob();
};

/**
 *
 * @param {File} file
 */
export const bulkUploadUser = async (file, type, downloadType) => {
	const token = getCookie(btoa('token'));
	const formData = new FormData();
	formData.append('file', file);
	let url = `${config.baseUrl[type]}`;
	if (downloadType === 'affiliate') {
		url += '/affiliate/upload-dsa';
	} else {
		url += '/upload-backend-user';
	}
	return await (
		await fetch(url, {
			method: 'POST',
			headers: {
				Authorization: `Bearer ${token}`,
				Accept: 'application/json',
			},
			body: formData,
		})
	).json();
};

// export const bulkUploadTemplate = async (type) => {
// 	const token = getCookie(btoa('token'));
// 	let url = `${config.baseUrl[type]}/download-backend-user-template`;

// 	return await (
// 		await fetch(url, {
// 			headers: {
// 				Authorization: `Bearer ${token}`,
// 				...config.defaultHeaders,
// 			},
// 		})
// 	).blob();
// };

// /**
//  *
//  * @param {File} file
//  */
// export const bulkUploadUser = async (file, type) => {
// 	const token = getCookie(btoa('token'));
// 	const formData = new FormData();
// 	formData.append('file', file);
// 	let url = `${config.baseUrl[type]}/upload-backend-user`;

// 	return await (await fetch(url, {
// 		method: 'POST',
// 		headers: {
// 			Authorization: `Bearer ${token}`,
// 			Accept: 'application/json'
// 		},
// 		body: formData
// 	})).json();
// };

import React from 'react';
const BankAccountDetails = ({
	setAffiliateKyc,
	affiliateKyc,
	view,
	errors
}) => {
	const onChange = (value, type) => {
		setAffiliateKyc(values => ({
			...values,
			bank_info: { ...values.bank_info, [type]: value }
		}));
	};

	return (
		<div className='content'>
			<h5>Bank Account Details</h5>
			<div className='row '>
				<div className='col-md-4'>
					<div className='form-group'>
						<label>
							Account Name <tt>*</tt>
						</label>
						<input
							value={affiliateKyc.bank_info.account_name}
							disabled={view}
							type='text'
							className='form-control'
							placeholder='Account Name'
							// value={}
							onChange={e => onChange(e.target.value, 'account_name')}
						/>
						{errors && (
							<p className='mt-2 mb-0 text-danger'>
								{errors?.fields?.['bank_info.account_name']?.[0]}
							</p>
						)}
					</div>
				</div>
				<div className='col-md-4'>
					<div className='form-group'>
						<label>
							Account Number <tt>*</tt>
						</label>
						<input
							value={affiliateKyc.bank_info.account_number}
							disabled={view}
							type='text'
							className='form-control'
							placeholder=' Account Number'
							// value={}

							onChange={e => onChange(e.target.value, 'account_number')}
						/>
						{errors && (
							<p className='mt-2 mb-0 text-danger'>
								{errors?.fields?.['bank_info.account_number']?.[0]}
							</p>
						)}
					</div>
				</div>
				<div className='col-md-4 row'>
					<div className='form-group col-md-9'>
						<label> IFSC</label>
						<input
							value={affiliateKyc.bank_info.ifsc}
							disabled={view}
							type='text'
							className='form-control'
							placeholder='IFSC'
							onChange={e => onChange(e.target.value, 'ifsc')}
						/>
						{errors && (
							<p className='mt-2 mb-0 text-danger'>
								{errors?.fields?.['bank_info.ifsc']?.[0]}
							</p>
						)}
					</div>
					<div className='col-md-1 mt-4'>
						<button
							type='button'
							className='btn btn-custom btn-custom-height'
							data-toggle='modal'
							data-target='#myModal'
						>
							Fetch Bank
						</button>
					</div>
				</div>
				<div className='col-md-4'>
					<div className='form-group'>
						<label>
							Bank Name <tt>*</tt>
						</label>
						<input
							value={affiliateKyc.bank_info.bank_name}
							disabled={view}
							type='text'
							className='form-control'
							placeholder='Bank Name'
							onChange={e => onChange(e.target.value, 'bank_name')}
						/>
						{errors && (
							<p className='mt-2 mb-0 text-danger'>
								{errors?.fields?.['bank_info.bank_name']?.[0]}
							</p>
						)}
					</div>
				</div>
				<div className='col-md-4'>
					<div className='form-group'>
						<label>
							Branch <tt>*</tt>
						</label>
						<input
							disabled={view}
							value={affiliateKyc.bank_info.branch_name}
							onChange={e => onChange(e.target.value, 'branch_name')}
							className='form-control'
							type='text'
							placeholder='Branch'
							// value={}
						/>
						{errors && (
							<p className='mt-2 mb-0 text-danger'>
								{errors?.fields?.['bank_info.branch_name']?.[0]}
							</p>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

export default BankAccountDetails;

import Button from '../../../components/Common/Button';
import { Context } from '../../../config/store';
import { toast } from 'react-toastify';
import message from '../../../constant/message';
import { useState, useContext } from 'react';
import { generateCode } from '../../../services/manage-affiliate';

const GenerateCode = ({ field, row, otherProps }) => {
	const [loading, setStatus] = useState(false);
	const { state } = useContext(Context);
	const [generatedCode, setGeneratedCode] = useState(false);
	const requestGenerateCode = async () => {
		try {
			const response = await generateCode(state.userType, row.part_contact_id);
			if (response?.data?.status?.code === 1) {
				toast.success(
					response?.data?.data?.messages?.[0]?.[0] || message.SAVED_SUCCESS
				);
				setGeneratedCode(response?.data?.data?.dsaCode);
			}
		} catch (err) {
			let error = err?.response?.data?.messages?.error;
			toast.error(error?.form?.[0] || error?.others?.[0] || message.ERROR_FORM);
		}
	};
	const checkHasPermission = (checkPermission) => {
		// if (state.is_super_admin === 1) return true;
		const permission = state.permission.find(
			(permission) => permission === checkPermission
		);

		if (permission) return true;
		else return false;
	};
	if (
		otherProps?.responseData?.resource?.partner_code &&
		row.part_contact_id &&
		(state.userType === 'affiliate' ? field : true)
	)
		return (
			<div className='d-flex align-items-center'>
				<div className='form-group'>
					{checkHasPermission('partner-code-creation') ? (
						<div>
							<Button type='btn-custom' clickHandler={requestGenerateCode}>
								{loading ? <div className='loader' /> : 'Generate Code'}
							</Button>
						</div>
					) : null}
				</div>
			</div>
		);
	return null;
};

export default GenerateCode;

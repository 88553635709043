import React from 'react';
import { dailyActivity } from '../../services/dashboard';
import objectToQueryParams from '../../common/objectToQueryParams';
const DailyActivities = ({ type }) => {
	const [date, setDate] = React.useState('');
	const [activities, setActivities] = React.useState([]);
	const getDailyActivities = async () => {
		const queryParams = objectToQueryParams({ date });
		const response = await dailyActivity({ type, queryParams });
		if (response?.status?.code === 1) {
			setActivities(response?.data?.activities?.data || []);
		}
	};
	React.useEffect(() => {
		getDailyActivities();
	}, [date, type]);
	return (
		<div className='panel'>
			<div className='head_top'>
				<div className='row  align-items-center '>
					<div className='col-9'>
						<h4>Daily activities</h4>
					</div>
					<div className='col-3'>
						<input
							onChange={e => setDate(e.target.value)}
							type='date'
							className='form-control  custom-textfield'
							placeholder='Date'
						/>
						{/* <select className='form-control custom-textfield float-right'>
							<option>8 July, 2021</option>
							<option>8 June, 2021</option>
							<option>8 May, 2021</option>
							<option>8 April, 2021</option>
							<option>8 March, 2021</option>
						</select> */}
					</div>
				</div>
			</div>
			<div className='p-3'>
				<ul className='listing mb-0'>
					{activities.map(activity => (
						<li key={activity.id}>
							<span className='time'>{activity?.updated_at}</span> - LD
							{(activity?.lead_id || '').toString().padStart(2, '0')} lead
							application is{' '}
							<span className='text-primary'>{activity?.status_name}</span>
						</li>
					))}

					{/* <li>
						<span className='time'>4:26 pm</span> - LD001, LD002 and LD004 were{' '}
						<span className='text-primary'>contacted</span>
					</li>
					<li>
						<span className='time'>4:15 pm</span> - LD011 loan application is{' '}
						<span className='text-danger'>rejected</span>
					</li>
					<li>
						<span className='time'>4:04 pm</span> - LD012 loan application is{' '}
						<span className='text-warning'>on hold</span>
					</li> */}
				</ul>
			</div>
		</div>
	);
};

export default DailyActivities;

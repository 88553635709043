import React from 'react';
import * as ReactModal from 'react-modal';

/**
 *
 * @param {any} children
 * @param {Boolean} state
 * @param {Function} closeModal
 * @param {Object} style
 */
const Modal = ({ children, state, closeModal, style,removeBorderSpace }) => {
	const customStyles = {
		content: {
			top: '50%',
			left: '50%',
			right: 'auto',
			bottom: 'auto',
			marginRight: '-50%',
			transform: 'translate(-50%, -50%)',
			padding: '0px',
			width: '420px',
			...style
		}
	};
	return (
		<ReactModal isOpen={state} onRequestClose={closeModal} style={customStyles}>
			<div className='modal-content'>
				<div className={`modal-body ${removeBorderSpace ? 'p-0 overflow-hidden' : ''} h-100`}>{children}</div>
			</div>
		</ReactModal>
	);
};

export default Modal;

import React from 'react';
import ApplicationTableRow from './ApplicationTableRow';
const ApplicationTable = ({ leads, loading }) => {
	return (
		<div className='panel '>
			<div className='table-responsive '>
				<table className='table table-striped'>
					<thead>
						<tr>
							<th>LAF ID</th>
							<th>Applicant Name</th>
							{/* <th>Email</th> */}
							<th>Mobile Number</th>
							<th>Date Added</th>
							<th>Branch</th>
							<th>Product</th>
							<th>Loan Amt</th>
							<th>Assigned to</th>
							<th>Status</th>
							<th>Action</th>
						</tr>
					</thead>
					<tbody>
						<div className='d-block text-center'>
							{loading && <div className='loader' />}
						</div>
						{leads.map(lead => (
							<ApplicationTableRow key={lead.id} {...lead} />
							// <LeadListing
							//     leadId={leadId}
							//     reloadTable={getLeads}
							//     state={state}
							//     setSelectedLeadId={setSelectedLeadId}
							//     userType={state.userType}
							//     toggleModal={toggleModalState}
							//     {...lead}
							// />
						))}
					</tbody>
				</table>
			</div>
		</div>
	);
};

export default ApplicationTable;

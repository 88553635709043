import React from 'react';
const SelectParameter = ({
	title,
	index,
	value,
	parentTitle,
	type,
	id,
	config,
	defaultValue,
	parentId,
	setConfig,
	sectionTitle,
	defaultSelected,
	globalConfig
}) => {
	const [selected, setSelected] = React.useState(false);
	const [optional, setStatus] = React.useState('false');
	const toggleValue = state => {
		setStatus(state);
		setConfig(values => {
			return {
				...values,
				...config,
				[parentId]: {
					title: parentTitle,
					type,
					...values[parentId],
					...config[parentId],
					[id]: {
						...globalConfig[parentId][id],
						value: state === 'true' ? '1' : '0'
					}
				}
			};
		});
	};
	const removeValue = () => {
		setSelected(false);
		delete config[parentId][id];
		setConfig(config);
		setStatus('false');
	};
	// React.useEffect(() => {
	// 	if (!defaultSelection) {
	// 		setSelected(defaultSelected);
	// 	}
	// }, [defaultSelected]);
	React.useEffect(() => {
		const defaultSelection = value ? Boolean(Number(value)) : false;
		const defaultVal = Boolean(Number(defaultValue));
		if (value) {
			setSelected(true);
			if (defaultSelection) {
				toggleValue('true');
			} else {
				toggleValue('false');
			}
		} else if (!value && defaultVal) {
			setSelected(true);
			toggleValue('true');
		}
	}, []);
	return (
		<tr>
			<td>{!index && sectionTitle}</td>
			<td
				onClick={() => {
					setSelected(true);
					toggleValue('false');
				}}
			>
				{!selected && (
					<>
						{title}
						{'  '} <i className='fa fa-arrow-right' />
					</>
				)}
			</td>
			{/* {defaultValue} */}
			<td onClick={() => !Boolean(Number(defaultValue)) && removeValue()}>
				{selected && (
					<>
						<i className='fa fa-arrow-left' />
						{'  '}
						{title}
					</>
				)}
			</td>
			{selected && (
				<td>
					{
						<div>
							<div>
								<input
									type='radio'
									name={id + 'select'}
									checked={optional === 'false'}
									id={id + 'optional'}
									disabled={Boolean(Number(defaultValue))}
									onChange={e => toggleValue('false')}
								/>
								<label for='optional' className='ml-1'>
									Optional
								</label>
							</div>
							<div>
								<input
									type='radio'
									name={id + 'select'}
									disabled={Boolean(Number(defaultValue))}
									checked={optional === 'true'}
									id={id + 'mandatory'}
									onChange={e => toggleValue('true')}
								/>
								<label for='mandatory' className='ml-1'>
									Required
								</label>
							</div>
						</div>
					}
				</td>
			)}
		</tr>
	);
};

export default SelectParameter;

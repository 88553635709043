import { getCookie } from './cookie';
import config from './config';

export const getPartNotifications = async () => {
	const token = getCookie(btoa('token'));
	// return await (await fetch(`${config.baseUrl.admin}/get-lead-notifications`, {
	// 	headers: {
	// 		Authorization: `Bearer ${token}`,
	// 		...config.defaultHeaders
	// 	}
	// })).json();
};

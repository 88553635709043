import React from 'react';
const PerLogged = ({ body, setBody, error }) => {
	const [perLoggedSwitch, togglePerLoggedSwitch] = React.useState(false);
	const changeStatus = e => {
		if (e.target.checked) {
			body.logged_lead = {};
			setBody(body);
		} else {
			body.logged_lead = undefined;
		}
		togglePerLoggedSwitch(e.target.checked);
		// if (!e.target.checked) setBody({ ...body, logged_lead: undefined });
	};
	React.useEffect(() => {
		if (body.logged_lead) {
			togglePerLoggedSwitch(true);
		}
	}, []);
	return (
		<div className='mx-3 mt-3'>
			<div className='row'>
				<div className='col-md-2'>
					<h6 className='mt-2'>Per Logged Case</h6>
				</div>
				<div className='col-md-3'>
					<label className='switch'>
						<input
							checked={perLoggedSwitch}
							value={perLoggedSwitch}
							onChange={changeStatus}
							className='switch-input'
							type='checkbox'
						/>
						<span className='switch-label' data-on='On' data-off='Off' />{' '}
						<span className='switch-handle' />{' '}
					</label>
				</div>
			</div>
			{perLoggedSwitch && (
				<>
					{' '}
					<div className='col-md-3 p-0'>
						<div className='form-group'>
							<label>
								Type <tt>*</tt>
							</label>
							<select
								value={body?.logged_lead?.amt_type}
								onChange={e =>
									setBody({
										...body,
										logged_lead: {
											...body.logged_lead,
											amt_type: e.target.value
										}
									})
								}
								className='form-control'
							>
								<option value={null}>Select Type</option>
								<option value='fixed'>Fixed Amount</option>
								<option value='percent'>Percentage</option>
							</select>
							{error && (
								<p className='mt-2 mb-0 text-danger'>
									{error?.fields?.['logged_lead.amt_type']?.[0]}
								</p>
							)}
						</div>
					</div>
					<div className='col-md-3'>
						<div className='form-group row'>
							<label htmlFor className=' col-md-4 px-0 col-form-label'>
								Secured <tt>*</tt>
							</label>
							<div className='col-md-8 p-0'>
								<input
									value={body?.logged_lead?.base_payout_sec}
									onChange={e =>
										setBody(value => ({
											...value,
											logged_lead: {
												...value.logged_lead,
												base_payout_sec: e.target.value
											}
										}))
									}
									className='form-control'
									id
									placeholder={
										body?.logged_lead?.amt_type === 'percent'
											? 'Fixed Percentage'
											: 'Fixed Amount'
									}
									type={'number'}
								/>
							</div>
						</div>
						{error && (
							<p className='mt-2 mb-0 text-danger'>
								{error?.fields?.['logged_lead.base_payout_sec']?.[0]}
							</p>
						)}
					</div>
					<div className='col-md-3'>
						<div className='form-group row'>
							<label htmlFor className=' col-md-4 px-0 col-form-label'>
								Unsecured <tt>*</tt>
							</label>
							<div className='col-md-8 p-0'>
								<input
									value={body?.logged_lead?.base_payout_unsec}
									onChange={e =>
										setBody(value => ({
											...value,
											logged_lead: {
												...value.logged_lead,
												base_payout_unsec: e.target.value
											}
										}))
									}
									type={'number'}
									className='form-control'
									id
									placeholder={
										body?.logged_lead?.amt_type === 'percent'
											? 'Fixed Percentage'
											: 'Fixed Amount'
									}
								/>
							</div>
						</div>
						{error && (
							<p className='mt-2 mb-0 text-danger'>
								{error?.fields?.['logged_lead.base_payout_unsec']?.[0]}
							</p>
						)}
					</div>
				</>
			)}
		</div>
	);
};

export default PerLogged;
